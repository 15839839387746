.b__items-fast-filter {
	width: 100%;
	overflow-x: auto;
	
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	
	::-webkit-scrollbar {
		opacity: 0;
		width: 0px;  /* Remove scrollbar space */
		background: transparent;  /* Optional: just make scrollbar invisible */
	}
	
	&::-webkit-scrollbar {
		opacity: 0;
		width: 0px;  /* Remove scrollbar space */
		background: transparent;  /* Optional: just make scrollbar invisible */
	}
  
  .b__profile-content & {
    margin-top: -15px;
    margin-bottom: 15px;
  }
  
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
	  flex-shrink: 0;
    @include clear();
	  white-space: nowrap;
	  
	  display: -webkit-flex;
	  display: -moz-flex;
	  display: -ms-flex;
	  display: -o-flex;
	  display: flex;
	  
    
    li {
      display: block;
		flex-shrink: 0;
      padding: 0 16px;
      height: 25px;
      
      a {
        text-decoration: none;
        font-size: 14px;
        color: #999999;
      }
      
      &.active {
        border-bottom: solid 2px #3399ff;
        a {
          color: #3399ff;
        }
      }
      
      &.has-no-items {
        a {
          color: #d6d6d6;
          cursor: default;
        }
      }
      
    }
  }
  
}

@media (min-width: 768px) and (max-width: 1199px) {
	.b__items-fast-filter {
		
		ul {
			li {
				padding-left: 8px;
				padding-right: 8px;
			}
		}
	}
}

@media (max-width: 767px) {
	.b__items-fast-filter {
		width: calc(100% + 30px);
		margin-left: -15px;
		
		ul {
			padding-left: 15px;
			padding-right: 15px;
			li {
				padding-left: 8px;
				padding-right: 8px;
			}
		}
	}
}