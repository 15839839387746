.b__home-map {
	width: 100%;
	height: 90vh;
	min-height: 700px;
	position: relative;

	#home-map {
		width: 100%;
		height: 100%;

		div {
			will-change: transform;
			transition: none;
		}

		a[title="Click to see this area on Google Maps"] {
			display: none !important;
		}

		.gm-style-cc {
			display:  none !important;
		}

		/* Google map zoom controls */
		.gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
			bottom: 50% !important;
			right: 56px !important;

			> .gmnoprint {
				transform: translateY(-50%);
				> div {
					cursor: pointer;
					user-select: none;
					box-shadow: none !important;
					border-radius: 0 !important;
					background-color: transparent !important;
					width: auto !important;
					height: auto !important;
					> div {
						display: none !important;
					}
				}
			}
			button {
				width: 48px !important;
				height: 48px !important;
				background-color: white !important;
				border-radius: 50% !important;
				box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);

				&:last-child {
					margin-top: 4px !important;
				}

				> div {
					left: 17px !important;
					top: 16px !important;
					img {
						transition: none;
					}
				}
			}
		}

		.marker {
			position: absolute;
			cursor: pointer;
			width: 1px;
			height: 1px;
			z-index: 190;
		}

		.marker-thumb {
			position: absolute;
			font-size: 0;
			margin-left: -28px;
			margin-top: -51px;

			&__icon {
				&-path {
					stroke: white;
					fill: white;
					color: white;
				}
			}

			&__photo {
				border-radius: 50%;
				background-color: white;
				// box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
				position: absolute;
				left: 12px;
				top: 7px;
				width: 33px;
				height: 33px;
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center;
			}
		}
  }
}