.notification {
  padding-top: 15px;
  padding-bottom: 7px;
  .notification-title {
    font-size: 22px;
    font-weight: 600;
    color: rgb(34, 124, 214);
    line-height: 1.2;
    text-align: center;
    margin-bottom: 10px;
  }
  .notification-text {
    font-size: 14px;
    color: rgb(173, 173, 173);
    line-height: 1.286;
    text-align: center;
  }
  .notification-action {
    margin-top: 28px;
  }
}