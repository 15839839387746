.link {
  
  text-decoration: none;
  
  &.link--continue {
    padding: 3px 0;
    font-size: 16px;
    font-weight: 600;
    color: rgb(34, 124, 214);
    line-height: 1.2;
    text-align: center;
    display: inline-block;
    @include vertical-align-content();
    color: #999999;
    
    &:after {
      content: '';
      vertical-align: middle;
      display: inline-block;
  		background-image: url(data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgd2lkdGg9IjEwcHgiIGhlaWdodD0iMThweCI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiAgZmlsbD0icmdiKDM0LCAxMjQsIDIxNCkiIGQ9Ik05Ljk4OSw5LjM3NiBMMS4zNzgsMTcuOTg5IEwwLjAxNSwxNi42MjQgTDcuNjM2LDkuMDAwIEwwLjAxNSwxLjM3NiBMMS4zNzgsMC4wMTEgTDkuOTg5LDguNjI0IEw5LjYxMyw5LjAwMCBMOS45ODksOS4zNzYgWiIvPjwvc3ZnPg==);
      width: 10px;
      height: 18px;
      margin-left: 17px;
      filter: grayscale(1);
      opacity: .7;
      margin-top: -1px;
    }
  }
  
  &.link--more {
    font-size: 16px;
    color: #227cd6;
    font-weight: 400;  
  }
  
  &.link--back {
    
    &:before {
      content: '';
      width: 6px;
      height: 12px;
      display: inline-block;
      background-image: url(data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgd2lkdGg9IjZweCIgaGVpZ2h0PSIxMnB4Ij48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiICBvcGFjaXR5PSIwLjUwMiIgZmlsbD0icmdiKDEwMiwgMTAyLCAxMDIpIiBkPSJNNi4wMTAsMTEuMjg0IEw1LjI4MywxMi4wMTAgTC0wLjAxMCw2LjcxNiBMMC4yMDYsNi41MDAgTC0wLjAxMCw2LjI4NSBMNS4yODMsLTAuMDEwIEw2LjAxMCwwLjcxNiBMMS4xODIsNi40NTYgTDYuMDEwLDExLjI4NCBaIi8+PC9zdmc+);
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      top: 50%;
      left: -20px;
      transform: translateY(-50%);
    }
    
  }
  
}

span.badge {
  display: inline-block;
  position: absolute;
  top:0;
  right: 0;
  transform: translateX(40%) translateY(-50%);
  border: solid 2px white;
  background: $color__main_blue;
  color: white;
  font-size: 10px;
  font-weight: bold;
  font-family: $font__default;
  width: 20px;
  height: 20px;
  line-height: 16px;
  text-align: center;
  border-radius: 50%;
  background-color: rgb(51, 153, 255);
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
}

#change-password {
  color: #333333;
  font-weight: bold;
  text-decoration: none;
  position: relative;
  display: inline-block;
  &:after {
    content: '';
    display: block;
    background-image: url('data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgd2lkdGg9IjEzcHgiIGhlaWdodD0iN3B4Ij48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiICBvcGFjaXR5PSIwLjI1MSIgZmlsbD0icmdiKDEwMiwgMTAyLCAxMDIpIiBkPSJNMTMuMDEwLDAuNzE2IEw2LjcxNiw3LjAxMCBMNi41MDAsNi43OTQgTDYuMjg0LDcuMDEwIEwtMC4wMTAsMC43MTYgTDAuNzE1LC0wLjAxMCBMNi41MDAsNS43NzQgTDEyLjI4NCwtMC4wMTAgTDEzLjAxMCwwLjcxNiBaIi8+PC9zdmc+');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 20px;
    height: 20px;
    position: absolute;
    right: -39px;
    top: 50%;
    transform: translateY(-50%);
  }
}