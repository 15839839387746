.site-container {
	// @include container;
	max-width: 1290px;
	margin-left: auto;
	margin-right: auto;
	padding: 0 38px;


	@include media(phone-only) {
		padding: 0 15px;
	}
}

.site-container-4 {
	text-align: center;
	position: relative;
	z-index: 1;
	width: 450px;
	margin: 0;
	float: none;

	@include center-block;

}

.layout-no_nav {
	.page--signin,
	.page--signup,
	.page--notification {
		display: table-cell;
		vertical-align: middle;
	}

	#layout-content {
		width: 100%;
		height: 100%;
		display: table;
	}
}

#layout-content {
	//min-height: calc(100% - 677px);
	min-height: calc(100vh - 298px);
}

#widthTempOption {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    left: -200%;
    top: -100%;
    font-size: 16px;
    font-weight: 400;
    padding-right: 20px;
    width: auto;
    white-space: nowrap;
	transition: none !important;
	
}


#postings_container {
	background-repeat: no-repeat;
	background-size: 60px auto;
	background-color: #f9f9f9;
	
	background-position: center center;
	
	background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzgiIGhlaWdodD0iMzgiIHZpZXdCb3g9IjAgMCAzOCAzOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBzdHJva2U9IiMyNzkzZTAiPiAgICA8ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMSAxKSIgc3Ryb2tlLXdpZHRoPSIyIj4gICAgICAgICAgICA8Y2lyY2xlIHN0cm9rZS1vcGFjaXR5PSIuNSIgY3g9IjE4IiBjeT0iMTgiIHI9IjE4IiAvPiAgICAgICAgICAgIDxwYXRoIGQ9Ik0zNiAxOGMwLTkuOTQtOC4wNi0xOC0xOC0xOCI+ICAgICAgICAgICAgICAgIDxhbmltYXRlVHJhbnNmb3JtIGF0dHJpYnV0ZU5hbWU9InRyYW5zZm9ybSIgdHlwZT0icm90YXRlIiBmcm9tPSIwIDE4IDE4IiB0bz0iMzYwIDE4IDE4IiBkdXI9IjFzIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIgLz4gPC9wYXRoPiAgICAgICAgPC9nPiAgICA8L2c+PC9zdmc+);
	
	> * {
		pointer-events: none;
		opacity: 0 !important;
		transition: opacity 0.4s ease-in-out;
	}
	
	&.loaded {
		background-image: none;
		
		> * {
			pointer-events: all;
			opacity: 1 !important;
		}
	}
}




@media (max-width: 767px) {
	.site-container-4 { 
		width: 100%;
		max-width: none;
	}
	
	.layout-no_nav {
		.page--signin,
		.page--signup,
		.page--notification {
			.b__panel {
				border-radius: 0;
			}
		}
	}
}