.b__items_item {
  margin-bottom: gutter();
  background: white;
  border-radius: 4px;
  overflow: hidden;
  @include trans(box-shadow);

  .b__items_item * {
    transition: none !important;
  }
  
  .btn {
    white-space: nowrap;
    
  }
  

  &.b__items_item--no-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
    height: 150px;
    margin: 0 !important;
  }

  &:hover {
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.05);
  }
  
  .item-title {
    a {
      text-decoration: none;
      font-size: 16px;
      font-weight: 600;
      color: $color__main_gray;
    }
  }    
}

.b__widget-items {
  margin-top: 45px;
  
  .b__title {
    text-align: left;
    margin-bottom: 10px;
  }
}