.b__profile {
	padding-bottom: 90px;

	&-header {
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		background-repeat: no-repeat;
		background-color: $color__main_gray;
		height: 175px;

		img {
			display: block;
			height: 100%;
			width: 100%;
			opacity: .25;
			object-fit: cover;
		}
	}

	&-inner {
		min-height: 500px;

		.site-container {
			@include clear();
		}
	}

	&-card {
		width: span(3);
		float: left;
		margin-top: -60px;
		margin-bottom: 0;
		margin-left: 0;
		margin-right: 0;
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;
		position: relative;
		text-align: center;

		&:before {
			content: '';
			display: block;
			width: 148px;
			height: 148px;
			border-radius: 50%;
			background-color: white;
			opacity: .25;
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
			z-index: 0;
		}

		&:after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			height: 100px;
			background: white;
			z-index: 0;
			border-radius: 6px;
		}

		& > div {
			position: relative;
			z-index: 1;
		}

		&_photo {
			width: 140px;
			height: 140px;
			background-color: #f9f9f9;
			margin-top: -70px;
			margin-bottom: 15px;
			border-radius: 50%;
			@include center-block;
			position: relative;

			.responsiv-uploader-fileupload.style-image-single {
				display: block;
				width: 140px;
				height: 140px;

				.upload-button {
					display: block;
					border: 0;
					width: 140px !important;
					height: 140px !important;

					&:hover {
						border: 0;
					}

					i {
						display: none;
					}
				}

				.upload-files-container,
				.upload-object {
					max-height: 140px;
					height: 140px;
					padding: 0;
					width: 140px;
					max-width: 140px;
				}
			}

			.responsiv-uploader-fileupload.style-image-single .upload-object .info {
				height: auto;
				top: 0;
			}

			.responsiv-uploader-fileupload .upload-object p.size {
				display: none;
			}

			.responsiv-uploader-fileupload .upload-object h4 {
				height: 100%;
				width: 100%;
				margin: 0;
				padding: 0;
				opacity: 0;
				position: relative;
				background-color: rgba(0, 0, 0, 0.75);
				border-radius: 50%;
				transition: opacity 500ms ease-out 0s;

				a {
					&.upload-remove-button {
						display: block !important;
						opacity: 1 !important;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translateY(-50%) translateX(-50%);
						text-indent: -9999px;
						overflow: hidden;
						width: 14px;
						height: 18px;
						background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 14 18' style='enable-background:new 0 0 14 18;' xml:space='preserve'%3E%3Crect style='fill: %23FFFFFF' x='3.8' y='6.9' width='1.3' height='8.3'/%3E%3Crect style='fill: %23FFFFFF' x='6.4' y='6.9' width='1.3' height='8.3'/%3E%3Crect style='fill: %23FFFFFF' x='8.9' y='6.9' width='1.3' height='8.3'/%3E%3Cpath style='fill: %23FFFFFF' d='M13.4,4.2V3.5c0-0.4-0.3-0.7-0.6-0.7h-2.6c0-0.1,0-0.2,0-0.2V2.1C10.2,0.9,9.3,0,8.3,0H5.7 c-1,0-1.9,0.9-1.9,2.1v0.5c0,0.1,0,0.2,0,0.2H1.3c-0.3,0-0.6,0.3-0.6,0.7v0.7H0v1.4h1.3v10.4c0,1.1,0.9,2.1,1.9,2.1h7.6 c1,0,1.9-0.9,1.9-2.1V5.5H14V4.2H13.4z M5.1,2c0-0.4,0.3-0.7,0.6-0.7h2.5c0.3,0,0.6,0.3,0.6,0.7v0.7H5.1V2z M11.5,15.9 c0,0.4-0.3,0.7-0.6,0.7H3.2c-0.4,0-0.6-0.3-0.6-0.7V5.5h8.9V15.9z'/%3E%3C/svg%3E%0A");
						background-position: center center;
						background-repeat: no-repeat;
					}
				}
			}

			.responsiv-uploader-fileupload .upload-object:hover {
				h4 {
					opacity: 1;
				}
			}

			.responsiv-uploader-fileupload .upload-object h4 span {
				display: none;
			}

			.responsiv-uploader-fileupload.style-image-single .upload-object .icon-container.image img {
				border-radius: 50%;
			}

			.responsiv-uploader-fileupload.style-image-single .upload-object .icon-container {
				width: 140px;
				height: 140px;
				border: 0;
				background-color: transparent;

				img {
					max-width: 140px;
					max-height: 140px;
				}
			}

			img {
				display: block;
				width: 100%;
				height: 100%;
				border-radius: 50%;
				object-fit: cover;
				cursor: pointer;
			}

			&.b__profile-card_photo-hint {
				display: none;
			}

			&.no-photo {
				margin-bottom: 55px;

				&:after {
					content: 'Upload photo';
					font-size: 12px;
					color: #999999;
					position: absolute;
					left: 0;
					right: 0;
					bottom: -30px;
				}

				.b__profile-card_photo-hint {
					display: block;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translateX(-50%) translateY(-50%);
					width: 56px;
					height: 40px;

					.icon {
						width: 56px;
						height: 40px;
						fill: #c7c7c7;
						color: #c7c7c7;
					}
				}

				&:hover {
					.b__profile-card_photo-hint {
						.icon {
							fill: #999;
							color: #999;
						}
					}
				}

				.b__profile-card_photo-upload {
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					border-radius: 50%;

					input {
						border-radius: 50%;
						display: block;
						width: 100%;
						height: 100%;
						opacity: 0;
						cursor: pointer;
					}
				}

				.b__profile-card_photo-controls {
					display: none;
				}
			}
		}

		&_name {
			font-size: 23.47px;
		}

		&_place {
			font-size: 16px;
			color: rgb(153, 153, 153);
			line-height: 1.2;
			margin-top: 5px;
		}

		&_meta {
			@include clear();
			border-top: solid 1px #f0f0f0;
			border-bottom: solid 1px #f0f0f0;
			margin-top: 24px;

			strong {
				padding-top: 10px;
				display: block;
				font-size: 32px;
				color: #414141;
				font-weight: bold;
			}
			span {
				padding-bottom: 14px;
				font-size: 12px;
				color: #999999;
				display: block;
			}
			&-items {
				width: calc(50% - 1px);
				float: left;
			}
			&-points {
				width: calc(50% + 1px);
				float: right;
				border-left: solid 1px #f0f0f0;
			}
		}
		&_verify {
			margin: 20px 0;
			padding: 0 20px;
			font-size: 12px;
			a {
				color: $color__main_blue;
			}
			form {
				margin-top: 8px;
			}
		}
		&_about {
			margin-top: 22px;
			font-size: 12px;
			//    color: #999999;
			color: rgb(65, 65, 65);
			line-height: 1.833;
			padding-left: 20px;
			padding-right: 20px;
			text-align: center;
			padding-bottom: 15px;
		}
		&_actions {
			margin-top: 10px;
			padding: 0 20px;
		}
		.b__messanger-peer & {
			margin-top: 0;
			width: 100%;
			float: none;
			padding-top: 48px;
			&:before,
			&:after {
				content: none;
			}
			&>div {
				z-index: auto;
			}
			&_actions {
				&:first-child {
					display: none;
					margin-top: 16px;
					margin-bottom: 11px;
					text-align: right;
				}
			}
			&_photo {
				margin-top: 0;
			}
		}
	}

	&-content {
		width: span(9 of 12);
		margin-left: gutter();
		float: left;
		.b__title {
			margin-bottom: 15px;
		}
	}

	&-users {
		padding-bottom: 50px;
		
		.b__users.b__users--grid {
			width: 100%;
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			flex-wrap: wrap;
			
			.b__users_item {
				margin-right: 20px;
			}
		}
	}

	&-items {
		padding-bottom: 90px;
	}

	&--edit {
		.b__profile-content {
			padding-top: 40px;

			.form {
				width: span(8 of 12);
				float: left;

				.b__panel {
					padding-left: 30px;
					padding-right: 30px;
					padding-bottom: 30px;
				}

				.field {
					&:after {
						content: '';
						clear: both;
						display: block;
					}

					&+.field {
						margin-top: 25px;
					}
				}

				.field--text,
				.field--select,
				.field--password {
					width: 50%;

					input {
						height: 42px;
						font-size: 15px;
					}

					.selectize-input {
						height: 42px;

						input {
							height: 38px;
							background: transparent;
						}

						&>* {
							font-size: 15px !important;
						}
					}
				}

				.field--submit {
					width: 50%;

					.btn {
						height: 46px;
					}
				}

				label {
					font-size: 14px;
					font-weight: 500;
					margin-bottom: 5px;
					display: block;
				}
			}
		}
	}
}

.profile-navigation {
	margin-top: -60px;
	padding-bottom: 45px;

	ul {
		padding: 0;
		margin: 0;
		list-style: none;
		@include clear();

		li {
			height: 60px;
			display: block;
			margin-right: 50px;
			float: left;
			position: relative;
			@include vertical-align-content();

			a {
				font-size: 18px;
				font-weight: 600;
				color: rgb(153, 153, 153);
				line-height: 1.2;
				text-decoration: none;
			}

			&.active {
				&:after {
					content: '';
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 11px 11px 11px;
					border-color: transparent transparent #f9f9f9 transparent;
					position: absolute;
					bottom: 0;
					left: 50%;
					transform: translateX(-50%);
				}

				a {
					color: white;
				}
			}
		}
	}
}

.user-profile-image-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.9);
	z-index: -1;
	opacity: 0;
	visibility: hidden;
	transform: scale(1.4);
	transition: all 250ms ease-in-out, z-index 0s linear 250ms;
	display: block;

	&.is-visible {
		z-index: 9999;
		opacity: 1;
		visibility: visible;
		transform: scale(1);
		transition: all 250ms ease-in-out;
	}

	button {
		display: block;
		position: absolute;
		top: 20px;
		right: 20px;
		width: 40px;
		height: 40px;
		border-radius: 0;
		border: none;
		text-align: center;
		cursor: pointer;
		padding: 0;
		font-size: 0;
		background-color: transparent;
		transform-origin: center;
		transition: transform 200ms ease-in-out;

		&:hover {
			transform: scale(1.1);
		}

		.icon {
			fill: white;
			color: white;
		}
	}

	img {
		width: auto;
		max-width: 80%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

@media (min-width: 768px) and (max-width: 1199px) {
	.b__profile {
		&-inner {
			.site-container {
				display: -webkit-flex;
				display: -moz-flex;
				display: -ms-flex;
				display: -o-flex;
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				align-items: flex-start;
			}
		}
		&-card {
			float: none;
			width: 250px;
			flex-shrink: 0;
		}
		
		&--edit {
			.b__profile {
				&-content {
					.form {
						float: none;
						width: 100%;
					}
				}
			}
		}
		
		&-content {
			width: calc(100% - 270px);
		}
	}
}

@media (min-width: 768px) and (max-width: 1000px) {
	.b__profile {
		&-users {

			.b__users.b__users--grid {

				.b__users_item {
					width: 30%;
					margin-right: 5%;
					
					&:nth-child(3n) {
						margin-right: 0;
					}
				}
			}
		}
	}
}


@media (max-width: 767px) {
	.profile-navigation {
		margin-top: 0;
		width: calc(100% + 30px);
		margin-left: -15px;
		
		overflow-x: auto;
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		
		ul {
			flex-shrink: 0;
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			padding-left: 5px;
			padding-right: 5px;
			
			li {
				float: none;
				flex-shrink: 0;
				height: auto;
				margin-right: 0;
				padding-left: 10px;
				padding-right: 10px;
				
				a {
					font-size: 14px;
				}
				
				&.active {
					&:after {
						display: none !important;
					}
					a {
						color: #000;
					}
				}
			}
		}
	}
	.b__profile {
		&-inner {
			.site-container {
				display: block;
			}
		}
		&-items {
		padding-bottom: 0;
		}
		&-card {
			float: none;
			width: 100%;
			flex-shrink: 0;
			padding-top: 1px;
			
			margin-bottom: 40px;
		}
		
		&-content {
			float: none;
			width: 100%;
			margin-left: 0;
			padding-top: 0;
		}
		
		&-users {

			.b__users.b__users--grid {

				.b__users_item {
					width: 50%;
					margin-right: 0;
					padding-left: 10px;
					padding-right: 10px;
					margin-bottom: 20px;
				}
			}
		}
		
		&--edit {
			.b__profile {
				&-content {
					float: none;
					width: 100%;
					margin-left: 0;
					padding-top: 0;
					
					.form {
						float: none;
						width: 100%;

						.field--text, .field--select, .field--password {
							width: 100%;
						}
						
						.b__panel {
							padding-left: 20px;
							padding-right: 20px;
						}
					}
				}
			}
		}
	}
}
