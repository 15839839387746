.b__items.b__items--listing {
	height: calc(100% - 115px);
	position: relative;
	padding-right: 0;
	margin-right: -20px;
	
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	flex-wrap: wrap;
	
	width: calc(100% + 28px);
	box-sizing: border-box;
	
	
	#item-list {
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		flex-wrap: wrap;
		width: 100%;
	}
	
	&.no-scroll {
		height: auto;
		max-height: none;
	}

	.b__items_item {
		width: calc(50% - 28px);
		margin-left: 0;
		margin-right: 28px;
		height: 175px;
		position: relative;


		.item-thumbnail {
			width: 212px;
			float: left;
			height: 100%;
			flex-shrink: 0;


			a {
				display: block;
				font-size: 0;
				height: 100%;
				width: 100%;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			.item-university {
				display: none;
			}
		}

		.item-university {
			width: 80px;
			height: 38px;
			text-align: center;
			font-size: 10px;
			font-family: $font__special;
			color: white;
			position: absolute;
			transform: rotate(45deg);
			right: -26px;
			top: -6px;
			line-height: 60px;

			span {
				display: inline-block;
				vertical-align: middle;
			}
		}

		.item-content {
			flex-grow: 3;
			flex-shrink: 1;
			width: calc(100% - 212px);
			height: 100%;
			float: right;
			position: relative;
			padding-left: 15px;
			padding-right: 15px;
			padding-top: 20px;
			padding-bottom: 20px;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
		}

		.item-title {
			width: calc(100% - 60px);
			margin-bottom: 8px;
		}

		.item-description {
			width: calc(100% - 20px);
			font-size: 12px;
			font-weight: 400;
		}

		.item-place {
			width: 25px;
			height: 33px;
			position: absolute;
			right: 19px;
			top: 18px;
			display: none;

			span {
				font-size: 14px;
				text-align: center;
				font-weight: 600;
				color: #a81c30;
				position: absolute;
				left: 1px;
				right: 2px;
				top: 4px;
			}
		}
		
		.item-author-price {
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-end;
			
			margin-top: auto;
			width: 100%;
		}

		.item-author {
			width: 50%;
			
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: flex-start;

			&_photo {
				margin-right: 12px;
				width: 33px;
				height: 33px;
				flex-shrink: 0;
				border: solid 1px #cccccc;
				border-radius: 50%;
				overflow: hidden;
				text-align: center;
				line-height: 28px;

				img {
					width: 31px;
					height: 31px;
					object-fit: contain;
				}

				.icon {
					fill: #cccccc;
					color: #cccccc;
					width: 60%;
					height: 60%;
					display: inline-block;
					vertical-align: middle;
				}
			}

			&_description {
				flex-grow: 3;
				flex-shrink: 1;
				font-size: 12px;
				line-height: 1.4;
			}

			&_name {
				a {
					text-decoration: none;
					color: inherit;
				}
			}

			&_place {
				color: #999999;
			}
		}

		.item-price {
			position: relative;
			flex-grow: 3;
			flex-shrink: 1;
			
			width: 50%;
			padding-left: 10px;
			
			
			.item-meta {
				position: absolute;
				bottom: -12px;
				right: 0;
			}
		}
	}
}

.b__items.ps-container {
	margin-right: -20px;
	padding-right: 20px;
	height: calc(100% - 115px);
}

@media (max-width: 1199px) {
	.b__items.b__items--listing {
		height: auto;
		max-height: none;
		
		&.no-scroll {
			height: auto;
			max-height: none;
		}
	}
}

@media (max-width: 1030px) {
	.b__items.b__items--listing {
		height: auto;
		
		.b__items_item {
			width: calc(33.33% - 28px);
			margin-right: 28px;
			height: auto;
			
			.item-thumbnail {
				width: 100%;
				float: none;
				height: 210px;
			}
			
			.item-content {
				float: none;
				width: 100%;
				height: auto;
				height: calc(100% - 210px);
				
				> * {
					flex-shrink: 0;
				}
			}
			
			.item-description {
				width: 100%;
				margin-bottom: 15px;
			}
			
			.item-author-price {
				margin-top: auto;
				width: 100%;
				display: block;
			}
			
			.item-author {
				margin-bottom: 16px;
				width: 100%;
			}
			
			.item-price {
				width: 100%;
			}
		}
	}
}

@media (min-width: 768px) and (max-width: 1030px) {
	.b__items.b__items--listing {
		
		
	}
}


@media (max-width: 767px) {
	.b__items.b__items--listing {
		height: auto;
		margin: 0;
		width: 100%;
		padding-right: 0;
		padding-top: 20px;
		
		.b__items_item {
			width: 100%;
			height: auto;
			margin-right: 0;
			
			.item-thumbnail {
				width: 100%;
				height: 210px;
				position: relative;
				font-size: 0;

				a:not(.link--edit) {
					display: block;
					height: 100%;
				}

				.link--edit {
					position: absolute;
					font-size: initial;
				}

				img {
					display: block;
					width: 100%;
					height: 100%;
					object-fit: cover;
				}

				.item-university {
					display: none;
				}
			}

			.item-university {
				width: 80px;
				height: 38px;
				text-align: center;
				font-size: 10px;
				font-family: $font__special;
				color: white;
				position: absolute;
				transform: rotate(45deg);
				right: -26px;
				top: -6px;
				line-height: 60px;
				z-index: 1;

				span {
					display: inline-block;
					vertical-align: middle;
				}
			}

			.item-content {
				width: 100%;
				height: calc(100% - 210px);
				position: relative;
				padding-left: 18px;
				padding-right: 18px;
				padding-top: 15px;
				padding-bottom: 15px;
				box-sizing: border-box;

				display: flex;
				flex-direction: column;
			}

			.item-title {
				margin-bottom: 8px;
			}

			.item-description {
				font-size: 12px;
				font-weight: 400;
				width: 100%;
				margin-bottom: 15px;
			}

			.item-place {
				width: 25px;
				height: 33px;
				position: absolute;
				right: 19px;
				top: 18px;
				display: none;

				span {
					font-size: 14px;
					text-align: center;
					font-weight: 600;
					color: #a81c30;
					position: absolute;
					left: 1px;
					right: 2px;
					top: 4px;
				}
			}

			.item-author-price {
				margin-top: auto;
				width: 100%;
				display: block;
			}

			.item-author {
				width: 100%;
				margin-bottom: 16px;
				position: relative;

				&_photo {
					margin-right: 12px;
					width: 33px;
					height: 33px;
					float: left;
					border: solid 1px #cccccc;
					border-radius: 50%;
					overflow: hidden;
					text-align: center;
					line-height: 28px;

					img {
						width: 31px;
						height: 31px;
						object-fit: cover;
					}

					.icon {
						fill: #cccccc;
						color: #cccccc;
						width: 60%;
						height: 60%;
						display: inline-block;
						vertical-align: middle;
					}
				}

				&_description {
					float: left;
					font-size: 12px;
					line-height: 1.4;
				}

				&_name {
					a {
						text-decoration: none;
						color: inherit;
					}
				}

				&_place {
					color: #999999;
				}
			}

			.item-price {
				width: 100%;
				position: relative;
				margin-bottom: 0;
			}
		}
	}
}