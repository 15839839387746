.chat-items {
  padding: 15px;
  position: relative;
//  left: 0;
//  right: 0;
//  bottom: 0;
  margin-right: 0;
  
  .ps-container:not(.ps-active-y) & {
    position: absolute;
  }
  
  .chat-item {
    position: relative;
    padding: 5px 0;
    @include clear();
    
    &_author-photo {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      overflow: hidden;
      float: right;
      margin-left: 10px;
      
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    
    &_body {
      float: right;
      padding: 12px 15px 13px 19px;
      font-size: 13px;
      overflow: hidden;
      color: white;
      font-weight: 500;
      max-width: calc(100% - 50px);
      border-radius: 4px;
      background-color: rgb(51, 153, 255);
    }
   
    &.chat-item--self {
      
      .chat-item_author-photo {
        float: left;
        margin-left: 0;
        margin-right: 10px;
      }
      .chat-item_body {
        float: left;
        background-color: white;
        color: #414141;
      }
    }
   
    &.chat-item--date {
      text-align: center;
      font-size: 10px;
      color: rgb(153, 153, 153);
      line-height: 1.2;
      padding: 9px 0 2px;
    }
    
  }
  
}