body {
	&.fixed-navigation:not(.page--main) {
		padding-top: 80px;
	}
	&.page-messenger {
		min-height: 100vh;
		height: 100vh;
		overflow: hidden;
	}
	&.page-signin,
	&.page-signup,
	&.page-verify,
	&.page-verify-code,
	&.page-verify-school,
	&.page-home-short,
	&.page-forgot-password{
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		background-attachment: fixed;
		
		position: relative;
		
		padding-bottom: 50px;
		padding-top: 50px;
		height: auto;
		
		.b__panel-after {
			color: #fff;

			a {
				color: #fff;
			}
		}
		
		@include media(phone-only) {
			padding-top: 30px;
			padding-bottom: 30px;
		}
		&:before {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			height: 157px;
			z-index: 0;
			background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
			background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
			background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
		}
	}
	&.page-signin {
		background-image: url(../images/bg__signin.jpg);
	}
	&.page-signup {
		background-image: url(../images/bg__signup.jpg);
	}
	&.page-verify,
	&.page-verify-code,
	&.page-verify-school {
		background-image: url(../images/bg__notification.jpg);
	}
	&.page-forgot-password {
		background-image: url(../images/bg__signin.jpg);
	}
	
	&.page--ambassador-signup {
		.b__ambassador-signup {
			background: url(../images/bg__ambassador-signup.jpg) center center no-repeat;
			background-size: cover;
			position: relative;
			&:before {
				content: '';
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				height: 157px;
				z-index: 0;
				background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
				background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
				background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
			}
		}
	}
	&.page-postings {
		.b__content {
			position: relative;
			padding-bottom: 0;
			padding-top: 0;
			.site-container {
				padding-top: 25px;
				height: calc(100vh - 80px);
				box-sizing: border-box;
				.b__items-container {
					height: 100%;
				}
				.b__items-container.has-map {
					height: calc(100% + 25px);
					+ .b__items-map {
						height: 100%;
					}
				}
			}
		}
		footer#layout-footer {
			display: none;
		}
	}
}

.b__main-header {
	height: 380px;
	background: url(../images/bg__header.jpg) center top no-repeat;
	background-size: cover;
	margin-top: -80px;
	&:before {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		height: 157px;
		z-index: 0;
		background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
		background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
	}
}

.page--signup {
	
}

@include media-breakpoint-down(sm) {
	body {
		&.fixed-navigation:not(.page--main) {
			padding-top: 50px;
		}
	}
}


@media (max-width: 767px) {
	body.page-postings .b__content .site-container {
		height: auto;
	}
	
	body.page-messenger {
		min-height: 0;
		overflow: unset;
		height: auto;
	}
}
