.b__feed {
	padding-top: 40px;
	padding-bottom: 55px;

	.site-container {
		@include clear;
		display: flex;
		flex-direction: row;
	}

	&-filters {
		width: span(3);
		float: left;
		padding-top: 39px;
	}

	div[id*=-container] {
		width: span(6);
		margin-left: gutter();

		.b__title {
			text-align: left;
		}
	}

	&-suggestion {
		width: span(3);
		margin-right: 0;
		margin-left: gutter();

		.b__title {
			text-align: center;
			justify-content: center;
		}

		:root & .b__users_item:last-child {
			margin-bottom: 0;
		}
	}

	.b__title {
		margin-bottom: 18px;
	}

	.input-search {
		margin-bottom: 18px;
		border-radius: 4px;
		background-color: rgb(255, 255, 255);
		height: 52px;
		position: relative;

		button {
			position: absolute;
			z-index: 2;
			font-size: 0;
			border: 0;
			background-color: transparent;
			left: 14px;
			top: 50%;
			transform: translateY(-50%);
			padding: 0;
			margin: 0;

			svg {
				width: 26px;
				height: 26px;
				fill: #efefef;
				color: #efefef;
			}
		}

		input {
			width: 100%;
			height: 100%;
			padding-left: 51px;
			z-index: 1;
			font-size: 14px;
			font-weight: 500;
			border: 0;
			background-color: transparent;
			color: rgb(51, 51, 51);
			box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.01);
			transition: all 300ms ease-out 0s;

			&:hover {
				box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
			}

			&:focus {
				box-shadow: 0px 5px 3px 0px rgba(0, 0, 0, 0.05);
			}

			&::-webkit-input-placeholder {
				color: #efefef;
			}

			&:-moz-placeholder {
				color: #efefef;
			}

			&::-moz-placeholder {
				color: #efefef;
			}

			&:-ms-input-placeholder {
				color: #efefef;
			}
		}
	}

	.input-place {
		margin-bottom: 18px;
		border-radius: 4px;
		background-color: rgb(255, 255, 255);
		box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.01);
		position: relative;
		height: 52px;
		transition: all 300ms ease-out 0s;

		&:hover {
			box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
		}

		&:focus {
			box-shadow: 0px 5px 3px 0px rgba(0, 0, 0, 0.05);
		}

		input {
			&::-webkit-input-placeholder {
				color: #efefef;
			}

			&:-moz-placeholder {
				color: #efefef;
			}

			&::-moz-placeholder {
				color: #efefef;
			}

			&:-ms-input-placeholder {
				color: #efefef;
			}
		}

		svg {
			width: 22px;
			height: 30px;
			fill: #efefef;
			color: #efefef;
			position: absolute;
			left: 16px;
			top: 50%;
			transform: translateY(-50%);
		}

		.selectize-control {
			height: 100%;
			font-size: 14px;
			font-weight: 500;
		}

		.selectize-dropdown {
			margin-top: 17px;
			position: absolute;
			background: white;
			box-sizing: border-box;
			box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
			border-radius: 6px;
			border-width: 0;
			white-space: normal;
			z-index: 150;

			&:after {
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 7px 7px 7px;
				border-color: transparent transparent #ffffff transparent;
				position: absolute;
				top: -7px;
				right: 15px;
				left: auto;
			}

			&-content {
				padding: 15px 27px;

				> div {
					font-size: 13px;
					font-weight: 500;
					line-height: 2.1;
					transition: color 100ms ease-out 0s;

					* {
						transition: color 100ms ease-out 0s;
					}

					&:hover {
						color: #227cd6;
					}
				}
			}
		}

		.selectize-input {
			height: 100%;
			padding-left: 51px;

			@include vertical-align-content;

			.item {
				display: inline-block;
			}

			input {
				border: 0;
			}
		}
	}

	.list-type {
		padding: 0;
		margin: 0;
		list-style: none;

		li {
			position: relative;
			line-height: 2.571;
			padding-left: 51px;

			svg {
				position: absolute;
				width: 20px;
				height: 20px;
				left: 18px;
				top: 50%;
				fill: #efefef;
				color: #efefef;
				transform: translateY(-50%);
			}

			input {
				visibility: hidden;
				position: absolute;
				z-index: -1;

				+ label {
					font-weight: 500;
					font-size: 14px;
					color: rgb(169, 169, 169);
					cursor: pointer;
				}

				&:checked + label {
					color: #333333;
				}
			}
		}
	}

	&-item {
		border-radius: 6px;
		background-color: white;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 24px;
		overflow: hidden;

		+ .b__feed-item {
			margin-top: 20px;
		}

		&--post {
			position: relative;

			.total-comments {
				margin-top: 8px;
			}
		}

		&__title {
			padding-top: 15px;
			padding-bottom: 13px;
			height: 35px;
			box-sizing: content-box;
			display: table;
			font-size: 0;

			.author-photo {
				border: solid 1px #cccccc;
				width: 35px;
				height: 35px;
				border-radius: 50%;
				overflow: hidden;
				display: inline-block;
				margin-right: 11px;

				img {
					display: block;
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			span.title {
				font-size: 12px;
				font-weight: 400;
				color: #bbbbbb;
				display: table-cell;
				width: 100%;
				vertical-align: middle;

				.author-name,
				.author-in {
					display: inline-block;
					font-weight: 600;
					color: #333333;
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}

		.b__items_item {
			overflow: initial;
			display: flex;
			margin-bottom: 0;

			@include clear;

			&:hover {
				box-shadow: none;
			}

			.item-thumbnail {
				width: 50%;
				font-size: 0;
			}

			.item-content {
				width: calc(50% - 26px);
				margin-left: auto;
				position: relative;
				padding-bottom: 48px;

				.author-comment {
					font-size: 15px;
					color: #333;
				}
			}

			.item-title {
				margin-bottom: 12px;
				margin-top: -4px;

				a {
					font-size: 18px;
				}
			}

			.item-meta {
				height: auto;
//				position: absolute;
//				bottom: -15px;
//				left: 0;
//				right: 0;
				text-align: right;
			}

			.item-share {
				display: inline-block;
				font-weight: 600;
				color: #bbbbbb;
				font-size: 14px;
				text-decoration: none;

				&:hover {
					color: #227cd6;
				}
			}

			.item-price {
				margin-left: 0;
				display: inline-block;
			}

			.item-description {
				font-size: 15px;
				line-height: 24px;
			}

			.total-comments {
				display: inline-block;
				font-weight: 600;
				color: #bbbbbb;
				font-size: 14px;
				text-decoration: none;
				float: left;
				margin-top: 8px;
			}

			.item-content-links {
				text-align: right;
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;

				.total-comments {
					margin-top: 2px;
				}

				.reply {
					display: inline-block;
					font-weight: 600;
					color: #bbbbbb;
					font-size: 14px;
					text-decoration: none;

					&:hover {
						color: #227cd6;
					}
				}
			}
		}

		.item-university {
			position: absolute;
			height: 40px;
			width: 100px;
			transform: rotate(45deg);
			right: -35px;
			top: -5px;
			z-index: 10;

			span {
				font-family: 'Ahellya';
				font-size: 10px;
				color: white;
				text-align: center;
				display: inline-block;
				position: absolute;
				bottom: 1px;
				left: 0;
				right: 0;
			}
		}

		&.b__feed-item--comment {
			.item-thumbnail {
				position: relative;
				overflow: hidden;
				margin-top: 15px;
				width: 29.491525423728817%;
			}

			.item-content {
				width: calc(100% - 29.491525423728817% - 26px);
			}
		}
	}

	.b__items-controls {
		text-align: center;
	}
}

.b__feed-suggestion {
	.b__users.b__users--grid .b__users_item {
		width: 50%;
		height: auto;
		padding-left: 10px;
		padding-right: 10px;
		margin-bottom: 30px;
		
		.user-pic {
			width: 110px;
			height: 110px;
		}
	}
	
	.b__users.b__users--grid {
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		flex-wrap: wrap;
		
	}
}

@media (min-width: 768px) and (max-width: 1199px)  {
	.b__feed {
		.site-container {
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			align-items: flex-start;
		}
		
		div[id*=-container] {
			width: calc(100% - 278px);
			float: none;
		}
		
		&-suggestion {
			float: none;
			width: 100%;
			margin-left: 0;
			
			margin-top: 40px;
			
			
		}
		
		&-filters {
			float: none;
			width: 250px;
		}
	}
}

@media (max-width: 767px)  {
	.b__feed {
		
		.site-container { 
			display: block;
		}
		
		div[id*=-container] {
			width: 100%;
			float: none;
			margin-left: 0;
			margin-bottom: 40px;
		}
		
		&-suggestion {
			float: none;
			width: 100%;
			margin-left: 0;
			
			
		}
		
		&-filters {
			float: none;
			width: 100%;
			margin-bottom: 40px;
			padding-top: 0;
		}
	}
	
	.b__feed-item {
		padding-left: 0;
		padding-right: 0;
		padding-top: 0;
		padding-bottom: 14px;
	}
	
	.b__feed-item .b__items_item {
		display: block;
		width: 100%;
	}
	
	.b__feed-item__title {
		padding-left: 15px;
		padding-right: 30px;
	}
	
	.b__feed-item .b__items_item .item-thumbnail {
		width: 100%;
		height: 150px;
		margin-top: 0;
		
		a {
			display: block;
			width: 100%;
			height: 100%;
			
			img {
				display: block;
				width: 100%;
				height: 100%;
				
				object-fit: cover;
			}
		}
	}
	
	.b__feed-item.b__feed-item--comment .item-thumbnail {
		width: 100%;
		height: 150px;
		margin-top: 0;
		
		a {
			display: block;
			width: 100%;
			height: 100%;
			
			img {
				display: block;
				width: 100%;
				height: 100%;
				
				object-fit: cover;
			}
		}
	}
	
	.b__feed-item .b__items_item .item-content-links {
		width: 100%;
		
		position: relative;
		top: auto;
		bottom: auto;
		left: auto;
		right: auto;
	}
	
	.b__feed-item .b__items_item .item-content {
		width: 100%;
		padding-bottom: 0;
		padding-left: 15px;
		padding-right: 15px;
		padding-top: 15px;
		margin: 0;
		
		.b__feed-item__title {
			padding-left: 0;
			padding-right: 0;
		} 
	}
	
	.b__feed-item.b__feed-item--comment .item-content {
		width: 100%;
		padding-left: 15px;
		padding-right: 15px;
		padding-bottom: 0;
		padding-top: 0;
	}
}




