:root .dialogs {
    padding: 0;
    margin: 0;
    list-style: none;
    
    .dialog {
      background-color: white;
      border-bottom: solid 1px #f0f0f0;
      padding: 10px 20px 10px 20px;
      cursor: pointer;
      position: relative;
      @include clear();
      
      &_author-photo {
        border-width: 1px;
        border-color: rgb(240, 240, 240);
        border-style: solid;
        border-radius: 50%;
        background-color: rgb(255, 255, 255);
        width: 52px;
        height: 52px;
//        overflow: hidden;
        float: left;
        
        span.badge {
          top: 8px;
          right: 4px;
        }
        
        img {
          border-radius: 50%;
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      
      &_content {
        width: calc(100% - 64px);
        float: right;
      }
      
      &_meta {
        padding-top: 8px;
        padding-bottom: 4px;
       @include clear(); 
      }
      
      &_date {
        font-size: 10px;
        line-height: 1.2;
        padding-top: 2px;
        color: rgb(153, 153, 153);
        float: right;
      }
      
      &_author-name {
        font-size: 12px;
        line-height: 1.2;
        font-weight: 500;
        color: #a0a0a0;
        float: left;
      }
      
      &_body {
        font-size: 13px;
        font-weight: 500;
        color: #414141;
        line-height: 1.2;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      
      &.dialog--not-read {
        background-color: #f5faff;
        
        .dialog_author-name {
          color: #3399ff;
        }
      }
      
      .dialog_current-arrow {
        display: none;
      }
      
      &.dialog--current {
        .dialog_author-name {
          color: #3399ff;
        }
        .dialog_author-photo {
          border: solid 2px #3399ff;
        }
        .dialog_current-arrow {
          display: block;
          width: 10px;
          height: 20px;
          position: absolute;
          top: 50%;
          margin-top: -10px;
          right: -10px;
          overflow: hidden;
          &:before {
            content: '';
            width: 22px;
            height: 22px;
            background-color: white;
            border-width: 1px;
            border-color: #ebebeb;
            border-style: solid;
            transform: rotate(45deg);
            position: absolute;
            right: 5px;
            top: 0;
          }
        }
      }
      
    }
    
  }