.b__short {
  
  position: relative;
	min-height: 450px;
  height: calc(100vh - 80px);
  width: 100vw;
	
	padding-top: 120px;
	padding-bottom: 200px;
	
	&__mobile-tabs {
		display: none;
	}

	
  .b__short-inner {
    // display: table-cell;
    // vertical-align: middle;
    position: relative;
    z-index: 2;
	  text-align: center;
  }

  &:before {
    content: '';
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    background-color: rgba(36, 131, 227, .65);
  }

  #map__user-ip-location {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;

    .gm-style-cc,
    a {
      display: none !important;
    }

  }
}



.b__items.b__items--map .b__items_item {
	position: relative;
	height: 175px;
}

.b__items_item .item-content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}


.b__items.b__items--map .b__items_item .item-thumbnail img,
.b__items.b__items--grid .b__items_item .item-thumbnail img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.b__items.b__items--grid .b__items_item .item-content {
    height: 100%;
}

.chat-items {
//	height: inherit;
    margin-right: 1.5rem;
    padding: 1rem 0 0 1rem;
}

.b__messanger-inner {
	height: calc(100vh - 80px);
    background-color: #f9f9f9;
    display: flex;
}

.b__messanger-dialogs {
    background: white;
    margin-right: 0;
    height: 100%;
    border-right: solid 1px #ebebeb;
    padding-bottom: 0;
    width: 25% !important;

    
}

.b__messanger-chat {
    margin-right: 0rem;
    padding: 0rem;
    width: 50% !important;
    display: flex;
    flex-direction: column;
}

.b__messanger-peer {
    /* float: left; */
    /* margin-right: 2.16718%; */
    margin-right: 0;
    width: 25% !important;
}

.b__messanger-chat_form {
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
}

.b__feed-item .b__items_item .item-meta {
	height: auto !important;
}

.b__users.b__users--grid .b__users_item {
	height: 225px;
	width: 20%;
}

.column-of-text {
    max-width: 52rem;
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
	
	padding-top: 30px;
	padding-bottom: 50px;
}

.b__short {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    width: 100%;
}

.b__short.has-message {
    align-items: flex-end;
}

.b__short .site-container {
    width: 100%;
}

.b__short .b__short-inner {
	position: relative;
	z-index: 2;
	width: 100%;
    flex: 1 1 100%;
    display: flex;
    align-items: center;
}

.b__short.has-message .b__short-inner {
    align-items: flex-start;
}

.b__short .b__short-inner.message {
    max-width: 50%;
    color: #fff;
    flex: 1 0 50%;
    align-self: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.b__short .b__short-inner.message h1 {
    margin-bottom: 0;
	margin-top: 0;
}


.b__description,
.b__short .b__short-inner.message p {
    line-height: 1.5rem;
    text-align: center;
    margin-top: 0;
}

.b__how-it-works.b__how-it-works-about {
    padding-bottom: 35px;
}

.b__how-it-works .b__description {
    max-width: 50%;
    margin: 0 auto;
}

.b__users.b__users--grid .b__users_item .user-name {
	font-size: 18px;
	margin-top: 10px;
	max-height: 22px;
	overflow: hidden;
}

@include media-breakpoint-down(sm) {
	.b__how-it-works {
		padding-bottom: 80px;
		
		.b__description {
			max-width: 100%;
			margin: 0;
		}
	}
	
	.b__short {
	  min-height: calc(100vh - 50px);
	  height: auto;
		
		padding-top: 50px;
		padding-bottom: 80px;
		
		.b__short-inner {
			margin-top: 0;
		}
		
		&__mobile-tabs {
			display: block;
			width: 100%;
			
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			
			margin-bottom: 10px;
			
			
			select {
				-webkit-appearance: none;
				border: none;
				outline: none !important;
				background: none;
				cursor: pointer;
				border-radius: 0;
				padding: 0;
				margin: 0;
				
				background: none;
				color: #fff;
				border: none;
				width: auto;
				
				font-weight: 600;
				font-size: 16px;
				
				padding-right: 20px;
				
				background-size: 18px auto;
				background-repeat: no-repeat;
				background-position: right center;
				background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNSAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTExLjA5MzggNC42ODc1TDcuNSA4LjI4MTI1TDMuOTA2MjUgNC42ODc1TDIuODEyNSA1Ljc4MTI1TDcuNSAxMC40Njg3TDEyLjE4NzUgNS43ODEyNUwxMS4wOTM4IDQuNjg3NVoiIGZpbGw9IiNmZmYiLz48L3N2Zz4=);
			
			}
		}
		
		.b__short-inner.message {
			width: 100%;
			max-width: 100%;
			flex: none;
			padding-left: 15px;
			padding-right: 15px;
		}
	}
}

@media (max-width: 1199px) {
	.column-of-text {
		max-width: 100%;
	}
}
