.header {
	background: white;
	height: 80px;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: 1001;
	
	@include media(phone-only) {
		text-align: center;          
	}
	
	.header-top {
		width: 100%;
		height: 80px;
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}
	
	.logo {
		flex-shrink: 0;
		display: block;
	
		@include media(phone-only) {
			float: none;          
		}
	}
	
	.header-search {
		flex-grow: 5;
		flex-shrink: 1;
		margin-left: 33px;
		position: relative;
		
		.input-query {
			
			&:before {
				display: none !important;
			}
			
			input {
				padding-left: 70px;
			}
		}
		
		&__toggle {
			display: none;
		}
	}
	
	&.header--extended {
		position: absolute;
		background: transparent;
		
		.header-navigation {
			margin-top: 0;

			& > ul > li {
				line-height: 37px;
			}
		}
		
		.site-container {
			position: relative;
			z-index: 1;
		}
		

		.btn {
			min-width: 0;
			padding: 0px 28px;
		}

		.page-home & {
			background-color: #227cd6;

			.logo {
				
				span {
					color: white;
				}
			}

			.btn {
				&:not(.btn--pure) {
					background-color: white;
					color: rgba(34, 125, 215, 1);
				}
			}
		}
	}
	
	&:not(.header--extended) {
		box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
		
		.page-about & {
			margin-top: -80px;
			transition: transform 350ms ease-out 0s;
		}
		
		.header-search {
			&__toggle {
				svg {
					fill: #666 !important;
				}
			}
		}
		
		.header {
			
			&__mobile-navigation {
				ul {
					li {
						a {
							svg {
								fill: #666;
							}
							
							svg.icon.icon--activity-feed {
								color: #666;
							}
							
						}
						
						.add-button {
							&:before,
							&:after {
								background-color: #666;
							}
						}
					}
				}
			}
			
			&__menu-toggle {
				span {
					background-color: #666;
					
					&:before,
					&:after {
						background-color: #666;
					}
				}
			}
		}
	}
	
	&.out {
		transform: translateY(0%);
	}
	
	&.out-off {
		transform: translateY(100%);
	}

	
	&__menu-toggle {
		
		span {
			display: block;
			width: 24px;
			height: 2px;
			
			background-color: #fff;
			position: relative;
			
			&:before,
			&:after {
				content: "";
				display: block;
				
				width: 100%;
				height: 2px;
				
				background-color: #fff;
				
				position: absolute;
				left: 0;
			}
			
			&:before {
				top: -6px;
			}
			
			&:after {
				top: 6px;
			}
		}
	}
	
	&__mobile-navigation {
		display: none;
		flex-shrink: 0;
		
		ul {
			list-style: none;
			padding: 0;
			margin: 0;
			
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			
			li {
				a {
					text-decoration: none !important;
					
					width: 34px;
					height: 40px;
					position: relative;
					display: block;
					
					display: -webkit-flex;
					display: -moz-flex;
					display: -ms-flex;
					display: -o-flex;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					
					svg {
						width: 23px;
						height: 23px;
						
						fill: #fff;
						
						&.icon.icon--activity-feed {
							width: 20px;
							height: 20px;
						}
						
						&.icon.icon--speech-bubble {
							width: 22px;
							height: 22px;
						}
					}
					
					span.badge {
						opacity: 0;
						
						&.active {
							opacity: 1;
						}
					}
					
					.header-user-menu_photo {
						display: -webkit-flex;
						display: -moz-flex;
						display: -ms-flex;
						display: -o-flex;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
					}
				}
				
				.add-button {
					&:before,
					&:after {
						content: "";
						display: block;
						
						background-color: #fff;
						
						height: 2px;
						width: 20px;
						
						position: absolute;
						top: 50%;
						left: 50%;
						
						margin-left: -10px;
						margin-top: -1px;
					}
					
					&:after {
						transform: rotate(90deg);
					}
				}
				
				&:not(:last-child) {
					margin-right: 3px;
				}
			}
		}
	}
	
	
}

@include media-breakpoint-down(md) {
	.header {
		.header-search {
			width: 100%;
			position: absolute;
			left: 0;
			top: 100%;
			background-color: #fff;
			margin-left: 0;
			border-top: 1px solid #dbdbdb;
			border-bottom: 1px solid #dbdbdb;
			
			display: none;
			
			&.active {
				display: block;
			}

			.input-query {
				height: 60px;

				input {
					padding-left: 70px;
				}
			}
			
			&__toggle {
				width: 40px;
				height: 40px;
				
				display: -webkit-flex;
				display: -moz-flex;
				display: -ms-flex;
				display: -o-flex;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				
				
				
				margin-left: auto;
				
				margin-right: 20px;
				
				text-decoration: none !important;
				
				span {
					display: -webkit-flex;
					display: -moz-flex;
					display: -ms-flex;
					display: -o-flex;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					
				}
				
				.active-icon {
					display: none;
				}
				
				svg {
					width: 26px;
					height: 26px;
					fill: #fff;
				}
				
				&.active {
					.active-icon {
						display: flex;
					}
					
					.unactive-icon {
						display: none;
					}
				}
			}
		}
	}
}


@include media-breakpoint-down(sm) {
	.header {
		position: fixed !important;
		height: 50px !important;
		
		&__mobile-navigation {
			display: block;
		}
		
		.header-top {
			padding-top: 0 !important;
			height: 50px;
			justify-content: space-between;
			
			&:after {
				display: none !important;
			}
			
			.logo {
				font-size: 26px;
				margin-top: 0 !important;
			}
		}
		
		.header-search {
			.input-query {
				height: 50px;

				.icon {
					left: 20px;
				}

				input {
					padding-left: 60px;
				}
			}

			&__toggle {
				width: 34px;
				margin-right: 3px;
				
				svg {
					width: 22px;
					height: 22px;
				}
				
				svg.icon.icon--close {
					width: 18px;
					height: 18px;
				}
			}
		}
		
		&__mobile-navigation {
			ul {
				li {
					a {
						span.badge {
							transform: none !important;
						}
					}
				}
			}
		}
	}
	
	.header-navigation {
		display: none;
	}
}


