.b__ambassador-signup {
	padding-top: 38px;
	padding-bottom: 110px;
}

.ambassador-signup {
	@include clear;

	.ambassador-signup_form {
		position: relative;
		z-index: 1;

		width: span(4);
		margin-left: 0;
		margin-right: 0;
	}

	.ambassador-signup_title {
		position: relative;
		z-index: 1;

		width: span(8);
		margin-right: 0;

		box-sizing: border-box;
		padding: 25vh 40px 0;
		color: white;

		h1 {
			font-size: 36px;
			line-height: 1.167;
			font-weight: 600;
			margin: 0;
		}

		h2 {
			font-size: 36px;
			line-height: 1.167;
			font-weight: 300;
			margin: 0;
		}
	}
}

@media (min-width: 768px) and (max-width: 1199px) {
	.ambassador-signup {
		.ambassador-signup_form {
			width: 350px;
		}
		
		.ambassador-signup_title {
			width: 100%;
		}
	}
}

@media (max-width: 767px) {
	.ambassador-signup {
		.ambassador-signup_form {
			width: 100%;
			
		}
		
		.ambassador-signup_title {
			width: 100%;
			padding: 0;
			padding-top: 40px;
			
			h1 {
				font-size: 24px;
			}
			
			h2 {
				font-size: 24px;
			}
		}
	}
}



