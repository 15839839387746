.b__school {
	.b__school-header {
		height: 556px;
		position: relative;

		@include clear;

		.site-container:after {
			content: none;
		}

		> img {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			height: 100%;
			width: 100%;
			object-fit: cover;
			z-index: -1;
		}

		&-content {
			width: 300px;
			float: left;
		}

		.b__panel {
			padding: 30px 18px;
		}
	}

	&-title {
		font-family: $font__special;
		font-size: 48px;
		text-align: center;
		margin-top: 30px;
		margin-bottom: 45px;
	}

	&-description {
		margin-top: 36px;
		margin-bottom: 30px;

		&-text {
			font-size: 13px;
			color: rgb(51, 51, 51);
			line-height: 1.538;
		}
	}

	&-ambassador {
		margin: 0;
	}

	.ambassador-contact {
		@include clear;

		margin-bottom: 18px;

		&_photo {
			border-style: solid;
			border-width: 2px;
			border-color: rgb(238, 238, 238);
			border-radius: 50%;
			background-color: rgb(255, 255, 255);
			width: 82px;
			height: 82px;
			overflow: hidden;
			float: left;

			img {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		&_details {
			float: right;
			width: calc(100% - 100px);
			padding-top: 10px;
		}

		&_name {
			font-weight: 600;
			font-size: 18px;
			color: rgb(51, 51, 51);
			line-height: 1.2;
			padding-bottom: 4px;
		}

		&_name-sub {
			font-size: 14px;
			color: rgb(204, 204, 204);
			line-height: 1.2;
		}
	}

	.ambassador-contact-form {
		.form {
			padding: 0;

			.field--text,
			.field--password,
			.field--email,
			.field--tel {
				input {
					height: 46px;
					font-size: 14px;
				}
			}

			.field--textarea {
				textarea {
					font-size: 14px;
					padding-top: 12px;
					padding-bottom: 12px;
					min-height: 70px;
				}
			}

			.field--submit {
				input {
					height: 46px;
				}
			}
		}
	}

	&-content {
		.site-container {
			@include clear;
		}

		&_inner {
			width: span(9);
			float: right;
			margin-right: 0;
			margin-left: gutter();
			padding-bottom: 110px;
		}
	}

	&-widget {
		.b__title {
			margin-top: 22px;
			margin-bottom: 24px;
			text-align: left;
		}
		
		.b__users {
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			flex-wrap: wrap;
			
			.b__user_item {
				margin-right: 20px;
			}
		}
	}
}


@media (min-width: 768px) and (max-width: 1199px) {
	.b__school {
		&-content {
			
			&_inner {
				margin-left: 0;
				width: 100%;
				float: none;
			}
		}
		&-widget {
			.b__users {
				.b__user_item {
					margin-right: 0;
				}
				
				.b__users_item.b__users_item--more {
					width: 100%;
				}
				
				#more-button-onMore-container {
					width: 20%;
				}
			}
		}
		.b__school-header {
			
			&-content {
				width: 300px;
				float: left;
			}

			.b__panel {
				padding: 30px 18px;
			}
		}
		
	}
}

@media (max-width: 767px) {
	.b__school {
		&-content_inner {
			padding-bottom: 0;
		}
		&-content {
			
			&_inner {
				margin-left: 0;
				width: 100%;
				float: none;
			}
		}
		.b__school-header {
			
			&-content {
				width: 100%;
				float: left;
			}

			.b__panel {
				padding: 30px 18px;
			}
		}
		
	}
}
