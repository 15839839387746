.logo {
  font-family: $font__extra;
  text-decoration: none;
  line-height: 1;
  font-size: 21.8px;
  color: #666666;
  font-weight: bold;
  font-style: italic;
  
  span {
    color: #227cd6;
  }

  .header.header--extended &,
  .layout-no_nav & {
    font-size: 36px;
    color: white;
    
    span {
      color: #cccccc;
    }
  }
  
  footer & {
    font-size: 14px;
    color: #666666;
    font-weight: bold;
    text-decoration: none;
    
    &:hover {
      color: #999;
    } 
  }
}