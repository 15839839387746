@keyframes spinanim {
	from {
			transform:rotate(0deg);
	}
	to {
			transform:rotate(360deg);
	}
}

.b__items-container {
	width: 100%;

	@include trans(width);

	&.has-map {
		width: calc(50% + 28px);
		padding-right: 28px;
		float: left;
		margin-top: -25px;
		padding-top: 25px;
		z-index: 4;
		position: relative;
	}
}

.b__items-map {
	display: none;
	overflow: hidden;
	position: absolute;
	top: 0px;
	width: calc(50vw - 28px);
	left: calc(50% + 28px);

	.b__items-container.has-map + & {
		display: block;
	}

	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: -10px;
		width: 10px;  
		height: 100%;
		box-shadow: 10px 0px 5px 0px rgba(0, 0, 0, 0.05);
		z-index: 20;
	}

	.marker-wrapper {
		position: absolute;
		cursor: pointer;
		width: 31px;
		height: 31px;
		background-color: transparent;
		z-index: 190;
		transform: translateX(-50%) translateY(-100%);
	}

	.marker-thumb {
		border-width: 0px;
		width: 31px;
		height: 31px;
		text-align: center;
		font-size: 18px;
		font-family: "SF UI  Text";
		line-height: 31px;
		font-weight: bold;
		color: white;

		&__mask {
			display: block;
			width: 100%;
			height: 100%;
			border-radius: 50%;
			box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
			transition: transform 200ms ease-in-out !important;

			&:hover {
				transform: scale(1.1);
			}
		}

		&__photo {
			width: 100%;
			height: 100%;
			border-radius: 50%;
			transition: color 100ms ease-in-out;
		}

		&.isForSale {
			.marker-thumb__photo {
				background-color: $color__main_red;
			}
		}

		&.isForRepair {
			.marker-thumb__photo {
				// background-color: #f2cd4a;
				// background-color: $color__main_green;
				background-color: $color__main_yellow;
			}
		}

		&.isForRent {
			.marker-thumb__photo {
				// background-color: green;
				// background-color: $color__main_purple;
				background-color: $color__main_green;
			}
		}

		&.isForExchange {
			.marker-thumb__photo {
				background-color: $color__main_orange;
			}
		}

		&.isForDonation {
			.marker-thumb__photo {
				background-color: $color__main_blue;
			}
		}
	}

	.marker-info {
		position: absolute;
		left: 50%;
		bottom: 31px;
		width: 300px;
		padding: 18px 20px;
		margin-left: -150px;
		background-color: white;
		border-radius: 5px;
		text-align: left;
		color: #333;
		box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
		opacity: 0;
		transition: all 300ms ease-in-out !important;
		transform: translateY(0) scale(0.1);
		transform-origin: 50% 100%;
		z-index: -1;
		visibility: hidden;
		cursor: default;

		&:after {
			content: '';
			position: absolute;
			left: 50%;
			bottom: -16px;
			width: 0;
			height: 0;
			margin-left: -17px;
			border-style: solid;
			border-width: 16px 17px 0 17px;
			border-color: #fff transparent transparent transparent;
		}

		.item-title {
			font-size: 16px;
			font-weight: 600;
			color: #333333;
			line-height: 1.3;
			
			padding-right: 30px;

			a {
				color: #333333;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		.close-tooltip {
			color: #999;
			font-size: 0;
			color: transparent;
			width: 20px;
			height: 20px;
		  position:  absolute;
		  right: 5px;
		  top: 10px;
		  cursor: pointer;
			
			&:before,
			&:after {
				content: "";
				display: block;
				
				width: 14px;
				height: 2px;
				
				background-color: #999;
				
				position: absolute;
				top: 50%;
				right: 50%;
				
				transition: all 0.2s ease-in-out;
			}
			
			&:before {
				transform: rotate(45deg);
			}
			
			&:after {
				transform: rotate(-45deg);
			}
			
			&:hover {
				
				&:before,
				&:after {
					background-color: #000;
				}
			}
		}

		.item-description {
			margin-top: 4px;
			margin-bottom: 12px;
			font-size: 12px;
			font-weight: 400;
			line-height: 1.4;
		}

		.item-author {
			height: 33px;

			a {
				color: #333;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}

			&_photo {
				overflow: hidden;
				float: left;
				width: 33px;
				height: 33px;
				margin-right: 12px;
				border: solid 1px #cccccc;
				border-radius: 50%;
				text-align: center;
				line-height: 28px;

				img {
					width: 31px;
					height: 31px;
					object-fit: cover;
				}

				.icon {
					display: inline-block;
					vertical-align: middle;
					width: 60%;
					height: 60%;
					fill: #cccccc;
					color: #cccccc;
				}
			}

			&_description {
				float: left;
				font-size: 12px;
			}

			&_name {
				font-weight: 400;
			}
		}
	}

	.is-showed {
		.marker-thumb {
			&__mask {
				box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
				transform: scale(.5);
				transform-origin: 50% 50%;
				color: transparent;
			}
		}
		.marker-info {
			opacity: 1;
			z-index: 1111;
			transform: translateY(0) scale(1);
			visibility: visible;
		}
	}
}

.b__items.b__items--map {
	@include clear();

	position: relative;
	width: calc(100% + 22px);
	height: calc(100% - 115px);
	margin-right: -22px;
	padding-right: 22px;

	.b__items_item {
		position: relative;
		height: 175px;

		@include clear();

		& + .b__items_item {
			margin-top: 17px;
		}

		.item-thumbnail {
			width: 212px;
			float: left;
			height: 100%;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			.item-university {
				display: none;
			}
		}

		.item-university {
			position: absolute;
			top: -6px;
			right: -26px;
			width: 80px;
			height: 38px;
			text-align: center;
			font-size: 10px;
			font-family: $font__special;
			line-height: 60px;
			color: white;
			transform: rotate(45deg);

			span {
				display: inline-block;
				vertical-align: middle;
			}
		}

		.item-content {
			position: relative;
			float: right;
			width: calc(100% - 212px);
			height: 100%;
			padding-left: 20px;
			box-sizing: border-box;
		}

		.item-title {
			width: calc(100% - 60px);
			margin-bottom: 8px;
			margin-top: 20px;
		}

		.item-description {
			width: calc(100% - 60px);
			font-size: 12px;
			font-weight: 400;
		}

		.item-place {
			position: absolute;
			top: 18px;
			right: 19px;
			width: 25px;
			height: 33px;
			display: block;

			span {
				position: absolute;
				top: 4px;
				left: 1px;
				right: 2px;
				font-size: 14px;
				font-weight: 600;
				text-align: center;
				color: #a81c30;
			}
		}

		.item-author {
			position: absolute;
			height: 33px;
			bottom: 24px;
			@include clear();

			&_photo {
				float: left;
				overflow: hidden;
				width: 33px;
				height: 33px;
				margin-right: 12px;
				border: solid 1px #cccccc;
				border-radius: 50%;
				text-align: center;
				line-height: 28px;

				img {
					width: 31px;
					height: 31px;
					object-fit: cover;
				}

				.icon {
					width: 60%;
					height: 60%;
					display: inline-block;
					vertical-align: middle;
					fill: #cccccc;
					color: #cccccc;
				}
			}

			&_description {
				float: left;
				font-size: 12px;
				line-height: 1.4;
			}

			&_name {
				a {
					text-decoration: none;
					color: inherit;
				}
			}

			&_place {
				color: #999999;        
			}
		}

		.item-price {
			position: absolute;
			right: 19px;
			bottom: 20px;
		}
	}
}

.map-cluster__awesome {
	line-height: 41px !important;

	#items-map & {
		transition: transform 200ms ease;
		transform-origin: 50%;
	}

	&:hover {
		transform: scale(1.1);
	}

	&:before {
		content: '';
		position: absolute;
		top: 4px;
		left: 50%;
		width: 31px;
		height: 31px;
		border-radius: 50%;
		border-width: 0px;
		box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
		transform: translateX(-50%);
		z-index: -1;
	}

	&.isForSale {
		&:before {
			background-color: $color__main_red; //#b72d32;
		}
	}

	&.isForRepair {
		&:before {
			background-color: $color__main_yellow;
		}
	}

	&.isForRent {
		&:before {
			background-color:  $color__main_green;
		}
	}

	&.isForExchange {
		&:before {
			background-color: $color__main_orange;
		}
	}

	&.isForDonation {
		&:before {
			background-color: $color__main_blue; // #227cd6;
		}
	}
}

#items-map {
	position: relative;
	width: 100%;
	height: 100%;
	background-color: #A5CBFF;

	&.loading {
		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(255, 255, 255, 0.9);
		}

		&:before {
			content: '';
			display: block;
			position: absolute;
			border: solid 6px rgba(0, 0, 0, 0.08);
			border-left-color: transparent;
			border-radius: 50%;
			top: 50%;
			left: 50%;
			width: 60px;
			height: 60px;
			z-index: 1;
			transform: translateY(-50%) translateX(-50%);
			transform-origin: center;
			animation-name: spinanim;
			animation-duration: 2000ms;
			animation-iteration-count: infinite;
			animation-timing-function: linear;
		}
	}

	div {
		// will-change: transform;
		transition: none;
	}

	a[title="Click to see this area on Google Maps"] {
		display: none !important;
	}

	.gm-style-cc {
		display:  none !important;
	}

	/* Google map zoom controls */
	.gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
		bottom: 50% !important;
		right: 56px !important;

		> .gmnoprint {
			transform: translateY(-50%);
			> div {
				cursor: pointer;
				user-select: none;
				box-shadow: none !important;
				border-radius: 0 !important;
				background-color: transparent !important;
				width: auto !important;
				height: auto !important;
				> div {
					display: none !important;
				}
			}
		}
		button {
			width: 48px !important;
			height: 48px !important;
			background-color: white !important;
			border-radius: 50% !important;
			box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);

			&:last-child {
				margin-top: 4px !important;
			}

			> div {
				left: 17px !important;
				top: 16px !important;
				img {
					transition: none;
				}
			}
		}
	}
}


@media (max-width: 1199px) {
	.b__items--map  {
		display: none;
	}
	.b__items-map  {
		position: relative;
		top: auto;
		right: auto;
		left: auto;
		bottom: auto;
		
		height: calc(100vh - 220px) !important;
		box-shadow: border-box;
		
		width: calc(100% + 76px);
		margin-left: -38px;
		
		&:before {
			display: none;
		}
		
	}
	
	.b__items-container.has-map {
		float: none;
		width: 100%;
		margin: 0;
		padding: 0;
		height: auto !important;
	}
}

@media (max-width: 767px) {
	.b__items-map  {
		height: calc(100vh - 160px) !important;
		
		width: calc(100% + 30px);
		margin-left: -15px;
		
		
		
	}
}
