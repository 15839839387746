footer#layout-footer {
	background-color: white;
	padding-top: 50px;
	text-align: center;

	.page-messenger & {
		display: none;
	}

	.footer__signup {
		padding-top: 5px;
		padding-bottom: 50px;
		.btn {
			display: inline-block;
			font-size: 14px;
			min-width: 0;
			padding-left: 30px;
			padding-right: 30px;
		}
	}

	.footer-logo {
		margin-bottom: 15px;
	}

	.footer-menu {
	&.footer-menu--categories {
		margin-bottom: 8px;
	}
	a {
		display: inline-block;
		font-size: 12px;
		text-decoration: none;
		color: rgba(72, 72, 72, 0.5);
		margin: 0 8px;
		padding-top: 9px;
		padding-bottom: 8px;
		vertical-align: middle;

		&.link--bordered {
		padding-left: 24px;
		padding-right: 24px;
		border: solid 1px rgba(72, 72, 72, 0.5);
		border-radius: 4px;
		
		&:hover {
			border: solid 1px rgba(72, 72, 72, 1);
		}
		}

		&:hover {
		color: rgba(72, 72, 72, 1);
		}
	}
	}

	
	.footer-social {
		margin-top: 18px;
		margin-bottom: 8px;
		a {
			display: inline-block;
			text-decoration: none;
			vertical-align: middle;
			margin-left: 14px;
			margin-right: 14px;

			&:hover {
				svg {
					fill: rgba(72, 72, 72, 1);
					color: rgba(72, 72, 72, 1);
				}
			}
		}
		svg {
			height: 16px;
			width: 16px;
			fill: rgba(72, 72, 72, 0.5);
			color: rgba(72, 72, 72, 0.5);
		}
	}

	.footer-copyrights {
		padding-top: 18px;
		padding-bottom: 28px;

		font-size: 12px;
		color: rgba(72, 72, 72, 0.25);
	}
}

@include media-breakpoint-down(sm) {
	footer#layout-footer .footer-menu a.link--bordered {
		&:last-child {
			width: 190px;
			margin-left: calc((100vw - 220px) / 2);
			margin-right: calc((100vw - 220px) / 2);
		}
	}
}