.chat-form {
  @include clear();
  background-color: white;
  position: relative;
  min-height: 63px;

  display: flex;

  

  :root #comment-form & {
    display: flex;
    flex-direction: row;
    align-items: center;

    &_text {
      width: calc(100% - 300px);
    }
    &_actions {
      width: auto;

      .cancel.btn.btn--submit {
        color: #aaa;
        justify-content: center;
        align-content: center;
        display: flex;
        align-items: center;
        &:hover {
          color: #3399ff;
        }
      }
    }
  }

  .cancel-reply {
    padding-left: 0;
  }

  small {
    flex-shrink: 0;
    margin-left: auto;
    margin-right: 20px;
  }
  
  &_text {
    width: calc(100% - 110px);
    
    textarea {
      width: calc(100% - 40px);
      border: 0;
      
      height: 17px;
      
      font-size: 14px;
      line-height: 1.2;
      
      box-sizing: content-box;
      padding: 26px 20px 20px;
      
      resize: none;
  
      
      &::-webkit-input-placeholder {
      	color: rgb(218, 218, 218);
      }
      &:-moz-placeholder { /* Firefox 18- */
      	color: rgb(218, 218, 218);  
      }
      &::-moz-placeholder {  /* Firefox 19+ */
      	color: rgb(218, 218, 218); 
      }
      &:-ms-input-placeholder {  
      	color: rgb(218, 218, 218); 
      }
      
    }
  }
  
  &_actions {
    
    width: 110px;
    height: 100%;
    order: 3;
    
    padding: 15px 22px;
    white-space: nowrap;
    font-size: 0;
    text-align: center;
    &:before {
      content: '';
      height: 105%;
      vertical-align: middle;
      display: inline-block;
    }
  }
  
  &_submit {
    vertical-align: middle;
    display: inline-block;
  }

}


@media (max-width: 767px) {
  .chat-form {

    .cancel-reply {
      padding-left: 25px;
    }

    :root #comment-form & {
      display: block;
      
      small {
        float: none;
        position: absolute;
        bottom: 50px;
        left: 0;
        
        width: 100%;
        text-align: center;
      }

      &_text {
        width: 100%;
        margin-bottom: 35px;
        
        textarea {
          width: 100%;
          height: 110px;
          box-sizing: border-box;
        }
      }
      &_actions {
        width: 100%;
        position: relative;
        top: auto;
        right: auto;
        left: auto;
        height: 40px;
        
        .btn {
          line-height: 20px;
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
    }
  }
}
