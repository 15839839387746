.b__items.b__items--grid {
	height: calc(100% - 115px);
	padding-right: 0;
	margin-right: 0;
	position: relative;
	@include clear;
	
	width: calc(100% + 28px);
	box-sizing: border-box;
	
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	flex-wrap: wrap;
	
	
	#item-list {
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		flex-wrap: wrap;
		width: 100%;
	}
	
	&.no-scroll {
		height: auto;
	}

	.b__items_item {
		width: calc(25% - 28px);
		margin-left: 0;
		margin-right: 28px;
		position: relative;


		.b__profile-items &,
		.b__school-widget & {
			width: span(4);

			&:nth-child(4n+1) {
				margin-left: gutter() / 2;
			}

			&:nth-child(4n+4) {
				margin-right: gutter() / 2;
			}

			&:nth-child(3n+1) {
				margin-left: 0;
			}

			&:nth-child(3n+3) {
				margin-right: 0;
			}

			.item-author-price .item-price {
				right: 0;
			}

			&.b__items_item--no-content {
				height: 240px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 4px;
				text-align: center;
				font-size: 14px;
				font-weight: 500;
				color: rgb(153, 153, 153);

				.btn {
					display: inline-block;
					height: 46px;
					background: #3399ff;
					border-color: #3399ff;
					box-shadow: 0 2px 0 0 #2e89e5;
					padding: 14px 0;
				}
			}
		}

		.item-thumbnail {
			width: 100%;
			height: 210px;
			position: relative;
			font-size: 0;

			a:not(.link--edit) {
				display: block;
				height: 100%;
			}

			.link--edit {
				position: absolute;
				font-size: initial;
			}

			img {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			.item-university {
				display: none;
			}
		}

		.item-university {
			width: 80px;
			height: 38px;
			text-align: center;
			font-size: 10px;
			font-family: $font__special;
			color: white;
			position: absolute;
			transform: rotate(45deg);
			right: -26px;
			top: -6px;
			line-height: 60px;
			z-index: 1;

			span {
				display: inline-block;
				vertical-align: middle;
			}
		}

		.item-content {
			width: 100%;
			height: calc(100% - 210px);
			position: relative;
			padding-left: 15px;
			padding-right: 15px;
			padding-top: 20px;
			padding-bottom: 20px;
			box-sizing: border-box;
			
			display: flex;
			flex-direction: column;
			
			> * {
				flex-shrink: 0;
			}
		}

		.item-title {
			margin-bottom: 8px;
		}

		.item-description {
			font-size: 12px;
			font-weight: 400;
			width: 100%;
			margin-bottom: 15px;
		}

		.item-place {
			width: 25px;
			height: 33px;
			position: absolute;
			right: 19px;
			top: 18px;
			display: none;

			span {
				font-size: 14px;
				text-align: center;
				font-weight: 600;
				color: #a81c30;
				position: absolute;
				left: 1px;
				right: 2px;
				top: 4px;
			}
		}
		
		.item-author-price {
			margin-top: auto;
			width: 100%;
		}

		.item-author {
			@include clear;

			margin-bottom: 16px;
			position: relative;
			
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;

			&_photo {
				flex-shrink: 0;
				margin-right: 12px;
				width: 33px;
				height: 33px;
				border: solid 1px #cccccc;
				border-radius: 50%;
				overflow: hidden;
				text-align: center;
				line-height: 28px;

				img {
					width: 31px;
					height: 31px;
					object-fit: cover;
				}

				.icon {
					fill: #cccccc;
					color: #cccccc;
					width: 60%;
					height: 60%;
					display: inline-block;
					vertical-align: middle;
				}
			}

			&_description {
				font-size: 12px;
				line-height: 1.4;
			}

			&_name {
				a {
					text-decoration: none;
					color: inherit;
				}
			}

			&_place {
				color: #999999;
			}
		}

		.item-price {
			position: relative;
			margin-bottom: 0;
		}
	}
}


@media (max-width: 767px) {
	.b__items.b__items--grid {
		height: auto;
		
		&.no-scroll {
			height: auto;
		}

		.b__items_item {
			width: 100%;
			float: none;
			margin-bottom: 20px;
			margin-left: 0;
			margin-right: 0;

			&:nth-child(4n+1) {
				margin-left: 0;
			}

			&:nth-child(4n+4) {
				margin-right: 0;
			}

			.b__profile-items &,
			.b__school-widget & {
				width: 100%;

				&:nth-child(4n+1) {
					margin-left: 0;
				}

				&:nth-child(4n+4) {
					margin-right: 0;
				}

				&:nth-child(3n+1) {
					margin-left: 0;
				}

				&:nth-child(3n+3) {
					margin-right: 0;
				}
			}
		}
	}
}

@media (max-width: 1030px) {
	.b__items.b__items--grid {
		height: auto;
		
		.b__items_item {
			width: calc(33.33% - 28px);
		}
	}
	
	.b__profile-items .b__items.b__items--grid .b__items_item, .b__school-widget .b__items.b__items--grid .b__items_item {
		width: calc(50% - 28px);
	}
	
	.b__profile-items .b__items.b__items--grid .b__items_item:nth-child(3n+3), 
	.b__school-widget .b__items.b__items--grid .b__items_item:nth-child(3n+3) {
		margin-right: 28px;
	}
}


@media (max-width: 767px) {
	.b__items.b__items--grid {
		height: auto;
		margin: 0;
		width: 100%;
		padding-right: 0;
		
		.b__items_item {
			width: 100%;
			margin-right: 0;
		}
	}
	
	.b__items.b__items--grid {
		height: auto;
		
		.b__items_item {
			width: calc(33.33% - 28px);
		}
	}
	
	.b__profile-items .b__items.b__items--grid .b__items_item, .b__school-widget .b__items.b__items--grid .b__items_item {
		width: 100%;
		margin-right: 0;
	}
	
	.b__profile-items .b__items.b__items--grid .b__items_item:nth-child(3n+3), 
	.b__school-widget .b__items.b__items--grid .b__items_item:nth-child(3n+3) {
		margin-right: 0;
	}
}

