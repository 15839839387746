.dropdown-box {
  position: relative;
}
.dropdown {
  .dropdown-arrow {
    display: block;
    position: absolute;
    width: 14px;
    height: 8px;
    top: 50%;
    right: 15px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: transform 250ms ease-out 0s;
    .icon {
      width: 14px;
      height: 8px;
      fill: #dcdcdc;
    }
  }
  &:hover {
    .dropdown-arrow {
      .icon {
        fill: darken(#dcdcdc, 10%);
      }
    }
  }
  &.show-dropdown {
    .dropdown-arrow {
      transform: rotate(180deg) translateY(50%);
      backface-visibility: hidden;
      .icon {
        fill: darken(#dcdcdc, 20%);
      }
    }
  }
}
.dropdown-content {
  position: absolute;
  background: white;
  box-sizing: border-box;
  margin-top: 17px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  border-width: 0;
  white-space: normal;
  right: 0;
  z-index: 150;
  
  ul {
    list-style: none;
    text-align: left;
    padding: 15px 20px;
    margin: 0;
    li {
      line-height: 1.5;
      a {
        font-size: 14px;
        text-decoration: none;
        color: $color__main_gray;
      }
      
    }
  }
  
  &:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7px 7px 7px;
    border-color: transparent transparent #ffffff transparent;
    position: absolute;
    top: -7px;
    right: 15px;
    left: auto;
  }
  
  .dropdown-content_header {
    padding: 17px 20px;    
    font-size: 14px;
    font-weight: 500;
    color: #414141;
  }
  
  .dropdown-content_footer {
    background-color: #fafafa;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: #414141;
    padding-top: 12px;
    padding-bottom: 14px;
    border-radius: 0 0 6px 6px;
    
    a {
      text-decoration: none;
      color: #414141;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  
  .dropdown + & {
    display: none;
  }
  
  .dropdown.show-dropdown + & {
    display: block;
  }
  
}