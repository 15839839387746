.header-user-menu {
	position: relative;
	padding: 5px 35px 5px 5px;
	margin: 0 0 0 5px;
	display: inline-block;
	vertical-align: middle;
	white-space: nowrap;
	background: white;
	border-radius: 19px;
	box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
	height: 40px;
	border: 0;
	font-size: 0;
	line-height: initial;

	.header-user-menu_photo {
		display: inline-block;
		vertical-align: middle;
		width: 30px;
		height: 30px;
		background-color: #f9f9f9;
		border-radius: 50%;
		position: relative;

		&.has-avatar {
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
		}

		.icon {
			width: 16px;
			height: 16px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
			fill: #c7c7c7;
			color: #c7c7c7;
		}
	}

	.header-user-menu_name {
		display: inline-block;
		vertical-align: middle;
		font-size: 13px;
		color: $color__main_gray;
		line-height: 1.2;
		margin: 0 10px;
	}

	&-dropdown {
		ul {
			display: block;
			font-size: 13px;
			font-weight: 500;
			padding: 15px 27px;
			white-space: nowrap;

			li {
				display: block;
				line-height: 2.1;

				a {
					text-decoration: none;
					color: $color__main_gray;

					&:hover {
						color: $color__main_blue;
					}
				}
			}
		}
	}
}