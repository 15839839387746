.b__users.b__users--grid {
	@include clear;

	.b__users_item {
		text-align: center;
		margin-bottom: 50px;


		.user {
			&-pic {
				border-style: solid;
				border-width: 4px;
				border-color: rgba(255, 255, 255, 0.25);
				border-radius: 50%;
				background-color: rgba(255, 255, 255, 0.25);
				width: 144px;
				height: 144px;

				@include center-block;

				overflow: hidden;

				img {
					display: block;
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			&-name {
				font-size: 18px;
				margin-top: 10px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				width: 100%;
				text-align: center;
				display: block;

				a {
					color: inherit;
					text-decoration: none;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					width: 100%;
					text-align: center;
					display: block;
				}
			}

			&-place {
				font-size: 12px;
				color: #999999;
				margin-top: 6px;
			}

			&-actions {
				margin-top: 10px;
			}
		}

		&:hover {
			.user {
				&-name {
					a {
						color: $color__main_blue;
					}
				}
			}
		}
	}

	.b__users_item.b__users_item--more {
		border-style: solid;
		border-width: 4px;
		border-color: rgba(255, 255, 255, 0.25);
		border-radius: 50%;
		background-color: rgba(255, 255, 255, 0.25);
		width: 144px;
		height: 144px;
		overflow: hidden;
		font-size: 18px;
		text-decoration: none;
		color: #333333;

		@include vertical-align-content;

		> span {
			display: inline-block;
			vertical-align: middle;

			span {
				display: block;
				font-size: 12px;
				color: #999999;
			}
		}
	}
}


@media (min-width: 768px) and (max-width: 1199px)  {
	.b__users.b__users--grid {
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
		flex-wrap: wrap;

		.b__users_item  {
			width: 20%;
			height: auto;
			padding-left: 5px;
			padding-right: 5px;

			.user-pic {
				width: 100px;
				height: 100px;

			}
		}
	}
}

@media (max-width: 767px)  {
	.b__users.b__users--grid {
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
		flex-wrap: wrap;

		.b__users_item  {
			width: 50%;
			height: auto;
			padding-left: 10px;
			padding-right: 10px;

			.user-pic {
				width: 100px;
				height: 100px;

			}
		}
	}
}
