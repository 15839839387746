@import "functions";
@import "mixins";

@import "variables";

/* Grid system */
@import "../../node_modules/susy/sass/susy";
// @import "susy/sass/susy";

//$grid: 12 (82px 28px);
// $susy: (
//   columns: 12,
//   gutters: 28px/82px,
//   container: 1290px,
//   global-box-sizing: border-box 
// );

$susy: (
  'columns': susy-repeat(12),
  'gutters': 28px,
  'spread': 'narrow',
  'container-spread': 'narrow',
);

// $columns: susy-repeat(12, 82px);
// $gutters: 28px;

*,
*:before,
*:after { box-sizing: border-box; }


/* Reset */
@import "normalize";

/* Layout */
@import "site/layout/layout";

/* Pages */
@import "site/pages/pages";

/* Typography */
@import "typography/fonts";

.text--left {
  text-align: left !important;
}
.text-danger {
  color: red !important;
}
.text-warning {
  color: #f0ad4e !important;
  a {
    color: #f0ad4e !important;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}

/* Elements */
@import "elements/logo";
@import "elements/buttons";
@import "elements/icons";
@import "elements/form";
@import "elements/panel";
@import "elements/links";
@import "elements/modal";
@import "elements/dropdown";
@import "elements/tabs";

/* Site */
@import "site/header/header";
@import "site/header/header-menu";
@import "site/header/navigation";
@import "site/header/search";
@import "site/header/header-user-menu";
@import "site/header/speech-bubble-dropdown";

/* Blocks */
@import "site/blocks/index";
@import "site/blocks/short";
@import "site/blocks/achievements";
@import "site/blocks/universities";
@import "site/blocks/home-items";
@import "site/blocks/home-map";
@import "site/blocks/how-it-works";
@import "site/blocks/notifications";
@import "site/blocks/ambassador-signup";
@import "site/blocks/posting-item";
@import "site/blocks/comments";
@import "site/blocks/profile";
@import "site/blocks/school";
@import "site/blocks/feed";

/* Messager */
@import "site/messanger/messanger";
@import "site/messanger/dialogs";
@import "site/messanger/chat-form";
@import "site/messanger/chat-items";

/* Items */
@import "site/items/items-controls";
@import "site/items/items";
@import "site/items/items-listing";
@import "site/items/items-grid";
@import "site/items/items-map";
@import "site/items/items-fast-filter";
@import "site/items/item";

/* Users */
@import "site/users/users-grid";
@import "site/users/people";

/* Plugins */
@import "site/plugins/slick";
@import "site/plugins/selectize";
@import "site/plugins/fancybox";
@import "site/plugins/scrollbar";

/* Footer */
@import "site/footer/footer";
