.selectize-control {
  cursor: pointer;
}
.selectize-dropdown-content {
	overflow-y: auto;
	overflow-x: hidden;
	max-height: 200px;
	-webkit-overflow-scrolling: touch;
}

/*
  
.wtf {
  .selectize-control.plugin-drag_drop.multi {
  	>.selectize-input {
  		>div.ui-sortable-placeholder {
  			visibility: visible !important;
  			background: #f2f2f2 !important;
  			background: rgba(0, 0, 0, 0.06) !important;
  			border: 0 none !important;
  			-webkit-box-shadow: inset 0 0 12px 4px #ffffff;
  			box-shadow: inset 0 0 12px 4px #ffffff;
  		}
  	}
  }
  .selectize-control.plugin-drag_drop {
  	.ui-sortable-placeholder {
  		&::after {
  			content: '!';
  			visibility: hidden;
  		}
  	}
  	.ui-sortable-helper {
  		-webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  		box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  	}
  }
  .selectize-dropdown-header {
  	position: relative;
  	padding: 5px 8px;
  	border-bottom: 1px solid #d0d0d0;
  	background: #f8f8f8;
  	-webkit-border-radius: 3px 3px 0 0;
  	-moz-border-radius: 3px 3px 0 0;
  	border-radius: 3px 3px 0 0;
  }
  .selectize-dropdown-header-close {
  	position: absolute;
  	right: 8px;
  	top: 50%;
  	color: #303030;
  	opacity: 0.4;
  	margin-top: -12px;
  	line-height: 20px;
  	font-size: 20px !important;
  	&:hover {
  		color: #000000;
  	}
  }
  .selectize-dropdown.plugin-optgroup_columns {
  	.optgroup {
  		border-right: 1px solid #f2f2f2;
  		border-top: 0 none;
  		float: left;
  		-webkit-box-sizing: border-box;
  		-moz-box-sizing: border-box;
  		box-sizing: border-box;
  		&:last-child {
  			border-right: 0 none;
  		}
  		&:before {
  			display: none;
  		}
  	}
  	.optgroup-header {
  		border-top: 0 none;
  	}
  }
  .selectize-control.plugin-remove_button {
  	[data-value] {
  		position: relative;
  		padding-right: 24px !important;
  		.remove {
  			z-index: 1;
  			position: absolute;
  			top: 0;
  			right: 0;
  			bottom: 0;
  			width: 17px;
  			text-align: center;
  			font-weight: bold;
  			font-size: 12px;
  			color: inherit;
  			text-decoration: none;
  			vertical-align: middle;
  			display: inline-block;
  			padding: 2px 0 0 0;
  			border-left: 1px solid #d0d0d0;
  			-webkit-border-radius: 0 2px 2px 0;
  			-moz-border-radius: 0 2px 2px 0;
  			border-radius: 0 2px 2px 0;
  			-webkit-box-sizing: border-box;
  			-moz-box-sizing: border-box;
  			box-sizing: border-box;
  			&:hover {
  				background: rgba(0, 0, 0, 0.05);
  			}
  		}
  	}
  	[data-value].active {
  		.remove {
  			border-left-color: #cacaca;
  		}
  	}
  	.disabled {
  		[data-value] {
  			.remove {
  				&:hover {
  					background: none;
  				}
  				border-left-color: #ffffff;
  			}
  		}
  	}
  	.remove-single {
  		position: absolute;
  		right: 28px;
  		top: 6px;
  		font-size: 23px;
  	}
  }
  .selectize-control {
  	position: relative;
  	.selectize-input.disabled {
  		opacity: 0.5;
  		background-color: #fafafa;
  	}
  }
  .selectize-dropdown {
  	color: #303030;
  	font-family: inherit;
  	font-size: 13px;
  	line-height: 18px;
  	-webkit-font-smoothing: inherit;
  	position: absolute;
  	z-index: 10;
  	border: 1px solid #d0d0d0;
  	background: #ffffff;
  	margin: -1px 0 0 0;
  	border-top: 0 none;
  	-webkit-box-sizing: border-box;
  	-moz-box-sizing: border-box;
  	box-sizing: border-box;
  	-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  	-webkit-border-radius: 0 0 3px 3px;
  	-moz-border-radius: 0 0 3px 3px;
  	border-radius: 0 0 3px 3px;
  	[data-selectable] {
  		cursor: pointer;
  		overflow: hidden;
  		padding: 5px 8px;
  		.highlight {
  			background: rgba(125, 168, 208, 0.2);
  			-webkit-border-radius: 1px;
  			-moz-border-radius: 1px;
  			border-radius: 1px;
  		}
  	}
  	.optgroup-header {
  		padding: 5px 8px;
  		color: #303030;
  		background: #ffffff;
  		cursor: default;
  	}
  	.optgroup {
  		&:first-child {
  			.optgroup-header {
  				border-top: 0 none;
  			}
  		}
  	}
  	.active {
  		background-color: #f5fafd;
  		color: #495c68;
  	}
  	.active.create {
  		color: #495c68;
  	}
  	.create {
  		color: rgba(48, 48, 48, 0.5);
  	}
  }
  .selectize-input {
  	color: #303030;
  	font-family: inherit;
  	font-size: 13px;
  	line-height: 18px;
  	-webkit-font-smoothing: inherit;
  	background: #ffffff;
  	cursor: text;
  	display: inline-block;
  	border: 1px solid #d0d0d0;
  	padding: 8px 8px;
  	display: inline-block;
  	width: 100%;
  	overflow: hidden;
  	position: relative;
  	z-index: 1;
  	-webkit-box-sizing: border-box;
  	-moz-box-sizing: border-box;
  	box-sizing: border-box;
  	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
  	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
  	-webkit-border-radius: 3px;
  	-moz-border-radius: 3px;
  	border-radius: 3px;
  	input {
  		color: #303030;
  		font-family: inherit;
  		font-size: 13px;
  		line-height: 18px;
  		-webkit-font-smoothing: inherit;
  	}
  	>* {
  		vertical-align: baseline;
  		display: -moz-inline-stack;
  		display: inline-block;
  		zoom: 1;
  		*display: inline;
  	}
  	>input {
  		display: inline-block !important;
  		padding: 0 !important;
  		min-height: 0 !important;
  		max-height: none !important;
  		max-width: 100% !important;
  		margin: 0 2px 0 0 !important;
  		text-indent: 0 !important;
  		border: 0 none !important;
  		background: none !important;
  		line-height: inherit !important;
  		-webkit-user-select: auto !important;
  		-webkit-box-shadow: none !important;
  		box-shadow: none !important;
  		&::-ms-clear {
  			display: none;
  		}
  		&:focus {
  			outline: none !important;
  		}
  	}
  	&::after {
  		content: ' ';
  		display: block;
  		clear: left;
  	}
  }
  .selectize-control.single {
  	.selectize-input.input-active {
  		background: #ffffff;
  		cursor: text;
  		display: inline-block;
  		cursor: text;
  		input {
  			cursor: text;
  		}
  	}
  	.selectize-input {
  		cursor: pointer;
  		input {
  			cursor: pointer;
  		}
  		&:after {
  			content: ' ';
  			display: block;
  			position: absolute;
  			top: 50%;
  			right: 15px;
  			margin-top: -3px;
  			width: 0;
  			height: 0;
  			border-style: solid;
  			border-width: 5px 5px 0 5px;
  			border-color: #808080 transparent transparent transparent;
  		}
  	}
  	.selectize-input.dropdown-active {
  		&:after {
  			margin-top: -4px;
  			border-width: 0 5px 5px 5px;
  			border-color: transparent transparent #808080 transparent;
  		}
  	}
  }
  .selectize-control.multi {
  	.selectize-input.has-items {
  		padding: 6px 8px 3px;
  	}
  	.selectize-input {
  		>div {
  			cursor: pointer;
  			margin: 0 3px 3px 0;
  			padding: 2px 6px;
  			background: #f2f2f2;
  			color: #303030;
  			border: 0 solid #d0d0d0;
  		}
  		>div.active {
  			background: #e8e8e8;
  			color: #303030;
  			border: 0 solid #cacaca;
  		}
  	}
  	.selectize-input.disabled {
  		>div {
  			color: #7d7d7d;
  			background: #ffffff;
  			border: 0 solid #ffffff;
  		}
  		>div.active {
  			color: #7d7d7d;
  			background: #ffffff;
  			border: 0 solid #ffffff;
  		}
  	}
  }
  .selectize-input.full {
  	background-color: #ffffff;
  }
  .selectize-input.disabled {
  	cursor: default !important;
  	* {
  		cursor: default !important;
  	}
  }
  .selectize-input.focus {
  	-webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15);
  	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15);
  }
  .selectize-input.dropdown-active {
  	-webkit-border-radius: 3px 3px 0 0;
  	-moz-border-radius: 3px 3px 0 0;
  	border-radius: 3px 3px 0 0;
  	&::before {
  		content: ' ';
  		display: block;
  		position: absolute;
  		background: #f0f0f0;
  		height: 1px;
  		bottom: 0;
  		left: 0;
  		right: 0;
  	}
  }
  .selectize-dropdown-content {
  	overflow-y: auto;
  	overflow-x: hidden;
  	max-height: 200px;
  	-webkit-overflow-scrolling: touch;
  }
  .selectize-control.rtl.single {
  	.selectize-input {
  		&:after {
  			left: 15px;
  			right: auto;
  		}
  	}
  }
  .selectize-control.rtl {
  	.selectize-input {
  		>input {
  			margin: 0 4px 0 -2px !important;
  		}
  	}
  }
}

*/