.b__panel {
  border-radius: 6px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
  padding-top: 30px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 50px;
  margin: 25px 0 30px;
  
  .form &:first-child {
    margin-top: 0;
  }
  
  .ambassador-signup & {
    padding-left: 7px;
    padding-right: 7px;
    margin: 0;
  }
  
  .b__title {
    font-size: 22px;
    color: rgb(51, 51, 51);
    line-height: 1.2;
    margin-bottom: 20px;
  }
  
  &-after {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: black;
    
    @include vertical-align-content();
    
    a {
      text-decoration: none;
      color: black;
      font-size: 20px;
      font-weight: 600;
      display: inline-block;
      vertical-align: middle;
    }
  }

}

@media (max-width: 767px) {
	.b__panel {
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 30px;
		padding-top: 30px;
	}
}