.b__item {
	width: calc(50% + 28px);
	padding-right: 28px;
	float: left;
	z-index: 4;
	position: relative;

	.b__posting-item & {
		padding-bottom: 76px;
	}

	&-map {
		width: calc(50% - 28px);
		float: left;
		margin-left: 0;
		position: fixed;
		overflow: hidden;
		margin-bottom: -75px;
		position: absolute;
		left: calc(50% + 28px);
		max-height: calc(100vh - 80px);

		&:before {
			z-index: 20;
			content: '';
			display: block;
			position: absolute;
			left: -10px;
			top: 0;
			height: 100%;
			width: 10px;  
			box-shadow: 10px 0px 5px 0px rgba(0, 0, 0, 0.05);
		}

		#item-map {
			width: 100%;
			height: 100%;
			
			div {
				will-change: transform;
				transition: none;
			}
			
			a[title="Click to see this area on Google Maps"] {
				display: none !important;
			}
			
			.gm-style-cc {
				display:  none !important;
			}

			/* Google map zoom controls */
			.gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
				bottom: 50% !important;
				right: 56px !important;

				> .gmnoprint {
					transform: translateY(-50%);
					> div {
						cursor: pointer;
						user-select: none;
						box-shadow: none !important;
						border-radius: 0 !important;
						background-color: transparent !important;
						width: auto !important;
						height: auto !important;
						> div {
							display: none !important;
						}
					}
				}
				button {
					width: 48px !important;
					height: 48px !important;
					background-color: white !important;
					border-radius: 50% !important;
					box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);

					&:last-child {
						margin-top: 4px !important;
					}

					> div {
						left: 17px !important;
						top: 16px !important;
						img {
							transition: none;
						}
					}
				}
			}
		}
		
		.marker {
			a {
				cursor: pointer;
			}
			&-thumb {
				width: 100%;
				height: 100%;
				position: relative;
				border: solid 2px #3399ff;
				background-color: rgba(52, 154, 255, 0.25);
				border-radius: 50%;
				&__mask {
					background-image: url(data:image/svg+xml,%3Csvg%20%0A%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%0A%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%0A%20width%3D%2259px%22%20height%3D%2270px%22%3E%0A%3Cdefs%3E%0A%3Cfilter%20filterUnits%3D%22userSpaceOnUse%22%20id%3D%22Filter_0%22%20x%3D%22-1px%22%20y%3D%22-1px%22%20width%3D%2260px%22%20height%3D%2271px%22%20%20%3E%0A%20%20%20%20%3CfeOffset%20in%3D%22SourceAlpha%22%20dx%3D%220%22%20dy%3D%225%22%20/%3E%0A%20%20%20%20%3CfeGaussianBlur%20result%3D%22blurOut%22%20stdDeviation%3D%223.162%22%20/%3E%0A%20%20%20%20%3CfeFlood%20flood-color%3D%22rgb%280%2C%200%2C%200%29%22%20result%3D%22floodOut%22%20/%3E%0A%20%20%20%20%3CfeComposite%20operator%3D%22atop%22%20in%3D%22floodOut%22%20in2%3D%22blurOut%22%20/%3E%0A%20%20%20%20%3CfeComponentTransfer%3E%3CfeFuncA%20type%3D%22linear%22%20slope%3D%220.25%22/%3E%3C/feComponentTransfer%3E%0A%20%20%20%20%3CfeMerge%3E%0A%20%20%20%20%3CfeMergeNode/%3E%0A%20%20%20%20%3CfeMergeNode%20in%3D%22SourceGraphic%22/%3E%0A%20%20%3C/feMerge%3E%0A%3C/filter%3E%0A%0A%3C/defs%3E%0A%3Cg%20filter%3D%22url%28%23Filter_0%29%22%3E%0A%3Cpath%20fill-rule%3D%22evenodd%22%20%20stroke%3D%22rgb%28255%2C%20255%2C%20255%29%22%20stroke-width%3D%222px%22%20stroke-linecap%3D%22butt%22%20stroke-linejoin%3D%22miter%22%20fill%3D%22rgb%28255%2C%20255%2C%20255%29%22%0A%20d%3D%22M28.500%2C6.999%20C37.613%2C6.999%2045.001%2C14.387%2045.001%2C23.500%20C45.001%2C32.613%2028.500%2C50.000%2028.500%2C50.000%20C28.500%2C50.000%2012.000%2C32.613%2012.000%2C23.500%20C12.000%2C14.387%2019.388%2C6.999%2028.500%2C6.999%20Z%22/%3E%0A%3C/g%3E%0A%3C/svg%3E);
					background-position: center center;
					background-repeat: no-repeat;
					width: 59px;
					height: 70px;
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translateX(-50%) translateY(-50%);
				}
				&__photo {
					position: absolute;
					width: 33px;
					height: 33px;
					border-radius: 50%;
					background-color: #a81c30;
					background-position: center center;
					background-repeat: no-repeat;
					background-size: cover;
					left: 12px;
					top: 7px;
				}
			}
		}
	 
	}
	
	
	
	&-container {
		@include clear();
		background: white;
		margin-top: -25px;
		padding-bottom: 75px;
	}
	
	&-title {
		padding-top: 25px;
		padding-bottom: 15px;
		@include clear();
		transition: color 300ms ease-out 0s;
		
		a.link--back {
			position: relative;
			display: inline-block;
			text-decoration: none;
			color: $color__main_gray;
			font-size: 18px;
			font-weight: 500;
		}
		
		a.link--item-share {
			display: inline-block;
			font-size: 14px;
			font-weight: 500;
			color: #bbbbbb;
			text-decoration: none;
			float: right;
			margin-top: 5px;
		}
	}
	
	&-images {
		position: relative;
		height: 428px;
		overflow: hidden;
		
		&_counter {
			position: absolute;
			left: 18px;
			bottom: 24px;
			user-select: none;
			
			.icon {
				fill: white;
				color: white;
				width: 29px;
				height: 25px;
				display: inline-block;
				vertical-align: middle;
				margin-right: 4px;
			}
			
			span {
				font-size: 16px;
				font-weight: bold;
				color: white;
				text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);
				display: inline-block;
				vertical-align: middle;
			}
		}
		
		&_zoom {
			position: absolute;
			width: 46px;
			height: 46px;
			background: white;
			border-radius: 50%;
			right: 17px;
			bottom: 17px;
			cursor: pointer;
			box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.05);

			
			.icon {
				width: 21px;
				height: 20px;
				fill: $color__main_gray;
				color: $color__main_gray;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translateX(-50%) translateY(-50%);
			}
		}
		
		.slick-list {
			border-radius: 4px 4px 0 0;
		}
		&_carousel {
			.carousel-item {
				display: block;
				text-decoration: none;
				height: 428px;
				cursor: zoom-in;
				img {
					width: 100%;
					height: 100%;
					display: block;
					object-fit: cover;
				}
			}
		}
	}
	
	&-details {
		padding-top: 30px;
		padding-bottom: 45px;
		@include clear();
		
		&_description,
		&_additional {
			width: calc(50% - 14px);
		}
		
		&_description {
			float: left;
			padding: 0 18px;
	
			font-size: 13px;
			color: rgb(51, 51, 51);
			line-height: 1.846;
		}
		
		&_additional {
			float: right;
			padding-right: 18px;
			@include clear();
		}

		&_meta {
			font-size: 12px;
			color: rgb(153, 153, 153);
			line-height: 1.2;
			text-align: right;
			
			padding-top: 11px;
			// float: right;
			// width: 50%;
		}
		
		&_author {
			clear: both;
			padding-top: 25px;
			@include clear();
			
			&-photo {
				width: 72px;
				height: 72px;
				float: left;
				margin-right: 18px;
				overflow: hidden;
				border-radius: 50%;
				border: solid 1px rgba(0, 0, 0, 0.08);
				img {
					display: block;
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			
			&-details {
				width: calc(100% - 90px);
				float: right;
				padding: 5px 0 0;
				
				& > div {
					height: 22px;
				}
			}
			
			&-name {
				font-size: 16px;
				line-height: 22px;
				a {
					text-decoration: none;
					color: inherit;
				}
			}
			&-meta {
				font-size: 12px;
				line-height: 24px;
				span {
					display: inline-block;
					
					& + span {
						margin-left: 14px;
					}
				}
			}
			&-place {
				line-height: 24px;
				font-size: 12px;
				color: rgb(153, 153, 153);
			}
		}
		
		&_actions {
			margin-top: 20px;
			
			.btn {
				width: 100%;
				border-color: rgb(51, 153, 255);
				background-color: rgb(51, 153, 255);
				font-size: 14px;
				padding: 10px 0;

				&:hover {
					background-color: #3399ff;
				}
			}
		}
	}
	
}
.b__items_item {
	.item-title a {
		transition: color 240ms ease-out 0s;
		&:hover {
			color: $color__main_blue;
		}
	}
}


@media (max-width: 1199px) {
	.b__item {
		float: none;
		width: 100%;
		padding-right: 0;
		margin-bottom: 40px;
		
		&-map {
			width: 100% !important;
			height: 500px !important;
			margin-bottom: 0;
			float: none;
			position: relative;
			top: auto;
			left: auto;
			right: auto;
			bottom: auto;
		}
	}
}

@media (max-width: 767px) {
	#comments, #comment-form-wrapper {
		width: 100% !important;
	}
	
	.b__item {
		
		&-comments {
			margin-top: 0 !important;
			padding: 0;
		}
		
		&-map {
			height: 400px !important;
		}
		
		&-images {
			height: 60vw;
		}
		
		&-images_carousel {
			.carousel-item {
				height: 60vw;
			}
		}
		
		&-details_description {
			float: none;
			padding: 0;
			width: 100%;
			margin-bottom: 30px;
		}
		
		&-details_additional {
			float: none;
			padding: 0;
			width: 100%;
		}
	}
}




