.b__messanger {
	background-color: white;

	.site-container {
		height: 100%;
		max-width: 100%;
		padding: 0;
	}

	&-back-button {
		font-size: 14px;
		color: #666;
		display: none;
	}

	&-inner {
		height: calc(100vh - 80px);

		@include clear;

		background-color: #f9f9f9;
	}

	&-dialogs {
		background: white;

		width: span(4);

		height: 100%;
		border-right: solid 1px #ebebeb;
		padding-bottom: 30px;

		display: flex;
    	flex-direction: column;
    

		.dialogs {
			max-height: none;
			overflow: visible;

		}

		&_body {
			flex-shrink: 1;
			width: 100%;
		}

		&_title {
			width: 100%;
			flex-shrink: 0;
			height: 50px;
			border-bottom: solid 1px #ebebeb;
			padding-left: 22px;
			font-size: 14px;
			color: rgb(65, 65, 65);
			font-weight: 500;

			@include vertical-align-content;

			&:before {
				height: 105%;
			}
		}
	}

	&-chat {
		width: span(5);

		position: relative;
		height: 100%;
		display: flex;
		overflow: hidden;
		flex-direction: column;

		&_form {
			width: 100%;
			flex-shrink: 0;
			border-top: solid 1px #ebebeb;
			overflow: hidden;

			.chat-form {
				display: flex;
				align-items: flex-end;

				&_actions {
					height: auto;
				}
			}
		}

		&_body {
			position: relative;
			flex-shrink: 1;
			flex-grow: 3;
			height: calc(100% - 63px);
			overflow-y: auto;
			width: 100%;
		}
	}

	&-peer {
		width: span(3);
		margin-right: 0;

		border-left: solid 1px #ebebeb;
		background-color: white;
		height: 100%;
		padding-bottom: 30px;
	}
}


@media (max-width: 767px) {

	#messenger {
		overflow: hidden;
		width: 100%;
	}
	.b__messanger {
		background-color: white;
		height: 100%;

		.site-container {
			height: 100%;
		}

		&-back-button {
			order: 1;
			padding-left: 15px;
			padding-right: 15px;
			padding-top: 10px;
			padding-bottom: 10px;

			&.active {
				display: block;
			}
		}

		&-inner {
			height: 100%;
			width: 100%;
			
			flex-direction: column;
			
			padding-bottom: 0;
			
			> * {
				flex-shrink: 0 !important;
			}
		}
		
		&-dialogs {
			width: 100% !important;
			order: 1;
			height: 100%;
			border: none;
			padding-bottom: 0;
			
			&_body {
				height: calc(100vh - 100px);
			}

			&.mobile-hidden {
				display: none;
			}
		}
		
		&-peer {
			order: 2;
			width: 100% !important;
			height: auto;
			border: none;
			flex: auto;
			padding-bottom: 15px;
			padding-top: 15px;
			background-color: #f6f6f6;

			display: none;

			&.active {
				display: block;
			}
			
			
			.b__profile-card {
				position: relative;
				
				padding-left: 70px;
				padding-top: 0;
				padding-bottom: 0;
				text-align: left;
				margin-bottom: 0;
				
				&_photo {
					width: 40px;
					height: 40px;
					
					position: absolute;
					top: 0;
					left: 15px;
				}
				
				&_name {
					font-size: 15px;
				}
				
				&_place {
					font-size: 12px;
				}
			}
			
			.b__profile-card_meta,
			.b__profile-card_about {
				display: none;
			}
		}
		
		&-chat {
			order: 3;
			flex-shrink: 1 !important;
			flex-grow: 3 !important;
			width: 100% !important;
			height: calc(100vh - 153px);
			padding-bottom: 0 !important;

			display: none;

			&_form {
				padding-bottom: env(safe-area-inset-bottom);
			}

			&.active {
				display: block;
			}
		}
	}
}
