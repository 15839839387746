svg.icon {
	display: inline-block;
	width: 32px;
	height: 32px;
	@include trans(fill);

	.item-place & {
		fill: #ae2b3e;
		color: #ae2b3e;
		width: 100%;
		height: 100%;
	}
	
	&.icon--social-fb,
	&.icon--social-tb {
		width: 10px;
		height: 18px;  
	}
	
	&.icon--social-tw {
		width: 16px;
		height: 13px;
	}
	
	&.icon--social-ig {
		width: 16px;
		height: 16px;
	}
	
	&.icon--social-pi {
		width: 14px;
		height: 18px;
	}
	
	&.icon--speech-bubble {
		width: 26px;
		height: 25px;
		fill: #bfbfbf;
		color: #bfbfbf;
		
		a:hover & {
			fill: #666666;
			color: #666666;
		}
		
		.header--extended & {
			fill: white;
			color: white;
		}
		.header--extended a.link--speech-bubble:hover & {
			fill: #bfbfbf;
			color: #bfbfbf;
		}
	}
	
	&.icon--activity-feed {
		width: 23px;
		height: 23px;
		fill: #bfbfbf;
		color: #bfbfbf;

		a:hover & {
			fill: #666666;
			color: #666666;
		}
		
		.header--extended & {
			fill: white;
			color: white;
		}
		.header--extended a.link--activity-feed:hover & {
			fill: #bfbfbf;
			color: #bfbfbf;
		}
	}
	
	&.icon--lazy-load {
		width: 26px;
		height: 26px;
		fill: $color__main_blue;
		color: $color__main_blue;
		display: inline-block;
		vertical-align: middle;
		position: relative;
		top: -2px;
	}
	
	&.icon--edit {
		width: 16px;
		height: 15px;
		fill: #c7c7c7;
		color: #c7c7c7;
		vertical-align: middle;
		position: relative;
		top: -2px;
	}
	
	&.icon--actions {
		fill: #bebebe;
		color: #bebebe;
		width: 23px;
		height: 23px;

		button:hover & {
			fill: $color__main_gray;
			color: $color__main_gray;
		}
	}
	
	&.icon--file {
		width: 22px;
		height: 19px;
		fill: #bfbfbf;
		color: #bfbfbf;
		
		.chat-form_file:hover & {
			fill: $color__main_gray;
			color: $color__main_gray;
		}
	}
	
	&.icon--search {
		.field--search & {
			width: 26px;
			height: 25px;
			fill: #dadada;
			color: #dadada;
		}
	}
}