.tabs {
  &__item {
    display: none;
    
    &:first-child {
      display: block;
    }
  }
}

.tabs-content {
  
}