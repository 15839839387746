.header-menu {
	position: fixed;
	top: 0;
	left: 0;
	
	z-index: 1100;
	
	background-color: #fff;
	
	width: 100%;
	height: 100%;
	
	overflow-y: auto;
	
	display: none;
	
	&.active {
		display: block;
	}
	
	&__inner-content {
		width: 100%;
		
		padding-left: 15px;
		padding-right: 15px;
		padding-bottom: 100px;
	}
	
	&__header {
		height: 50px;
		width: 100%;
		
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		
		margin-bottom: 40px;
	}
	
	&__logo {
		font-family: TeXGyreAdventor;
		line-height: 1;
		font-size: 26px;
		color: #000;
		font-weight: 700;
		font-style: italic;
		text-decoration: none !important;
	}
	
	
	&__close-button {
		-webkit-appearance: none;
		border: none;
		outline: none !important;
		background: none;
		cursor: pointer;
		
		width: 34px;
		height: 40px;
		
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		
		text-align: center;
		
		color: #000;
		
		svg {
			width: 20px;
			height: 20px;
		}
		
		&:active {
			opacity: 0.7;
		}
		
	}
	
	&__enter-buttons {
		width: 100%;
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		
		margin-top: 40px;
		
		.btn {
			width: 48%;
			margin: 0;
		}
		
		.btn.btn--footer-pure {
			color: #666;
		}
	}
	
	&__navigation {
		width: 100%;
		
		ul {
			list-style: none;
			padding: 0;
			margin: 0;
			
			width: 100%;
			
			li {
				a:not(.btn) {
					font-size: 15px;
					color: #666666;
					text-decoration: none !important;
					position: relative;
					
					display: -webkit-flex;
					display: -moz-flex;
					display: -ms-flex;
					display: -o-flex;
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					align-items: center;
					
					span.badge {
						position: relative;
						margin-left: auto;
						flex-shrink: 0;
						transform: none;
						top: auto;
						right: auto;
						left: auto;
						bottom: auto;
					}
					
					.header-user-menu_photo {
						display: -webkit-flex;
						display: -moz-flex;
						display: -ms-flex;
						display: -o-flex;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						
						width: 24px;
						height: 24px;
						
						flex-shrink: 0;
						margin-right: 10px;
						fill: #bbb;
						
						img {
							border-radius: 50%;
							width: 100%;
							height: 100%;
							
							object-fit: cover;
						}
					}

					&:hover {
						color: $color__main_gray;
					}
				}
				
				&:not(:last-child) {
					margin-bottom: 15px;
				}
			}
			
			.divider {
				height: 1px;
				background-color: #eee;
				width: 100%;
				
				margin-top: 20px !important;
				margin-bottom: 20px !important;
				display: block;
			}
		}
	}
}

@include media-breakpoint-up(lg) {
	.header-menu {
		display: none !important;
	}
}

