$font__default: 'SF UI  Text';
$font__extra: 'TeXGyreAdventor';
$font__special: 'Ahellya';


$color__main_blue: #227cd6;
$color__main_blue__opacity: rgba(34, 125, 215, 0.9);;
$color__main_gray: #333333;
$color__site_green: #33cc99;
$color__main_red: #cc3333;
$color__main_green: #33cc99;
$color__main_yellow: #e8ce2c;
$color__main_orange: #ffa500;
$color__main_purple: #ba3bdd;

