.b__people {
	@include clear;

	position: relative;
	overflow: hidden;

	&:before {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		width: 50%;
		background-color: white;
		bottom: 0;
		z-index: -1;
		margin-left: -25%;

		@include media(tablet-p-only) {
			content: none;
		}


		@include media(phone-only) {
			content: none;
		}
	}

	&-sidebar {
		background-color: white;
		height: 100%;

		@include media(tablet-l) {
			width: span(3);
		}


		@include media(tablet-p-only) {
			width: auto;
			height: 150px !important;
			padding: 0 38px;
			margin: 0 -38px;
			float: none;
		}


		@include media(phone-only) {
			width: auto;
			height: 150px !important;
			padding: 0 38px;
			margin: 0 -38px;
			float: none;
		}


		.b__title {
			text-align: left;
			padding-top: 40px;
		}

		&_form {
			margin-top: 30px;
		}
	}

	&-inner {
		width: span(9);
		margin-right: 0;

		@include media(phone-only) {
			width: 100%;
			float: none;
		}


		@include media(tablet-p-only) {
			width: 100%;
			float: none;
		}


		min-height: 700px;
		padding-top: 40px;
		padding-bottom: 50px;
	}
}