.btn {
  display: block;
  background-color: $color__main_blue;
  color: rgba(255, 255, 255, 0.9);
  border-radius: 6px;
  height: 38px;
  font-weight: 600;
  border: solid 1px $color__main_blue;
  box-sizing: border-box;
  padding: 9px 0px;
  text-decoration: none;
  text-align: center;
  // min-width: 150px;
  max-width: 100%;

  header.header & {
    min-width: 0;
    padding: 9px 30px;
  }

  .item-price &,
  .b__item-details_price & {
    height: auto;
    padding: 6px 8px;
    font-weight: bold;
    font-size: 13px;
    border-radius: 3px;
    min-width: auto;
    opacity: .9;
    transition: all 300ms ease-out 0s;
    &:hover {
      opacity: 1;
    }
  }
  .b__item-details_price & {
    white-space: nowrap;
  }
  
  &[disabled="disabled"] {
    opacity: .5;
  }
  
  &:hover {
    color: white;
  }

  .header & {
    background-color: $color__main_blue__opacity;
    color: rgba(255, 255, 255, 0.9);
    font-size: 14px;
    &:hover {
      background-color: $color__main_blue;
    }
  }
  
  &.btn--blue {
    background-color: #009fe3;
    border: solid 1px #009fe3;
  }
  
  &.btn--pure {
    background-color: transparent;
    border: solid 1px white;
    
    .header.header--extended & {
      background-color: transparent;
      font-size: 14px;
      border-color: rgba(255, 255, 255, 0.9);
      color: rgba(255, 255, 255, 0.9);
    }
    
    .header:not(.header--extended) & {
      background-color: transparent;
      border: solid 1px $color__main_blue__opacity;
      color: $color__main_blue__opacity;
      font-size: 14px;
/*
      &:hover {
        border-color: $color__main_blue;
        color: $color__main_blue;
      }
*/    
    }
  }
  
  &.btn--green {
    background-color: $color__site_green;
    border: solid 1px $color__site_green;
  }
  
  &.btn--red {
    background-color: $color__main_red;
    border: solid 1px $color__main_red;
  }
  
  &.btn--gray {
    background-color: $color__main_gray;
    border: solid 1px $color__main_gray;
  }
  
  &.btn--footer-pure {
    border: solid 1px #666666;
    background: transparent;
  }
  
  &.btn--gmail {
    border: solid 2px #dd473b;
    color: #dd473b;
    font-size: 16px;
    font-weight: 600;
    background: transparent;
    height: 56px;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 56px;
    display: inline-block;
    
    .icon {
      margin-top: -4px;
      margin-right: 18px;
      width: 30px;
      height: 24px;
      vertical-align: middle;
      display: inline-block;
    }
  }
  @-moz-keyframes spin {
    from { -moz-transform: rotate(0deg); }
    to { -moz-transform: rotate(360deg); }
  }
  @-webkit-keyframes spin {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
  }
  @keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
  }
  &.btn--lazy-load {
    display: inline-block;
    background: transparent;
    color: $color__main_blue;
    border: 0;
    padding: 0;
    margin: 30px 0 0;
    font-size: 15.87px;
    font-weight: 400;
    
    .icon {
      margin-right: 6px;
    }

    &:hover {
      .icon {
        animation: spin 1s infinite;
      }
    }

    .b__items & {
      margin-bottom: 40px;
      display: block;
      margin-left: auto;
      margin-right: auto;
      clear: both;
    }
  }
  
  &.btn--follow {
    border-width: 1px;
    border-color: rgb(51, 153, 255);
    border-style: solid;
    border-radius: 6px;

    height: 38px;
    font-size: 14px;
    font-weight: 600;
    color: #3399ff;
    background: transparent;
    display: inline-block;
    width: 137px;
  }
  
  &.btn--following {
    border-width: 1px;
    border-color: #969696;
    border-style: solid;
    border-radius: 6px;

    height: 38px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    background: #969696;
    display: inline-block;
    width: 137px;
  }
  
  &.btn--send-message {
    height: 48px;
    background-color: #3399ff;
    border-color: #3399ff;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
  }
  
  &.btn--price {
    background-color: white;
    border: solid 1px $color__main_red;
    color: $color__main_red;
   
  }
  &.btn--rent {
    background-color: white;
    border: solid 1px $color__main_green;
    color: $color__main_green;
    // border: solid 1px #ba3bdd;
    // color: #ba3bdd;
    // #ba3bdd;
  }
  &.btn--repair {
    background-color: white;
    border: solid 1px $color__main_yellow;
    color: $color__main_yellow;
  }
  &.btn--exchange {
    background-color: white;
    border: solid 1px $color__main_orange;
    color: $color__main_orange;
  }
  &.btn--donate {
    background-color: white;
    border: solid 1px $color__main_blue;
    color: $color__main_blue;
  }
  
  &.btn--actions {
    display: inline-block;
    padding: 0;
    margin: 0;
    width: 23px;
    height: 23px;
    border: 0;
    background-color: transparent;
    
    &:before {
      content: none;
    }
  }
  
  &.btn--submit {
    padding: 5px;
    background-color: transparent;
    border: 0;
    font-size: 14px;
    font-weight: bold;
    color: #3399ff;
    height: auto;

    .b__messanger & {
      min-width: auto;
    }
  }
  &.btn--search {
    background-color: transparent;
    border: 0;
    padding: 0;
    margin-top: 0;
    display: inline-block;
    vertical-align: middle;
  }
  
  &.btn-social.btn-facebook {
    border-radius: 4px;
    border: 0;
    margin-top: 19px;
    text-align: center;
    background: #3b5998;
    display: block;
    font-size: 16px;
    font-weight: 600;
    color: white;
    text-decoration: none;
    height: 56px;
    @include vertical-align-content();
    .icon {
      width: 25px;
      height: 25px;
      fill: white;
      display: inline-block;
      vertical-align: middle;
      float: left;
      margin-top: 15px;
      margin-left: 15px;
    }
  }
}

:root .color {
  &--light-gray {
    color: #999999;
  }
}