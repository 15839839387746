.search {
	width: 100%;

	@include media(desktop) {
		width: span(8 of 12 wide);
		margin-right: 0;
		margin-left: span(2 wide);
	}

	.b__main-header & {
		padding-top: 280px;
		margin-bottom: -37px;
	}

	.search-tabs,
	.search-tabs-content {
		padding: 0;
		margin: 0;
		list-style: none;
	}

	.search-tabs {
		font-size: 0;
		white-space: nowrap;
		position: relative;
		z-index: 1;
		width: 80%;
		
		text-align: left;

		// }
		.search-tab {
			display: inline-block;
			width: auto;
			height: 61px;
			text-align: center;
			position: relative;


			&:not(:first-child):before {
				content: '';
				display: block;
				left: -1px;
				top: 50%;
				transform: translateY(-50%);
				background-color: rgb(255, 255, 255);
				opacity: 0.251;
				position: absolute;
				width: 1px;
				height: 30px;
			}

			label {
				font-size: 18px;
				font-weight: 600;
				color: rgba(255,255,255,0.5);
				text-decoration: none;
				display: block;
				width: 100%;
				height: 100%;
				vertical-align: middle;
				cursor: pointer;
				margin-bottom: 0;
				
				padding-left: 20px;
				padding-right: 20px;
				min-width: 99px;

				@include vertical-align-content;
				
				&:hover {
					color: #fff;
				}
			}

			input {
				display: none;

				&:checked + label {
					background: white;
					border-radius: 6px 6px 0 0;
					position: relative;
					color: $color__main_gray;

					&:after {
						content: '';
						display: block;
						bottom: -10px;
						left: 0;
						right: 0;
						background: white;
						position: absolute;
						height: 10px;
						z-index: 0;
					}
				}
			}
		}
	}

	.search-tabs-content {
		border-radius: 6px;

		// overflow: hidden;
		background-color: rgb(255, 255, 255);
		box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.05);
		height: 78px;
		position: relative;
		z-index: 2;

		@include clear;

		.input {
			width: 80%;
			float: left;

			@include clear;

			height: 78px;
		}

		.submit {
			width: 20%;
			height: 100%;
			float: right;
			border-radius: 0 6px 6px 0;
			overflow: hidden;

			.btn {
				height: 100%;
				width: 100%;
				border-radius: 0;
				font-size: 20px;
				opacity: 1;
				transition: all 240ms ease-out 0s;

				.page-home & {
					background-color: #add96c;
					border-color: #add96c;
					
					&:hover {
						background-color: darken(#add96c, 5%);
						border-color: darken(#add96c, 5%);
					}
					
					&:hover {
						background-color: darken(#add96c, 10%);
						border-color: darken(#add96c, 10%);
					}
				}
			}
		}
	}

	.input-place {
		position: relative;
		width: 50%;
		height: 78px;
		float: left;

		.selectize-input {
			.item {
				height: 100%;
				padding-left: 51px;

				&:before {
					content: '';
					height: 105%;
					display: inline-block;
					vertical-align: middle;
				}
			}
		}

		.selectize-dropdown {
			margin-top: 17px;
			position: absolute;
			background: white;
			box-sizing: border-box;

			// margin-top: 17px;
			box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
			border-radius: 6px;
			border-width: 0;
			white-space: normal;

			// right: 0;
			z-index: 150;

			&:after {
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 7px 7px 7px;
				border-color: transparent transparent #ffffff transparent;
				position: absolute;
				top: -7px;
				right: 15px;
				left: auto;
			}

			&-content {
				padding: 15px 27px;

				> div {
					font-size: 13px;
					font-weight: 500;
					line-height: 2.1;
					transition: color 100ms ease-out 0s;

					* {
						transition: color 100ms ease-out 0s;
					}

					&:hover {
						color: #227cd6;
					}
				}
			}
		}

		.icon {
			width: 22px;
			height: 30px;
			fill: #bfbfbf;
			color: #bfbfbf;
			display: inline-block;
			vertical-align: middle;
			position: absolute;
			left: 26px;
			top: 50%;
			transform: translateY(-50%);
		}

		.selectize-control,
		.selectize-input {
			height: 100%;
		}

		input {
			width: 100%;
			padding-right: 0;
			//margin-left: 51px;
			background-color: transparent;
			padding-left: 70px;
			font-size: 16px;
			font-weight: 400;
			font-family: $font__default;
			color: $color__main_gray;
			border: 0;

			// width: calc(100% - 51px);
			width: 100% !important;
			cursor: pointer;
			height: 100%;
			display: inline-block;
			vertical-align: middle;

			&::-webkit-input-placeholder {
				color: #bfbfbf;
			}

			&:-moz-placeholder {
				/* Firefox 18- */
				color: #bfbfbf;
			}

			&::-moz-placeholder {
				/* Firefox 19+ */
				color: #bfbfbf;
			}

			&:-ms-input-placeholder {
				color: #bfbfbf;
			}
		}
	}
}

.input-query {
	position: relative;
	box-sizing: border-box;
	
	&:before {
		content: "";
		display: block;
		width: 1px;
		height: 40px;
		background-color: #bfbfbf;
		opacity: .3;
		position: absolute;
		top: 50%;
		margin-top: -20px;
		right: 0;
		pointer-events: none;
		z-index: 8;
	}

	.search & {
		height: 78px;
		width: 50%;
		float: left;
	}

	.header-search & {
		float: none;
		width: auto;
		height: 80px;

		&:hover {
			.icon {
				fill: #9d9d9d;
				color: #9d9d9d;
			}

			input {
				&::-webkit-input-placeholder {
					color: #9d9d9d;
				}

				&:-moz-placeholder {
					color: #9d9d9d;
				}

				&::-moz-placeholder {
					color: #9d9d9d;
				}

				&:-ms-input-placeholder {
					color: #9d9d9d;
				}
			}
		}
	}

	.search-button {
		-webkit-appearance: none;
		padding: 0;
		margin: 0;
		cursor: pointer;
		width: 50px;
		height: 50px;

		background: none;
		box-shadow: none;
		border: none;

		border-radius: 0;

		position: absolute;
		top: 12px;
		left: 20px;

		z-index: 6;

		fill: #bbbbbb;
		color: #bbbbbb;
			
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		transition: all 0.2s ease-in-out;

		.icon {
			width: 26px;
			height: 25px;
			fill: #9d9d9d;
			color: #9d9d9d;
			display: inline-block;
			vertical-align: middle;
			position: relative;
			left: auto;
			top: auto;
			transform: none;
			z-index: 1;

			transition: all 0.2s ease-in-out;
		}

		&:hover {

			.icon {
				fill: #9d9d9d;
				color: #9d9d9d;
			}
		}
	}

	.icon {
		width: 26px;
		height: 25px;
		fill: #bfbfbf;
		color: #bfbfbf;
		display: inline-block;
		vertical-align: middle;
		position: absolute;
		left: 24px;
		top: 50%;
		transform: translateY(-50%);
		z-index: 1;

		.header-search & {
			fill: #bbbbbb;
			color: #bbbbbb;
		}
	}

	input {
		position: relative;
		z-index: 2;
		padding-left: 70px;
		padding-right: 0;
		width: 100%;
		font-size: 16px;
		font-weight: 400;
		font-family: $font__default;
		color: $color__main_gray;
		border: 0;
		background-color: transparent;
		display: inline-block;
		vertical-align: middle;
		height: 100%;

		.header-search & {
			font-size: 14px;
			font-weight: 500;
			padding: 12px;

			&::-webkit-input-placeholder {
				color: #bbbbbb;
			}

			&:-moz-placeholder {
				/* Firefox 18- */
				color: #bbbbbb;
			}

			&::-moz-placeholder {
				/* Firefox 19+ */
				color: #bbbbbb;
			}

			&:-ms-input-placeholder {
				color: #bbbbbb;
			}
		}

		&::-webkit-input-placeholder {
			color: #bfbfbf;
		}

		&:-moz-placeholder {
			/* Firefox 18- */
			color: #bfbfbf;
		}

		&::-moz-placeholder {
			/* Firefox 19+ */
			color: #bfbfbf;
		}

		&:-ms-input-placeholder {
			color: #bfbfbf;
		}
	}
}

@include media-breakpoint-down(lg) {
	.search {
		width: 100%;
		max-width: 777px;
		margin: 0 auto;
		
		.search-tabs {
			
			.search-tab {
				
				label {
					padding-left: 19px;
					padding-right: 19px;
				}
			}
		}
	}

	.input-query {
		.search-button {
			left: 10px;
		}
	}
}

@include media-breakpoint-down(md) {
	.search {
		max-width: 692px;
		
		.input-query {
			width: 40%;
		}
		
		.input-place {
			width: 60%;
		}
		
		.search-tabs {
			
			.search-tab {
				
				label {
					padding-left: 18px;
					padding-right: 18px;
					
					min-width: 80px;
				}
			}
		}
	}

	.input-query {
		.search-button {
			left: 10px;
			top: 5px;
		}
	}
}


@include media-breakpoint-down(sm) {
	.search {
		max-width: 500px;
		margin: 0 auto;
		
		.search-tabs {
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			width: 100%;
			
			.search-tab {
				height: 40px;
				flex: 1;
				
				label {
//					font-size: 3.8vw;
//					padding-left: 10px;
//					min-width: 40px;
//					padding-right: 10px;
					
					font-size: 3.4vw;
//					padding-left: 2.7vw;
					min-width: 0;
//					padding-right: 2.7vw;
					
					padding-left: 10px;
					padding-right: 10px;
					text-align: center;
					width: 100%;
				}
			}
		} 
		
		
		.input-query {
			width: 100%;
			float: none;
			height: 50px;
			
			border-bottom: 1px solid rgba(191,191,191,0.3);
			
			&:before {
				display: none;
			}
			
			.icon {
				left: 13px;
			}
			
			input {
				padding-left: 50px;
			}
		}
		
		.input-place {
			width: 100%;
			float: none;
			height: 50px;
			
			.icon {
				left: 14px;
			}
			
			input {
				padding-left: 50px;
			}
		}
		
		.search-tabs-content {
			border-radius: 6px;
			height: auto;
			
			.input {
				width: 100%;
				float: none;
				height: auto;
			}
			
			.submit {
				width: 100%;
				float: none;
				height: 50px;
				border-radius: 0 0 6px 6px;
			}
		}
	}

	.input-query {
		.search-button {
			left: 6px;
			top: 0;

			.icon {
				left: auto !important;
			}
		}
	}
}

@media (max-width: 399px) {
	.search {
		.search-tabs {
//			padding-bottom: 4px;
//			margin-bottom: -4px;
//			width: 100%;
//			overflow-x: auto;
//			
			
			.search-tab {
				
//				&:last-child {
//					padding-right: 15px;
//				}
				
				label {
					font-size: 3.4vw;
					padding-left: 2.7vw;
					min-width: 0;
					padding-right: 2.7vw;
				}
				
//				input {
//
//					&:checked + label {
//						&:after {
//							bottom: -4px;
//							height: 4px;
//						}
//					}
//				}
			}
		} 
	}
}

@media (min-width: 480px) and (max-width: 767px) {
	.search {
		.search-tabs {
			.search-tab {
				label {
					font-size: 16px;
				}
			}
		}
	}
}

