.b__item-comments {
  padding: 0 18px;
  
  .b__title {
    text-align: left;
    margin-bottom: 18px;
  }
  
}

.b__comments {
  padding-bottom: 25px;
  
  ul.comments {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  
  .comment {
    
    .comment {
      margin-top: 10px;
    }
    
    .comments {
      padding-left: 44px;
    }
    
    &-item {
      @include clear();
    }
    
    &-author-img {
      width: 35px;
      height: 35px;
      overflow: hidden;
      border-radius: 50%;
      float: left;
      
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    
    &-details {
      width: calc(100% - 45px);
      float: right;
      line-height: 18px;
    }
    
    &-author-name {
      display: inline-block;
      font-size: 15px;
      font-weight: 600;
      color: rgb(34, 124, 214);
      line-height: 1.2;
      &:after {
        content: '\00a0';
      }
    }
    
    &-text {
      font-size: 15px;
      display: inline-block;
      overflow: hidden;
      width: 100%;

      p {
        margin: 0;
        display: inline-block;

        + p {
          margin-top: 15px;
        }
      }
    }
    
    &-meta {
      font-size: 12px;
      color: rgb(187, 187, 187);
      line-height: 18px;
      
      a {
        text-decoration: none;
        color: rgb(187, 187, 187);
        
        &:hover {
          text-decoration: underline;
        }
      }
    }
    
  }
  
}