@font-face {
	font-family: 'TeXGyreAdventor';
	src: url('../fonts/hinted-subset-TeXGyreAdventor-BoldItalic.eot');
	src: local('TeXGyreAdventor-BoldItalic'),
		url('../fonts/hinted-subset-TeXGyreAdventor-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/hinted-subset-TeXGyreAdventor-BoldItalic.woff2') format('woff2'),
		url('../fonts/hinted-subset-TeXGyreAdventor-BoldItalic.woff') format('woff'),
		url('../fonts/hinted-subset-TeXGyreAdventor-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Ahellya';
	src: url('../fonts/hinted-subset-Ahellya.eot');
	src: local('Ahellya'),
		url('../fonts/hinted-subset-Ahellya.eot?#iefix') format('embedded-opentype'),
		url('../fonts/hinted-subset-Ahellya.woff2') format('woff2'),
		url('../fonts/hinted-subset-Ahellya.woff') format('woff'),
		url('../fonts/hinted-subset-Ahellya.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'SF UI  Text';
	src: url('../fonts/hinted-subset-SFUIText-Regular.eot');
	src: local('SF UI  Text Regular'), local('SFUIText-Regular'),
		url('../fonts/hinted-subset-SFUIText-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/hinted-subset-SFUIText-Regular.woff2') format('woff2'),
		url('../fonts/hinted-subset-SFUIText-Regular.woff') format('woff'),
		url('../fonts/hinted-subset-SFUIText-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'SF UI  Text';
	src: url('../fonts/hinted-subset-SFUIText-Light.eot');
	src: local('SF UI  Text Light'), local('SFUIText-Light'),
		url('../fonts/hinted-subset-SFUIText-Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/hinted-subset-SFUIText-Light.woff2') format('woff2'),
		url('../fonts/hinted-subset-SFUIText-Light.woff') format('woff'),
		url('../fonts/hinted-subset-SFUIText-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'SF UI  Text';
	src: url('../fonts/hinted-subset-SFUIText-Bold.eot');
	src: local('SF UI  Text Bold'), local('SFUIText-Bold'),
		url('../fonts/hinted-subset-SFUIText-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/hinted-subset-SFUIText-Bold.woff2') format('woff2'),
		url('../fonts/hinted-subset-SFUIText-Bold.woff') format('woff'),
		url('../fonts/hinted-subset-SFUIText-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'SF UI  Text';
	src: url('../fonts/hinted-subset-SFUIText-Medium.eot');
	src: local('SF UI  Text Medium'), local('SFUIText-Medium'),
		url('../fonts/hinted-subset-SFUIText-Medium.eot?#iefix') format('embedded-opentype'),
		url('../fonts/hinted-subset-SFUIText-Medium.woff2') format('woff2'),
		url('../fonts/hinted-subset-SFUIText-Medium.woff') format('woff'),
		url('../fonts/hinted-subset-SFUIText-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'SF UI  Text';
	src: url('../fonts/hinted-subset-SFUIText-Semibold.eot');
	src: local('SF UI  Text Semibold'), local('SFUIText-Semibold'),
		url('../fonts/hinted-subset-SFUIText-Semibold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/hinted-subset-SFUIText-Semibold.woff2') format('woff2'),
		url('../fonts/hinted-subset-SFUIText-Semibold.woff') format('woff'),
		url('../fonts/hinted-subset-SFUIText-Semibold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}
