form.form {
	.b__panel & {
		padding: 0 20px;

		@include media(phone-only) {
			padding: 0 15px;
		}
	}

	.field {
		&--hr {
			height: 2px;
			background-color: rgba(222, 219, 219, 0.31);
			margin: 30px 0;
			border-radius: 4px;
		}

		& + .field {
			margin-top: 10px;

			&--hr {
				margin-top: 30px;
			}

			&--fb-button {
				margin-top: 19px;
			}
		}

		&--textarea {
			textarea {
				resize: none;
				-webkit-appearance: none;
				background: none;
				box-shadow: none;
				
				width: 100%;
				box-sizing: border-box;
				padding: 15px 15px;
				min-height: 165px;
				color: $color__main_gray;
				font-size: 16px;
				font-weight: 400;
				font-family: $font__default;
				border-width: 2px;
				border-color: #f2f2f2;
				border-style: solid;
				border-radius: 4px;

				&::-webkit-input-placeholder {
					color: #cccccc;
				}

				&:-moz-placeholder {
					color: #cccccc;
				}

				&::-moz-placeholder {
					color: #cccccc;
				}

				&:-ms-input-placeholder {
					color: #cccccc;
				}

				&:hover,
				&:active,
				&:focus {
					border-color: darken(#f2f2f2, 5%);
				}
			}
		}

		&--text,
		&--password,
		&--email,
		&--tel {
			input {
				resize: none;
				-webkit-appearance: none;
				background: none;
				box-shadow: none;
				
				
				border-width: 2px;
				border-color: #f2f2f2;
				border-style: solid;
				border-radius: 4px;
				background-color: rgb(255, 255, 255);
				height: 52px;
				width: 100%;
				box-sizing: border-box;
				padding: 0 15px;
				color: $color__main_gray;
				font-size: 16px;
				font-weight: 400;
				font-family: $font__default;
				transition: border-color 300ms ease-out 0s;

				&::-webkit-input-placeholder {
					color: #cccccc;
				}

				&:-moz-placeholder {
					color: #cccccc;
				}

				&::-moz-placeholder {
					color: #cccccc;
				}

				&:-ms-input-placeholder {
					color: #cccccc;
				}

				&:-webkit-autofill {
					background-color: #fff;
				}

				&:hover,
				&:active {
					border-color: darken(#f2f2f2, 5%);
				}
			}
		}

		&--submit {
			input[type="submit"],
			.btn {
				border-radius: 4px;
				width: 100%;
				height: 56px;
				box-sizing: border-box;
				font-size: 16px;
				font-weight: 600;
			}
		}

		&--links {
			padding: 16px 0 0px;

			a {
				font-size: 12px;
				color: #adadad;
				line-height: 1.2;
				text-decoration: none;
			}
		}

		&--select {
			
			.selectize-control {
				position: relative;
				text-align: left;

				.selectize-input {
					border-width: 2px;
					border-color: #f2f2f2;
					border-style: solid;
					border-radius: 4px;
					background-color: rgb(255, 255, 255);
					height: 52px;
					width: 100%;
					box-sizing: border-box;
					padding: 0 15px;
					color: $color__main_gray;
					font-size: 16px;
					font-weight: 400;
					font-family: $font__default;
					text-align: left;

					@include vertical-align-content;

					&:hover,
					&:active,
					&:focus {
						border-color: darken(#f2f2f2, 5%);
					}

					.item,
					input {
						display: inline-block;
						vertical-align: middle;
						color: $color__main_gray;
						font-size: 16px;
						font-weight: 400;
						font-family: $font__default;
						margin: 0;
						border: 0;
						pointer-events: none;

						&::-webkit-input-placeholder {
							color: #cccccc;
						}

						&:-moz-placeholder {
							color: #cccccc;
						}

						&::-moz-placeholder {
							color: #cccccc;
						}

						&:-ms-input-placeholder {
							color: #cccccc;
						}
					}

					.item {
						width: calc(100% - 30px) !important;
					}

					&.not-full {
						input {
							color: #cccccc;
						}
					}

					&:after {
						content: '';
						display: block;
						position: absolute;
						width: 13px;
						height: 7px;
						background-image: url("data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgd2lkdGg9IjEzcHgiIGhlaWdodD0iN3B4Ij48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiICBvcGFjaXR5PSIwLjI1MSIgZmlsbD0icmdiKDEwMiwgMTAyLCAxMDIpIiBkPSJNMTMuMDEwLDAuNzE2IEw2LjcxNiw3LjAxMCBMNi41MDAsNi43OTQgTDYuMjg0LDcuMDEwIEwtMC4wMTAsMC43MTYgTDAuNzE1LC0wLjAxMCBMNi41MDAsNS43NzQgTDEyLjI4NCwtMC4wMTAgTDEzLjAxMCwwLjcxNiBaIi8+PC9zdmc+");
						border: 0;
						top: 50%;
						right: 15px;
						transform: translateY(-50%);
						margin: 0;
					}
				}

				.selectize-dropdown {
					position: absolute;
					background: white;
					box-sizing: border-box;
					margin-top: 17px;
					width: 100% !important;
					box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
					border-radius: 6px;
					border-width: 0;
					z-index: 99;

					&:after {
						content: '';
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 0 7px 7px 7px;
						border-color: transparent transparent #ffffff transparent;
						position: absolute;
						top: -7px;
						right: 15px;
					}

					.selectize-dropdown-content {
						padding: 10px 0;
					}

					.option {
						padding: 5px 15px;
						cursor: pointer;

						&.active {
							color: #3399ff;
						}
					}
				}
			}
		}

		&--radio {
			.field-radio {
				@include clear;

				font-size: 0;
				position: relative;

				label {
					color: $color__main_blue;
					border: solid 2px $color__main_blue;
					border-left-width: 1px;
					border-right-width: 1px;
					text-align: center;
					float: left;
					width: 20%;
					height: 46px;

					@include vertical-align-content;

					font-weight: 400;
					cursor: pointer;

					:root & {
						font-size: 14px;
						margin-bottom: 0;
					}

					&:nth-of-type(1) {
						border-left-width: 2px;
						border-radius: 4px 0 0 4px;
					}

					&:nth-of-type(5) {
						border-right-width: 2px;
						border-radius: 0 4px 4px 0;
					}
				}

				input {
					display: none;
					position: absolute;

					&:checked + label {
						color: white;
						background-color: $color__main_blue;
					}
				}
			}
		}

		.field-part-box {
			@include clear;

			.field-part-pre {
				width: 44px;
				background: #f2f2f2;
				height: 52px;
				float: left;
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;
				text-align: center;
				font-size: 14px;
				font-weight: 500;
				color: rgba(51, 51, 51, 0.5);
				line-height: 52px;
				padding-left: 2px;

				& + .field-part {
					input {
						border-top-left-radius: 0;
						border-bottom-left-radius: 0;
					}
				}
			}

			.field-part {
				width: calc(100% - 44px);
				float: left;
			}
		}
	}

	:root .b__modal & {
		width: 302px;

		.field {
			&--text,
			&--password,
			&--email,
			&--tel {
				input {
					height: 56px;
				}
			}

			&--submit {
				margin-top: 30px;
			}

			&--select {
				.selectize-control {
					.selectize-input {
						height: 56px;
					}
				}
			}
		}
	}

	.b__people-sidebar_form & {
		.field {
			&--search {
				.btn {
					width: 26px;
					height: 25px;

					&:before {
						content: none;
					}
				}

				input {
					width: calc(100% - 57px);
					display: inline-block;
					margin-left: 11px;
					padding: 0;
					border: 0;
					font-size: 14px;
					line-height: 1.2;

					&::-webkit-input-placeholder {
						color: rgb(191, 191, 191);
					}

					&:-moz-placeholder {
						/* Firefox 18- */
						color: rgb(191, 191, 191);
					}

					&::-moz-placeholder {
						/* Firefox 19+ */
						color: rgb(191, 191, 191);
					}

					&:-ms-input-placeholder {
						color: rgb(191, 191, 191);
					}
				}
			}
		}
	}

	&.form--posting-item {
		padding: 0;

		.field {
			& + .field {
				margin-top: 28px;
			}

			label {
				display: block;
				font-size: 12px;
				font-weight: 500;
				margin-bottom: 8px;
			}

			&.field--submit,
			&.field--part {
				max-width: 275px;
			}

			&.field--select {
				max-width: 50%;
				min-width: 275px;
			}
		}
	}
}

select {
	-webkit-appearance: none;
	border: none;
	background: none;
	box-shadow: none;
	outline: none !important;
	cursor: pointer;
	
	border-width: 2px;
	border-color: #f2f2f2;
	border-style: solid;
	border-radius: 4px;
	background-color: rgb(255, 255, 255);
	height: 52px;
	width: 100%;
	box-sizing: border-box;
	padding: 0 15px;
	color: $color__main_gray;
	font-size: 16px;
	font-weight: 400;
	font-family: $font__default;
	text-align: left;

	background-image: url("data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgd2lkdGg9IjEzcHgiIGhlaWdodD0iN3B4Ij48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiICBvcGFjaXR5PSIwLjI1MSIgZmlsbD0icmdiKDEwMiwgMTAyLCAxMDIpIiBkPSJNMTMuMDEwLDAuNzE2IEw2LjcxNiw3LjAxMCBMNi41MDAsNi43OTQgTDYuMjg0LDcuMDEwIEwtMC4wMTAsMC43MTYgTDAuNzE1LC0wLjAxMCBMNi41MDAsNS43NzQgTDEyLjI4NCwtMC4wMTAgTDEzLjAxMCwwLjcxNiBaIi8+PC9zdmc+");

	background-repeat: no-repeat;
	background-size: 13px auto;
	background-position: right 15px center;

	@include vertical-align-content;

	&:hover,
	&:active,
	&:focus {
		border-color: darken(#f2f2f2, 5%);
	}
}

.gm-err-autocomplete {
	background-image: none !important;
	
}

@include media-breakpoint-down(sm) {
	.form.form {
		
		&.form--posting-item {
			.field.field--select {
				width: 100%;
				min-width: 0;
				max-width: none;
			}
			
			.field.field--submit, 
			.field.field--part {
				width: 100%;
				max-width: none;
			}
		}
		
		&.form--posting-item .field label {
			margin-bottom: 15px;
		}
		
		.field {
			
			&--select {
				max-width: 100%;
				min-width: 0;
			}
		}
		
		.field--radio {
			.field-radio {
				display: -webkit-flex;
				display: -moz-flex;
				display: -ms-flex;
				display: -o-flex;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				align-items: flex-start;
				
				label {
					float: none;
					width: 50%;
					border: none;
					background: transparent;
					
					height: auto;
					
					position: relative;
					
					color: #000;
					font-size: 16px !important;
					line-height: 20px;
					font-weight: 400;
					text-align: left;
					
					padding: 0;
					margin: 0;
					
					padding-left: 30px;
					margin-bottom: 15px !important;
					
					border-radius: 0 !important;
					
					&:before {
						content: "";
						display: block;
						
						border: 2px solid #f2f2f2;
						border-radius: 4px;
						
						width: 20px;
						height: 20px;
						
						position: absolute;
						top: 0;
						left: 0;
						transition: all 0.2s ease-in-out;
					}
					
					&:after {
						content: "";
						display: block;
						
						background-color: #227cd6;
						
						width: 10px;
						height: 10px;
						
						border-radius: 2px;
						
						position: absolute;
						top: 5px;
						left: 5px;
						
						opacity: 0;
						
						transition: all 0.2s ease-in-out;
					}
				}
				
				input:checked + label {
					color: #000;
					background-color: transparent;
					
					&:before {
						border-color: #227cd6;
					}
					
					&:after {
						opacity: 1;
					}
				}
			}
		}
	}
}

