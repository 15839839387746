.b__how-it-works {
	padding-top: 35px;
	padding-bottom: 150px;
	background: #fff;
}

.carousel-how-it-works {
	padding-top: 120px;

	.item {
		@include clear;

		.item-image {
			width: span(6);
			float: left;

			height: 100%;
			text-align: right;
			padding-right: 15px;
			box-sizing: border-box;

			@include media(phone-only) {
				width: 100%;
				float: none;
				padding: 0;
			}


			img {
				max-width: 100%;
				display: inline-block;

				@include media(phone-only) {
					height: auto;
				}
			}
		}

		.item-content {
			width: span(6);
			float: left;
			margin-left: 0;
			margin-right: 0;
			height: 100%;
			font-size: 14px;
			line-height: 1.57;
			padding-left: 45px;
			padding-right: 45px;
			box-sizing: border-box;

			@include media(phone-only) {
				width: 100%;
				float: none;
				padding-left: 10px;
				padding-right: 10px;
				text-align: center;
			}


			&:before {
				content: '';
				display: inline-block;
				height: 100%;
				vertical-align: middle;
			}
		}

		.item-title {
			font-size: 24px;
			font-weight: 600;
			margin-bottom: 25px;
		}
	}
}