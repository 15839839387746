.modal {
  display: none;
  padding: 0 25px;
}

:root .b__modal {
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.25);
  
  .b__title {
    margin-bottom: 24px;
  }
    
  .fancybox-skin {
    border-radius: 6px;  
  }
}

[data-modal-open] {
  cursor: pointer;
}