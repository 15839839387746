.b__achievements {
	padding-top: 103px;
	padding-bottom: 70px;

	.achievements {
		@include clear;

		.achievement {
			width: span(3);
			float: left;
			margin-left: gutter() / 2;
			margin-right: gutter() / 2;
			border-radius: 6px;
			background-color: rgb(255, 255, 255);
			box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
			text-align: center;

			@include media(phone-only) {
				width: span(12);

				& + .achievement {
					margin-top: gutter() * 2;
				}
			}


			@include media(tablet-p-only) {
				width: span(6);

				margin-top: gutter();

				&:nth-child(2n+2) {
					margin-right: 0;
				}
			}


			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}

			&-icon {
				margin-top: 34px;
				margin-bottom: 20px;
				height: 66px;
			}

			&-text {
				font-size: 22px;
				font-weight: 300;

				strong {
					font-weight: 600;
					display: block;
				}
			}

			&-controls {
				margin-bottom: 35px;
				margin-top: 24px;
				width: 190px;

				@include center-block;

				text-align: center;

				.btn {
					display: block;
					font-size: 14px;
					font-weight: 600;
					height: 46px;
					box-shadow: 0 2px 0 0 #008fcc;
					padding: 13px 0;

					@include media(tablet-l-only) {
						max-width: 90%;
						margin-left: auto;
						margin-right: auto;

						// display: inline-block;
					}


					// &:before {
					//   height: 110%;
					// }

					&.btn--green {
						box-shadow: 0 2px 0 0 #2eb789;
					}

					&.btn--gray {
						box-shadow: 0 2px 0 0 #2e2e2e;
					}

					&.btn--red {
						box-shadow: 0 2px 0 0 #b72e2e;
					}
				}
			}

			&.achievement--green {
				.icon {
					display: inline-block;
					margin-top: 2px;
					fill: $color__site_green;
					color: $color__site_green;
					width: 50px;
					height: 64px;
				}

				strong {
					color: $color__site_green;
				}
			}

			&.achievement--blue {
				.icon {
					fill: $color__main_blue;
					color: $color__main_blue;
					width: 64px;
					height: 64px;
				}

				strong {
					color: $color__main_blue;
				}
			}

			&.achievement--gray {
				.icon {
					display: inline-block;
					margin-top: 15px;
					fill: $color__main_gray;
					color: $color__main_gray;
					width: 64px;
					height: 38px;
				}

				strong {
					color: $color__main_gray;
				}
			}

			&.achievement--red {
				.icon {
					display: inline-block;
					margin-top: 6px;
					fill: $color__main_red;
					color: $color__main_red;
					width: 52px;
					height: 52px;
				}

				strong {
					color: $color__main_red;
				}
			}
		}
	}
}