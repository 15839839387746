nav.header-navigation {
	
	flex-shrink: 0;
	
	@include media(phone-only) {
		display: none;
	}

	> ul {
		padding: 0;
		margin: 0;
		list-style: none;
		font-size: 0;
		white-space: nowrap;

		> li {
			font-size: initial;
			display: inline-block;
			vertical-align: middle;
			position: relative;

			&.is-margined {
				margin: 0 10px;
			}

			> a:not(.btn) {
				font-size: 14px;
				color: #666666;
				text-decoration: none;
				display: inline-block;
				vertical-align: middle;
				position: relative;
				margin: 0 15px;

				&:hover {
					color: $color__main_gray;
				}

				.header.header--extended & {
					color: white;

					&:hover {
						color: white;
					}
				}
			}
		}

		a.btn {
			margin-left: 20px;
		}
	}

	.link--speech-bubble,
	.link--activity-feed {
		height: 25px;
		display: inline-block;
		vertical-align: middle;
		position: relative;
		line-height: 0;
		// top: 2px;

		&.active {
			.icon {
				fill: #3399ff;
				color: #3399ff;
			}
		}
	}
}