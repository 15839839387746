.b__content {
  padding-top: 25px;
  padding-bottom: 110px;
}

.b__title {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  
  .b__home-map & {
    background-color: rgb(249, 249, 249);
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.05);
    position: absolute;
    height: 40px;
    z-index: 90;
    left: 50%;
    top: 0;
    transform: translateX(-50%) translateY(-50%);
    padding: 0 34px;
    border-radius: 34px;
    font-size: 16px;

    .page-how-it-works & {
      background-color: white;
    }
    
    &:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      height: 100%;
    }
    
  }
  
}