.b__items-controls {
	padding-bottom: 30px;

	.b__items + & {
		text-align: center;
		padding-top: 20px;
		padding-bottom: 0;
	}

	&_top,
	&_bottom {
		@include clear;
	}

	&_top {
		margin-bottom: 14px;
		display: flex;
		justify-content: space-between;
	}

	.b__items-controls_results {
		margin-left: 0;

		padding: 6px 0;
		font-size: 12px;
		font-weight: 600;
	}

	.b__items-controls_view-switcher {
		margin-right: 0;
		margin-left: auto;

		text-align: right;
		font-size: 0;

		.view-switcher {
			border: 0;
			background: transparent;
			display: inline-block;
			vertical-align: middle;
			padding: 0;

			&[data-view-switcher=map] {
				margin-left: 22px;
			}

			&[data-view-switcher=listing] {
				margin-left: 25px;
			}

			.icon {
				fill: #dadada;
				color: #dadada;

				&.icon--view-switcher {
					&--grid {
						width: 19px;
						height: 19px;
					}

					&--listing {
						width: 25px;
						height: 19px;
					}

					&--map {
						width: 29px;
						height: 26px;
					}
				}
			}

			&:hover:not(.active) {
				.icon {
					fill: #999999;
					color: #999999;
				}
			}

			&.active {
				.icon {
					fill: #bbbbbb;
					color: #bbbbbb;
				}
			}
		}
	}

	.b__items-controls_filters {
		font-size: 0;

		form {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;

			.b__items-container.has-map & {
				justify-content: space-between;
			}
		}
	}

	.b__items-controls_filter {
		display: inline-block;

		&:not(:last-child) {
			margin-right: 8px;
		}

		&.university {
			min-width: 220px;
		}

		&.type {
			min-width: 138px;
		}

		select {
			/* Styling */
			cursor: pointer;
			appearance: none;
			line-height: normal;
			position: relative;
			box-sizing: border-box;
			width: 100%;
			height: 45px;
			background-image: url("data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgd2lkdGg9IjEzcHgiIGhlaWdodD0iN3B4Ij48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiICBvcGFjaXR5PSIwLjI1MSIgZmlsbD0icmdiKDEwMiwgMTAyLCAxMDIpIiBkPSJNMTMuMDEwLDAuNzE2IEw2LjcxNiw3LjAxMCBMNi41MDAsNi43OTQgTDYuMjg0LDcuMDEwIEwtMC4wMTAsMC43MTYgTDAuNzE1LC0wLjAxMCBMNi41MDAsNS43NzQgTDEyLjI4NCwtMC4wMTAgTDEzLjAxMCwwLjcxNiBaIi8+PC9zdmc+");
			background-position: right 17px top 50%;
			background-repeat: no-repeat;
			padding: 0 48px 0 20px;
			border: 0;
			border-radius: 6px;
			color: $color__main_gray;
			font-size: 14px;
			font-weight: 500;
			font-family: $font__default;
			text-align: left;
			background-color: rgb(255, 255, 255);
			box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
		}
	}

	.b__items-controls_orders {
		width: span(2);
		margin: 0;
		text-align: right;
		padding-top: 13px;
	}

	.b__items-controls_order {
		display: inline-block;
		padding: 0;
		margin: 0;
		background: transparent;
		border: 0;
		color: #bfbfbf;
		font-size: 12px;
		font-weight: 500;

		&:after {
			font-family: 'Times New Roman';
		}

		&.ASC {
			&:after {
				content: '/002193';
			}
		}

		&.DESC {
			&:after {
				content: '/002191';
			}
		}
	}
}

@media (min-width: 768px) and (max-width: 1199px) {
	.b__items-controls {
		[data-view-switcher="listing"] {
			display: none !important;
		}
	}
}

@media (max-width: 767px) {
	.b__items-controls {
		padding-bottom: 0;
		
		.b__items-controls_filters {
			form {
				width: calc(100% + 30px);
				margin-left: -15px;
				overflow: hidden;
			}
		}
		
		[data-view-switcher="grid"] {
			display: none !important;
		}
		
		.b__items-controls_filter {
			width: 33.33%;
			min-width: 0 !important;
			
			select {
				background-color: transparent;
				border-radius: 0;
				box-shadow: none;
				border: 1px solid rgba(0,0,0,0.1);
				
				padding-right: 20px;
				background-position: center right 5px;
				background-size: 8px auto;
				
				font-size: 11px;
				padding-left: 10px;
				border-left: none;
			}
			
			&:last-child {
				select {
					border-right: none;
				}
			}
			
			&:not(:last-child) {
				margin-right: 0;
			}
		}
	}
}
