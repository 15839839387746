.b__posting-item {
	.b__title {
		text-align: left;
		margin-bottom: 12px;
	}

	.b__panel {
		margin: 0;
		border-radius: 4px;
		background-color: rgb(255, 255, 255);
		box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
	}
}

.posting-item {
	@include clear;

	.posting-item_images {
		width: span(4);
		float: left;

		.responsiv-uploader-fileupload.style-image-multi .upload-button {
			width: 100%;
			margin-left: 0;
			margin-right: 0;
			margin-bottom: 25px;
			float: none;
			line-height: normal;
			height: auto;
			padding: 43px 0;
			border: dashed 2px #ddd;
			border-radius: 4px;
			box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);
			text-align: center;
			transition: box-shadow 300ms ease-out 0s;

			&-icon {
				display: none;
			}

			&:focus {
				background-color: white;
			}

			&:before {
				display: block;
				width: 72px;
				height: 62px;
				margin-left: auto;
				margin-right: auto;
				background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 24 21' style='enable-background:new 0 0 24 21;' xml:space='preserve' %3E%3Cpath style='fill: %23333333;' d='M21,3c-1.4,0-2.9,0-2.9,0l-0.9-2c-0.2-0.5-1.1-1-1.8-1H8.8C8,0,7.1,0.5,6.9,1L6,3 c0,0-1.5,0-3,0C0.6,3,0,5,0,5v14c0,0,1.5,2,3,2s16.4,0,18,0s3-2,3-2V5C24,5,23.5,3,21,3z M12,18c-3.3,0-6-2.7-6-6c0-3.3,2.7-6,6-6 c3.3,0,6,2.7,6,6C18,15.3,15.3,18,12,18z M12,8c-2.2,0-4,1.8-4,4s1.8,4,4,4s4-1.8,4-4S14.2,8,12,8z'/%3E%3C/svg%3E");
				background-position: center center;
				background-repeat: no-repeat;
				background-size: cover;
				opacity: .25;
				margin-bottom: 15px;
				transition: opacity 300ms ease-out 0s;
			}

			&:after {
				content: 'Choose images from your computer or drag and drop them';
				font-size: 14px;
				font-weight: 400;
				color: #333333;
				opacity: .25;
				max-width: 60%;
				display: inline-block;
				transition: opacity 300ms ease-out 0s;
			}

			&:hover {
				box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);

				&:before,
				&:after {
					opacity: .75;
				}
			}
		}

		.responsiv-uploader-fileupload.style-image-multi .upload-files-container {
			margin-left: 0;

			@include clear;

			.upload-object {
				width: 100%;
				position: relative;
				border-radius: 4px;
				margin-bottom: 25px;

				&:first-child {
					height: 0;
					padding-top: 69%;
					box-sizing: border-box;
					display: block;
				}

				&:not(:first-child) {
					float: left;
					width: 46.61835748792271%;
					height: 138px;
					margin-right: 6.763285%;

					&:nth-child(2n + 1) {
						margin-right: 0;
					}
				}

				.image {
					position: absolute;
					left: -1px;
					top: -1px;
					right: -1px;
					bottom: -1px;
					width: auto;
					height: auto;
					border-radius: 4px;
					border-width: 0;

					img {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						object-fit: cover;
						object-position: 50% 50%;
						border-radius: 4px;
					}
				}

				.meta {
					margin: 0 10px 0 10px;
				}

				.info {
					opacity: 0;
					z-index: 5;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					margin: 0;
					transition: all 300ms ease-out 0s;
					padding: 40px;
					background: rgba(0, 0, 0, 0.75);
					border-radius: 4px;

					.size {
						display: none;
					}

					h4 {
						color: white;
						margin: 0;
						position: relative;
						width: 100%;
						height: 100%;

						span {
							display: none;
						}

						a {
							display: block !important;
							opacity: 1 !important;
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translateY(-50%) translateX(-50%);
							text-indent: -9999px;
							overflow: hidden;
							width: 14px;
							height: 18px;
							background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 14 18' style='enable-background:new 0 0 14 18;' xml:space='preserve'%3E%3Crect style='fill: %23FFFFFF' x='3.8' y='6.9' width='1.3' height='8.3'/%3E%3Crect style='fill: %23FFFFFF' x='6.4' y='6.9' width='1.3' height='8.3'/%3E%3Crect style='fill: %23FFFFFF' x='8.9' y='6.9' width='1.3' height='8.3'/%3E%3Cpath style='fill: %23FFFFFF' d='M13.4,4.2V3.5c0-0.4-0.3-0.7-0.6-0.7h-2.6c0-0.1,0-0.2,0-0.2V2.1C10.2,0.9,9.3,0,8.3,0H5.7 c-1,0-1.9,0.9-1.9,2.1v0.5c0,0.1,0,0.2,0,0.2H1.3c-0.3,0-0.6,0.3-0.6,0.7v0.7H0v1.4h1.3v10.4c0,1.1,0.9,2.1,1.9,2.1h7.6 c1,0,1.9-0.9,1.9-2.1V5.5H14V4.2H13.4z M5.1,2c0-0.4,0.3-0.7,0.6-0.7h2.5c0.3,0,0.6,0.3,0.6,0.7v0.7H5.1V2z M11.5,15.9 c0,0.4-0.3,0.7-0.6,0.7H3.2c-0.4,0-0.6-0.3-0.6-0.7V5.5h8.9V15.9z'/%3E%3C/svg%3E%0A");
							background-position: center center;
							background-repeat: no-repeat;
						}
					}
				}

				&:hover {
					.info {
						opacity: 1;
					}
				}
			}
		}
	}

	.posting-item_details {
		width: span(8);
		float: left;
		margin-right: 0;
		margin-left: gutter();

		.b__panel {
			padding-left: 30px;
			padding-right: 30px;
			padding-bottom: 70px;
			padding-top: 30px;
		}
	}
}

@include media-breakpoint-down(md) {
	.posting-item {
		.posting-item_images {
			width: 100%;
			float: none;
			margin-bottom: 0;
		}
		
		.posting-item_details {
			width: 100%;
			margin-left: 0;
			float: none;
			
			.b__items.b__items--listing {
				height: auto;
				width: 100%;
				max-height: none !important;
				
				margin: 0;
			}
			
			.b__panel {
				padding-top: 30px;
				padding-bottom: 30px;
				padding-left: 15px;
				padding-right: 15px;
			}
		}
	}
}






