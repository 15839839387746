body.compensate-for-scrollbar {
    overflow: hidden;
}

.fancybox-active {
    height: auto;
}

.fancybox-is-hidden {
    left: -9999px;
    margin: 0;
    position: absolute !important;
    top: -9999px;
    visibility: hidden;
}

.fancybox-container {
    backface-visibility: hidden;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
    height: 100%;
    left: 0;
    position: fixed;
    -webkit-tap-highlight-color: transparent;
    top: 0;
    transform: translateZ(0);
    width: 100%;
    z-index: 99992;
}

.fancybox-container * {
    box-sizing: border-box;
}

.fancybox-outer,
.fancybox-inner,
.fancybox-bg,
.fancybox-stage {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.fancybox-outer {
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
}

.fancybox-bg {
    background: rgb(30, 30, 30);
    opacity: 0;
    transition-duration: inherit;
    transition-property: opacity;
    transition-timing-function: cubic-bezier(.47, 0, .74, .71);
}

.fancybox-is-open .fancybox-bg {
    opacity: .87;
    transition-timing-function: cubic-bezier(.22, .61, .36, 1);
}

.fancybox-infobar,
.fancybox-toolbar,
.fancybox-caption,
.fancybox-navigation .fancybox-button {
    direction: ltr;
    opacity: 0;
    position: absolute;
    transition: opacity .25s, visibility 0s linear .25s;
    visibility: hidden;
    z-index: 99997;
}

.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-toolbar .fancybox-toolbar,
.fancybox-show-caption .fancybox-caption,
.fancybox-show-nav .fancybox-navigation .fancybox-button {
    opacity: 1;
    transition: opacity .25s, visibility 0s;
    visibility: visible;
}

.fancybox-infobar {
    color: #ccc;
    font-size: 13px;
    -webkit-font-smoothing: subpixel-antialiased;
    height: 44px;
    left: 0;
    line-height: 44px;
    min-width: 44px;
    mix-blend-mode: difference;
    padding: 0 10px;
    pointer-events: none;
    text-align: center;
    top: 0;
    -webkit-touch-callout: none;
    user-select: none;
}

.fancybox-toolbar {
    right: 0;
    top: 0;
}

.fancybox-stage {
    direction: ltr;
    overflow: visible;
    -webkit-transform: translate3d(0, 0, 0);
    z-index: 99994;
}

.fancybox-is-open .fancybox-stage {
    overflow: hidden;
}

.fancybox-slide {
    backface-visibility: hidden;
    display: none;
    height: 100%;
    left: 0;
    outline: none;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: 44px;
    position: absolute;
    text-align: center;
    top: 0;
    transition-property: transform, opacity;
    white-space: normal;
    width: 100%;
    z-index: 99994;
}

.fancybox-slide::before {
    content: '';
    display: inline-block;
    height: 100%;
    margin-right: -.25em;
    vertical-align: middle;
    width: 0;
}

.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--previous,
.fancybox-slide--current,
.fancybox-slide--next {
    display: block;
}

.fancybox-slide--next {
    z-index: 99995;
}

.fancybox-slide--image {
    overflow: visible;
    padding: 44px 0;
}

.fancybox-slide--image::before {
    display: none;
}

.fancybox-slide--html {
    padding: 6px 6px 0 6px;
}

.fancybox-slide--iframe {
    padding: 44px 44px 0;
}

.fancybox-content {
    background: #fff;
    display: inline-block;
    margin: 0 0 6px 0;
    max-width: 100%;
    overflow: auto;
    padding: 0;
    padding: 24px;
    position: relative;
    text-align: left;
    vertical-align: middle;
}

.fancybox-slide--image .fancybox-content {
    animation-timing-function: cubic-bezier(.5, 0, .14, 1);
    backface-visibility: hidden;
    background: transparent;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    left: 0;
    margin: 0;
    max-width: none;
    overflow: visible;
    padding: 0;
    position: absolute;
    top: 0;
    transform-origin: top left;
    transition-property: transform, opacity;
    user-select: none;
    z-index: 99995;
}

.fancybox-can-zoomOut .fancybox-content {
    cursor: zoom-out;
}

.fancybox-can-zoomIn .fancybox-content {
    cursor: zoom-in;
}

.fancybox-can-drag .fancybox-content {
    cursor: grab;
}

.fancybox-is-dragging .fancybox-content {
    cursor: grabbing;
}

.fancybox-container [data-selectable='true'] {
    cursor: text;
}

.fancybox-image,
.fancybox-spaceball {
    background: transparent;
    border: 0;
    height: 100%;
    left: 0;
    margin: 0;
    max-height: none;
    max-width: none;
    padding: 0;
    position: absolute;
    top: 0;
    user-select: none;
    width: 100%;
}

.fancybox-spaceball {
    z-index: 1;
}

.fancybox-slide--html .fancybox-content {
    margin-bottom: 6px;
}

.fancybox-slide--video .fancybox-content,
.fancybox-slide--map .fancybox-content,
.fancybox-slide--iframe .fancybox-content {
    height: 100%;
    margin: 0;
    overflow: visible;
    padding: 0;
    width: 100%;
}

.fancybox-slide--video .fancybox-content {
    background: #000;
}

.fancybox-slide--map .fancybox-content {
    background: #e5e3df;
}

.fancybox-slide--iframe .fancybox-content {
    background: #fff;
    height: calc(100% - 44px);
    margin-bottom: 44px;
}

.fancybox-video,
.fancybox-iframe {
    background: transparent;
    border: 0;
    height: 100%;
    margin: 0;
    overflow: hidden;
    padding: 0;
    width: 100%;
}

.fancybox-iframe {
    vertical-align: top;
}

.fancybox-error {
    background: #fff;
    cursor: default;
    max-width: 400px;
    padding: 40px;
    width: 100%;
}

.fancybox-error p {
    color: #444;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    padding: 0;
}

/* Buttons */

.fancybox-button {
    background: rgba(30, 30, 30, .6);
    border: 0;
    border-radius: 0;
    cursor: pointer;
    display: inline-block;
    height: 44px;
    margin: 0;
    outline: none;
    padding: 10px;
    transition: color .2s;
    vertical-align: top;
    width: 44px;
}

.fancybox-button,
.fancybox-button:visited,
.fancybox-button:link {
    color: #ccc;
}

.fancybox-button:focus,
.fancybox-button:hover {
    color: #fff;
}

.fancybox-button.disabled,
.fancybox-button.disabled:hover,
.fancybox-button[disabled],
.fancybox-button[disabled]:hover {
    color: #888;
    cursor: default;
}

.fancybox-button svg {
    display: block;
    overflow: visible;
    position: relative;
    shape-rendering: geometricPrecision;
}

.fancybox-button svg path {
    fill: transparent;
    stroke: currentColor;
    stroke-linejoin: round;
    stroke-width: 3;
}

.fancybox-button--play svg path:nth-child(2) {
    display: none;
}

.fancybox-button--pause svg path:nth-child(1) {
    display: none;
}

.fancybox-button--play svg path,
.fancybox-button--share svg path,
.fancybox-button--thumbs svg path {
    fill: currentColor;
}

.fancybox-button--share svg path {
    stroke-width: 1;
}

/* Navigation arrows */

.fancybox-navigation .fancybox-button {
    height: 38px;
    opacity: 0;
    padding: 6px;
    position: absolute;
    top: 50%;
    width: 38px;
}

.fancybox-show-nav .fancybox-navigation .fancybox-button {
    transition: opacity .25s, visibility 0s, color .25s;
}

.fancybox-navigation .fancybox-button::after {
    content: '';
    left: -25px;
    padding: 50px;
    position: absolute;
    top: -25px;
}

.fancybox-navigation .fancybox-button--arrow_left {
    left: 6px;
}

.fancybox-navigation .fancybox-button--arrow_right {
    right: 6px;
}

/* Close button on the top right corner of html content */

.fancybox-close-small {
    background: transparent;
    border: 0;
    border-radius: 0;
    color: #555;
    cursor: pointer;
    height: 44px;
    margin: 0;
    padding: 6px;
    position: absolute;
    right: 0;
    top: 0;
    width: 44px;
    z-index: 10;
}

.fancybox-close-small svg {
    fill: transparent;
    opacity: .8;
    stroke: currentColor;
    stroke-width: 1.5;
    transition: stroke .1s;
}

.fancybox-close-small:focus {
    outline: none;
}

.fancybox-close-small:hover svg {
    opacity: 1;
}

.fancybox-slide--image .fancybox-close-small,
.fancybox-slide--video .fancybox-close-small,
.fancybox-slide--iframe .fancybox-close-small {
    color: #ccc;
    padding: 5px;
    right: -12px;
    top: -44px;
}

.fancybox-slide--image .fancybox-close-small:hover svg,
.fancybox-slide--video .fancybox-close-small:hover svg,
.fancybox-slide--iframe .fancybox-close-small:hover svg {
    background: transparent;
    color: #fff;
}

.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-drag .fancybox-close-small {
    display: none;
}

/* Caption */

.fancybox-caption {
    bottom: 0;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    left: 0;
    line-height: 1.5;
    padding: 25px 44px 25px 44px;
    right: 0;
}

.fancybox-caption::before {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAEtCAQAAABjBcL7AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAHRJREFUKM+Vk8EOgDAIQ0vj/3+xBw8qIZZueFnIKC90MCAI8DlrkHGeqqGIU6lVigrBtpCWqeRWoHDNqs0F7VNVBVxmHRlvoVqjaYkdnDIaivH2HqZ5+oZj3JUzWB+cOz4G48Bg+tsJ/tqu4dLC/4Xb+0GcF5BwBC0AA53qAAAAAElFTkSuQmCC);
    background-repeat: repeat-x;
    background-size: contain;
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: -25px;
    z-index: -1;
}

.fancybox-caption::after {
    border-bottom: 1px solid rgba(255, 255, 255, .3);
    content: '';
    display: block;
    left: 44px;
    position: absolute;
    right: 44px;
    top: 0;
}

.fancybox-caption a,
.fancybox-caption a:link,
.fancybox-caption a:visited {
    color: #ccc;
    text-decoration: none;
}

.fancybox-caption a:hover {
    color: #fff;
    text-decoration: underline;
}

/* Loading indicator */

.fancybox-loading {
    animation: fancybox-rotate .8s infinite linear;
    background: transparent;
    border: 6px solid rgba(100, 100, 100, .5);
    border-radius: 100%;
    border-top-color: #fff;
    height: 60px;
    left: 50%;
    margin: -30px 0 0 -30px;
    opacity: .6;
    padding: 0;
    position: absolute;
    top: 50%;
    width: 60px;
    z-index: 99999;
}

@keyframes fancybox-rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

/* Transition effects */

.fancybox-animated {
    transition-timing-function: cubic-bezier(0, 0, .25, 1);
}

/* transitionEffect: slide */

.fancybox-fx-slide.fancybox-slide--previous {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--next {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--current {
    opacity: 1;
    transform: translate3d(0, 0, 0);
}

/* transitionEffect: fade */

.fancybox-fx-fade.fancybox-slide--previous,
.fancybox-fx-fade.fancybox-slide--next {
    opacity: 0;
    transition-timing-function: cubic-bezier(.19, 1, .22, 1);
}

.fancybox-fx-fade.fancybox-slide--current {
    opacity: 1;
}

/* transitionEffect: zoom-in-out */

.fancybox-fx-zoom-in-out.fancybox-slide--previous {
    opacity: 0;
    transform: scale3d(1.5, 1.5, 1.5);
}

.fancybox-fx-zoom-in-out.fancybox-slide--next {
    opacity: 0;
    transform: scale3d(.5, .5, .5);
}

.fancybox-fx-zoom-in-out.fancybox-slide--current {
    opacity: 1;
    transform: scale3d(1, 1, 1);
}

/* transitionEffect: rotate */

.fancybox-fx-rotate.fancybox-slide--previous {
    opacity: 0;
    transform: rotate(-360deg);
}

.fancybox-fx-rotate.fancybox-slide--next {
    opacity: 0;
    transform: rotate(360deg);
}

.fancybox-fx-rotate.fancybox-slide--current {
    opacity: 1;
    transform: rotate(0deg);
}

/* transitionEffect: circular */

.fancybox-fx-circular.fancybox-slide--previous {
    opacity: 0;
    transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
}

.fancybox-fx-circular.fancybox-slide--next {
    opacity: 0;
    transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
}

.fancybox-fx-circular.fancybox-slide--current {
    opacity: 1;
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
}

/* transitionEffect: tube */

.fancybox-fx-tube.fancybox-slide--previous {
    transform: translate3d(-100%, 0, 0) scale(.1) skew(-10deg);
}

.fancybox-fx-tube.fancybox-slide--next {
    transform: translate3d(100%, 0, 0) scale(.1) skew(10deg);
}

.fancybox-fx-tube.fancybox-slide--current {
    transform: translate3d(0, 0, 0) scale(1);
}

.fancybox-button--fullscreen::before {
	width: 15px;
	height: 11px;
	left: calc(50% - 7px);
	top: calc(50% - 6px);
	border: 2px solid;
	background: none;
}

/* Share */

.fancybox-share {
    background: #f4f4f4;
    border-radius: 3px;
    max-width: 90%;
    padding: 30px;
    text-align: center;
}

.fancybox-share h1 {
    color: #222;
    font-size: 35px;
    font-weight: 700;
    margin: 0 0 20px 0;
}

.fancybox-share p {
    margin: 0;
    padding: 0;
}

.fancybox-share__button {
    border: 0;
    border-radius: 3px;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    line-height: 40px;
    margin: 0 5px 10px 5px;
    min-width: 130px;
    padding: 0 15px;
    text-decoration: none;
    transition: all .2s;
    user-select: none;
    white-space: nowrap;
}

.fancybox-share__button:visited,
.fancybox-share__button:link {
    color: #fff;
}

.fancybox-share__button:hover {
    text-decoration: none;
}

.fancybox-share__button--fb {
    background: #3b5998;
}

.fancybox-share__button--fb:hover {
    background: #344e86;
}

.fancybox-share__button--pt {
    background: #bd081d;
}

.fancybox-share__button--pt:hover {
    background: #aa0719;
}

.fancybox-share__button--tw {
    background: #1da1f2;
}

.fancybox-share__button--tw:hover {
    background: #0d95e8;
}

.fancybox-share__button svg {
    height: 25px;
    margin-right: 7px;
    position: relative;
    top: -1px;
    vertical-align: middle;
    width: 25px;
}

.fancybox-share__button svg path {
    fill: #fff;
}

.fancybox-share__input {
    background: transparent;
    border: 0;
    border-bottom: 1px solid #d7d7d7;
    border-radius: 0;
    color: #5d5b5b;
    font-size: 14px;
    margin: 10px 0 0 0;
    outline: none;
    padding: 10px 15px;
    width: 100%;
}

/* Slideshow button */

.fancybox-button--play {}

.fancybox-button--play::before,
.fancybox-button--pause::before {
	top: calc(50% - 6px);
	left: calc(50% - 4px);
	background: transparent;
}

.fancybox-button--play::before {
	width: 0;
	height: 0;
	border-top: 6px inset transparent;
	border-bottom: 6px inset transparent;
	border-left: 10px solid;
	border-radius: 1px;
}

.fancybox-button--pause::before {
	width: 7px;
	height: 11px;
	border-style: solid;
	border-width: 0 2px 0 2px;
}

/* Thumbs */

.fancybox-thumbs {
    background: #fff;
    bottom: 0;
    display: none;
    margin: 0;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    padding: 2px 2px 4px 2px;
    position: absolute;
    right: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    top: 0;
    width: 212px;
    z-index: 99995;
}

.fancybox-thumbs-x {
    overflow-x: auto;
    overflow-y: hidden;
}

.fancybox-show-thumbs .fancybox-thumbs {
    display: block;
}

.fancybox-show-thumbs .fancybox-inner {
    right: 212px;
}

.fancybox-thumbs > ul {
    font-size: 0;
    height: 100%;
    list-style: none;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
    position: absolute;
    position: relative;
    white-space: nowrap;
    width: 100%;
}

.fancybox-thumbs-x > ul {
    overflow: hidden;
}

.fancybox-thumbs-y > ul::-webkit-scrollbar {
    width: 7px;
}

.fancybox-thumbs-y > ul::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
}

.fancybox-thumbs-y > ul::-webkit-scrollbar-thumb {
    background: #2a2a2a;
    border-radius: 10px;
}

.fancybox-thumbs > ul > li {
    backface-visibility: hidden;
    cursor: pointer;
    float: left;
    height: 75px;
    margin: 2px;
    max-height: calc(100% - 8px);
    max-width: calc(50% - 4px);
    outline: none;
    overflow: hidden;
    padding: 0;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    width: 100px;
}

.fancybox-thumbs-loading {
    background: rgba(0, 0, 0, .1);
}

.fancybox-thumbs > ul > li {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.fancybox-thumbs > ul > li:before {
    border: 4px solid #4ea7f9;
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: all .2s cubic-bezier(.25, .46, .45, .94);
    z-index: 99991;
}

.fancybox-thumbs .fancybox-thumbs-active:before {
    opacity: 1;
}

/* Styling for Small-Screen Devices */

@media all and (max-width: 800px) {
    .fancybox-thumbs {
        width: 110px;
    }
    .fancybox-show-thumbs .fancybox-inner {
        right: 110px;
    }
    .fancybox-thumbs > ul > li {
        max-width: calc(100% - 10px);
    }
}

// @charset "UTF-8";
// body.fancybox-active {
// 	overflow: hidden; }

// body.fancybox-iosfix {
// 	position: fixed;
// 	left: 0;
// 	right: 0; }

// .fancybox-is-hidden {
// 	position: absolute;
// 	top: -9999px;
// 	left: -9999px;
// 	visibility: hidden; }

// .fancybox-container {
// 	position: fixed;
// 	top: 0;
// 	left: 0;
// 	width: 100%;
// 	height: 100%;
// 	z-index: 99992;
// 	-webkit-tap-highlight-color: transparent;
// 	-webkit-backface-visibility: hidden;
// 	backface-visibility: hidden;
// 	-webkit-transform: translateZ(0);
// 	transform: translateZ(0);
// 	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

// .fancybox-outer,
// .fancybox-inner,
// .fancybox-bg,
// .fancybox-stage {
// 	position: absolute;
// 	top: 0;
// 	right: 0;
// 	bottom: 0;
// 	left: 0; }

// .fancybox-outer {
// 	overflow-y: auto;
// 	-webkit-overflow-scrolling: touch; }

// .fancybox-bg {
// 	background: #1e1e1e;
// 	opacity: 0;
// 	transition-duration: inherit;
// 	transition-property: opacity;
// 	transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71); }

// .fancybox-is-open .fancybox-bg {
// 	opacity: 0.87;
// 	transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1); }

// .fancybox-infobar,
// .fancybox-toolbar,
// .fancybox-caption-wrap {
// 	position: absolute;
// 	direction: ltr;
// 	z-index: 99997;
// 	opacity: 0;
// 	visibility: hidden;
// 	transition: opacity .25s, visibility 0s linear .25s;
// 	box-sizing: border-box; }

// .fancybox-show-infobar .fancybox-infobar,
// .fancybox-show-toolbar .fancybox-toolbar,
// .fancybox-show-caption .fancybox-caption-wrap {
// 	opacity: 1;
// 	visibility: visible;
// 	transition: opacity .25s, visibility 0s; }

// .fancybox-infobar {
// 	top: 0;
// 	left: 0;
// 	font-size: 13px;
// 	padding: 0 10px;
// 	height: 44px;
// 	min-width: 44px;
// 	line-height: 44px;
// 	color: #ccc;
// 	text-align: center;
// 	pointer-events: none;
// 	-webkit-user-select: none;
// 	-moz-user-select: none;
// 	-ms-user-select: none;
// 	user-select: none;
// 	-webkit-touch-callout: none;
// 	-webkit-tap-highlight-color: transparent;
// 	-webkit-font-smoothing: subpixel-antialiased;
// 	mix-blend-mode: exclusion; }

// .fancybox-toolbar {
// 	top: 0;
// 	right: 0;
// 	margin: 0;
// 	padding: 0; }

// .fancybox-stage {
// 	overflow: hidden;
// 	direction: ltr;
// 	z-index: 99994;
// 	-webkit-transform: translate3d(0, 0, 0); }

// .fancybox-is-closing .fancybox-stage {
// 	overflow: visible; }

// .fancybox-slide {
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	width: 100%;
// 	height: 100%;
// 	margin: 0;
// 	padding: 0;
// 	overflow: auto;
// 	outline: none;
// 	white-space: normal;
// 	box-sizing: border-box;
// 	text-align: center;
// 	z-index: 99994;
// 	-webkit-overflow-scrolling: touch;
// 	display: none;
// 	-webkit-backface-visibility: hidden;
// 	backface-visibility: hidden;
// 	transition-property: opacity, -webkit-transform;
// 	transition-property: transform, opacity;
// 	transition-property: transform, opacity, -webkit-transform; }

// .fancybox-slide::before {
// 	content: '';
// 	display: inline-block;
// 	vertical-align: middle;
// 	height: 100%;
// 	width: 0; }

// .fancybox-is-sliding .fancybox-slide,
// .fancybox-slide--previous,
// .fancybox-slide--current,
// .fancybox-slide--next {
// 	display: block; }

// .fancybox-slide--image {
// 	overflow: visible; }

// .fancybox-slide--image::before {
// 	display: none; }

// .fancybox-slide--video .fancybox-content,
// .fancybox-slide--video iframe {
// 	background: #000; }

// .fancybox-slide--map .fancybox-content,
// .fancybox-slide--map iframe {
// 	background: #E5E3DF; }

// .fancybox-slide--next {
// 	z-index: 99995; }

// .fancybox-slide > * {
// 	display: inline-block;
// 	position: relative;
// 	padding: 24px;
// 	margin: 44px 0 44px;
// 	border-width: 0;
// 	vertical-align: middle;
// 	text-align: left;
// 	background-color: #fff;
// 	overflow: auto;
// 	box-sizing: border-box; }

// .fancybox-slide > title,
// .fancybox-slide > style,
// .fancybox-slide > meta,
// .fancybox-slide > link,
// .fancybox-slide > script,
// .fancybox-slide > base {
// 	display: none; }

// .fancybox-slide .fancybox-image-wrap {
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	margin: 0;
// 	padding: 0;
// 	border: 0;
// 	z-index: 99995;
// 	background: transparent;
// 	cursor: default;
// 	overflow: visible;
// 	-webkit-transform-origin: top left;
// 	-ms-transform-origin: top left;
// 	transform-origin: top left;
// 	background-size: 100% 100%;
// 	background-repeat: no-repeat;
// 	-webkit-backface-visibility: hidden;
// 	backface-visibility: hidden;
// 	-webkit-user-select: none;
// 	-moz-user-select: none;
// 	-ms-user-select: none;
// 	user-select: none;
// 	transition-property: opacity, -webkit-transform;
// 	transition-property: transform, opacity;
// 	transition-property: transform, opacity, -webkit-transform; }

// .fancybox-can-zoomOut .fancybox-image-wrap {
// 	cursor: -webkit-zoom-out;
// 	cursor: zoom-out; }

// .fancybox-can-zoomIn .fancybox-image-wrap {
// 	cursor: -webkit-zoom-in;
// 	cursor: zoom-in; }

// .fancybox-can-drag .fancybox-image-wrap {
// 	cursor: -webkit-grab;
// 	cursor: grab; }

// .fancybox-is-dragging .fancybox-image-wrap {
// 	cursor: -webkit-grabbing;
// 	cursor: grabbing; }

// .fancybox-image,
// .fancybox-spaceball {
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	width: 100%;
// 	height: 100%;
// 	margin: 0;
// 	padding: 0;
// 	border: 0;
// 	max-width: none;
// 	max-height: none;
// 	-webkit-user-select: none;
// 	-moz-user-select: none;
// 	-ms-user-select: none;
// 	user-select: none; }

// .fancybox-spaceball {
// 	z-index: 1; }

// .fancybox-slide--iframe .fancybox-content {
// 	padding: 0;
// 	width: 80%;
// 	height: 80%;
// 	max-width: calc(100% - 100px);
// 	max-height: calc(100% - 88px);
// 	overflow: visible;
// 	background: #fff; }

// .fancybox-iframe {
// 	display: block;
// 	margin: 0;
// 	padding: 0;
// 	border: 0;
// 	width: 100%;
// 	height: 100%;
// 	background: #fff; }

// .fancybox-error {
// 	margin: 0;
// 	padding: 40px;
// 	width: 100%;
// 	max-width: 380px;
// 	background: #fff;
// 	cursor: default; }

// .fancybox-error p {
// 	margin: 0;
// 	padding: 0;
// 	color: #444;
// 	font-size: 16px;
// 	line-height: 20px; }

// /* Buttons */
// .fancybox-button {
// 	box-sizing: border-box;
// 	display: inline-block;
// 	vertical-align: top;
// 	width: 44px;
// 	height: 44px;
// 	margin: 0;
// 	padding: 10px;
// 	border: 0;
// 	border-radius: 0;
// 	background: rgba(30, 30, 30, 0.6);
// 	transition: color .3s ease;
// 	cursor: pointer;
// 	outline: none; }

// .fancybox-button,
// .fancybox-button:visited,
// .fancybox-button:link {
// 	color: #ccc; }

// .fancybox-button:focus,
// .fancybox-button:hover {
// 	color: #fff; }

// .fancybox-button[disabled] {
// 	color: #ccc;
// 	cursor: default;
// 	opacity: 0.6; }

// .fancybox-button svg {
// 	display: block;
// 	position: relative;
// 	overflow: visible;
// 	shape-rendering: geometricPrecision; }

// .fancybox-button svg path {
// 	fill: currentColor;
// 	stroke: currentColor;
// 	stroke-linejoin: round;
// 	stroke-width: 3; }

// .fancybox-button--share svg path {
// 	stroke-width: 1; }

// .fancybox-button--play svg path:nth-child(2) {
// 	display: none; }

// .fancybox-button--pause svg path:nth-child(1) {
// 	display: none; }

// .fancybox-button--zoom svg path {
// 	fill: transparent; color: transparent; }

// /* Navigation arrows */
// .fancybox-navigation {
// 	display: none; }

// .fancybox-show-nav .fancybox-navigation {
// 	display: block; }

// .fancybox-navigation button {
// 	position: absolute;
// 	top: 50%;
// 	margin: -50px 0 0 0;
// 	z-index: 99997;
// 	background: transparent;
// 	width: 60px;
// 	height: 100px;
// 	padding: 17px; }

// .fancybox-navigation button:before {
// 	content: "";
// 	position: absolute;
// 	top: 30px;
// 	right: 10px;
// 	width: 40px;
// 	height: 40px;
// 	background: rgba(30, 30, 30, 0.6); }

// .fancybox-navigation .fancybox-button--arrow_left {
// 	left: 0; }

// .fancybox-navigation .fancybox-button--arrow_right {
// 	right: 0; }

// /* Close button on the top right corner of html content */
// .fancybox-close-small {
// 	position: absolute;
// 	top: 0;
// 	right: 0;
// 	width: 40px;
// 	height: 40px;
// 	padding: 0;
// 	margin: 0;
// 	border: 0;
// 	border-radius: 0;
// 	background: transparent;
// 	z-index: 10;
// 	cursor: pointer; }

// .fancybox-close-small:after {
// 	content: '×';
// 	position: absolute;
// 	top: 5px;
// 	right: 5px;
// 	width: 30px;
// 	height: 30px;
// 	font: 22px/30px Arial,"Helvetica Neue",Helvetica,sans-serif;
// 	color: #888;
// 	font-weight: 300;
// 	text-align: center;
// 	border-radius: 50%;
// 	border-width: 0;
// 	background-color: transparent;
// 	transition: background-color .25s;
// 	box-sizing: border-box;
// 	z-index: 2; }

// .fancybox-close-small:focus {
// 	outline: none; }

// .fancybox-close-small:focus:after {
// 	outline: 1px dotted #888; }

// .fancybox-close-small:hover:after {
// 	color: #555;
// 	background: #eee; }

// .fancybox-slide--image .fancybox-close-small,
// .fancybox-slide--iframe .fancybox-close-small {
// 	top: 0;
// 	right: -40px; }

// .fancybox-slide--image .fancybox-close-small:after,
// .fancybox-slide--iframe .fancybox-close-small:after {
// 	font-size: 35px;
// 	color: #aaa; }

// .fancybox-slide--image .fancybox-close-small:hover:after,
// .fancybox-slide--iframe .fancybox-close-small:hover:after {
// 	color: #fff;
// 	background: transparent; }

// .fancybox-is-scaling .fancybox-close-small,
// .fancybox-is-zoomable.fancybox-can-drag .fancybox-close-small {
// 	display: none; }

// /* Caption */
// .fancybox-caption-wrap {
// 	bottom: 0;
// 	left: 0;
// 	right: 0;
// 	padding: 60px 2vw 0 2vw;
// 	background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.1) 20%, rgba(0, 0, 0, 0.2) 40%, rgba(0, 0, 0, 0.6) 80%, rgba(0, 0, 0, 0.8) 100%);
// 	pointer-events: none; }

// .fancybox-caption {
// 	padding: 30px 0;
// 	border-top: 1px solid rgba(255, 255, 255, 0.4);
// 	font-size: 14px;
// 	color: #fff;
// 	line-height: 20px;
// 	-webkit-text-size-adjust: none; }

// .fancybox-caption a,
// .fancybox-caption button,
// .fancybox-caption select {
// 	pointer-events: all;
// 	position: relative;
// 	/* Fix IE11 */ }

// .fancybox-caption a {
// 	color: #fff;
// 	text-decoration: underline; }

// /* Loading indicator */
// .fancybox-slide > .fancybox-loading {
// 	border: 6px solid rgba(100, 100, 100, 0.4);
// 	border-top: 6px solid rgba(255, 255, 255, 0.6);
// 	border-radius: 100%;
// 	height: 50px;
// 	width: 50px;
// 	-webkit-animation: fancybox-rotate .8s infinite linear;
// 	animation: fancybox-rotate .8s infinite linear;
// 	background: transparent;
// 	position: absolute;
// 	top: 50%;
// 	left: 50%;
// 	margin-top: -30px;
// 	margin-left: -30px;
// 	z-index: 99999; }

// @-webkit-keyframes fancybox-rotate {
// 	from {
// 		-webkit-transform: rotate(0deg);
// 		transform: rotate(0deg); }
// 	to {
// 		-webkit-transform: rotate(359deg);
// 		transform: rotate(359deg); } }

// @keyframes fancybox-rotate {
// 	from {
// 		-webkit-transform: rotate(0deg);
// 		transform: rotate(0deg); }
// 	to {
// 		-webkit-transform: rotate(359deg);
// 		transform: rotate(359deg); } }

// /* Transition effects */
// .fancybox-animated {
// 	transition-timing-function: cubic-bezier(0, 0, 0.25, 1); }

// /* transitionEffect: slide */
// .fancybox-fx-slide.fancybox-slide--previous {
// 	-webkit-transform: translate3d(-100%, 0, 0);
// 	transform: translate3d(-100%, 0, 0);
// 	opacity: 0; }

// .fancybox-fx-slide.fancybox-slide--next {
// 	-webkit-transform: translate3d(100%, 0, 0);
// 	transform: translate3d(100%, 0, 0);
// 	opacity: 0; }

// .fancybox-fx-slide.fancybox-slide--current {
// 	-webkit-transform: translate3d(0, 0, 0);
// 	transform: translate3d(0, 0, 0);
// 	opacity: 1; }

// /* transitionEffect: fade */
// .fancybox-fx-fade.fancybox-slide--previous,
// .fancybox-fx-fade.fancybox-slide--next {
// 	opacity: 0;
// 	transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }

// .fancybox-fx-fade.fancybox-slide--current {
// 	opacity: 1; }

// /* transitionEffect: zoom-in-out */
// .fancybox-fx-zoom-in-out.fancybox-slide--previous {
// 	-webkit-transform: scale3d(1.5, 1.5, 1.5);
// 	transform: scale3d(1.5, 1.5, 1.5);
// 	opacity: 0; }

// .fancybox-fx-zoom-in-out.fancybox-slide--next {
// 	-webkit-transform: scale3d(0.5, 0.5, 0.5);
// 	transform: scale3d(0.5, 0.5, 0.5);
// 	opacity: 0; }

// .fancybox-fx-zoom-in-out.fancybox-slide--current {
// 	-webkit-transform: scale3d(1, 1, 1);
// 	transform: scale3d(1, 1, 1);
// 	opacity: 1; }

// /* transitionEffect: rotate */
// .fancybox-fx-rotate.fancybox-slide--previous {
// 	-webkit-transform: rotate(-360deg);
// 	-ms-transform: rotate(-360deg);
// 	transform: rotate(-360deg);
// 	opacity: 0; }

// .fancybox-fx-rotate.fancybox-slide--next {
// 	-webkit-transform: rotate(360deg);
// 	-ms-transform: rotate(360deg);
// 	transform: rotate(360deg);
// 	opacity: 0; }

// .fancybox-fx-rotate.fancybox-slide--current {
// 	-webkit-transform: rotate(0deg);
// 	-ms-transform: rotate(0deg);
// 	transform: rotate(0deg);
// 	opacity: 1; }

// /* transitionEffect: circular */
// .fancybox-fx-circular.fancybox-slide--previous {
// 	-webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
// 	transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
// 	opacity: 0; }

// .fancybox-fx-circular.fancybox-slide--next {
// 	-webkit-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
// 	transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
// 	opacity: 0; }

// .fancybox-fx-circular.fancybox-slide--current {
// 	-webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
// 	transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
// 	opacity: 1; }

// /* transitionEffect: tube */
// .fancybox-fx-tube.fancybox-slide--previous {
// 	-webkit-transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
// 	transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg); }

// .fancybox-fx-tube.fancybox-slide--next {
// 	-webkit-transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
// 	transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg); }

// .fancybox-fx-tube.fancybox-slide--current {
// 	-webkit-transform: translate3d(0, 0, 0) scale(1);
// 	transform: translate3d(0, 0, 0) scale(1); }

// /* Share */
// .fancybox-share {
// 	padding: 30px;
// 	border-radius: 3px;
// 	background: #f4f4f4;
// 	max-width: 90%;
// 	text-align: center; }

// .fancybox-share h1 {
// 	color: #222;
// 	margin: 0 0 20px 0;
// 	font-size: 35px;
// 	font-weight: 700; }

// .fancybox-share p {
// 	margin: 0;
// 	padding: 0; }

// p.fancybox-share__links {
// 	margin-right: -10px; }

// .fancybox-share__button {
// 	display: inline-block;
// 	text-decoration: none;
// 	margin: 0 10px 10px 0;
// 	padding: 0 15px;
// 	min-width: 130px;
// 	border: 0;
// 	border-radius: 3px;
// 	background: #fff;
// 	white-space: nowrap;
// 	font-size: 14px;
// 	font-weight: 700;
// 	line-height: 40px;
// 	-webkit-user-select: none;
// 	-moz-user-select: none;
// 	-ms-user-select: none;
// 	user-select: none;
// 	color: #fff;
// 	transition: all .2s; }

// .fancybox-share__button:hover {
// 	text-decoration: none; }

// .fancybox-share__button--fb {
// 	background: #3b5998; }

// .fancybox-share__button--fb:hover {
// 	background: #344e86; }

// .fancybox-share__button--pt {
// 	background: #bd081d; }

// .fancybox-share__button--pt:hover {
// 	background: #aa0719; }

// .fancybox-share__button--tw {
// 	background: #1da1f2; }

// .fancybox-share__button--tw:hover {
// 	background: #0d95e8; }

// .fancybox-share__button svg {
// 	position: relative;
// 	top: -1px;
// 	width: 25px;
// 	height: 25px;
// 	margin-right: 7px;
// 	vertical-align: middle; }

// .fancybox-share__button svg path {
// 	fill: #fff; color: #fff; }

// .fancybox-share__input {
// 	box-sizing: border-box;
// 	width: 100%;
// 	margin: 10px 0 0 0;
// 	padding: 10px 15px;
// 	background: transparent;
// 	color: #5d5b5b;
// 	font-size: 14px;
// 	outline: none;
// 	border: 0;
// 	border-bottom: 2px solid #d7d7d7; }

// /* Thumbs */
// .fancybox-thumbs {
// 	display: none;
// 	position: absolute;
// 	top: 0;
// 	bottom: 0;
// 	right: 0;
// 	width: 212px;
// 	margin: 0;
// 	padding: 2px 2px 4px 2px;
// 	background: #fff;
// 	-webkit-tap-highlight-color: transparent;
// 	-webkit-overflow-scrolling: touch;
// 	-ms-overflow-style: -ms-autohiding-scrollbar;
// 	box-sizing: border-box;
// 	z-index: 99995; }

// .fancybox-thumbs-x {
// 	overflow-y: hidden;
// 	overflow-x: auto; }

// .fancybox-show-thumbs .fancybox-thumbs {
// 	display: block; }

// .fancybox-show-thumbs .fancybox-inner {
// 	right: 212px; }

// .fancybox-thumbs > ul {
// 	list-style: none;
// 	position: absolute;
// 	position: relative;
// 	width: 100%;
// 	height: 100%;
// 	margin: 0;
// 	padding: 0;
// 	overflow-x: hidden;
// 	overflow-y: auto;
// 	font-size: 0;
// 	white-space: nowrap; }

// .fancybox-thumbs-x > ul {
// 	overflow: hidden; }

// .fancybox-thumbs-y > ul::-webkit-scrollbar {
// 	width: 7px; }

// .fancybox-thumbs-y > ul::-webkit-scrollbar-track {
// 	background: #fff;
// 	border-radius: 10px;
// 	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }

// .fancybox-thumbs-y > ul::-webkit-scrollbar-thumb {
// 	background: #2a2a2a;
// 	border-radius: 10px; }

// .fancybox-thumbs > ul > li {
// 	float: left;
// 	overflow: hidden;
// 	padding: 0;
// 	margin: 2px;
// 	width: 100px;
// 	height: 75px;
// 	max-width: calc(50% - 4px);
// 	max-height: calc(100% - 8px);
// 	position: relative;
// 	cursor: pointer;
// 	outline: none;
// 	-webkit-tap-highlight-color: transparent;
// 	-webkit-backface-visibility: hidden;
// 	backface-visibility: hidden;
// 	box-sizing: border-box; }

// li.fancybox-thumbs-loading {
// 	background: rgba(0, 0, 0, 0.1); }

// .fancybox-thumbs > ul > li > img {
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	max-width: none;
// 	max-height: none;
// 	-webkit-touch-callout: none;
// 	-webkit-user-select: none;
// 	-moz-user-select: none;
// 	-ms-user-select: none;
// 	user-select: none; }

// .fancybox-thumbs > ul > li:before {
// 	content: '';
// 	position: absolute;
// 	top: 0;
// 	right: 0;
// 	bottom: 0;
// 	left: 0;
// 	border: 4px solid #4ea7f9;
// 	z-index: 99991;
// 	opacity: 0;
// 	transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94); }

// .fancybox-thumbs > ul > li.fancybox-thumbs-active:before {
// 	opacity: 1; }

// /* Styling for Small-Screen Devices */
// @media all and (max-width: 800px) {
// 	.fancybox-thumbs {
// 		width: 110px; }
// 	.fancybox-show-thumbs .fancybox-inner {
// 		right: 110px; }
// 	.fancybox-thumbs > ul > li {
// 		max-width: calc(100% - 10px); } }



// // /*! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */
// // .fancybox-wrap,
// // .fancybox-skin,
// // .fancybox-outer,
// // .fancybox-inner,
// // .fancybox-image,
// // .fancybox-wrap iframe,
// // .fancybox-wrap object,
// // .fancybox-nav,
// // .fancybox-nav span,
// // .fancybox-tmp
// // {
// // 	padding: 0;
// // 	margin: 0;
// // 	border: 0;
// // 	outline: none;
// // 	vertical-align: top;
// // }

// // .fancybox-wrap {
// // 	position: absolute;
// // 	top: 0;
// // 	left: 0;
// // 	z-index: 8020;
// // }

// // .fancybox-skin {
// // 	position: relative;
// // 	background: #fff;
// // 	color: #444;
// // 	text-shadow: none;
// // }

// // .fancybox-opened {
// // 	z-index: 8030;
// // }

// // .fancybox-opened .fancybox-skin {
// // 	-webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
// // 	   -moz-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
// // 	        box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
// // }

// // .fancybox-outer, .fancybox-inner {
// // 	position: relative;
// // }

// // .fancybox-inner {
// // 	overflow: hidden;
// // }

// // .fancybox-type-iframe .fancybox-inner {
// // 	-webkit-overflow-scrolling: touch;
// // }

// // .fancybox-error {
// // 	color: #444;
// // 	font: 14px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
// // 	margin: 0;
// // 	padding: 15px;
// // 	white-space: nowrap;
// // }

// // .fancybox-image, .fancybox-iframe {
// // 	display: block;
// // 	width: 100%;
// // 	height: 100%;
// // }

// // .fancybox-image {
// // 	max-width: 100%;
// // 	max-height: 100%;
// // }

// // #fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
// // 	//background-image: url('fancybox_sprite.png');
// // }

// // #fancybox-loading {
// // 	position: fixed;
// // 	top: 50%;
// // 	left: 50%;
// // 	margin-top: -22px;
// // 	margin-left: -22px;
// // 	background-position: 0 -108px;
// // 	opacity: 0.8;
// // 	cursor: pointer;
// // 	z-index: 8060;
// // }

// // #fancybox-loading div {
// // 	width: 44px;
// // 	height: 44px;
// // 	//background: url('fancybox_loading.gif') center center no-repeat;
// // }

// // .fancybox-close {
// // 	position: absolute;
// // 	top: -30px;
// // 	right: -30px;
// // 	width: 15px;
// // 	height: 15px;
// // 	padding: 5px;
// // 	cursor: pointer;
// // 	z-index: 8040;
// // 	box-sizing: content-box;
	
// // 	.icon {
// //   	width: 15px;
// //   	height: 15px;
// //   	fill: white;
// // 	}
// // }

// // .fancybox-nav {
// //   display: none;
// // 	position: absolute;
// // 	top: 0;
// // 	width: 40%;
// // 	height: 100%;
// // 	cursor: pointer;
// // 	text-decoration: none;
// // 	//background: transparent url('blank.gif'); /* helps IE */
// // 	-webkit-tap-highlight-color: rgba(0,0,0,0);
// // 	z-index: 8040;
// // }

// // .fancybox-prev {
// // 	left: 0;
// // }

// // .fancybox-next {
// // 	right: 0;
// // }

// // .fancybox-nav span {
// // 	position: absolute;
// // 	top: 50%;
// // 	width: 36px;
// // 	height: 34px;
// // 	margin-top: -18px;
// // 	cursor: pointer;
// // 	z-index: 8040;
// // 	visibility: hidden;
// // }

// // .fancybox-prev span {
// // 	left: 10px;
// // 	background-position: 0 -36px;
// // }

// // .fancybox-next span {
// // 	right: 10px;
// // 	background-position: 0 -72px;
// // }

// // .fancybox-nav:hover span {
// // 	visibility: visible;
// // }

// // .fancybox-tmp {
// // 	position: absolute;
// // 	top: -99999px;
// // 	left: -99999px;
// // 	visibility: hidden;
// // 	max-width: 99999px;
// // 	max-height: 99999px;
// // 	overflow: visible !important;
// // }

// // /* Overlay helper */

// // .fancybox-lock {
// //     overflow: hidden !important;
// //     width: auto;
// // }

// // .fancybox-lock body {
// //     overflow: hidden !important;
// // }

// // .fancybox-lock-test {
// //     overflow-y: hidden !important;
// // }

// // .fancybox-overlay {
// // 	position: absolute;
// // 	top: 0;
// // 	left: 0;
// // 	overflow: hidden;
// // 	display: none;
// // 	z-index: 8010;
// // 	background: rgba(0, 0, 0, 0.75);
// // }

// // .fancybox-overlay-fixed {
// // 	position: fixed;
// // 	bottom: 0;
// // 	right: 0;
// // }

// // .fancybox-lock .fancybox-overlay {
// // 	overflow: auto;
// // 	overflow-y: scroll;
// // }

// // /* Title helper */

// // .fancybox-title {
// // 	visibility: hidden;
// // 	font: normal 13px/20px $font__default;
// // 	position: relative;
// // 	text-shadow: none;
// // 	z-index: 8050;
// // }

// // .fancybox-opened .fancybox-title {
// // 	visibility: visible;
// // }

// // .fancybox-title-float-wrap {
// // 	position: absolute;
// // 	bottom: 0;
// // 	right: 50%;
// // 	margin-bottom: -35px;
// // 	z-index: 8050;
// // 	text-align: center;
// // }

// // .fancybox-title-float-wrap .child {
// // 	display: inline-block;
// // 	margin-right: -100%;
// // 	padding: 2px 20px;
// // 	background: transparent; /* Fallback for web browsers that doesn't support RGBa */
// // 	background: rgba(0, 0, 0, 0.8);
	
// // 	text-shadow: 0 1px 2px #222;
// // 	color: #FFF;
// // 	font-weight: bold;
// // 	line-height: 24px;
// // 	white-space: nowrap;
// // }

// // .fancybox-title-outside-wrap {
// // 	position: relative;
// // 	margin-top: 10px;
// // 	color: #fff;
// // }

// // .fancybox-title-inside-wrap {
// // 	padding-top: 10px;
// // }

// // .fancybox-title-over-wrap {
// // 	position: absolute;
// // 	bottom: 0;
// // 	left: 0;
// // 	color: #fff;
// // 	padding: 10px;
// // 	background: #000;
// // 	background: rgba(0, 0, 0, .8);
// // }

// // #fancybox-thumbs {
// // 	position: fixed;
// // 	left: 0;
// // 	width: 100%;
// // 	overflow: hidden;
// // 	z-index: 8050;
// // 	padding: 5px 0;
// // }

// // #fancybox-thumbs.bottom {
// // 	bottom: 44px;
// // }

// // #fancybox-thumbs.top {
// // 	top: 2px;
// // }

// // #fancybox-thumbs ul {
// // 	position: relative;
// // 	list-style: none;
// // 	margin: 0;
// // 	padding: 0;
// // 	@include clear(); 
// // }

// // #fancybox-thumbs ul li {
// // 	float: left;
// // 	padding: 0px;
// // 	opacity: 1;
// // 	border: 0;
// // 	background: black;
// // }

// // #fancybox-thumbs ul li.active {
// // 	padding: 0;
// // 	position: relative;
// // 	z-index: 5;
// // 	box-shadow: 0 0 0 2px $color__main_blue;
// // 	a {
// //   	opacity: 0.5;
// // 	}
// // }

// // #fancybox-thumbs ul li:hover {
// // 	opacity: 1;
// // }

// // #fancybox-thumbs ul li a {
// // 	display: block;
// // 	position: relative;
// // 	overflow: hidden;
// // 	border: 0;
// // 	outline: none;
// // }

// // #fancybox-thumbs ul li img {
// // 	display: block;
// // 	position: relative;
// // 	border: 0;
// // 	padding: 0;
// // 	max-width: none;
// // }