/* Grid system */
*,
*:before,
*:after {
  box-sizing: border-box; }

/* Reset */
html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  height: 100%; }
  @media only screen and (max-width: 599px) {
    html {
      height: auto; } }

*:focus {
  outline: none; }

body {
  font-family: "SF UI  Text";
  color: #333333;
  background-color: #f9f9f9;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  min-height: 100%;
  height: 100%;
  box-sizing: border-box; }
  @media only screen and (max-width: 599px) {
    body {
      min-height: 100vh; } }

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0;
  max-width: 100%; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

h5 {
  font-size: 14px;
  font-weight: bold; }

/* Layout */
.site-container {
  max-width: 1290px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 38px; }
  @media only screen and (max-width: 599px) {
    .site-container {
      padding: 0 15px; } }

.site-container-4 {
  text-align: center;
  position: relative;
  z-index: 1;
  width: 450px;
  margin: 0;
  float: none;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.layout-no_nav .page--signin,
.layout-no_nav .page--signup,
.layout-no_nav .page--notification {
  display: table-cell;
  vertical-align: middle; }

.layout-no_nav #layout-content {
  width: 100%;
  height: 100%;
  display: table; }

#layout-content {
  min-height: calc(100vh - 298px); }

#widthTempOption {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  left: -200%;
  top: -100%;
  font-size: 16px;
  font-weight: 400;
  padding-right: 20px;
  width: auto;
  white-space: nowrap;
  transition: none !important; }

#postings_container {
  background-repeat: no-repeat;
  background-size: 60px auto;
  background-color: #f9f9f9;
  background-position: center center;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzgiIGhlaWdodD0iMzgiIHZpZXdCb3g9IjAgMCAzOCAzOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBzdHJva2U9IiMyNzkzZTAiPiAgICA8ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMSAxKSIgc3Ryb2tlLXdpZHRoPSIyIj4gICAgICAgICAgICA8Y2lyY2xlIHN0cm9rZS1vcGFjaXR5PSIuNSIgY3g9IjE4IiBjeT0iMTgiIHI9IjE4IiAvPiAgICAgICAgICAgIDxwYXRoIGQ9Ik0zNiAxOGMwLTkuOTQtOC4wNi0xOC0xOC0xOCI+ICAgICAgICAgICAgICAgIDxhbmltYXRlVHJhbnNmb3JtIGF0dHJpYnV0ZU5hbWU9InRyYW5zZm9ybSIgdHlwZT0icm90YXRlIiBmcm9tPSIwIDE4IDE4IiB0bz0iMzYwIDE4IDE4IiBkdXI9IjFzIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIgLz4gPC9wYXRoPiAgICAgICAgPC9nPiAgICA8L2c+PC9zdmc+); }
  #postings_container > * {
    pointer-events: none;
    opacity: 0 !important;
    transition: opacity 0.4s ease-in-out; }
  #postings_container.loaded {
    background-image: none; }
    #postings_container.loaded > * {
      pointer-events: all;
      opacity: 1 !important; }

@media (max-width: 767px) {
  .site-container-4 {
    width: 100%;
    max-width: none; }
  .layout-no_nav .page--signin .b__panel,
  .layout-no_nav .page--signup .b__panel,
  .layout-no_nav .page--notification .b__panel {
    border-radius: 0; } }

/* Pages */
body.fixed-navigation:not(.page--main) {
  padding-top: 80px; }

body.page-messenger {
  min-height: 100vh;
  height: 100vh;
  overflow: hidden; }

body.page-signin, body.page-signup, body.page-verify, body.page-verify-code, body.page-verify-school, body.page-home-short, body.page-forgot-password {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
  padding-bottom: 50px;
  padding-top: 50px;
  height: auto; }
  body.page-signin .b__panel-after, body.page-signup .b__panel-after, body.page-verify .b__panel-after, body.page-verify-code .b__panel-after, body.page-verify-school .b__panel-after, body.page-home-short .b__panel-after, body.page-forgot-password .b__panel-after {
    color: #fff; }
    body.page-signin .b__panel-after a, body.page-signup .b__panel-after a, body.page-verify .b__panel-after a, body.page-verify-code .b__panel-after a, body.page-verify-school .b__panel-after a, body.page-home-short .b__panel-after a, body.page-forgot-password .b__panel-after a {
      color: #fff; }
  @media only screen and (max-width: 599px) {
    body.page-signin, body.page-signup, body.page-verify, body.page-verify-code, body.page-verify-school, body.page-home-short, body.page-forgot-password {
      padding-top: 30px;
      padding-bottom: 30px; } }
  body.page-signin:before, body.page-signup:before, body.page-verify:before, body.page-verify-code:before, body.page-verify-school:before, body.page-home-short:before, body.page-forgot-password:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 157px;
    z-index: 0;
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.6) 0%, transparent 100%);
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.6) 0%, transparent 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 0%, transparent 100%); }

body.page-signin {
  background-image: url(../images/bg__signin.jpg); }

body.page-signup {
  background-image: url(../images/bg__signup.jpg); }

body.page-verify, body.page-verify-code, body.page-verify-school {
  background-image: url(../images/bg__notification.jpg); }

body.page-forgot-password {
  background-image: url(../images/bg__signin.jpg); }

body.page--ambassador-signup .b__ambassador-signup {
  background: url(../images/bg__ambassador-signup.jpg) center center no-repeat;
  background-size: cover;
  position: relative; }
  body.page--ambassador-signup .b__ambassador-signup:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 157px;
    z-index: 0;
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.6) 0%, transparent 100%);
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.6) 0%, transparent 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 0%, transparent 100%); }

body.page-postings .b__content {
  position: relative;
  padding-bottom: 0;
  padding-top: 0; }
  body.page-postings .b__content .site-container {
    padding-top: 25px;
    height: calc(100vh - 80px);
    box-sizing: border-box; }
    body.page-postings .b__content .site-container .b__items-container {
      height: 100%; }
    body.page-postings .b__content .site-container .b__items-container.has-map {
      height: calc(100% + 25px); }
      body.page-postings .b__content .site-container .b__items-container.has-map + .b__items-map {
        height: 100%; }

body.page-postings footer#layout-footer {
  display: none; }

.b__main-header {
  height: 380px;
  background: url(../images/bg__header.jpg) center top no-repeat;
  background-size: cover;
  margin-top: -80px; }
  .b__main-header:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 157px;
    z-index: 0;
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.6) 0%, transparent 100%);
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.6) 0%, transparent 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 0%, transparent 100%); }

@media (max-width: 767.98px) {
  body.fixed-navigation:not(.page--main) {
    padding-top: 50px; } }

@media (max-width: 767px) {
  body.page-postings .b__content .site-container {
    height: auto; }
  body.page-messenger {
    min-height: 0;
    overflow: unset;
    height: auto; } }

/* Typography */
@font-face {
  font-family: 'TeXGyreAdventor';
  src: url("../fonts/hinted-subset-TeXGyreAdventor-BoldItalic.eot");
  src: local("TeXGyreAdventor-BoldItalic"), url("../fonts/hinted-subset-TeXGyreAdventor-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/hinted-subset-TeXGyreAdventor-BoldItalic.woff2") format("woff2"), url("../fonts/hinted-subset-TeXGyreAdventor-BoldItalic.woff") format("woff"), url("../fonts/hinted-subset-TeXGyreAdventor-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic; }

@font-face {
  font-family: 'Ahellya';
  src: url("../fonts/hinted-subset-Ahellya.eot");
  src: local("Ahellya"), url("../fonts/hinted-subset-Ahellya.eot?#iefix") format("embedded-opentype"), url("../fonts/hinted-subset-Ahellya.woff2") format("woff2"), url("../fonts/hinted-subset-Ahellya.woff") format("woff"), url("../fonts/hinted-subset-Ahellya.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'SF UI  Text';
  src: url("../fonts/hinted-subset-SFUIText-Regular.eot");
  src: local("SF UI  Text Regular"), local("SFUIText-Regular"), url("../fonts/hinted-subset-SFUIText-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/hinted-subset-SFUIText-Regular.woff2") format("woff2"), url("../fonts/hinted-subset-SFUIText-Regular.woff") format("woff"), url("../fonts/hinted-subset-SFUIText-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'SF UI  Text';
  src: url("../fonts/hinted-subset-SFUIText-Light.eot");
  src: local("SF UI  Text Light"), local("SFUIText-Light"), url("../fonts/hinted-subset-SFUIText-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/hinted-subset-SFUIText-Light.woff2") format("woff2"), url("../fonts/hinted-subset-SFUIText-Light.woff") format("woff"), url("../fonts/hinted-subset-SFUIText-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'SF UI  Text';
  src: url("../fonts/hinted-subset-SFUIText-Bold.eot");
  src: local("SF UI  Text Bold"), local("SFUIText-Bold"), url("../fonts/hinted-subset-SFUIText-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/hinted-subset-SFUIText-Bold.woff2") format("woff2"), url("../fonts/hinted-subset-SFUIText-Bold.woff") format("woff"), url("../fonts/hinted-subset-SFUIText-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'SF UI  Text';
  src: url("../fonts/hinted-subset-SFUIText-Medium.eot");
  src: local("SF UI  Text Medium"), local("SFUIText-Medium"), url("../fonts/hinted-subset-SFUIText-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/hinted-subset-SFUIText-Medium.woff2") format("woff2"), url("../fonts/hinted-subset-SFUIText-Medium.woff") format("woff"), url("../fonts/hinted-subset-SFUIText-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'SF UI  Text';
  src: url("../fonts/hinted-subset-SFUIText-Semibold.eot");
  src: local("SF UI  Text Semibold"), local("SFUIText-Semibold"), url("../fonts/hinted-subset-SFUIText-Semibold.eot?#iefix") format("embedded-opentype"), url("../fonts/hinted-subset-SFUIText-Semibold.woff2") format("woff2"), url("../fonts/hinted-subset-SFUIText-Semibold.woff") format("woff"), url("../fonts/hinted-subset-SFUIText-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal; }

.text--left {
  text-align: left !important; }

.text-danger {
  color: red !important; }

.text-warning {
  color: #f0ad4e !important; }
  .text-warning a {
    color: #f0ad4e !important;
    text-decoration: underline; }
    .text-warning a:hover {
      text-decoration: none; }

/* Elements */
.logo {
  font-family: "TeXGyreAdventor";
  text-decoration: none;
  line-height: 1;
  font-size: 21.8px;
  color: #666666;
  font-weight: bold;
  font-style: italic; }
  .logo span {
    color: #227cd6; }
  .header.header--extended .logo,
  .layout-no_nav .logo {
    font-size: 36px;
    color: white; }
    .header.header--extended .logo span,
    .layout-no_nav .logo span {
      color: #cccccc; }
  footer .logo {
    font-size: 14px;
    color: #666666;
    font-weight: bold;
    text-decoration: none; }
    footer .logo:hover {
      color: #999; }

.btn {
  display: block;
  background-color: #227cd6;
  color: rgba(255, 255, 255, 0.9);
  border-radius: 6px;
  height: 38px;
  font-weight: 600;
  border: solid 1px #227cd6;
  box-sizing: border-box;
  padding: 9px 0px;
  text-decoration: none;
  text-align: center;
  max-width: 100%; }
  header.header .btn {
    min-width: 0;
    padding: 9px 30px; }
  .item-price .btn,
  .b__item-details_price .btn {
    height: auto;
    padding: 6px 8px;
    font-weight: bold;
    font-size: 13px;
    border-radius: 3px;
    min-width: auto;
    opacity: .9;
    transition: all 300ms ease-out 0s; }
    .item-price .btn:hover,
    .b__item-details_price .btn:hover {
      opacity: 1; }
  .b__item-details_price .btn {
    white-space: nowrap; }
  .btn[disabled="disabled"] {
    opacity: .5; }
  .btn:hover {
    color: white; }
  .header .btn {
    background-color: rgba(34, 125, 215, 0.9);
    color: rgba(255, 255, 255, 0.9);
    font-size: 14px; }
    .header .btn:hover {
      background-color: #227cd6; }
  .btn.btn--blue {
    background-color: #009fe3;
    border: solid 1px #009fe3; }
  .btn.btn--pure {
    background-color: transparent;
    border: solid 1px white; }
    .header.header--extended .btn.btn--pure {
      background-color: transparent;
      font-size: 14px;
      border-color: rgba(255, 255, 255, 0.9);
      color: rgba(255, 255, 255, 0.9); }
    .header:not(.header--extended) .btn.btn--pure {
      background-color: transparent;
      border: solid 1px rgba(34, 125, 215, 0.9);
      color: rgba(34, 125, 215, 0.9);
      font-size: 14px;
      /*
      &:hover {
        border-color: $color__main_blue;
        color: $color__main_blue;
      }
*/ }
  .btn.btn--green {
    background-color: #33cc99;
    border: solid 1px #33cc99; }
  .btn.btn--red {
    background-color: #cc3333;
    border: solid 1px #cc3333; }
  .btn.btn--gray {
    background-color: #333333;
    border: solid 1px #333333; }
  .btn.btn--footer-pure {
    border: solid 1px #666666;
    background: transparent; }
  .btn.btn--gmail {
    border: solid 2px #dd473b;
    color: #dd473b;
    font-size: 16px;
    font-weight: 600;
    background: transparent;
    height: 56px;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 56px;
    display: inline-block; }
    .btn.btn--gmail .icon {
      margin-top: -4px;
      margin-right: 18px;
      width: 30px;
      height: 24px;
      vertical-align: middle;
      display: inline-block; }

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg); }
  to {
    -moz-transform: rotate(360deg); } }

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }
  .btn.btn--lazy-load {
    display: inline-block;
    background: transparent;
    color: #227cd6;
    border: 0;
    padding: 0;
    margin: 30px 0 0;
    font-size: 15.87px;
    font-weight: 400; }
    .btn.btn--lazy-load .icon {
      margin-right: 6px; }
    .btn.btn--lazy-load:hover .icon {
      animation: spin 1s infinite; }
    .b__items .btn.btn--lazy-load {
      margin-bottom: 40px;
      display: block;
      margin-left: auto;
      margin-right: auto;
      clear: both; }
  .btn.btn--follow {
    border-width: 1px;
    border-color: #3399ff;
    border-style: solid;
    border-radius: 6px;
    height: 38px;
    font-size: 14px;
    font-weight: 600;
    color: #3399ff;
    background: transparent;
    display: inline-block;
    width: 137px; }
  .btn.btn--following {
    border-width: 1px;
    border-color: #969696;
    border-style: solid;
    border-radius: 6px;
    height: 38px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    background: #969696;
    display: inline-block;
    width: 137px; }
  .btn.btn--send-message {
    height: 48px;
    background-color: #3399ff;
    border-color: #3399ff;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600; }
  .btn.btn--price {
    background-color: white;
    border: solid 1px #cc3333;
    color: #cc3333; }
  .btn.btn--rent {
    background-color: white;
    border: solid 1px #33cc99;
    color: #33cc99; }
  .btn.btn--repair {
    background-color: white;
    border: solid 1px #e8ce2c;
    color: #e8ce2c; }
  .btn.btn--exchange {
    background-color: white;
    border: solid 1px #ffa500;
    color: #ffa500; }
  .btn.btn--donate {
    background-color: white;
    border: solid 1px #227cd6;
    color: #227cd6; }
  .btn.btn--actions {
    display: inline-block;
    padding: 0;
    margin: 0;
    width: 23px;
    height: 23px;
    border: 0;
    background-color: transparent; }
    .btn.btn--actions:before {
      content: none; }
  .btn.btn--submit {
    padding: 5px;
    background-color: transparent;
    border: 0;
    font-size: 14px;
    font-weight: bold;
    color: #3399ff;
    height: auto; }
    .b__messanger .btn.btn--submit {
      min-width: auto; }
  .btn.btn--search {
    background-color: transparent;
    border: 0;
    padding: 0;
    margin-top: 0;
    display: inline-block;
    vertical-align: middle; }
  .btn.btn-social.btn-facebook {
    border-radius: 4px;
    border: 0;
    margin-top: 19px;
    text-align: center;
    background: #3b5998;
    display: block;
    font-size: 16px;
    font-weight: 600;
    color: white;
    text-decoration: none;
    height: 56px; }
    .btn.btn-social.btn-facebook:before {
      content: '';
      display: inline-block;
      height: 105%;
      vertical-align: middle; }
    .btn.btn-social.btn-facebook .icon {
      width: 25px;
      height: 25px;
      fill: white;
      display: inline-block;
      vertical-align: middle;
      float: left;
      margin-top: 15px;
      margin-left: 15px; }

:root .color--light-gray {
  color: #999999; }

svg.icon {
  display: inline-block;
  width: 32px;
  height: 32px;
  transition: fill 150ms ease-out 0s; }
  .item-place svg.icon {
    fill: #ae2b3e;
    color: #ae2b3e;
    width: 100%;
    height: 100%; }
  svg.icon.icon--social-fb, svg.icon.icon--social-tb {
    width: 10px;
    height: 18px; }
  svg.icon.icon--social-tw {
    width: 16px;
    height: 13px; }
  svg.icon.icon--social-ig {
    width: 16px;
    height: 16px; }
  svg.icon.icon--social-pi {
    width: 14px;
    height: 18px; }
  svg.icon.icon--speech-bubble {
    width: 26px;
    height: 25px;
    fill: #bfbfbf;
    color: #bfbfbf; }
    a:hover svg.icon.icon--speech-bubble {
      fill: #666666;
      color: #666666; }
    .header--extended svg.icon.icon--speech-bubble {
      fill: white;
      color: white; }
    .header--extended a.link--speech-bubble:hover svg.icon.icon--speech-bubble {
      fill: #bfbfbf;
      color: #bfbfbf; }
  svg.icon.icon--activity-feed {
    width: 23px;
    height: 23px;
    fill: #bfbfbf;
    color: #bfbfbf; }
    a:hover svg.icon.icon--activity-feed {
      fill: #666666;
      color: #666666; }
    .header--extended svg.icon.icon--activity-feed {
      fill: white;
      color: white; }
    .header--extended a.link--activity-feed:hover svg.icon.icon--activity-feed {
      fill: #bfbfbf;
      color: #bfbfbf; }
  svg.icon.icon--lazy-load {
    width: 26px;
    height: 26px;
    fill: #227cd6;
    color: #227cd6;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: -2px; }
  svg.icon.icon--edit {
    width: 16px;
    height: 15px;
    fill: #c7c7c7;
    color: #c7c7c7;
    vertical-align: middle;
    position: relative;
    top: -2px; }
  svg.icon.icon--actions {
    fill: #bebebe;
    color: #bebebe;
    width: 23px;
    height: 23px; }
    button:hover svg.icon.icon--actions {
      fill: #333333;
      color: #333333; }
  svg.icon.icon--file {
    width: 22px;
    height: 19px;
    fill: #bfbfbf;
    color: #bfbfbf; }
    .chat-form_file:hover svg.icon.icon--file {
      fill: #333333;
      color: #333333; }
  .field--search svg.icon.icon--search {
    width: 26px;
    height: 25px;
    fill: #dadada;
    color: #dadada; }

.b__panel form.form {
  padding: 0 20px; }
  @media only screen and (max-width: 599px) {
    .b__panel form.form {
      padding: 0 15px; } }

form.form .field--hr {
  height: 2px;
  background-color: rgba(222, 219, 219, 0.31);
  margin: 30px 0;
  border-radius: 4px; }

form.form .field + .field {
  margin-top: 10px; }
  form.form .field + .field--hr {
    margin-top: 30px; }
  form.form .field + .field--fb-button {
    margin-top: 19px; }

form.form .field--textarea textarea {
  resize: none;
  -webkit-appearance: none;
  background: none;
  box-shadow: none;
  width: 100%;
  box-sizing: border-box;
  padding: 15px 15px;
  min-height: 165px;
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  font-family: "SF UI  Text";
  border-width: 2px;
  border-color: #f2f2f2;
  border-style: solid;
  border-radius: 4px; }
  form.form .field--textarea textarea::-webkit-input-placeholder {
    color: #cccccc; }
  form.form .field--textarea textarea:-moz-placeholder {
    color: #cccccc; }
  form.form .field--textarea textarea::-moz-placeholder {
    color: #cccccc; }
  form.form .field--textarea textarea:-ms-input-placeholder {
    color: #cccccc; }
  form.form .field--textarea textarea:hover, form.form .field--textarea textarea:active, form.form .field--textarea textarea:focus {
    border-color: #e5e5e5; }

form.form .field--text input, form.form .field--password input, form.form .field--email input, form.form .field--tel input {
  resize: none;
  -webkit-appearance: none;
  background: none;
  box-shadow: none;
  border-width: 2px;
  border-color: #f2f2f2;
  border-style: solid;
  border-radius: 4px;
  background-color: white;
  height: 52px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 15px;
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  font-family: "SF UI  Text";
  transition: border-color 300ms ease-out 0s; }
  form.form .field--text input::-webkit-input-placeholder, form.form .field--password input::-webkit-input-placeholder, form.form .field--email input::-webkit-input-placeholder, form.form .field--tel input::-webkit-input-placeholder {
    color: #cccccc; }
  form.form .field--text input:-moz-placeholder, form.form .field--password input:-moz-placeholder, form.form .field--email input:-moz-placeholder, form.form .field--tel input:-moz-placeholder {
    color: #cccccc; }
  form.form .field--text input::-moz-placeholder, form.form .field--password input::-moz-placeholder, form.form .field--email input::-moz-placeholder, form.form .field--tel input::-moz-placeholder {
    color: #cccccc; }
  form.form .field--text input:-ms-input-placeholder, form.form .field--password input:-ms-input-placeholder, form.form .field--email input:-ms-input-placeholder, form.form .field--tel input:-ms-input-placeholder {
    color: #cccccc; }
  form.form .field--text input:-webkit-autofill, form.form .field--password input:-webkit-autofill, form.form .field--email input:-webkit-autofill, form.form .field--tel input:-webkit-autofill {
    background-color: #fff; }
  form.form .field--text input:hover, form.form .field--text input:active, form.form .field--password input:hover, form.form .field--password input:active, form.form .field--email input:hover, form.form .field--email input:active, form.form .field--tel input:hover, form.form .field--tel input:active {
    border-color: #e5e5e5; }

form.form .field--submit input[type="submit"],
form.form .field--submit .btn {
  border-radius: 4px;
  width: 100%;
  height: 56px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 600; }

form.form .field--links {
  padding: 16px 0 0px; }
  form.form .field--links a {
    font-size: 12px;
    color: #adadad;
    line-height: 1.2;
    text-decoration: none; }

form.form .field--select .selectize-control {
  position: relative;
  text-align: left; }
  form.form .field--select .selectize-control .selectize-input {
    border-width: 2px;
    border-color: #f2f2f2;
    border-style: solid;
    border-radius: 4px;
    background-color: white;
    height: 52px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 15px;
    color: #333333;
    font-size: 16px;
    font-weight: 400;
    font-family: "SF UI  Text";
    text-align: left; }
    form.form .field--select .selectize-control .selectize-input:before {
      content: '';
      display: inline-block;
      height: 105%;
      vertical-align: middle; }
    form.form .field--select .selectize-control .selectize-input:hover, form.form .field--select .selectize-control .selectize-input:active, form.form .field--select .selectize-control .selectize-input:focus {
      border-color: #e5e5e5; }
    form.form .field--select .selectize-control .selectize-input .item,
    form.form .field--select .selectize-control .selectize-input input {
      display: inline-block;
      vertical-align: middle;
      color: #333333;
      font-size: 16px;
      font-weight: 400;
      font-family: "SF UI  Text";
      margin: 0;
      border: 0;
      pointer-events: none; }
      form.form .field--select .selectize-control .selectize-input .item::-webkit-input-placeholder,
      form.form .field--select .selectize-control .selectize-input input::-webkit-input-placeholder {
        color: #cccccc; }
      form.form .field--select .selectize-control .selectize-input .item:-moz-placeholder,
      form.form .field--select .selectize-control .selectize-input input:-moz-placeholder {
        color: #cccccc; }
      form.form .field--select .selectize-control .selectize-input .item::-moz-placeholder,
      form.form .field--select .selectize-control .selectize-input input::-moz-placeholder {
        color: #cccccc; }
      form.form .field--select .selectize-control .selectize-input .item:-ms-input-placeholder,
      form.form .field--select .selectize-control .selectize-input input:-ms-input-placeholder {
        color: #cccccc; }
    form.form .field--select .selectize-control .selectize-input .item {
      width: calc(100% - 30px) !important; }
    form.form .field--select .selectize-control .selectize-input.not-full input {
      color: #cccccc; }
    form.form .field--select .selectize-control .selectize-input:after {
      content: '';
      display: block;
      position: absolute;
      width: 13px;
      height: 7px;
      background-image: url("data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgd2lkdGg9IjEzcHgiIGhlaWdodD0iN3B4Ij48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiICBvcGFjaXR5PSIwLjI1MSIgZmlsbD0icmdiKDEwMiwgMTAyLCAxMDIpIiBkPSJNMTMuMDEwLDAuNzE2IEw2LjcxNiw3LjAxMCBMNi41MDAsNi43OTQgTDYuMjg0LDcuMDEwIEwtMC4wMTAsMC43MTYgTDAuNzE1LC0wLjAxMCBMNi41MDAsNS43NzQgTDEyLjI4NCwtMC4wMTAgTDEzLjAxMCwwLjcxNiBaIi8+PC9zdmc+");
      border: 0;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      margin: 0; }
  form.form .field--select .selectize-control .selectize-dropdown {
    position: absolute;
    background: white;
    box-sizing: border-box;
    margin-top: 17px;
    width: 100% !important;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    border-width: 0;
    z-index: 99; }
    form.form .field--select .selectize-control .selectize-dropdown:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 7px 7px 7px;
      border-color: transparent transparent #ffffff transparent;
      position: absolute;
      top: -7px;
      right: 15px; }
    form.form .field--select .selectize-control .selectize-dropdown .selectize-dropdown-content {
      padding: 10px 0; }
    form.form .field--select .selectize-control .selectize-dropdown .option {
      padding: 5px 15px;
      cursor: pointer; }
      form.form .field--select .selectize-control .selectize-dropdown .option.active {
        color: #3399ff; }

form.form .field--radio .field-radio {
  font-size: 0;
  position: relative; }
  form.form .field--radio .field-radio:after {
    content: "";
    display: block;
    clear: both; }
  form.form .field--radio .field-radio label {
    color: #227cd6;
    border: solid 2px #227cd6;
    border-left-width: 1px;
    border-right-width: 1px;
    text-align: center;
    float: left;
    width: 20%;
    height: 46px;
    font-weight: 400;
    cursor: pointer; }
    form.form .field--radio .field-radio label:before {
      content: '';
      display: inline-block;
      height: 105%;
      vertical-align: middle; }
    :root form.form .field--radio .field-radio label {
      font-size: 14px;
      margin-bottom: 0; }
    form.form .field--radio .field-radio label:nth-of-type(1) {
      border-left-width: 2px;
      border-radius: 4px 0 0 4px; }
    form.form .field--radio .field-radio label:nth-of-type(5) {
      border-right-width: 2px;
      border-radius: 0 4px 4px 0; }
  form.form .field--radio .field-radio input {
    display: none;
    position: absolute; }
    form.form .field--radio .field-radio input:checked + label {
      color: white;
      background-color: #227cd6; }

form.form .field .field-part-box:after {
  content: "";
  display: block;
  clear: both; }

form.form .field .field-part-box .field-part-pre {
  width: 44px;
  background: #f2f2f2;
  height: 52px;
  float: left;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: rgba(51, 51, 51, 0.5);
  line-height: 52px;
  padding-left: 2px; }
  form.form .field .field-part-box .field-part-pre + .field-part input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

form.form .field .field-part-box .field-part {
  width: calc(100% - 44px);
  float: left; }

:root .b__modal form.form {
  width: 302px; }
  :root .b__modal form.form .field--text input, :root .b__modal form.form .field--password input, :root .b__modal form.form .field--email input, :root .b__modal form.form .field--tel input {
    height: 56px; }
  :root .b__modal form.form .field--submit {
    margin-top: 30px; }
  :root .b__modal form.form .field--select .selectize-control .selectize-input {
    height: 56px; }

.b__people-sidebar_form form.form .field--search .btn {
  width: 26px;
  height: 25px; }
  .b__people-sidebar_form form.form .field--search .btn:before {
    content: none; }

.b__people-sidebar_form form.form .field--search input {
  width: calc(100% - 57px);
  display: inline-block;
  margin-left: 11px;
  padding: 0;
  border: 0;
  font-size: 14px;
  line-height: 1.2; }
  .b__people-sidebar_form form.form .field--search input::-webkit-input-placeholder {
    color: #bfbfbf; }
  .b__people-sidebar_form form.form .field--search input:-moz-placeholder {
    /* Firefox 18- */
    color: #bfbfbf; }
  .b__people-sidebar_form form.form .field--search input::-moz-placeholder {
    /* Firefox 19+ */
    color: #bfbfbf; }
  .b__people-sidebar_form form.form .field--search input:-ms-input-placeholder {
    color: #bfbfbf; }

form.form.form--posting-item {
  padding: 0; }
  form.form.form--posting-item .field + .field {
    margin-top: 28px; }
  form.form.form--posting-item .field label {
    display: block;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 8px; }
  form.form.form--posting-item .field.field--submit, form.form.form--posting-item .field.field--part {
    max-width: 275px; }
  form.form.form--posting-item .field.field--select {
    max-width: 50%;
    min-width: 275px; }

select {
  -webkit-appearance: none;
  border: none;
  background: none;
  box-shadow: none;
  outline: none !important;
  cursor: pointer;
  border-width: 2px;
  border-color: #f2f2f2;
  border-style: solid;
  border-radius: 4px;
  background-color: white;
  height: 52px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 15px;
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  font-family: "SF UI  Text";
  text-align: left;
  background-image: url("data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgd2lkdGg9IjEzcHgiIGhlaWdodD0iN3B4Ij48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiICBvcGFjaXR5PSIwLjI1MSIgZmlsbD0icmdiKDEwMiwgMTAyLCAxMDIpIiBkPSJNMTMuMDEwLDAuNzE2IEw2LjcxNiw3LjAxMCBMNi41MDAsNi43OTQgTDYuMjg0LDcuMDEwIEwtMC4wMTAsMC43MTYgTDAuNzE1LC0wLjAxMCBMNi41MDAsNS43NzQgTDEyLjI4NCwtMC4wMTAgTDEzLjAxMCwwLjcxNiBaIi8+PC9zdmc+");
  background-repeat: no-repeat;
  background-size: 13px auto;
  background-position: right 15px center; }
  select:before {
    content: '';
    display: inline-block;
    height: 105%;
    vertical-align: middle; }
  select:hover, select:active, select:focus {
    border-color: #e5e5e5; }

.gm-err-autocomplete {
  background-image: none !important; }

@media (max-width: 767.98px) {
  .form.form.form--posting-item .field.field--select {
    width: 100%;
    min-width: 0;
    max-width: none; }
  .form.form.form--posting-item .field.field--submit,
  .form.form.form--posting-item .field.field--part {
    width: 100%;
    max-width: none; }
  .form.form.form--posting-item .field label {
    margin-bottom: 15px; }
  .form.form .field--select {
    max-width: 100%;
    min-width: 0; }
  .form.form .field--radio .field-radio {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start; }
    .form.form .field--radio .field-radio label {
      float: none;
      width: 50%;
      border: none;
      background: transparent;
      height: auto;
      position: relative;
      color: #000;
      font-size: 16px !important;
      line-height: 20px;
      font-weight: 400;
      text-align: left;
      padding: 0;
      margin: 0;
      padding-left: 30px;
      margin-bottom: 15px !important;
      border-radius: 0 !important; }
      .form.form .field--radio .field-radio label:before {
        content: "";
        display: block;
        border: 2px solid #f2f2f2;
        border-radius: 4px;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        transition: all 0.2s ease-in-out; }
      .form.form .field--radio .field-radio label:after {
        content: "";
        display: block;
        background-color: #227cd6;
        width: 10px;
        height: 10px;
        border-radius: 2px;
        position: absolute;
        top: 5px;
        left: 5px;
        opacity: 0;
        transition: all 0.2s ease-in-out; }
    .form.form .field--radio .field-radio input:checked + label {
      color: #000;
      background-color: transparent; }
      .form.form .field--radio .field-radio input:checked + label:before {
        border-color: #227cd6; }
      .form.form .field--radio .field-radio input:checked + label:after {
        opacity: 1; } }

.b__panel {
  border-radius: 6px;
  background-color: white;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
  padding-top: 30px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 50px;
  margin: 25px 0 30px; }
  .form .b__panel:first-child {
    margin-top: 0; }
  .ambassador-signup .b__panel {
    padding-left: 7px;
    padding-right: 7px;
    margin: 0; }
  .b__panel .b__title {
    font-size: 22px;
    color: #333333;
    line-height: 1.2;
    margin-bottom: 20px; }
  .b__panel-after {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: black; }
    .b__panel-after:before {
      content: '';
      display: inline-block;
      height: 105%;
      vertical-align: middle; }
    .b__panel-after a {
      text-decoration: none;
      color: black;
      font-size: 20px;
      font-weight: 600;
      display: inline-block;
      vertical-align: middle; }

@media (max-width: 767px) {
  .b__panel {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
    padding-top: 30px; } }

.link {
  text-decoration: none; }
  .link.link--continue {
    padding: 3px 0;
    font-size: 16px;
    font-weight: 600;
    color: #227cd6;
    line-height: 1.2;
    text-align: center;
    display: inline-block;
    color: #999999; }
    .link.link--continue:before {
      content: '';
      display: inline-block;
      height: 105%;
      vertical-align: middle; }
    .link.link--continue:after {
      content: '';
      vertical-align: middle;
      display: inline-block;
      background-image: url(data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgd2lkdGg9IjEwcHgiIGhlaWdodD0iMThweCI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiAgZmlsbD0icmdiKDM0LCAxMjQsIDIxNCkiIGQ9Ik05Ljk4OSw5LjM3NiBMMS4zNzgsMTcuOTg5IEwwLjAxNSwxNi42MjQgTDcuNjM2LDkuMDAwIEwwLjAxNSwxLjM3NiBMMS4zNzgsMC4wMTEgTDkuOTg5LDguNjI0IEw5LjYxMyw5LjAwMCBMOS45ODksOS4zNzYgWiIvPjwvc3ZnPg==);
      width: 10px;
      height: 18px;
      margin-left: 17px;
      filter: grayscale(1);
      opacity: .7;
      margin-top: -1px; }
  .link.link--more {
    font-size: 16px;
    color: #227cd6;
    font-weight: 400; }
  .link.link--back:before {
    content: '';
    width: 6px;
    height: 12px;
    display: inline-block;
    background-image: url(data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgd2lkdGg9IjZweCIgaGVpZ2h0PSIxMnB4Ij48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiICBvcGFjaXR5PSIwLjUwMiIgZmlsbD0icmdiKDEwMiwgMTAyLCAxMDIpIiBkPSJNNi4wMTAsMTEuMjg0IEw1LjI4MywxMi4wMTAgTC0wLjAxMCw2LjcxNiBMMC4yMDYsNi41MDAgTC0wLjAxMCw2LjI4NSBMNS4yODMsLTAuMDEwIEw2LjAxMCwwLjcxNiBMMS4xODIsNi40NTYgTDYuMDEwLDExLjI4NCBaIi8+PC9zdmc+);
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 50%;
    left: -20px;
    transform: translateY(-50%); }

span.badge {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(40%) translateY(-50%);
  border: solid 2px white;
  background: #227cd6;
  color: white;
  font-size: 10px;
  font-weight: bold;
  font-family: "SF UI  Text";
  width: 20px;
  height: 20px;
  line-height: 16px;
  text-align: center;
  border-radius: 50%;
  background-color: #3399ff;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05); }

#change-password {
  color: #333333;
  font-weight: bold;
  text-decoration: none;
  position: relative;
  display: inline-block; }
  #change-password:after {
    content: '';
    display: block;
    background-image: url("data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgd2lkdGg9IjEzcHgiIGhlaWdodD0iN3B4Ij48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiICBvcGFjaXR5PSIwLjI1MSIgZmlsbD0icmdiKDEwMiwgMTAyLCAxMDIpIiBkPSJNMTMuMDEwLDAuNzE2IEw2LjcxNiw3LjAxMCBMNi41MDAsNi43OTQgTDYuMjg0LDcuMDEwIEwtMC4wMTAsMC43MTYgTDAuNzE1LC0wLjAxMCBMNi41MDAsNS43NzQgTDEyLjI4NCwtMC4wMTAgTDEzLjAxMCwwLjcxNiBaIi8+PC9zdmc+");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 20px;
    height: 20px;
    position: absolute;
    right: -39px;
    top: 50%;
    transform: translateY(-50%); }

.modal {
  display: none;
  padding: 0 25px; }

:root .b__modal {
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.25); }
  :root .b__modal .b__title {
    margin-bottom: 24px; }
  :root .b__modal .fancybox-skin {
    border-radius: 6px; }

[data-modal-open] {
  cursor: pointer; }

.dropdown-box {
  position: relative; }

.dropdown .dropdown-arrow {
  display: block;
  position: absolute;
  width: 14px;
  height: 8px;
  top: 50%;
  right: 15px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: transform 250ms ease-out 0s; }
  .dropdown .dropdown-arrow .icon {
    width: 14px;
    height: 8px;
    fill: #dcdcdc; }

.dropdown:hover .dropdown-arrow .icon {
  fill: #c3c3c3; }

.dropdown.show-dropdown .dropdown-arrow {
  transform: rotate(180deg) translateY(50%);
  backface-visibility: hidden; }
  .dropdown.show-dropdown .dropdown-arrow .icon {
    fill: darkgray; }

.dropdown-content {
  position: absolute;
  background: white;
  box-sizing: border-box;
  margin-top: 17px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  border-width: 0;
  white-space: normal;
  right: 0;
  z-index: 150; }
  .dropdown-content ul {
    list-style: none;
    text-align: left;
    padding: 15px 20px;
    margin: 0; }
    .dropdown-content ul li {
      line-height: 1.5; }
      .dropdown-content ul li a {
        font-size: 14px;
        text-decoration: none;
        color: #333333; }
  .dropdown-content:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7px 7px 7px;
    border-color: transparent transparent #ffffff transparent;
    position: absolute;
    top: -7px;
    right: 15px;
    left: auto; }
  .dropdown-content .dropdown-content_header {
    padding: 17px 20px;
    font-size: 14px;
    font-weight: 500;
    color: #414141; }
  .dropdown-content .dropdown-content_footer {
    background-color: #fafafa;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: #414141;
    padding-top: 12px;
    padding-bottom: 14px;
    border-radius: 0 0 6px 6px; }
    .dropdown-content .dropdown-content_footer a {
      text-decoration: none;
      color: #414141; }
      .dropdown-content .dropdown-content_footer a:hover {
        text-decoration: underline; }
  .dropdown + .dropdown-content {
    display: none; }
  .dropdown.show-dropdown + .dropdown-content {
    display: block; }

.tabs__item {
  display: none; }
  .tabs__item:first-child {
    display: block; }

/* Site */
.header {
  background: white;
  height: 80px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1001; }
  @media only screen and (max-width: 599px) {
    .header {
      text-align: center; } }
  .header .header-top {
    width: 100%;
    height: 80px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
  .header .logo {
    flex-shrink: 0;
    display: block; }
    @media only screen and (max-width: 599px) {
      .header .logo {
        float: none; } }
  .header .header-search {
    flex-grow: 5;
    flex-shrink: 1;
    margin-left: 33px;
    position: relative; }
    .header .header-search .input-query:before {
      display: none !important; }
    .header .header-search .input-query input {
      padding-left: 70px; }
    .header .header-search__toggle {
      display: none; }
  .header.header--extended {
    position: absolute;
    background: transparent; }
    .header.header--extended .header-navigation {
      margin-top: 0; }
      .header.header--extended .header-navigation > ul > li {
        line-height: 37px; }
    .header.header--extended .site-container {
      position: relative;
      z-index: 1; }
    .header.header--extended .btn {
      min-width: 0;
      padding: 0px 28px; }
    .page-home .header.header--extended {
      background-color: #227cd6; }
      .page-home .header.header--extended .logo span {
        color: white; }
      .page-home .header.header--extended .btn:not(.btn--pure) {
        background-color: white;
        color: #227dd7; }
  .header:not(.header--extended) {
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05); }
    .page-about .header:not(.header--extended) {
      margin-top: -80px;
      transition: transform 350ms ease-out 0s; }
    .header:not(.header--extended) .header-search__toggle svg {
      fill: #666 !important; }
    .header:not(.header--extended) .header__mobile-navigation ul li a svg {
      fill: #666; }
    .header:not(.header--extended) .header__mobile-navigation ul li a svg.icon.icon--activity-feed {
      color: #666; }
    .header:not(.header--extended) .header__mobile-navigation ul li .add-button:before, .header:not(.header--extended) .header__mobile-navigation ul li .add-button:after {
      background-color: #666; }
    .header:not(.header--extended) .header__menu-toggle span {
      background-color: #666; }
      .header:not(.header--extended) .header__menu-toggle span:before, .header:not(.header--extended) .header__menu-toggle span:after {
        background-color: #666; }
  .header.out {
    transform: translateY(0%); }
  .header.out-off {
    transform: translateY(100%); }
  .header__menu-toggle span {
    display: block;
    width: 24px;
    height: 2px;
    background-color: #fff;
    position: relative; }
    .header__menu-toggle span:before, .header__menu-toggle span:after {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      background-color: #fff;
      position: absolute;
      left: 0; }
    .header__menu-toggle span:before {
      top: -6px; }
    .header__menu-toggle span:after {
      top: 6px; }
  .header__mobile-navigation {
    display: none;
    flex-shrink: 0; }
    .header__mobile-navigation ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center; }
      .header__mobile-navigation ul li a {
        text-decoration: none !important;
        width: 34px;
        height: 40px;
        position: relative;
        display: block;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .header__mobile-navigation ul li a svg {
          width: 23px;
          height: 23px;
          fill: #fff; }
          .header__mobile-navigation ul li a svg.icon.icon--activity-feed {
            width: 20px;
            height: 20px; }
          .header__mobile-navigation ul li a svg.icon.icon--speech-bubble {
            width: 22px;
            height: 22px; }
        .header__mobile-navigation ul li a span.badge {
          opacity: 0; }
          .header__mobile-navigation ul li a span.badge.active {
            opacity: 1; }
        .header__mobile-navigation ul li a .header-user-menu_photo {
          display: -webkit-flex;
          display: -moz-flex;
          display: -ms-flex;
          display: -o-flex;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center; }
      .header__mobile-navigation ul li .add-button:before, .header__mobile-navigation ul li .add-button:after {
        content: "";
        display: block;
        background-color: #fff;
        height: 2px;
        width: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -10px;
        margin-top: -1px; }
      .header__mobile-navigation ul li .add-button:after {
        transform: rotate(90deg); }
      .header__mobile-navigation ul li:not(:last-child) {
        margin-right: 3px; }

@media (max-width: 991.98px) {
  .header .header-search {
    width: 100%;
    position: absolute;
    left: 0;
    top: 100%;
    background-color: #fff;
    margin-left: 0;
    border-top: 1px solid #dbdbdb;
    border-bottom: 1px solid #dbdbdb;
    display: none; }
    .header .header-search.active {
      display: block; }
    .header .header-search .input-query {
      height: 60px; }
      .header .header-search .input-query input {
        padding-left: 70px; }
    .header .header-search__toggle {
      width: 40px;
      height: 40px;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      margin-right: 20px;
      text-decoration: none !important; }
      .header .header-search__toggle span {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
      .header .header-search__toggle .active-icon {
        display: none; }
      .header .header-search__toggle svg {
        width: 26px;
        height: 26px;
        fill: #fff; }
      .header .header-search__toggle.active .active-icon {
        display: flex; }
      .header .header-search__toggle.active .unactive-icon {
        display: none; } }

@media (max-width: 767.98px) {
  .header {
    position: fixed !important;
    height: 50px !important; }
    .header__mobile-navigation {
      display: block; }
    .header .header-top {
      padding-top: 0 !important;
      height: 50px;
      justify-content: space-between; }
      .header .header-top:after {
        display: none !important; }
      .header .header-top .logo {
        font-size: 26px;
        margin-top: 0 !important; }
    .header .header-search .input-query {
      height: 50px; }
      .header .header-search .input-query .icon {
        left: 20px; }
      .header .header-search .input-query input {
        padding-left: 60px; }
    .header .header-search__toggle {
      width: 34px;
      margin-right: 3px; }
      .header .header-search__toggle svg {
        width: 22px;
        height: 22px; }
      .header .header-search__toggle svg.icon.icon--close {
        width: 18px;
        height: 18px; }
    .header__mobile-navigation ul li a span.badge {
      transform: none !important; }
  .header-navigation {
    display: none; } }

.header-menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1100;
  background-color: #fff;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: none; }
  .header-menu.active {
    display: block; }
  .header-menu__inner-content {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 100px; }
  .header-menu__header {
    height: 50px;
    width: 100%;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px; }
  .header-menu__logo {
    font-family: TeXGyreAdventor;
    line-height: 1;
    font-size: 26px;
    color: #000;
    font-weight: 700;
    font-style: italic;
    text-decoration: none !important; }
  .header-menu__close-button {
    -webkit-appearance: none;
    border: none;
    outline: none !important;
    background: none;
    cursor: pointer;
    width: 34px;
    height: 40px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    color: #000; }
    .header-menu__close-button svg {
      width: 20px;
      height: 20px; }
    .header-menu__close-button:active {
      opacity: 0.7; }
  .header-menu__enter-buttons {
    width: 100%;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px; }
    .header-menu__enter-buttons .btn {
      width: 48%;
      margin: 0; }
    .header-menu__enter-buttons .btn.btn--footer-pure {
      color: #666; }
  .header-menu__navigation {
    width: 100%; }
    .header-menu__navigation ul {
      list-style: none;
      padding: 0;
      margin: 0;
      width: 100%; }
      .header-menu__navigation ul li a:not(.btn) {
        font-size: 15px;
        color: #666666;
        text-decoration: none !important;
        position: relative;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center; }
        .header-menu__navigation ul li a:not(.btn) span.badge {
          position: relative;
          margin-left: auto;
          flex-shrink: 0;
          transform: none;
          top: auto;
          right: auto;
          left: auto;
          bottom: auto; }
        .header-menu__navigation ul li a:not(.btn) .header-user-menu_photo {
          display: -webkit-flex;
          display: -moz-flex;
          display: -ms-flex;
          display: -o-flex;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 24px;
          height: 24px;
          flex-shrink: 0;
          margin-right: 10px;
          fill: #bbb; }
          .header-menu__navigation ul li a:not(.btn) .header-user-menu_photo img {
            border-radius: 50%;
            width: 100%;
            height: 100%;
            object-fit: cover; }
        .header-menu__navigation ul li a:not(.btn):hover {
          color: #333333; }
      .header-menu__navigation ul li:not(:last-child) {
        margin-bottom: 15px; }
      .header-menu__navigation ul .divider {
        height: 1px;
        background-color: #eee;
        width: 100%;
        margin-top: 20px !important;
        margin-bottom: 20px !important;
        display: block; }

@media (min-width: 992px) {
  .header-menu {
    display: none !important; } }

nav.header-navigation {
  flex-shrink: 0; }
  @media only screen and (max-width: 599px) {
    nav.header-navigation {
      display: none; } }
  nav.header-navigation > ul {
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 0;
    white-space: nowrap; }
    nav.header-navigation > ul > li {
      font-size: initial;
      display: inline-block;
      vertical-align: middle;
      position: relative; }
      nav.header-navigation > ul > li.is-margined {
        margin: 0 10px; }
      nav.header-navigation > ul > li > a:not(.btn) {
        font-size: 14px;
        color: #666666;
        text-decoration: none;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        margin: 0 15px; }
        nav.header-navigation > ul > li > a:not(.btn):hover {
          color: #333333; }
        .header.header--extended nav.header-navigation > ul > li > a:not(.btn) {
          color: white; }
          .header.header--extended nav.header-navigation > ul > li > a:not(.btn):hover {
            color: white; }
    nav.header-navigation > ul a.btn {
      margin-left: 20px; }
  nav.header-navigation .link--speech-bubble,
  nav.header-navigation .link--activity-feed {
    height: 25px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    line-height: 0; }
    nav.header-navigation .link--speech-bubble.active .icon,
    nav.header-navigation .link--activity-feed.active .icon {
      fill: #3399ff;
      color: #3399ff; }

.search {
  width: 100%; }
  @media only screen and (min-width: 1200px) {
    .search {
      width: calc(196px + ((100% - 336px) / 12 * 8));
      margin-right: 0;
      margin-left: calc(56px + ((100% - 308px) / 12 * 2)); } }
  .b__main-header .search {
    padding-top: 280px;
    margin-bottom: -37px; }
  .search .search-tabs,
  .search .search-tabs-content {
    padding: 0;
    margin: 0;
    list-style: none; }
  .search .search-tabs {
    font-size: 0;
    white-space: nowrap;
    position: relative;
    z-index: 1;
    width: 80%;
    text-align: left; }
    .search .search-tabs .search-tab {
      display: inline-block;
      width: auto;
      height: 61px;
      text-align: center;
      position: relative; }
      .search .search-tabs .search-tab:not(:first-child):before {
        content: '';
        display: block;
        left: -1px;
        top: 50%;
        transform: translateY(-50%);
        background-color: white;
        opacity: 0.251;
        position: absolute;
        width: 1px;
        height: 30px; }
      .search .search-tabs .search-tab label {
        font-size: 18px;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.5);
        text-decoration: none;
        display: block;
        width: 100%;
        height: 100%;
        vertical-align: middle;
        cursor: pointer;
        margin-bottom: 0;
        padding-left: 20px;
        padding-right: 20px;
        min-width: 99px; }
        .search .search-tabs .search-tab label:before {
          content: '';
          display: inline-block;
          height: 105%;
          vertical-align: middle; }
        .search .search-tabs .search-tab label:hover {
          color: #fff; }
      .search .search-tabs .search-tab input {
        display: none; }
        .search .search-tabs .search-tab input:checked + label {
          background: white;
          border-radius: 6px 6px 0 0;
          position: relative;
          color: #333333; }
          .search .search-tabs .search-tab input:checked + label:after {
            content: '';
            display: block;
            bottom: -10px;
            left: 0;
            right: 0;
            background: white;
            position: absolute;
            height: 10px;
            z-index: 0; }
  .search .search-tabs-content {
    border-radius: 6px;
    background-color: white;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.05);
    height: 78px;
    position: relative;
    z-index: 2; }
    .search .search-tabs-content:after {
      content: "";
      display: block;
      clear: both; }
    .search .search-tabs-content .input {
      width: 80%;
      float: left;
      height: 78px; }
      .search .search-tabs-content .input:after {
        content: "";
        display: block;
        clear: both; }
    .search .search-tabs-content .submit {
      width: 20%;
      height: 100%;
      float: right;
      border-radius: 0 6px 6px 0;
      overflow: hidden; }
      .search .search-tabs-content .submit .btn {
        height: 100%;
        width: 100%;
        border-radius: 0;
        font-size: 20px;
        opacity: 1;
        transition: all 240ms ease-out 0s; }
        .page-home .search .search-tabs-content .submit .btn {
          background-color: #add96c;
          border-color: #add96c; }
          .page-home .search .search-tabs-content .submit .btn:hover {
            background-color: #a2d458;
            border-color: #a2d458; }
          .page-home .search .search-tabs-content .submit .btn:hover {
            background-color: #96cf43;
            border-color: #96cf43; }
  .search .input-place {
    position: relative;
    width: 50%;
    height: 78px;
    float: left; }
    .search .input-place .selectize-input .item {
      height: 100%;
      padding-left: 51px; }
      .search .input-place .selectize-input .item:before {
        content: '';
        height: 105%;
        display: inline-block;
        vertical-align: middle; }
    .search .input-place .selectize-dropdown {
      margin-top: 17px;
      position: absolute;
      background: white;
      box-sizing: border-box;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      border-width: 0;
      white-space: normal;
      z-index: 150; }
      .search .input-place .selectize-dropdown:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 7px 7px 7px;
        border-color: transparent transparent #ffffff transparent;
        position: absolute;
        top: -7px;
        right: 15px;
        left: auto; }
      .search .input-place .selectize-dropdown-content {
        padding: 15px 27px; }
        .search .input-place .selectize-dropdown-content > div {
          font-size: 13px;
          font-weight: 500;
          line-height: 2.1;
          transition: color 100ms ease-out 0s; }
          .search .input-place .selectize-dropdown-content > div * {
            transition: color 100ms ease-out 0s; }
          .search .input-place .selectize-dropdown-content > div:hover {
            color: #227cd6; }
    .search .input-place .icon {
      width: 22px;
      height: 30px;
      fill: #bfbfbf;
      color: #bfbfbf;
      display: inline-block;
      vertical-align: middle;
      position: absolute;
      left: 26px;
      top: 50%;
      transform: translateY(-50%); }
    .search .input-place .selectize-control,
    .search .input-place .selectize-input {
      height: 100%; }
    .search .input-place input {
      width: 100%;
      padding-right: 0;
      background-color: transparent;
      padding-left: 70px;
      font-size: 16px;
      font-weight: 400;
      font-family: "SF UI  Text";
      color: #333333;
      border: 0;
      width: 100% !important;
      cursor: pointer;
      height: 100%;
      display: inline-block;
      vertical-align: middle; }
      .search .input-place input::-webkit-input-placeholder {
        color: #bfbfbf; }
      .search .input-place input:-moz-placeholder {
        /* Firefox 18- */
        color: #bfbfbf; }
      .search .input-place input::-moz-placeholder {
        /* Firefox 19+ */
        color: #bfbfbf; }
      .search .input-place input:-ms-input-placeholder {
        color: #bfbfbf; }

.input-query {
  position: relative;
  box-sizing: border-box; }
  .input-query:before {
    content: "";
    display: block;
    width: 1px;
    height: 40px;
    background-color: #bfbfbf;
    opacity: .3;
    position: absolute;
    top: 50%;
    margin-top: -20px;
    right: 0;
    pointer-events: none;
    z-index: 8; }
  .search .input-query {
    height: 78px;
    width: 50%;
    float: left; }
  .header-search .input-query {
    float: none;
    width: auto;
    height: 80px; }
    .header-search .input-query:hover .icon {
      fill: #9d9d9d;
      color: #9d9d9d; }
    .header-search .input-query:hover input::-webkit-input-placeholder {
      color: #9d9d9d; }
    .header-search .input-query:hover input:-moz-placeholder {
      color: #9d9d9d; }
    .header-search .input-query:hover input::-moz-placeholder {
      color: #9d9d9d; }
    .header-search .input-query:hover input:-ms-input-placeholder {
      color: #9d9d9d; }
  .input-query .search-button {
    -webkit-appearance: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    width: 50px;
    height: 50px;
    background: none;
    box-shadow: none;
    border: none;
    border-radius: 0;
    position: absolute;
    top: 12px;
    left: 20px;
    z-index: 6;
    fill: #bbbbbb;
    color: #bbbbbb;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out; }
    .input-query .search-button .icon {
      width: 26px;
      height: 25px;
      fill: #9d9d9d;
      color: #9d9d9d;
      display: inline-block;
      vertical-align: middle;
      position: relative;
      left: auto;
      top: auto;
      transform: none;
      z-index: 1;
      transition: all 0.2s ease-in-out; }
    .input-query .search-button:hover .icon {
      fill: #9d9d9d;
      color: #9d9d9d; }
  .input-query .icon {
    width: 26px;
    height: 25px;
    fill: #bfbfbf;
    color: #bfbfbf;
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    left: 24px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1; }
    .header-search .input-query .icon {
      fill: #bbbbbb;
      color: #bbbbbb; }
  .input-query input {
    position: relative;
    z-index: 2;
    padding-left: 70px;
    padding-right: 0;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    font-family: "SF UI  Text";
    color: #333333;
    border: 0;
    background-color: transparent;
    display: inline-block;
    vertical-align: middle;
    height: 100%; }
    .header-search .input-query input {
      font-size: 14px;
      font-weight: 500;
      padding: 12px; }
      .header-search .input-query input::-webkit-input-placeholder {
        color: #bbbbbb; }
      .header-search .input-query input:-moz-placeholder {
        /* Firefox 18- */
        color: #bbbbbb; }
      .header-search .input-query input::-moz-placeholder {
        /* Firefox 19+ */
        color: #bbbbbb; }
      .header-search .input-query input:-ms-input-placeholder {
        color: #bbbbbb; }
    .input-query input::-webkit-input-placeholder {
      color: #bfbfbf; }
    .input-query input:-moz-placeholder {
      /* Firefox 18- */
      color: #bfbfbf; }
    .input-query input::-moz-placeholder {
      /* Firefox 19+ */
      color: #bfbfbf; }
    .input-query input:-ms-input-placeholder {
      color: #bfbfbf; }

@media (max-width: 1299.98px) {
  .search {
    width: 100%;
    max-width: 777px;
    margin: 0 auto; }
    .search .search-tabs .search-tab label {
      padding-left: 19px;
      padding-right: 19px; }
  .input-query .search-button {
    left: 10px; } }

@media (max-width: 991.98px) {
  .search {
    max-width: 692px; }
    .search .input-query {
      width: 40%; }
    .search .input-place {
      width: 60%; }
    .search .search-tabs .search-tab label {
      padding-left: 18px;
      padding-right: 18px;
      min-width: 80px; }
  .input-query .search-button {
    left: 10px;
    top: 5px; } }

@media (max-width: 767.98px) {
  .search {
    max-width: 500px;
    margin: 0 auto; }
    .search .search-tabs {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      width: 100%; }
      .search .search-tabs .search-tab {
        height: 40px;
        flex: 1; }
        .search .search-tabs .search-tab label {
          font-size: 3.4vw;
          min-width: 0;
          padding-left: 10px;
          padding-right: 10px;
          text-align: center;
          width: 100%; }
    .search .input-query {
      width: 100%;
      float: none;
      height: 50px;
      border-bottom: 1px solid rgba(191, 191, 191, 0.3); }
      .search .input-query:before {
        display: none; }
      .search .input-query .icon {
        left: 13px; }
      .search .input-query input {
        padding-left: 50px; }
    .search .input-place {
      width: 100%;
      float: none;
      height: 50px; }
      .search .input-place .icon {
        left: 14px; }
      .search .input-place input {
        padding-left: 50px; }
    .search .search-tabs-content {
      border-radius: 6px;
      height: auto; }
      .search .search-tabs-content .input {
        width: 100%;
        float: none;
        height: auto; }
      .search .search-tabs-content .submit {
        width: 100%;
        float: none;
        height: 50px;
        border-radius: 0 0 6px 6px; }
  .input-query .search-button {
    left: 6px;
    top: 0; }
    .input-query .search-button .icon {
      left: auto !important; } }

@media (max-width: 399px) {
  .search .search-tabs .search-tab label {
    font-size: 3.4vw;
    padding-left: 2.7vw;
    min-width: 0;
    padding-right: 2.7vw; } }

@media (min-width: 480px) and (max-width: 767px) {
  .search .search-tabs .search-tab label {
    font-size: 16px; } }

.header-user-menu {
  position: relative;
  padding: 5px 35px 5px 5px;
  margin: 0 0 0 5px;
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  background: white;
  border-radius: 19px;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
  height: 40px;
  border: 0;
  font-size: 0;
  line-height: initial; }
  .header-user-menu .header-user-menu_photo {
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    background-color: #f9f9f9;
    border-radius: 50%;
    position: relative; }
    .header-user-menu .header-user-menu_photo.has-avatar {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover; }
    .header-user-menu .header-user-menu_photo .icon {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      fill: #c7c7c7;
      color: #c7c7c7; }
  .header-user-menu .header-user-menu_name {
    display: inline-block;
    vertical-align: middle;
    font-size: 13px;
    color: #333333;
    line-height: 1.2;
    margin: 0 10px; }
  .header-user-menu-dropdown ul {
    display: block;
    font-size: 13px;
    font-weight: 500;
    padding: 15px 27px;
    white-space: nowrap; }
    .header-user-menu-dropdown ul li {
      display: block;
      line-height: 2.1; }
      .header-user-menu-dropdown ul li a {
        text-decoration: none;
        color: #333333; }
        .header-user-menu-dropdown ul li a:hover {
          color: #227cd6; }

.speech-bubble-dropdown {
  min-width: 380px; }
  .speech-bubble-dropdown:after {
    right: 20px; }

/* Blocks */
.b__content {
  padding-top: 25px;
  padding-bottom: 110px; }

.b__title {
  text-align: center;
  font-size: 18px;
  font-weight: 600; }
  .b__home-map .b__title {
    background-color: #f9f9f9;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.05);
    position: absolute;
    height: 40px;
    z-index: 90;
    left: 50%;
    top: 0;
    transform: translateX(-50%) translateY(-50%);
    padding: 0 34px;
    border-radius: 34px;
    font-size: 16px; }
    .page-how-it-works .b__home-map .b__title {
      background-color: white; }
    .b__home-map .b__title:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      height: 100%; }

.b__short {
  position: relative;
  min-height: 450px;
  height: calc(100vh - 80px);
  width: 100vw;
  padding-top: 120px;
  padding-bottom: 200px; }
  .b__short__mobile-tabs {
    display: none; }
  .b__short .b__short-inner {
    position: relative;
    z-index: 2;
    text-align: center; }
  .b__short:before {
    content: '';
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(36, 131, 227, 0.65); }
  .b__short #map__user-ip-location {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0; }
    .b__short #map__user-ip-location .gm-style-cc,
    .b__short #map__user-ip-location a {
      display: none !important; }

.b__items.b__items--map .b__items_item {
  position: relative;
  height: 175px; }

.b__items_item .item-content {
  display: flex;
  flex-direction: column;
  align-items: stretch; }

.b__items.b__items--map .b__items_item .item-thumbnail img,
.b__items.b__items--grid .b__items_item .item-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover; }

.b__items.b__items--grid .b__items_item .item-content {
  height: 100%; }

.chat-items {
  margin-right: 1.5rem;
  padding: 1rem 0 0 1rem; }

.b__messanger-inner {
  height: calc(100vh - 80px);
  background-color: #f9f9f9;
  display: flex; }

.b__messanger-dialogs {
  background: white;
  margin-right: 0;
  height: 100%;
  border-right: solid 1px #ebebeb;
  padding-bottom: 0;
  width: 25% !important; }

.b__messanger-chat {
  margin-right: 0rem;
  padding: 0rem;
  width: 50% !important;
  display: flex;
  flex-direction: column; }

.b__messanger-peer {
  /* float: left; */
  /* margin-right: 2.16718%; */
  margin-right: 0;
  width: 25% !important; }

.b__messanger-chat_form {
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%; }

.b__feed-item .b__items_item .item-meta {
  height: auto !important; }

.b__users.b__users--grid .b__users_item {
  height: 225px;
  width: 20%; }

.column-of-text {
  max-width: 52rem;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 30px;
  padding-bottom: 50px; }

.b__short {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  width: 100%; }

.b__short.has-message {
  align-items: flex-end; }

.b__short .site-container {
  width: 100%; }

.b__short .b__short-inner {
  position: relative;
  z-index: 2;
  width: 100%;
  flex: 1 1 100%;
  display: flex;
  align-items: center; }

.b__short.has-message .b__short-inner {
  align-items: flex-start; }

.b__short .b__short-inner.message {
  max-width: 50%;
  color: #fff;
  flex: 1 0 50%;
  align-self: center;
  align-items: center;
  justify-content: center;
  flex-direction: column; }

.b__short .b__short-inner.message h1 {
  margin-bottom: 0;
  margin-top: 0; }

.b__description,
.b__short .b__short-inner.message p {
  line-height: 1.5rem;
  text-align: center;
  margin-top: 0; }

.b__how-it-works.b__how-it-works-about {
  padding-bottom: 35px; }

.b__how-it-works .b__description {
  max-width: 50%;
  margin: 0 auto; }

.b__users.b__users--grid .b__users_item .user-name {
  font-size: 18px;
  margin-top: 10px;
  max-height: 22px;
  overflow: hidden; }

@media (max-width: 767.98px) {
  .b__how-it-works {
    padding-bottom: 80px; }
    .b__how-it-works .b__description {
      max-width: 100%;
      margin: 0; }
  .b__short {
    min-height: calc(100vh - 50px);
    height: auto;
    padding-top: 50px;
    padding-bottom: 80px; }
    .b__short .b__short-inner {
      margin-top: 0; }
    .b__short__mobile-tabs {
      display: block;
      width: 100%;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 10px; }
      .b__short__mobile-tabs select {
        -webkit-appearance: none;
        border: none;
        outline: none !important;
        background: none;
        cursor: pointer;
        border-radius: 0;
        padding: 0;
        margin: 0;
        background: none;
        color: #fff;
        border: none;
        width: auto;
        font-weight: 600;
        font-size: 16px;
        padding-right: 20px;
        background-size: 18px auto;
        background-repeat: no-repeat;
        background-position: right center;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNSAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTExLjA5MzggNC42ODc1TDcuNSA4LjI4MTI1TDMuOTA2MjUgNC42ODc1TDIuODEyNSA1Ljc4MTI1TDcuNSAxMC40Njg3TDEyLjE4NzUgNS43ODEyNUwxMS4wOTM4IDQuNjg3NVoiIGZpbGw9IiNmZmYiLz48L3N2Zz4=); }
    .b__short .b__short-inner.message {
      width: 100%;
      max-width: 100%;
      flex: none;
      padding-left: 15px;
      padding-right: 15px; } }

@media (max-width: 1199px) {
  .column-of-text {
    max-width: 100%; } }

.b__achievements {
  padding-top: 103px;
  padding-bottom: 70px; }
  .b__achievements .achievements:after {
    content: "";
    display: block;
    clear: both; }
  .b__achievements .achievements .achievement {
    width: calc(56px + ((100% - 308px) / 12 * 3));
    float: left;
    margin-left: 14px;
    margin-right: 14px;
    border-radius: 6px;
    background-color: white;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
    text-align: center; }
    @media only screen and (max-width: 599px) {
      .b__achievements .achievements .achievement {
        width: calc(308px + (100% - 308px)); }
        .b__achievements .achievements .achievement + .achievement {
          margin-top: 56px; } }
    @media only screen and (min-width: 600px) and (max-width: 899px) {
      .b__achievements .achievements .achievement {
        width: calc(140px + ((100% - 308px) / 12 * 6));
        margin-top: 28px; }
        .b__achievements .achievements .achievement:nth-child(2n+2) {
          margin-right: 0; } }
    .b__achievements .achievements .achievement:first-child {
      margin-left: 0; }
    .b__achievements .achievements .achievement:last-child {
      margin-right: 0; }
    .b__achievements .achievements .achievement-icon {
      margin-top: 34px;
      margin-bottom: 20px;
      height: 66px; }
    .b__achievements .achievements .achievement-text {
      font-size: 22px;
      font-weight: 300; }
      .b__achievements .achievements .achievement-text strong {
        font-weight: 600;
        display: block; }
    .b__achievements .achievements .achievement-controls {
      margin-bottom: 35px;
      margin-top: 24px;
      width: 190px;
      display: block;
      margin-left: auto;
      margin-right: auto;
      text-align: center; }
      .b__achievements .achievements .achievement-controls .btn {
        display: block;
        font-size: 14px;
        font-weight: 600;
        height: 46px;
        box-shadow: 0 2px 0 0 #008fcc;
        padding: 13px 0; }
        @media only screen and (min-width: 900px) and (max-width: 1199px) {
          .b__achievements .achievements .achievement-controls .btn {
            max-width: 90%;
            margin-left: auto;
            margin-right: auto; } }
        .b__achievements .achievements .achievement-controls .btn.btn--green {
          box-shadow: 0 2px 0 0 #2eb789; }
        .b__achievements .achievements .achievement-controls .btn.btn--gray {
          box-shadow: 0 2px 0 0 #2e2e2e; }
        .b__achievements .achievements .achievement-controls .btn.btn--red {
          box-shadow: 0 2px 0 0 #b72e2e; }
    .b__achievements .achievements .achievement.achievement--green .icon {
      display: inline-block;
      margin-top: 2px;
      fill: #33cc99;
      color: #33cc99;
      width: 50px;
      height: 64px; }
    .b__achievements .achievements .achievement.achievement--green strong {
      color: #33cc99; }
    .b__achievements .achievements .achievement.achievement--blue .icon {
      fill: #227cd6;
      color: #227cd6;
      width: 64px;
      height: 64px; }
    .b__achievements .achievements .achievement.achievement--blue strong {
      color: #227cd6; }
    .b__achievements .achievements .achievement.achievement--gray .icon {
      display: inline-block;
      margin-top: 15px;
      fill: #333333;
      color: #333333;
      width: 64px;
      height: 38px; }
    .b__achievements .achievements .achievement.achievement--gray strong {
      color: #333333; }
    .b__achievements .achievements .achievement.achievement--red .icon {
      display: inline-block;
      margin-top: 6px;
      fill: #cc3333;
      color: #cc3333;
      width: 52px;
      height: 52px; }
    .b__achievements .achievements .achievement.achievement--red strong {
      color: #cc3333; }

.b__universities {
  background-color: white;
  padding-bottom: 80px;
  padding-top: 55px; }
  @media only screen and (max-width: 599px) {
    .b__universities {
      padding-top: 30px;
      padding-bottom: 40px; } }
  .b__universities .universities {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: -14px;
    margin-right: -14px;
    flex-wrap: wrap; }
  .b__universities .university {
    width: calc(25% - 28px);
    margin-left: 14px;
    margin-right: 14px;
    border: solid 2px #f9f9f9;
    border-radius: 3px;
    margin-top: 20px;
    margin-bottom: 5px;
    text-align: center;
    padding-bottom: 22px;
    text-decoration: none;
    transition: border-color 250ms ease-out 0s; }
    @media only screen and (max-width: 599px) {
      .b__universities .university {
        width: 100%;
        margin-left: 0;
        margin-right: 0; } }
    @media only screen and (min-width: 600px) and (max-width: 899px) {
      .b__universities .university {
        width: calc(50% - 28px); } }
    .b__universities .university:hover {
      border-color: #e5e5e5; }
    .b__universities .university-name {
      font-family: "Ahellya";
      font-size: 28px;
      margin-top: 35px;
      margin-bottom: 28px; }
    .b__universities .university-counter {
      font-size: 14px;
      color: #333333;
      padding-bottom: 2px; }
    .b__universities .university-sub {
      font-size: 12px;
      color: #cccccc; }

.university--yale .university-name {
  color: #084b85; }

.university--harvard .university-name {
  color: #a61b2f; }

.university--cornell .university-name {
  color: #b72d32; }

.university--princeton .university-name {
  color: #ff6600; }

.university--darmouth .university-name {
  color: #006132; }

.university--penn .university-name {
  color: #2b437b; }

.university--brown .university-name {
  color: #d50000; }

.university--columbia .university-name {
  color: #0055a7; }

.item-university.item-university--yale-university {
  background-color: #084b85; }

.item-university.item-university--yale {
  background-color: #084b85; }

.item-university.item-university--harvard-university {
  background-color: #a61b2f; }

.item-university.item-university--harvard {
  background-color: #a61b2f; }

.item-university.item-university--cornell-university {
  background-color: #b72d32; }

.item-university.item-university--cornell {
  background-color: #b72d32; }

.item-university.item-university--princeton-university {
  background-color: #ff6600; }

.item-university.item-university--princeton {
  background-color: #ff6600; }

.item-university.item-university--darmouth-university {
  background-color: #006132; }

.item-university.item-university--darmouth {
  background-color: #006132; }

.item-university.item-university--penn-university {
  background-color: #2b437b; }

.item-university.item-university--penn {
  background-color: #2b437b; }

.item-university.item-university--brown-university {
  background-color: #d50000; }

.item-university.item-university--brown {
  background-color: #d50000; }

.item-university.item-university--columbia-university {
  background-color: #0055a7; }

.item-university.item-university--columbia {
  background-color: #0055a7; }

.b__home-items {
  padding-top: 38px;
  padding-bottom: 125px; }
  .b__home-items .b__items {
    padding-top: 45px;
    max-height: calc(100vh - 80px);
    position: relative; }

.b__home-map {
  width: 100%;
  height: 90vh;
  min-height: 700px;
  position: relative; }
  .b__home-map #home-map {
    width: 100%;
    height: 100%;
    /* Google map zoom controls */ }
    .b__home-map #home-map div {
      will-change: transform;
      transition: none; }
    .b__home-map #home-map a[title="Click to see this area on Google Maps"] {
      display: none !important; }
    .b__home-map #home-map .gm-style-cc {
      display: none !important; }
    .b__home-map #home-map .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
      bottom: 50% !important;
      right: 56px !important; }
      .b__home-map #home-map .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom > .gmnoprint {
        transform: translateY(-50%); }
        .b__home-map #home-map .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom > .gmnoprint > div {
          cursor: pointer;
          user-select: none;
          box-shadow: none !important;
          border-radius: 0 !important;
          background-color: transparent !important;
          width: auto !important;
          height: auto !important; }
          .b__home-map #home-map .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom > .gmnoprint > div > div {
            display: none !important; }
      .b__home-map #home-map .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom button {
        width: 48px !important;
        height: 48px !important;
        background-color: white !important;
        border-radius: 50% !important;
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1); }
        .b__home-map #home-map .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom button:last-child {
          margin-top: 4px !important; }
        .b__home-map #home-map .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom button > div {
          left: 17px !important;
          top: 16px !important; }
          .b__home-map #home-map .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom button > div img {
            transition: none; }
    .b__home-map #home-map .marker {
      position: absolute;
      cursor: pointer;
      width: 1px;
      height: 1px;
      z-index: 190; }
    .b__home-map #home-map .marker-thumb {
      position: absolute;
      font-size: 0;
      margin-left: -28px;
      margin-top: -51px; }
      .b__home-map #home-map .marker-thumb__icon-path {
        stroke: white;
        fill: white;
        color: white; }
      .b__home-map #home-map .marker-thumb__photo {
        border-radius: 50%;
        background-color: white;
        position: absolute;
        left: 12px;
        top: 7px;
        width: 33px;
        height: 33px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center; }

.b__how-it-works {
  padding-top: 35px;
  padding-bottom: 150px;
  background: #fff; }

.carousel-how-it-works {
  padding-top: 120px; }
  .carousel-how-it-works .item:after {
    content: "";
    display: block;
    clear: both; }
  .carousel-how-it-works .item .item-image {
    width: calc(140px + ((100% - 308px) / 12 * 6));
    float: left;
    height: 100%;
    text-align: right;
    padding-right: 15px;
    box-sizing: border-box; }
    @media only screen and (max-width: 599px) {
      .carousel-how-it-works .item .item-image {
        width: 100%;
        float: none;
        padding: 0; } }
    .carousel-how-it-works .item .item-image img {
      max-width: 100%;
      display: inline-block; }
      @media only screen and (max-width: 599px) {
        .carousel-how-it-works .item .item-image img {
          height: auto; } }
  .carousel-how-it-works .item .item-content {
    width: calc(140px + ((100% - 308px) / 12 * 6));
    float: left;
    margin-left: 0;
    margin-right: 0;
    height: 100%;
    font-size: 14px;
    line-height: 1.57;
    padding-left: 45px;
    padding-right: 45px;
    box-sizing: border-box; }
    @media only screen and (max-width: 599px) {
      .carousel-how-it-works .item .item-content {
        width: 100%;
        float: none;
        padding-left: 10px;
        padding-right: 10px;
        text-align: center; } }
    .carousel-how-it-works .item .item-content:before {
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle; }
  .carousel-how-it-works .item .item-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 25px; }

.notification {
  padding-top: 15px;
  padding-bottom: 7px; }
  .notification .notification-title {
    font-size: 22px;
    font-weight: 600;
    color: #227cd6;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 10px; }
  .notification .notification-text {
    font-size: 14px;
    color: #adadad;
    line-height: 1.286;
    text-align: center; }
  .notification .notification-action {
    margin-top: 28px; }

.b__ambassador-signup {
  padding-top: 38px;
  padding-bottom: 110px; }

.ambassador-signup:after {
  content: "";
  display: block;
  clear: both; }

.ambassador-signup .ambassador-signup_form {
  position: relative;
  z-index: 1;
  width: calc(84px + ((100% - 308px) / 12 * 4));
  margin-left: 0;
  margin-right: 0; }

.ambassador-signup .ambassador-signup_title {
  position: relative;
  z-index: 1;
  width: calc(196px + ((100% - 308px) / 12 * 8));
  margin-right: 0;
  box-sizing: border-box;
  padding: 25vh 40px 0;
  color: white; }
  .ambassador-signup .ambassador-signup_title h1 {
    font-size: 36px;
    line-height: 1.167;
    font-weight: 600;
    margin: 0; }
  .ambassador-signup .ambassador-signup_title h2 {
    font-size: 36px;
    line-height: 1.167;
    font-weight: 300;
    margin: 0; }

@media (min-width: 768px) and (max-width: 1199px) {
  .ambassador-signup .ambassador-signup_form {
    width: 350px; }
  .ambassador-signup .ambassador-signup_title {
    width: 100%; } }

@media (max-width: 767px) {
  .ambassador-signup .ambassador-signup_form {
    width: 100%; }
  .ambassador-signup .ambassador-signup_title {
    width: 100%;
    padding: 0;
    padding-top: 40px; }
    .ambassador-signup .ambassador-signup_title h1 {
      font-size: 24px; }
    .ambassador-signup .ambassador-signup_title h2 {
      font-size: 24px; } }

.b__posting-item .b__title {
  text-align: left;
  margin-bottom: 12px; }

.b__posting-item .b__panel {
  margin: 0;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05); }

.posting-item:after {
  content: "";
  display: block;
  clear: both; }

.posting-item .posting-item_images {
  width: calc(84px + ((100% - 308px) / 12 * 4));
  float: left; }
  .posting-item .posting-item_images .responsiv-uploader-fileupload.style-image-multi .upload-button {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 25px;
    float: none;
    line-height: normal;
    height: auto;
    padding: 43px 0;
    border: dashed 2px #ddd;
    border-radius: 4px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);
    text-align: center;
    transition: box-shadow 300ms ease-out 0s; }
    .posting-item .posting-item_images .responsiv-uploader-fileupload.style-image-multi .upload-button-icon {
      display: none; }
    .posting-item .posting-item_images .responsiv-uploader-fileupload.style-image-multi .upload-button:focus {
      background-color: white; }
    .posting-item .posting-item_images .responsiv-uploader-fileupload.style-image-multi .upload-button:before {
      display: block;
      width: 72px;
      height: 62px;
      margin-left: auto;
      margin-right: auto;
      background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 24 21' style='enable-background:new 0 0 24 21;' xml:space='preserve' %3E%3Cpath style='fill: %23333333;' d='M21,3c-1.4,0-2.9,0-2.9,0l-0.9-2c-0.2-0.5-1.1-1-1.8-1H8.8C8,0,7.1,0.5,6.9,1L6,3 c0,0-1.5,0-3,0C0.6,3,0,5,0,5v14c0,0,1.5,2,3,2s16.4,0,18,0s3-2,3-2V5C24,5,23.5,3,21,3z M12,18c-3.3,0-6-2.7-6-6c0-3.3,2.7-6,6-6 c3.3,0,6,2.7,6,6C18,15.3,15.3,18,12,18z M12,8c-2.2,0-4,1.8-4,4s1.8,4,4,4s4-1.8,4-4S14.2,8,12,8z'/%3E%3C/svg%3E");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      opacity: .25;
      margin-bottom: 15px;
      transition: opacity 300ms ease-out 0s; }
    .posting-item .posting-item_images .responsiv-uploader-fileupload.style-image-multi .upload-button:after {
      content: 'Choose images from your computer or drag and drop them';
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      opacity: .25;
      max-width: 60%;
      display: inline-block;
      transition: opacity 300ms ease-out 0s; }
    .posting-item .posting-item_images .responsiv-uploader-fileupload.style-image-multi .upload-button:hover {
      box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05); }
      .posting-item .posting-item_images .responsiv-uploader-fileupload.style-image-multi .upload-button:hover:before, .posting-item .posting-item_images .responsiv-uploader-fileupload.style-image-multi .upload-button:hover:after {
        opacity: .75; }
  .posting-item .posting-item_images .responsiv-uploader-fileupload.style-image-multi .upload-files-container {
    margin-left: 0; }
    .posting-item .posting-item_images .responsiv-uploader-fileupload.style-image-multi .upload-files-container:after {
      content: "";
      display: block;
      clear: both; }
    .posting-item .posting-item_images .responsiv-uploader-fileupload.style-image-multi .upload-files-container .upload-object {
      width: 100%;
      position: relative;
      border-radius: 4px;
      margin-bottom: 25px; }
      .posting-item .posting-item_images .responsiv-uploader-fileupload.style-image-multi .upload-files-container .upload-object:first-child {
        height: 0;
        padding-top: 69%;
        box-sizing: border-box;
        display: block; }
      .posting-item .posting-item_images .responsiv-uploader-fileupload.style-image-multi .upload-files-container .upload-object:not(:first-child) {
        float: left;
        width: 46.61835748792271%;
        height: 138px;
        margin-right: 6.763285%; }
        .posting-item .posting-item_images .responsiv-uploader-fileupload.style-image-multi .upload-files-container .upload-object:not(:first-child):nth-child(2n + 1) {
          margin-right: 0; }
      .posting-item .posting-item_images .responsiv-uploader-fileupload.style-image-multi .upload-files-container .upload-object .image {
        position: absolute;
        left: -1px;
        top: -1px;
        right: -1px;
        bottom: -1px;
        width: auto;
        height: auto;
        border-radius: 4px;
        border-width: 0; }
        .posting-item .posting-item_images .responsiv-uploader-fileupload.style-image-multi .upload-files-container .upload-object .image img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: 50% 50%;
          border-radius: 4px; }
      .posting-item .posting-item_images .responsiv-uploader-fileupload.style-image-multi .upload-files-container .upload-object .meta {
        margin: 0 10px 0 10px; }
      .posting-item .posting-item_images .responsiv-uploader-fileupload.style-image-multi .upload-files-container .upload-object .info {
        opacity: 0;
        z-index: 5;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        transition: all 300ms ease-out 0s;
        padding: 40px;
        background: rgba(0, 0, 0, 0.75);
        border-radius: 4px; }
        .posting-item .posting-item_images .responsiv-uploader-fileupload.style-image-multi .upload-files-container .upload-object .info .size {
          display: none; }
        .posting-item .posting-item_images .responsiv-uploader-fileupload.style-image-multi .upload-files-container .upload-object .info h4 {
          color: white;
          margin: 0;
          position: relative;
          width: 100%;
          height: 100%; }
          .posting-item .posting-item_images .responsiv-uploader-fileupload.style-image-multi .upload-files-container .upload-object .info h4 span {
            display: none; }
          .posting-item .posting-item_images .responsiv-uploader-fileupload.style-image-multi .upload-files-container .upload-object .info h4 a {
            display: block !important;
            opacity: 1 !important;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
            text-indent: -9999px;
            overflow: hidden;
            width: 14px;
            height: 18px;
            background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 14 18' style='enable-background:new 0 0 14 18;' xml:space='preserve'%3E%3Crect style='fill: %23FFFFFF' x='3.8' y='6.9' width='1.3' height='8.3'/%3E%3Crect style='fill: %23FFFFFF' x='6.4' y='6.9' width='1.3' height='8.3'/%3E%3Crect style='fill: %23FFFFFF' x='8.9' y='6.9' width='1.3' height='8.3'/%3E%3Cpath style='fill: %23FFFFFF' d='M13.4,4.2V3.5c0-0.4-0.3-0.7-0.6-0.7h-2.6c0-0.1,0-0.2,0-0.2V2.1C10.2,0.9,9.3,0,8.3,0H5.7 c-1,0-1.9,0.9-1.9,2.1v0.5c0,0.1,0,0.2,0,0.2H1.3c-0.3,0-0.6,0.3-0.6,0.7v0.7H0v1.4h1.3v10.4c0,1.1,0.9,2.1,1.9,2.1h7.6 c1,0,1.9-0.9,1.9-2.1V5.5H14V4.2H13.4z M5.1,2c0-0.4,0.3-0.7,0.6-0.7h2.5c0.3,0,0.6,0.3,0.6,0.7v0.7H5.1V2z M11.5,15.9 c0,0.4-0.3,0.7-0.6,0.7H3.2c-0.4,0-0.6-0.3-0.6-0.7V5.5h8.9V15.9z'/%3E%3C/svg%3E%0A");
            background-position: center center;
            background-repeat: no-repeat; }
      .posting-item .posting-item_images .responsiv-uploader-fileupload.style-image-multi .upload-files-container .upload-object:hover .info {
        opacity: 1; }

.posting-item .posting-item_details {
  width: calc(196px + ((100% - 308px) / 12 * 8));
  float: left;
  margin-right: 0;
  margin-left: 28px; }
  .posting-item .posting-item_details .b__panel {
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 70px;
    padding-top: 30px; }

@media (max-width: 991.98px) {
  .posting-item .posting-item_images {
    width: 100%;
    float: none;
    margin-bottom: 0; }
  .posting-item .posting-item_details {
    width: 100%;
    margin-left: 0;
    float: none; }
    .posting-item .posting-item_details .b__items.b__items--listing {
      height: auto;
      width: 100%;
      max-height: none !important;
      margin: 0; }
    .posting-item .posting-item_details .b__panel {
      padding-top: 30px;
      padding-bottom: 30px;
      padding-left: 15px;
      padding-right: 15px; } }

.b__item-comments {
  padding: 0 18px; }
  .b__item-comments .b__title {
    text-align: left;
    margin-bottom: 18px; }

.b__comments {
  padding-bottom: 25px; }
  .b__comments ul.comments {
    padding: 0;
    margin: 0;
    list-style: none; }
  .b__comments .comment .comment {
    margin-top: 10px; }
  .b__comments .comment .comments {
    padding-left: 44px; }
  .b__comments .comment-item:after {
    content: "";
    display: block;
    clear: both; }
  .b__comments .comment-author-img {
    width: 35px;
    height: 35px;
    overflow: hidden;
    border-radius: 50%;
    float: left; }
    .b__comments .comment-author-img img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .b__comments .comment-details {
    width: calc(100% - 45px);
    float: right;
    line-height: 18px; }
  .b__comments .comment-author-name {
    display: inline-block;
    font-size: 15px;
    font-weight: 600;
    color: #227cd6;
    line-height: 1.2; }
    .b__comments .comment-author-name:after {
      content: '\00a0'; }
  .b__comments .comment-text {
    font-size: 15px;
    display: inline-block;
    overflow: hidden;
    width: 100%; }
    .b__comments .comment-text p {
      margin: 0;
      display: inline-block; }
      .b__comments .comment-text p + p {
        margin-top: 15px; }
  .b__comments .comment-meta {
    font-size: 12px;
    color: #bbbbbb;
    line-height: 18px; }
    .b__comments .comment-meta a {
      text-decoration: none;
      color: #bbbbbb; }
      .b__comments .comment-meta a:hover {
        text-decoration: underline; }

.b__profile {
  padding-bottom: 90px; }
  .b__profile-header {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #333333;
    height: 175px; }
    .b__profile-header img {
      display: block;
      height: 100%;
      width: 100%;
      opacity: .25;
      object-fit: cover; }
  .b__profile-inner {
    min-height: 500px; }
    .b__profile-inner .site-container:after {
      content: "";
      display: block;
      clear: both; }
  .b__profile-card {
    width: calc(56px + ((100% - 308px) / 12 * 3));
    float: left;
    margin-top: -60px;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    text-align: center; }
    .b__profile-card:before {
      content: '';
      display: block;
      width: 148px;
      height: 148px;
      border-radius: 50%;
      background-color: white;
      opacity: .25;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      z-index: 0; }
    .b__profile-card:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 100px;
      background: white;
      z-index: 0;
      border-radius: 6px; }
    .b__profile-card > div {
      position: relative;
      z-index: 1; }
    .b__profile-card_photo {
      width: 140px;
      height: 140px;
      background-color: #f9f9f9;
      margin-top: -70px;
      margin-bottom: 15px;
      border-radius: 50%;
      display: block;
      margin-left: auto;
      margin-right: auto;
      position: relative; }
      .b__profile-card_photo .responsiv-uploader-fileupload.style-image-single {
        display: block;
        width: 140px;
        height: 140px; }
        .b__profile-card_photo .responsiv-uploader-fileupload.style-image-single .upload-button {
          display: block;
          border: 0;
          width: 140px !important;
          height: 140px !important; }
          .b__profile-card_photo .responsiv-uploader-fileupload.style-image-single .upload-button:hover {
            border: 0; }
          .b__profile-card_photo .responsiv-uploader-fileupload.style-image-single .upload-button i {
            display: none; }
        .b__profile-card_photo .responsiv-uploader-fileupload.style-image-single .upload-files-container,
        .b__profile-card_photo .responsiv-uploader-fileupload.style-image-single .upload-object {
          max-height: 140px;
          height: 140px;
          padding: 0;
          width: 140px;
          max-width: 140px; }
      .b__profile-card_photo .responsiv-uploader-fileupload.style-image-single .upload-object .info {
        height: auto;
        top: 0; }
      .b__profile-card_photo .responsiv-uploader-fileupload .upload-object p.size {
        display: none; }
      .b__profile-card_photo .responsiv-uploader-fileupload .upload-object h4 {
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
        opacity: 0;
        position: relative;
        background-color: rgba(0, 0, 0, 0.75);
        border-radius: 50%;
        transition: opacity 500ms ease-out 0s; }
        .b__profile-card_photo .responsiv-uploader-fileupload .upload-object h4 a.upload-remove-button {
          display: block !important;
          opacity: 1 !important;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%);
          text-indent: -9999px;
          overflow: hidden;
          width: 14px;
          height: 18px;
          background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 14 18' style='enable-background:new 0 0 14 18;' xml:space='preserve'%3E%3Crect style='fill: %23FFFFFF' x='3.8' y='6.9' width='1.3' height='8.3'/%3E%3Crect style='fill: %23FFFFFF' x='6.4' y='6.9' width='1.3' height='8.3'/%3E%3Crect style='fill: %23FFFFFF' x='8.9' y='6.9' width='1.3' height='8.3'/%3E%3Cpath style='fill: %23FFFFFF' d='M13.4,4.2V3.5c0-0.4-0.3-0.7-0.6-0.7h-2.6c0-0.1,0-0.2,0-0.2V2.1C10.2,0.9,9.3,0,8.3,0H5.7 c-1,0-1.9,0.9-1.9,2.1v0.5c0,0.1,0,0.2,0,0.2H1.3c-0.3,0-0.6,0.3-0.6,0.7v0.7H0v1.4h1.3v10.4c0,1.1,0.9,2.1,1.9,2.1h7.6 c1,0,1.9-0.9,1.9-2.1V5.5H14V4.2H13.4z M5.1,2c0-0.4,0.3-0.7,0.6-0.7h2.5c0.3,0,0.6,0.3,0.6,0.7v0.7H5.1V2z M11.5,15.9 c0,0.4-0.3,0.7-0.6,0.7H3.2c-0.4,0-0.6-0.3-0.6-0.7V5.5h8.9V15.9z'/%3E%3C/svg%3E%0A");
          background-position: center center;
          background-repeat: no-repeat; }
      .b__profile-card_photo .responsiv-uploader-fileupload .upload-object:hover h4 {
        opacity: 1; }
      .b__profile-card_photo .responsiv-uploader-fileupload .upload-object h4 span {
        display: none; }
      .b__profile-card_photo .responsiv-uploader-fileupload.style-image-single .upload-object .icon-container.image img {
        border-radius: 50%; }
      .b__profile-card_photo .responsiv-uploader-fileupload.style-image-single .upload-object .icon-container {
        width: 140px;
        height: 140px;
        border: 0;
        background-color: transparent; }
        .b__profile-card_photo .responsiv-uploader-fileupload.style-image-single .upload-object .icon-container img {
          max-width: 140px;
          max-height: 140px; }
      .b__profile-card_photo img {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
        cursor: pointer; }
      .b__profile-card_photo.b__profile-card_photo-hint {
        display: none; }
      .b__profile-card_photo.no-photo {
        margin-bottom: 55px; }
        .b__profile-card_photo.no-photo:after {
          content: 'Upload photo';
          font-size: 12px;
          color: #999999;
          position: absolute;
          left: 0;
          right: 0;
          bottom: -30px; }
        .b__profile-card_photo.no-photo .b__profile-card_photo-hint {
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          width: 56px;
          height: 40px; }
          .b__profile-card_photo.no-photo .b__profile-card_photo-hint .icon {
            width: 56px;
            height: 40px;
            fill: #c7c7c7;
            color: #c7c7c7; }
        .b__profile-card_photo.no-photo:hover .b__profile-card_photo-hint .icon {
          fill: #999;
          color: #999; }
        .b__profile-card_photo.no-photo .b__profile-card_photo-upload {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          border-radius: 50%; }
          .b__profile-card_photo.no-photo .b__profile-card_photo-upload input {
            border-radius: 50%;
            display: block;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer; }
        .b__profile-card_photo.no-photo .b__profile-card_photo-controls {
          display: none; }
    .b__profile-card_name {
      font-size: 23.47px; }
    .b__profile-card_place {
      font-size: 16px;
      color: #999999;
      line-height: 1.2;
      margin-top: 5px; }
    .b__profile-card_meta {
      border-top: solid 1px #f0f0f0;
      border-bottom: solid 1px #f0f0f0;
      margin-top: 24px; }
      .b__profile-card_meta:after {
        content: "";
        display: block;
        clear: both; }
      .b__profile-card_meta strong {
        padding-top: 10px;
        display: block;
        font-size: 32px;
        color: #414141;
        font-weight: bold; }
      .b__profile-card_meta span {
        padding-bottom: 14px;
        font-size: 12px;
        color: #999999;
        display: block; }
      .b__profile-card_meta-items {
        width: calc(50% - 1px);
        float: left; }
      .b__profile-card_meta-points {
        width: calc(50% + 1px);
        float: right;
        border-left: solid 1px #f0f0f0; }
    .b__profile-card_verify {
      margin: 20px 0;
      padding: 0 20px;
      font-size: 12px; }
      .b__profile-card_verify a {
        color: #227cd6; }
      .b__profile-card_verify form {
        margin-top: 8px; }
    .b__profile-card_about {
      margin-top: 22px;
      font-size: 12px;
      color: #414141;
      line-height: 1.833;
      padding-left: 20px;
      padding-right: 20px;
      text-align: center;
      padding-bottom: 15px; }
    .b__profile-card_actions {
      margin-top: 10px;
      padding: 0 20px; }
    .b__messanger-peer .b__profile-card {
      margin-top: 0;
      width: 100%;
      float: none;
      padding-top: 48px; }
      .b__messanger-peer .b__profile-card:before, .b__messanger-peer .b__profile-card:after {
        content: none; }
      .b__messanger-peer .b__profile-card > div {
        z-index: auto; }
      .b__messanger-peer .b__profile-card_actions:first-child {
        display: none;
        margin-top: 16px;
        margin-bottom: 11px;
        text-align: right; }
      .b__messanger-peer .b__profile-card_photo {
        margin-top: 0; }
  .b__profile-content {
    width: calc(224px + ((100% - 308px) / 12 * 9));
    margin-left: 28px;
    float: left; }
    .b__profile-content .b__title {
      margin-bottom: 15px; }
  .b__profile-users {
    padding-bottom: 50px; }
    .b__profile-users .b__users.b__users--grid {
      width: 100%;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-wrap: wrap; }
      .b__profile-users .b__users.b__users--grid .b__users_item {
        margin-right: 20px; }
  .b__profile-items {
    padding-bottom: 90px; }
  .b__profile--edit .b__profile-content {
    padding-top: 40px; }
    .b__profile--edit .b__profile-content .form {
      width: calc(196px + ((100% - 308px) / 12 * 8));
      float: left; }
      .b__profile--edit .b__profile-content .form .b__panel {
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 30px; }
      .b__profile--edit .b__profile-content .form .field:after {
        content: '';
        clear: both;
        display: block; }
      .b__profile--edit .b__profile-content .form .field + .field {
        margin-top: 25px; }
      .b__profile--edit .b__profile-content .form .field--text,
      .b__profile--edit .b__profile-content .form .field--select,
      .b__profile--edit .b__profile-content .form .field--password {
        width: 50%; }
        .b__profile--edit .b__profile-content .form .field--text input,
        .b__profile--edit .b__profile-content .form .field--select input,
        .b__profile--edit .b__profile-content .form .field--password input {
          height: 42px;
          font-size: 15px; }
        .b__profile--edit .b__profile-content .form .field--text .selectize-input,
        .b__profile--edit .b__profile-content .form .field--select .selectize-input,
        .b__profile--edit .b__profile-content .form .field--password .selectize-input {
          height: 42px; }
          .b__profile--edit .b__profile-content .form .field--text .selectize-input input,
          .b__profile--edit .b__profile-content .form .field--select .selectize-input input,
          .b__profile--edit .b__profile-content .form .field--password .selectize-input input {
            height: 38px;
            background: transparent; }
          .b__profile--edit .b__profile-content .form .field--text .selectize-input > *,
          .b__profile--edit .b__profile-content .form .field--select .selectize-input > *,
          .b__profile--edit .b__profile-content .form .field--password .selectize-input > * {
            font-size: 15px !important; }
      .b__profile--edit .b__profile-content .form .field--submit {
        width: 50%; }
        .b__profile--edit .b__profile-content .form .field--submit .btn {
          height: 46px; }
      .b__profile--edit .b__profile-content .form label {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 5px;
        display: block; }

.profile-navigation {
  margin-top: -60px;
  padding-bottom: 45px; }
  .profile-navigation ul {
    padding: 0;
    margin: 0;
    list-style: none; }
    .profile-navigation ul:after {
      content: "";
      display: block;
      clear: both; }
    .profile-navigation ul li {
      height: 60px;
      display: block;
      margin-right: 50px;
      float: left;
      position: relative; }
      .profile-navigation ul li:before {
        content: '';
        display: inline-block;
        height: 105%;
        vertical-align: middle; }
      .profile-navigation ul li a {
        font-size: 18px;
        font-weight: 600;
        color: #999999;
        line-height: 1.2;
        text-decoration: none; }
      .profile-navigation ul li.active:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 11px 11px 11px;
        border-color: transparent transparent #f9f9f9 transparent;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%); }
      .profile-navigation ul li.active a {
        color: white; }

.user-profile-image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transform: scale(1.4);
  transition: all 250ms ease-in-out, z-index 0s linear 250ms;
  display: block; }
  .user-profile-image-overlay.is-visible {
    z-index: 9999;
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    transition: all 250ms ease-in-out; }
  .user-profile-image-overlay button {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    border-radius: 0;
    border: none;
    text-align: center;
    cursor: pointer;
    padding: 0;
    font-size: 0;
    background-color: transparent;
    transform-origin: center;
    transition: transform 200ms ease-in-out; }
    .user-profile-image-overlay button:hover {
      transform: scale(1.1); }
    .user-profile-image-overlay button .icon {
      fill: white;
      color: white; }
  .user-profile-image-overlay img {
    width: auto;
    max-width: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

@media (min-width: 768px) and (max-width: 1199px) {
  .b__profile-inner .site-container {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start; }
  .b__profile-card {
    float: none;
    width: 250px;
    flex-shrink: 0; }
  .b__profile--edit .b__profile-content .form {
    float: none;
    width: 100%; }
  .b__profile-content {
    width: calc(100% - 270px); } }

@media (min-width: 768px) and (max-width: 1000px) {
  .b__profile-users .b__users.b__users--grid .b__users_item {
    width: 30%;
    margin-right: 5%; }
    .b__profile-users .b__users.b__users--grid .b__users_item:nth-child(3n) {
      margin-right: 0; } }

@media (max-width: 767px) {
  .profile-navigation {
    margin-top: 0;
    width: calc(100% + 30px);
    margin-left: -15px;
    overflow-x: auto;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex; }
    .profile-navigation ul {
      flex-shrink: 0;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      padding-left: 5px;
      padding-right: 5px; }
      .profile-navigation ul li {
        float: none;
        flex-shrink: 0;
        height: auto;
        margin-right: 0;
        padding-left: 10px;
        padding-right: 10px; }
        .profile-navigation ul li a {
          font-size: 14px; }
        .profile-navigation ul li.active:after {
          display: none !important; }
        .profile-navigation ul li.active a {
          color: #000; }
  .b__profile-inner .site-container {
    display: block; }
  .b__profile-items {
    padding-bottom: 0; }
  .b__profile-card {
    float: none;
    width: 100%;
    flex-shrink: 0;
    padding-top: 1px;
    margin-bottom: 40px; }
  .b__profile-content {
    float: none;
    width: 100%;
    margin-left: 0;
    padding-top: 0; }
  .b__profile-users .b__users.b__users--grid .b__users_item {
    width: 50%;
    margin-right: 0;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 20px; }
  .b__profile--edit .b__profile-content {
    float: none;
    width: 100%;
    margin-left: 0;
    padding-top: 0; }
    .b__profile--edit .b__profile-content .form {
      float: none;
      width: 100%; }
      .b__profile--edit .b__profile-content .form .field--text, .b__profile--edit .b__profile-content .form .field--select, .b__profile--edit .b__profile-content .form .field--password {
        width: 100%; }
      .b__profile--edit .b__profile-content .form .b__panel {
        padding-left: 20px;
        padding-right: 20px; } }

.b__school .b__school-header {
  height: 556px;
  position: relative; }
  .b__school .b__school-header:after {
    content: "";
    display: block;
    clear: both; }
  .b__school .b__school-header .site-container:after {
    content: none; }
  .b__school .b__school-header > img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: -1; }
  .b__school .b__school-header-content {
    width: 300px;
    float: left; }
  .b__school .b__school-header .b__panel {
    padding: 30px 18px; }

.b__school-title {
  font-family: "Ahellya";
  font-size: 48px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 45px; }

.b__school-description {
  margin-top: 36px;
  margin-bottom: 30px; }
  .b__school-description-text {
    font-size: 13px;
    color: #333333;
    line-height: 1.538; }

.b__school-ambassador {
  margin: 0; }

.b__school .ambassador-contact {
  margin-bottom: 18px; }
  .b__school .ambassador-contact:after {
    content: "";
    display: block;
    clear: both; }
  .b__school .ambassador-contact_photo {
    border-style: solid;
    border-width: 2px;
    border-color: #eeeeee;
    border-radius: 50%;
    background-color: white;
    width: 82px;
    height: 82px;
    overflow: hidden;
    float: left; }
    .b__school .ambassador-contact_photo img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .b__school .ambassador-contact_details {
    float: right;
    width: calc(100% - 100px);
    padding-top: 10px; }
  .b__school .ambassador-contact_name {
    font-weight: 600;
    font-size: 18px;
    color: #333333;
    line-height: 1.2;
    padding-bottom: 4px; }
  .b__school .ambassador-contact_name-sub {
    font-size: 14px;
    color: #cccccc;
    line-height: 1.2; }

.b__school .ambassador-contact-form .form {
  padding: 0; }
  .b__school .ambassador-contact-form .form .field--text input,
  .b__school .ambassador-contact-form .form .field--password input,
  .b__school .ambassador-contact-form .form .field--email input,
  .b__school .ambassador-contact-form .form .field--tel input {
    height: 46px;
    font-size: 14px; }
  .b__school .ambassador-contact-form .form .field--textarea textarea {
    font-size: 14px;
    padding-top: 12px;
    padding-bottom: 12px;
    min-height: 70px; }
  .b__school .ambassador-contact-form .form .field--submit input {
    height: 46px; }

.b__school-content .site-container:after {
  content: "";
  display: block;
  clear: both; }

.b__school-content_inner {
  width: calc(224px + ((100% - 308px) / 12 * 9));
  float: right;
  margin-right: 0;
  margin-left: 28px;
  padding-bottom: 110px; }

.b__school-widget .b__title {
  margin-top: 22px;
  margin-bottom: 24px;
  text-align: left; }

.b__school-widget .b__users {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap; }
  .b__school-widget .b__users .b__user_item {
    margin-right: 20px; }

@media (min-width: 768px) and (max-width: 1199px) {
  .b__school-content_inner {
    margin-left: 0;
    width: 100%;
    float: none; }
  .b__school-widget .b__users .b__user_item {
    margin-right: 0; }
  .b__school-widget .b__users .b__users_item.b__users_item--more {
    width: 100%; }
  .b__school-widget .b__users #more-button-onMore-container {
    width: 20%; }
  .b__school .b__school-header-content {
    width: 300px;
    float: left; }
  .b__school .b__school-header .b__panel {
    padding: 30px 18px; } }

@media (max-width: 767px) {
  .b__school-content_inner {
    padding-bottom: 0; }
  .b__school-content_inner {
    margin-left: 0;
    width: 100%;
    float: none; }
  .b__school .b__school-header-content {
    width: 100%;
    float: left; }
  .b__school .b__school-header .b__panel {
    padding: 30px 18px; } }

.b__feed {
  padding-top: 40px;
  padding-bottom: 55px; }
  .b__feed .site-container {
    display: flex;
    flex-direction: row; }
    .b__feed .site-container:after {
      content: "";
      display: block;
      clear: both; }
  .b__feed-filters {
    width: calc(56px + ((100% - 308px) / 12 * 3));
    float: left;
    padding-top: 39px; }
  .b__feed div[id*=-container] {
    width: calc(140px + ((100% - 308px) / 12 * 6));
    margin-left: 28px; }
    .b__feed div[id*=-container] .b__title {
      text-align: left; }
  .b__feed-suggestion {
    width: calc(56px + ((100% - 308px) / 12 * 3));
    margin-right: 0;
    margin-left: 28px; }
    .b__feed-suggestion .b__title {
      text-align: center;
      justify-content: center; }
    :root .b__feed-suggestion .b__users_item:last-child {
      margin-bottom: 0; }
  .b__feed .b__title {
    margin-bottom: 18px; }
  .b__feed .input-search {
    margin-bottom: 18px;
    border-radius: 4px;
    background-color: white;
    height: 52px;
    position: relative; }
    .b__feed .input-search button {
      position: absolute;
      z-index: 2;
      font-size: 0;
      border: 0;
      background-color: transparent;
      left: 14px;
      top: 50%;
      transform: translateY(-50%);
      padding: 0;
      margin: 0; }
      .b__feed .input-search button svg {
        width: 26px;
        height: 26px;
        fill: #efefef;
        color: #efefef; }
    .b__feed .input-search input {
      width: 100%;
      height: 100%;
      padding-left: 51px;
      z-index: 1;
      font-size: 14px;
      font-weight: 500;
      border: 0;
      background-color: transparent;
      color: #333333;
      box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.01);
      transition: all 300ms ease-out 0s; }
      .b__feed .input-search input:hover {
        box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05); }
      .b__feed .input-search input:focus {
        box-shadow: 0px 5px 3px 0px rgba(0, 0, 0, 0.05); }
      .b__feed .input-search input::-webkit-input-placeholder {
        color: #efefef; }
      .b__feed .input-search input:-moz-placeholder {
        color: #efefef; }
      .b__feed .input-search input::-moz-placeholder {
        color: #efefef; }
      .b__feed .input-search input:-ms-input-placeholder {
        color: #efefef; }
  .b__feed .input-place {
    margin-bottom: 18px;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.01);
    position: relative;
    height: 52px;
    transition: all 300ms ease-out 0s; }
    .b__feed .input-place:hover {
      box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05); }
    .b__feed .input-place:focus {
      box-shadow: 0px 5px 3px 0px rgba(0, 0, 0, 0.05); }
    .b__feed .input-place input::-webkit-input-placeholder {
      color: #efefef; }
    .b__feed .input-place input:-moz-placeholder {
      color: #efefef; }
    .b__feed .input-place input::-moz-placeholder {
      color: #efefef; }
    .b__feed .input-place input:-ms-input-placeholder {
      color: #efefef; }
    .b__feed .input-place svg {
      width: 22px;
      height: 30px;
      fill: #efefef;
      color: #efefef;
      position: absolute;
      left: 16px;
      top: 50%;
      transform: translateY(-50%); }
    .b__feed .input-place .selectize-control {
      height: 100%;
      font-size: 14px;
      font-weight: 500; }
    .b__feed .input-place .selectize-dropdown {
      margin-top: 17px;
      position: absolute;
      background: white;
      box-sizing: border-box;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      border-width: 0;
      white-space: normal;
      z-index: 150; }
      .b__feed .input-place .selectize-dropdown:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 7px 7px 7px;
        border-color: transparent transparent #ffffff transparent;
        position: absolute;
        top: -7px;
        right: 15px;
        left: auto; }
      .b__feed .input-place .selectize-dropdown-content {
        padding: 15px 27px; }
        .b__feed .input-place .selectize-dropdown-content > div {
          font-size: 13px;
          font-weight: 500;
          line-height: 2.1;
          transition: color 100ms ease-out 0s; }
          .b__feed .input-place .selectize-dropdown-content > div * {
            transition: color 100ms ease-out 0s; }
          .b__feed .input-place .selectize-dropdown-content > div:hover {
            color: #227cd6; }
    .b__feed .input-place .selectize-input {
      height: 100%;
      padding-left: 51px; }
      .b__feed .input-place .selectize-input:before {
        content: '';
        display: inline-block;
        height: 105%;
        vertical-align: middle; }
      .b__feed .input-place .selectize-input .item {
        display: inline-block; }
      .b__feed .input-place .selectize-input input {
        border: 0; }
  .b__feed .list-type {
    padding: 0;
    margin: 0;
    list-style: none; }
    .b__feed .list-type li {
      position: relative;
      line-height: 2.571;
      padding-left: 51px; }
      .b__feed .list-type li svg {
        position: absolute;
        width: 20px;
        height: 20px;
        left: 18px;
        top: 50%;
        fill: #efefef;
        color: #efefef;
        transform: translateY(-50%); }
      .b__feed .list-type li input {
        visibility: hidden;
        position: absolute;
        z-index: -1; }
        .b__feed .list-type li input + label {
          font-weight: 500;
          font-size: 14px;
          color: darkgray;
          cursor: pointer; }
        .b__feed .list-type li input:checked + label {
          color: #333333; }
  .b__feed-item {
    border-radius: 6px;
    background-color: white;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 24px;
    overflow: hidden; }
    .b__feed-item + .b__feed-item {
      margin-top: 20px; }
    .b__feed-item--post {
      position: relative; }
      .b__feed-item--post .total-comments {
        margin-top: 8px; }
    .b__feed-item__title {
      padding-top: 15px;
      padding-bottom: 13px;
      height: 35px;
      box-sizing: content-box;
      display: table;
      font-size: 0; }
      .b__feed-item__title .author-photo {
        border: solid 1px #cccccc;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        overflow: hidden;
        display: inline-block;
        margin-right: 11px; }
        .b__feed-item__title .author-photo img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .b__feed-item__title span.title {
        font-size: 12px;
        font-weight: 400;
        color: #bbbbbb;
        display: table-cell;
        width: 100%;
        vertical-align: middle; }
        .b__feed-item__title span.title .author-name,
        .b__feed-item__title span.title .author-in {
          display: inline-block;
          font-weight: 600;
          color: #333333;
          text-decoration: none; }
          .b__feed-item__title span.title .author-name:hover,
          .b__feed-item__title span.title .author-in:hover {
            text-decoration: underline; }
    .b__feed-item .b__items_item {
      overflow: initial;
      display: flex;
      margin-bottom: 0; }
      .b__feed-item .b__items_item:after {
        content: "";
        display: block;
        clear: both; }
      .b__feed-item .b__items_item:hover {
        box-shadow: none; }
      .b__feed-item .b__items_item .item-thumbnail {
        width: 50%;
        font-size: 0; }
      .b__feed-item .b__items_item .item-content {
        width: calc(50% - 26px);
        margin-left: auto;
        position: relative;
        padding-bottom: 48px; }
        .b__feed-item .b__items_item .item-content .author-comment {
          font-size: 15px;
          color: #333; }
      .b__feed-item .b__items_item .item-title {
        margin-bottom: 12px;
        margin-top: -4px; }
        .b__feed-item .b__items_item .item-title a {
          font-size: 18px; }
      .b__feed-item .b__items_item .item-meta {
        height: auto;
        text-align: right; }
      .b__feed-item .b__items_item .item-share {
        display: inline-block;
        font-weight: 600;
        color: #bbbbbb;
        font-size: 14px;
        text-decoration: none; }
        .b__feed-item .b__items_item .item-share:hover {
          color: #227cd6; }
      .b__feed-item .b__items_item .item-price {
        margin-left: 0;
        display: inline-block; }
      .b__feed-item .b__items_item .item-description {
        font-size: 15px;
        line-height: 24px; }
      .b__feed-item .b__items_item .total-comments {
        display: inline-block;
        font-weight: 600;
        color: #bbbbbb;
        font-size: 14px;
        text-decoration: none;
        float: left;
        margin-top: 8px; }
      .b__feed-item .b__items_item .item-content-links {
        text-align: right;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0; }
        .b__feed-item .b__items_item .item-content-links .total-comments {
          margin-top: 2px; }
        .b__feed-item .b__items_item .item-content-links .reply {
          display: inline-block;
          font-weight: 600;
          color: #bbbbbb;
          font-size: 14px;
          text-decoration: none; }
          .b__feed-item .b__items_item .item-content-links .reply:hover {
            color: #227cd6; }
    .b__feed-item .item-university {
      position: absolute;
      height: 40px;
      width: 100px;
      transform: rotate(45deg);
      right: -35px;
      top: -5px;
      z-index: 10; }
      .b__feed-item .item-university span {
        font-family: 'Ahellya';
        font-size: 10px;
        color: white;
        text-align: center;
        display: inline-block;
        position: absolute;
        bottom: 1px;
        left: 0;
        right: 0; }
    .b__feed-item.b__feed-item--comment .item-thumbnail {
      position: relative;
      overflow: hidden;
      margin-top: 15px;
      width: 29.491525423728817%; }
    .b__feed-item.b__feed-item--comment .item-content {
      width: calc(100% - 29.491525423728817% - 26px); }
  .b__feed .b__items-controls {
    text-align: center; }

.b__feed-suggestion .b__users.b__users--grid .b__users_item {
  width: 50%;
  height: auto;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 30px; }
  .b__feed-suggestion .b__users.b__users--grid .b__users_item .user-pic {
    width: 110px;
    height: 110px; }

.b__feed-suggestion .b__users.b__users--grid {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap; }

@media (min-width: 768px) and (max-width: 1199px) {
  .b__feed .site-container {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start; }
  .b__feed div[id*=-container] {
    width: calc(100% - 278px);
    float: none; }
  .b__feed-suggestion {
    float: none;
    width: 100%;
    margin-left: 0;
    margin-top: 40px; }
  .b__feed-filters {
    float: none;
    width: 250px; } }

@media (max-width: 767px) {
  .b__feed .site-container {
    display: block; }
  .b__feed div[id*=-container] {
    width: 100%;
    float: none;
    margin-left: 0;
    margin-bottom: 40px; }
  .b__feed-suggestion {
    float: none;
    width: 100%;
    margin-left: 0; }
  .b__feed-filters {
    float: none;
    width: 100%;
    margin-bottom: 40px;
    padding-top: 0; }
  .b__feed-item {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 14px; }
  .b__feed-item .b__items_item {
    display: block;
    width: 100%; }
  .b__feed-item__title {
    padding-left: 15px;
    padding-right: 30px; }
  .b__feed-item .b__items_item .item-thumbnail {
    width: 100%;
    height: 150px;
    margin-top: 0; }
    .b__feed-item .b__items_item .item-thumbnail a {
      display: block;
      width: 100%;
      height: 100%; }
      .b__feed-item .b__items_item .item-thumbnail a img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover; }
  .b__feed-item.b__feed-item--comment .item-thumbnail {
    width: 100%;
    height: 150px;
    margin-top: 0; }
    .b__feed-item.b__feed-item--comment .item-thumbnail a {
      display: block;
      width: 100%;
      height: 100%; }
      .b__feed-item.b__feed-item--comment .item-thumbnail a img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover; }
  .b__feed-item .b__items_item .item-content-links {
    width: 100%;
    position: relative;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto; }
  .b__feed-item .b__items_item .item-content {
    width: 100%;
    padding-bottom: 0;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    margin: 0; }
    .b__feed-item .b__items_item .item-content .b__feed-item__title {
      padding-left: 0;
      padding-right: 0; }
  .b__feed-item.b__feed-item--comment .item-content {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 0;
    padding-top: 0; } }

/* Messager */
.b__messanger {
  background-color: white; }
  .b__messanger .site-container {
    height: 100%;
    max-width: 100%;
    padding: 0; }
  .b__messanger-back-button {
    font-size: 14px;
    color: #666;
    display: none; }
  .b__messanger-inner {
    height: calc(100vh - 80px);
    background-color: #f9f9f9; }
    .b__messanger-inner:after {
      content: "";
      display: block;
      clear: both; }
  .b__messanger-dialogs {
    background: white;
    width: calc(84px + ((100% - 308px) / 12 * 4));
    height: 100%;
    border-right: solid 1px #ebebeb;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column; }
    .b__messanger-dialogs .dialogs {
      max-height: none;
      overflow: visible; }
    .b__messanger-dialogs_body {
      flex-shrink: 1;
      width: 100%; }
    .b__messanger-dialogs_title {
      width: 100%;
      flex-shrink: 0;
      height: 50px;
      border-bottom: solid 1px #ebebeb;
      padding-left: 22px;
      font-size: 14px;
      color: #414141;
      font-weight: 500; }
      .b__messanger-dialogs_title:before {
        content: '';
        display: inline-block;
        height: 105%;
        vertical-align: middle; }
      .b__messanger-dialogs_title:before {
        height: 105%; }
  .b__messanger-chat {
    width: calc(112px + ((100% - 308px) / 12 * 5));
    position: relative;
    height: 100%;
    display: flex;
    overflow: hidden;
    flex-direction: column; }
    .b__messanger-chat_form {
      width: 100%;
      flex-shrink: 0;
      border-top: solid 1px #ebebeb;
      overflow: hidden; }
      .b__messanger-chat_form .chat-form {
        display: flex;
        align-items: flex-end; }
        .b__messanger-chat_form .chat-form_actions {
          height: auto; }
    .b__messanger-chat_body {
      position: relative;
      flex-shrink: 1;
      flex-grow: 3;
      height: calc(100% - 63px);
      overflow-y: auto;
      width: 100%; }
  .b__messanger-peer {
    width: calc(56px + ((100% - 308px) / 12 * 3));
    margin-right: 0;
    border-left: solid 1px #ebebeb;
    background-color: white;
    height: 100%;
    padding-bottom: 30px; }

@media (max-width: 767px) {
  #messenger {
    overflow: hidden;
    width: 100%; }
  .b__messanger {
    background-color: white;
    height: 100%; }
    .b__messanger .site-container {
      height: 100%; }
    .b__messanger-back-button {
      order: 1;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 10px;
      padding-bottom: 10px; }
      .b__messanger-back-button.active {
        display: block; }
    .b__messanger-inner {
      height: 100%;
      width: 100%;
      flex-direction: column;
      padding-bottom: 0; }
      .b__messanger-inner > * {
        flex-shrink: 0 !important; }
    .b__messanger-dialogs {
      width: 100% !important;
      order: 1;
      height: 100%;
      border: none;
      padding-bottom: 0; }
      .b__messanger-dialogs_body {
        height: calc(100vh - 100px); }
      .b__messanger-dialogs.mobile-hidden {
        display: none; }
    .b__messanger-peer {
      order: 2;
      width: 100% !important;
      height: auto;
      border: none;
      flex: auto;
      padding-bottom: 15px;
      padding-top: 15px;
      background-color: #f6f6f6;
      display: none; }
      .b__messanger-peer.active {
        display: block; }
      .b__messanger-peer .b__profile-card {
        position: relative;
        padding-left: 70px;
        padding-top: 0;
        padding-bottom: 0;
        text-align: left;
        margin-bottom: 0; }
        .b__messanger-peer .b__profile-card_photo {
          width: 40px;
          height: 40px;
          position: absolute;
          top: 0;
          left: 15px; }
        .b__messanger-peer .b__profile-card_name {
          font-size: 15px; }
        .b__messanger-peer .b__profile-card_place {
          font-size: 12px; }
      .b__messanger-peer .b__profile-card_meta,
      .b__messanger-peer .b__profile-card_about {
        display: none; }
    .b__messanger-chat {
      order: 3;
      flex-shrink: 1 !important;
      flex-grow: 3 !important;
      width: 100% !important;
      height: calc(100vh - 153px);
      padding-bottom: 0 !important;
      display: none; }
      .b__messanger-chat_form {
        padding-bottom: env(safe-area-inset-bottom); }
      .b__messanger-chat.active {
        display: block; } }

:root .dialogs {
  padding: 0;
  margin: 0;
  list-style: none; }
  :root .dialogs .dialog {
    background-color: white;
    border-bottom: solid 1px #f0f0f0;
    padding: 10px 20px 10px 20px;
    cursor: pointer;
    position: relative; }
    :root .dialogs .dialog:after {
      content: "";
      display: block;
      clear: both; }
    :root .dialogs .dialog_author-photo {
      border-width: 1px;
      border-color: #f0f0f0;
      border-style: solid;
      border-radius: 50%;
      background-color: white;
      width: 52px;
      height: 52px;
      float: left; }
      :root .dialogs .dialog_author-photo span.badge {
        top: 8px;
        right: 4px; }
      :root .dialogs .dialog_author-photo img {
        border-radius: 50%;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover; }
    :root .dialogs .dialog_content {
      width: calc(100% - 64px);
      float: right; }
    :root .dialogs .dialog_meta {
      padding-top: 8px;
      padding-bottom: 4px; }
      :root .dialogs .dialog_meta:after {
        content: "";
        display: block;
        clear: both; }
    :root .dialogs .dialog_date {
      font-size: 10px;
      line-height: 1.2;
      padding-top: 2px;
      color: #999999;
      float: right; }
    :root .dialogs .dialog_author-name {
      font-size: 12px;
      line-height: 1.2;
      font-weight: 500;
      color: #a0a0a0;
      float: left; }
    :root .dialogs .dialog_body {
      font-size: 13px;
      font-weight: 500;
      color: #414141;
      line-height: 1.2;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    :root .dialogs .dialog.dialog--not-read {
      background-color: #f5faff; }
      :root .dialogs .dialog.dialog--not-read .dialog_author-name {
        color: #3399ff; }
    :root .dialogs .dialog .dialog_current-arrow {
      display: none; }
    :root .dialogs .dialog.dialog--current .dialog_author-name {
      color: #3399ff; }
    :root .dialogs .dialog.dialog--current .dialog_author-photo {
      border: solid 2px #3399ff; }
    :root .dialogs .dialog.dialog--current .dialog_current-arrow {
      display: block;
      width: 10px;
      height: 20px;
      position: absolute;
      top: 50%;
      margin-top: -10px;
      right: -10px;
      overflow: hidden; }
      :root .dialogs .dialog.dialog--current .dialog_current-arrow:before {
        content: '';
        width: 22px;
        height: 22px;
        background-color: white;
        border-width: 1px;
        border-color: #ebebeb;
        border-style: solid;
        transform: rotate(45deg);
        position: absolute;
        right: 5px;
        top: 0; }

.chat-form {
  background-color: white;
  position: relative;
  min-height: 63px;
  display: flex; }
  .chat-form:after {
    content: "";
    display: block;
    clear: both; }
  :root #comment-form .chat-form {
    display: flex;
    flex-direction: row;
    align-items: center; }
    :root #comment-form .chat-form_text {
      width: calc(100% - 300px); }
    :root #comment-form .chat-form_actions {
      width: auto; }
      :root #comment-form .chat-form_actions .cancel.btn.btn--submit {
        color: #aaa;
        justify-content: center;
        align-content: center;
        display: flex;
        align-items: center; }
        :root #comment-form .chat-form_actions .cancel.btn.btn--submit:hover {
          color: #3399ff; }
  .chat-form .cancel-reply {
    padding-left: 0; }
  .chat-form small {
    flex-shrink: 0;
    margin-left: auto;
    margin-right: 20px; }
  .chat-form_text {
    width: calc(100% - 110px); }
    .chat-form_text textarea {
      width: calc(100% - 40px);
      border: 0;
      height: 17px;
      font-size: 14px;
      line-height: 1.2;
      box-sizing: content-box;
      padding: 26px 20px 20px;
      resize: none; }
      .chat-form_text textarea::-webkit-input-placeholder {
        color: #dadada; }
      .chat-form_text textarea:-moz-placeholder {
        /* Firefox 18- */
        color: #dadada; }
      .chat-form_text textarea::-moz-placeholder {
        /* Firefox 19+ */
        color: #dadada; }
      .chat-form_text textarea:-ms-input-placeholder {
        color: #dadada; }
  .chat-form_actions {
    width: 110px;
    height: 100%;
    order: 3;
    padding: 15px 22px;
    white-space: nowrap;
    font-size: 0;
    text-align: center; }
    .chat-form_actions:before {
      content: '';
      height: 105%;
      vertical-align: middle;
      display: inline-block; }
  .chat-form_submit {
    vertical-align: middle;
    display: inline-block; }

@media (max-width: 767px) {
  .chat-form .cancel-reply {
    padding-left: 25px; }
  :root #comment-form .chat-form {
    display: block; }
    :root #comment-form .chat-form small {
      float: none;
      position: absolute;
      bottom: 50px;
      left: 0;
      width: 100%;
      text-align: center; }
    :root #comment-form .chat-form_text {
      width: 100%;
      margin-bottom: 35px; }
      :root #comment-form .chat-form_text textarea {
        width: 100%;
        height: 110px;
        box-sizing: border-box; }
    :root #comment-form .chat-form_actions {
      width: 100%;
      position: relative;
      top: auto;
      right: auto;
      left: auto;
      height: 40px; }
      :root #comment-form .chat-form_actions .btn {
        line-height: 20px;
        padding-top: 10px;
        padding-bottom: 10px; } }

.chat-items {
  padding: 15px;
  position: relative;
  margin-right: 0; }
  .ps-container:not(.ps-active-y) .chat-items {
    position: absolute; }
  .chat-items .chat-item {
    position: relative;
    padding: 5px 0; }
    .chat-items .chat-item:after {
      content: "";
      display: block;
      clear: both; }
    .chat-items .chat-item_author-photo {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      overflow: hidden;
      float: right;
      margin-left: 10px; }
      .chat-items .chat-item_author-photo img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .chat-items .chat-item_body {
      float: right;
      padding: 12px 15px 13px 19px;
      font-size: 13px;
      overflow: hidden;
      color: white;
      font-weight: 500;
      max-width: calc(100% - 50px);
      border-radius: 4px;
      background-color: #3399ff; }
    .chat-items .chat-item.chat-item--self .chat-item_author-photo {
      float: left;
      margin-left: 0;
      margin-right: 10px; }
    .chat-items .chat-item.chat-item--self .chat-item_body {
      float: left;
      background-color: white;
      color: #414141; }
    .chat-items .chat-item.chat-item--date {
      text-align: center;
      font-size: 10px;
      color: #999999;
      line-height: 1.2;
      padding: 9px 0 2px; }

/* Items */
.b__items-controls {
  padding-bottom: 30px; }
  .b__items + .b__items-controls {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 0; }
  .b__items-controls_top:after, .b__items-controls_bottom:after {
    content: "";
    display: block;
    clear: both; }
  .b__items-controls_top {
    margin-bottom: 14px;
    display: flex;
    justify-content: space-between; }
  .b__items-controls .b__items-controls_results {
    margin-left: 0;
    padding: 6px 0;
    font-size: 12px;
    font-weight: 600; }
  .b__items-controls .b__items-controls_view-switcher {
    margin-right: 0;
    margin-left: auto;
    text-align: right;
    font-size: 0; }
    .b__items-controls .b__items-controls_view-switcher .view-switcher {
      border: 0;
      background: transparent;
      display: inline-block;
      vertical-align: middle;
      padding: 0; }
      .b__items-controls .b__items-controls_view-switcher .view-switcher[data-view-switcher=map] {
        margin-left: 22px; }
      .b__items-controls .b__items-controls_view-switcher .view-switcher[data-view-switcher=listing] {
        margin-left: 25px; }
      .b__items-controls .b__items-controls_view-switcher .view-switcher .icon {
        fill: #dadada;
        color: #dadada; }
        .b__items-controls .b__items-controls_view-switcher .view-switcher .icon.icon--view-switcher--grid {
          width: 19px;
          height: 19px; }
        .b__items-controls .b__items-controls_view-switcher .view-switcher .icon.icon--view-switcher--listing {
          width: 25px;
          height: 19px; }
        .b__items-controls .b__items-controls_view-switcher .view-switcher .icon.icon--view-switcher--map {
          width: 29px;
          height: 26px; }
      .b__items-controls .b__items-controls_view-switcher .view-switcher:hover:not(.active) .icon {
        fill: #999999;
        color: #999999; }
      .b__items-controls .b__items-controls_view-switcher .view-switcher.active .icon {
        fill: #bbbbbb;
        color: #bbbbbb; }
  .b__items-controls .b__items-controls_filters {
    font-size: 0; }
    .b__items-controls .b__items-controls_filters form {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start; }
      .b__items-container.has-map .b__items-controls .b__items-controls_filters form {
        justify-content: space-between; }
  .b__items-controls .b__items-controls_filter {
    display: inline-block; }
    .b__items-controls .b__items-controls_filter:not(:last-child) {
      margin-right: 8px; }
    .b__items-controls .b__items-controls_filter.university {
      min-width: 220px; }
    .b__items-controls .b__items-controls_filter.type {
      min-width: 138px; }
    .b__items-controls .b__items-controls_filter select {
      /* Styling */
      cursor: pointer;
      appearance: none;
      line-height: normal;
      position: relative;
      box-sizing: border-box;
      width: 100%;
      height: 45px;
      background-image: url("data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgd2lkdGg9IjEzcHgiIGhlaWdodD0iN3B4Ij48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiICBvcGFjaXR5PSIwLjI1MSIgZmlsbD0icmdiKDEwMiwgMTAyLCAxMDIpIiBkPSJNMTMuMDEwLDAuNzE2IEw2LjcxNiw3LjAxMCBMNi41MDAsNi43OTQgTDYuMjg0LDcuMDEwIEwtMC4wMTAsMC43MTYgTDAuNzE1LC0wLjAxMCBMNi41MDAsNS43NzQgTDEyLjI4NCwtMC4wMTAgTDEzLjAxMCwwLjcxNiBaIi8+PC9zdmc+");
      background-position: right 17px top 50%;
      background-repeat: no-repeat;
      padding: 0 48px 0 20px;
      border: 0;
      border-radius: 6px;
      color: #333333;
      font-size: 14px;
      font-weight: 500;
      font-family: "SF UI  Text";
      text-align: left;
      background-color: white;
      box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05); }
  .b__items-controls .b__items-controls_orders {
    width: calc(28px + ((100% - 308px) / 12 * 2));
    margin: 0;
    text-align: right;
    padding-top: 13px; }
  .b__items-controls .b__items-controls_order {
    display: inline-block;
    padding: 0;
    margin: 0;
    background: transparent;
    border: 0;
    color: #bfbfbf;
    font-size: 12px;
    font-weight: 500; }
    .b__items-controls .b__items-controls_order:after {
      font-family: 'Times New Roman'; }
    .b__items-controls .b__items-controls_order.ASC:after {
      content: '/002193'; }
    .b__items-controls .b__items-controls_order.DESC:after {
      content: '/002191'; }

@media (min-width: 768px) and (max-width: 1199px) {
  .b__items-controls [data-view-switcher="listing"] {
    display: none !important; } }

@media (max-width: 767px) {
  .b__items-controls {
    padding-bottom: 0; }
    .b__items-controls .b__items-controls_filters form {
      width: calc(100% + 30px);
      margin-left: -15px;
      overflow: hidden; }
    .b__items-controls [data-view-switcher="grid"] {
      display: none !important; }
    .b__items-controls .b__items-controls_filter {
      width: 33.33%;
      min-width: 0 !important; }
      .b__items-controls .b__items-controls_filter select {
        background-color: transparent;
        border-radius: 0;
        box-shadow: none;
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding-right: 20px;
        background-position: center right 5px;
        background-size: 8px auto;
        font-size: 11px;
        padding-left: 10px;
        border-left: none; }
      .b__items-controls .b__items-controls_filter:last-child select {
        border-right: none; }
      .b__items-controls .b__items-controls_filter:not(:last-child) {
        margin-right: 0; } }

.b__items_item {
  margin-bottom: 28px;
  background: white;
  border-radius: 4px;
  overflow: hidden;
  transition: box-shadow 150ms ease-out 0s; }
  .b__items_item .b__items_item * {
    transition: none !important; }
  .b__items_item .btn {
    white-space: nowrap; }
  .b__items_item.b__items_item--no-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
    height: 150px;
    margin: 0 !important; }
  .b__items_item:hover {
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.05); }
  .b__items_item .item-title a {
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    color: #333333; }

.b__widget-items {
  margin-top: 45px; }
  .b__widget-items .b__title {
    text-align: left;
    margin-bottom: 10px; }

.b__items.b__items--listing {
  height: calc(100% - 115px);
  position: relative;
  padding-right: 0;
  margin-right: -20px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 28px);
  box-sizing: border-box; }
  .b__items.b__items--listing #item-list {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
    width: 100%; }
  .b__items.b__items--listing.no-scroll {
    height: auto;
    max-height: none; }
  .b__items.b__items--listing .b__items_item {
    width: calc(50% - 28px);
    margin-left: 0;
    margin-right: 28px;
    height: 175px;
    position: relative; }
    .b__items.b__items--listing .b__items_item .item-thumbnail {
      width: 212px;
      float: left;
      height: 100%;
      flex-shrink: 0; }
      .b__items.b__items--listing .b__items_item .item-thumbnail a {
        display: block;
        font-size: 0;
        height: 100%;
        width: 100%; }
      .b__items.b__items--listing .b__items_item .item-thumbnail img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
      .b__items.b__items--listing .b__items_item .item-thumbnail .item-university {
        display: none; }
    .b__items.b__items--listing .b__items_item .item-university {
      width: 80px;
      height: 38px;
      text-align: center;
      font-size: 10px;
      font-family: "Ahellya";
      color: white;
      position: absolute;
      transform: rotate(45deg);
      right: -26px;
      top: -6px;
      line-height: 60px; }
      .b__items.b__items--listing .b__items_item .item-university span {
        display: inline-block;
        vertical-align: middle; }
    .b__items.b__items--listing .b__items_item .item-content {
      flex-grow: 3;
      flex-shrink: 1;
      width: calc(100% - 212px);
      height: 100%;
      float: right;
      position: relative;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 20px;
      padding-bottom: 20px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column; }
    .b__items.b__items--listing .b__items_item .item-title {
      width: calc(100% - 60px);
      margin-bottom: 8px; }
    .b__items.b__items--listing .b__items_item .item-description {
      width: calc(100% - 20px);
      font-size: 12px;
      font-weight: 400; }
    .b__items.b__items--listing .b__items_item .item-place {
      width: 25px;
      height: 33px;
      position: absolute;
      right: 19px;
      top: 18px;
      display: none; }
      .b__items.b__items--listing .b__items_item .item-place span {
        font-size: 14px;
        text-align: center;
        font-weight: 600;
        color: #a81c30;
        position: absolute;
        left: 1px;
        right: 2px;
        top: 4px; }
    .b__items.b__items--listing .b__items_item .item-author-price {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      margin-top: auto;
      width: 100%; }
    .b__items.b__items--listing .b__items_item .item-author {
      width: 50%;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start; }
      .b__items.b__items--listing .b__items_item .item-author_photo {
        margin-right: 12px;
        width: 33px;
        height: 33px;
        flex-shrink: 0;
        border: solid 1px #cccccc;
        border-radius: 50%;
        overflow: hidden;
        text-align: center;
        line-height: 28px; }
        .b__items.b__items--listing .b__items_item .item-author_photo img {
          width: 31px;
          height: 31px;
          object-fit: contain; }
        .b__items.b__items--listing .b__items_item .item-author_photo .icon {
          fill: #cccccc;
          color: #cccccc;
          width: 60%;
          height: 60%;
          display: inline-block;
          vertical-align: middle; }
      .b__items.b__items--listing .b__items_item .item-author_description {
        flex-grow: 3;
        flex-shrink: 1;
        font-size: 12px;
        line-height: 1.4; }
      .b__items.b__items--listing .b__items_item .item-author_name a {
        text-decoration: none;
        color: inherit; }
      .b__items.b__items--listing .b__items_item .item-author_place {
        color: #999999; }
    .b__items.b__items--listing .b__items_item .item-price {
      position: relative;
      flex-grow: 3;
      flex-shrink: 1;
      width: 50%;
      padding-left: 10px; }
      .b__items.b__items--listing .b__items_item .item-price .item-meta {
        position: absolute;
        bottom: -12px;
        right: 0; }

.b__items.ps-container {
  margin-right: -20px;
  padding-right: 20px;
  height: calc(100% - 115px); }

@media (max-width: 1199px) {
  .b__items.b__items--listing {
    height: auto;
    max-height: none; }
    .b__items.b__items--listing.no-scroll {
      height: auto;
      max-height: none; } }

@media (max-width: 1030px) {
  .b__items.b__items--listing {
    height: auto; }
    .b__items.b__items--listing .b__items_item {
      width: calc(33.33% - 28px);
      margin-right: 28px;
      height: auto; }
      .b__items.b__items--listing .b__items_item .item-thumbnail {
        width: 100%;
        float: none;
        height: 210px; }
      .b__items.b__items--listing .b__items_item .item-content {
        float: none;
        width: 100%;
        height: auto;
        height: calc(100% - 210px); }
        .b__items.b__items--listing .b__items_item .item-content > * {
          flex-shrink: 0; }
      .b__items.b__items--listing .b__items_item .item-description {
        width: 100%;
        margin-bottom: 15px; }
      .b__items.b__items--listing .b__items_item .item-author-price {
        margin-top: auto;
        width: 100%;
        display: block; }
      .b__items.b__items--listing .b__items_item .item-author {
        margin-bottom: 16px;
        width: 100%; }
      .b__items.b__items--listing .b__items_item .item-price {
        width: 100%; } }

@media (max-width: 767px) {
  .b__items.b__items--listing {
    height: auto;
    margin: 0;
    width: 100%;
    padding-right: 0;
    padding-top: 20px; }
    .b__items.b__items--listing .b__items_item {
      width: 100%;
      height: auto;
      margin-right: 0; }
      .b__items.b__items--listing .b__items_item .item-thumbnail {
        width: 100%;
        height: 210px;
        position: relative;
        font-size: 0; }
        .b__items.b__items--listing .b__items_item .item-thumbnail a:not(.link--edit) {
          display: block;
          height: 100%; }
        .b__items.b__items--listing .b__items_item .item-thumbnail .link--edit {
          position: absolute;
          font-size: initial; }
        .b__items.b__items--listing .b__items_item .item-thumbnail img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover; }
        .b__items.b__items--listing .b__items_item .item-thumbnail .item-university {
          display: none; }
      .b__items.b__items--listing .b__items_item .item-university {
        width: 80px;
        height: 38px;
        text-align: center;
        font-size: 10px;
        font-family: "Ahellya";
        color: white;
        position: absolute;
        transform: rotate(45deg);
        right: -26px;
        top: -6px;
        line-height: 60px;
        z-index: 1; }
        .b__items.b__items--listing .b__items_item .item-university span {
          display: inline-block;
          vertical-align: middle; }
      .b__items.b__items--listing .b__items_item .item-content {
        width: 100%;
        height: calc(100% - 210px);
        position: relative;
        padding-left: 18px;
        padding-right: 18px;
        padding-top: 15px;
        padding-bottom: 15px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column; }
      .b__items.b__items--listing .b__items_item .item-title {
        margin-bottom: 8px; }
      .b__items.b__items--listing .b__items_item .item-description {
        font-size: 12px;
        font-weight: 400;
        width: 100%;
        margin-bottom: 15px; }
      .b__items.b__items--listing .b__items_item .item-place {
        width: 25px;
        height: 33px;
        position: absolute;
        right: 19px;
        top: 18px;
        display: none; }
        .b__items.b__items--listing .b__items_item .item-place span {
          font-size: 14px;
          text-align: center;
          font-weight: 600;
          color: #a81c30;
          position: absolute;
          left: 1px;
          right: 2px;
          top: 4px; }
      .b__items.b__items--listing .b__items_item .item-author-price {
        margin-top: auto;
        width: 100%;
        display: block; }
      .b__items.b__items--listing .b__items_item .item-author {
        width: 100%;
        margin-bottom: 16px;
        position: relative; }
        .b__items.b__items--listing .b__items_item .item-author_photo {
          margin-right: 12px;
          width: 33px;
          height: 33px;
          float: left;
          border: solid 1px #cccccc;
          border-radius: 50%;
          overflow: hidden;
          text-align: center;
          line-height: 28px; }
          .b__items.b__items--listing .b__items_item .item-author_photo img {
            width: 31px;
            height: 31px;
            object-fit: cover; }
          .b__items.b__items--listing .b__items_item .item-author_photo .icon {
            fill: #cccccc;
            color: #cccccc;
            width: 60%;
            height: 60%;
            display: inline-block;
            vertical-align: middle; }
        .b__items.b__items--listing .b__items_item .item-author_description {
          float: left;
          font-size: 12px;
          line-height: 1.4; }
        .b__items.b__items--listing .b__items_item .item-author_name a {
          text-decoration: none;
          color: inherit; }
        .b__items.b__items--listing .b__items_item .item-author_place {
          color: #999999; }
      .b__items.b__items--listing .b__items_item .item-price {
        width: 100%;
        position: relative;
        margin-bottom: 0; } }

.b__items.b__items--grid {
  height: calc(100% - 115px);
  padding-right: 0;
  margin-right: 0;
  position: relative;
  width: calc(100% + 28px);
  box-sizing: border-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap; }
  .b__items.b__items--grid:after {
    content: "";
    display: block;
    clear: both; }
  .b__items.b__items--grid #item-list {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
    width: 100%; }
  .b__items.b__items--grid.no-scroll {
    height: auto; }
  .b__items.b__items--grid .b__items_item {
    width: calc(25% - 28px);
    margin-left: 0;
    margin-right: 28px;
    position: relative; }
    .b__profile-items .b__items.b__items--grid .b__items_item,
    .b__school-widget .b__items.b__items--grid .b__items_item {
      width: calc(84px + ((100% - 308px) / 12 * 4)); }
      .b__profile-items .b__items.b__items--grid .b__items_item:nth-child(4n+1),
      .b__school-widget .b__items.b__items--grid .b__items_item:nth-child(4n+1) {
        margin-left: 14px; }
      .b__profile-items .b__items.b__items--grid .b__items_item:nth-child(4n+4),
      .b__school-widget .b__items.b__items--grid .b__items_item:nth-child(4n+4) {
        margin-right: 14px; }
      .b__profile-items .b__items.b__items--grid .b__items_item:nth-child(3n+1),
      .b__school-widget .b__items.b__items--grid .b__items_item:nth-child(3n+1) {
        margin-left: 0; }
      .b__profile-items .b__items.b__items--grid .b__items_item:nth-child(3n+3),
      .b__school-widget .b__items.b__items--grid .b__items_item:nth-child(3n+3) {
        margin-right: 0; }
      .b__profile-items .b__items.b__items--grid .b__items_item .item-author-price .item-price,
      .b__school-widget .b__items.b__items--grid .b__items_item .item-author-price .item-price {
        right: 0; }
      .b__profile-items .b__items.b__items--grid .b__items_item.b__items_item--no-content,
      .b__school-widget .b__items.b__items--grid .b__items_item.b__items_item--no-content {
        height: 240px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        color: #999999; }
        .b__profile-items .b__items.b__items--grid .b__items_item.b__items_item--no-content .btn,
        .b__school-widget .b__items.b__items--grid .b__items_item.b__items_item--no-content .btn {
          display: inline-block;
          height: 46px;
          background: #3399ff;
          border-color: #3399ff;
          box-shadow: 0 2px 0 0 #2e89e5;
          padding: 14px 0; }
    .b__items.b__items--grid .b__items_item .item-thumbnail {
      width: 100%;
      height: 210px;
      position: relative;
      font-size: 0; }
      .b__items.b__items--grid .b__items_item .item-thumbnail a:not(.link--edit) {
        display: block;
        height: 100%; }
      .b__items.b__items--grid .b__items_item .item-thumbnail .link--edit {
        position: absolute;
        font-size: initial; }
      .b__items.b__items--grid .b__items_item .item-thumbnail img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover; }
      .b__items.b__items--grid .b__items_item .item-thumbnail .item-university {
        display: none; }
    .b__items.b__items--grid .b__items_item .item-university {
      width: 80px;
      height: 38px;
      text-align: center;
      font-size: 10px;
      font-family: "Ahellya";
      color: white;
      position: absolute;
      transform: rotate(45deg);
      right: -26px;
      top: -6px;
      line-height: 60px;
      z-index: 1; }
      .b__items.b__items--grid .b__items_item .item-university span {
        display: inline-block;
        vertical-align: middle; }
    .b__items.b__items--grid .b__items_item .item-content {
      width: 100%;
      height: calc(100% - 210px);
      position: relative;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 20px;
      padding-bottom: 20px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column; }
      .b__items.b__items--grid .b__items_item .item-content > * {
        flex-shrink: 0; }
    .b__items.b__items--grid .b__items_item .item-title {
      margin-bottom: 8px; }
    .b__items.b__items--grid .b__items_item .item-description {
      font-size: 12px;
      font-weight: 400;
      width: 100%;
      margin-bottom: 15px; }
    .b__items.b__items--grid .b__items_item .item-place {
      width: 25px;
      height: 33px;
      position: absolute;
      right: 19px;
      top: 18px;
      display: none; }
      .b__items.b__items--grid .b__items_item .item-place span {
        font-size: 14px;
        text-align: center;
        font-weight: 600;
        color: #a81c30;
        position: absolute;
        left: 1px;
        right: 2px;
        top: 4px; }
    .b__items.b__items--grid .b__items_item .item-author-price {
      margin-top: auto;
      width: 100%; }
    .b__items.b__items--grid .b__items_item .item-author {
      margin-bottom: 16px;
      position: relative;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex; }
      .b__items.b__items--grid .b__items_item .item-author:after {
        content: "";
        display: block;
        clear: both; }
      .b__items.b__items--grid .b__items_item .item-author_photo {
        flex-shrink: 0;
        margin-right: 12px;
        width: 33px;
        height: 33px;
        border: solid 1px #cccccc;
        border-radius: 50%;
        overflow: hidden;
        text-align: center;
        line-height: 28px; }
        .b__items.b__items--grid .b__items_item .item-author_photo img {
          width: 31px;
          height: 31px;
          object-fit: cover; }
        .b__items.b__items--grid .b__items_item .item-author_photo .icon {
          fill: #cccccc;
          color: #cccccc;
          width: 60%;
          height: 60%;
          display: inline-block;
          vertical-align: middle; }
      .b__items.b__items--grid .b__items_item .item-author_description {
        font-size: 12px;
        line-height: 1.4; }
      .b__items.b__items--grid .b__items_item .item-author_name a {
        text-decoration: none;
        color: inherit; }
      .b__items.b__items--grid .b__items_item .item-author_place {
        color: #999999; }
    .b__items.b__items--grid .b__items_item .item-price {
      position: relative;
      margin-bottom: 0; }

@media (max-width: 767px) {
  .b__items.b__items--grid {
    height: auto; }
    .b__items.b__items--grid.no-scroll {
      height: auto; }
    .b__items.b__items--grid .b__items_item {
      width: 100%;
      float: none;
      margin-bottom: 20px;
      margin-left: 0;
      margin-right: 0; }
      .b__items.b__items--grid .b__items_item:nth-child(4n+1) {
        margin-left: 0; }
      .b__items.b__items--grid .b__items_item:nth-child(4n+4) {
        margin-right: 0; }
      .b__profile-items .b__items.b__items--grid .b__items_item,
      .b__school-widget .b__items.b__items--grid .b__items_item {
        width: 100%; }
        .b__profile-items .b__items.b__items--grid .b__items_item:nth-child(4n+1),
        .b__school-widget .b__items.b__items--grid .b__items_item:nth-child(4n+1) {
          margin-left: 0; }
        .b__profile-items .b__items.b__items--grid .b__items_item:nth-child(4n+4),
        .b__school-widget .b__items.b__items--grid .b__items_item:nth-child(4n+4) {
          margin-right: 0; }
        .b__profile-items .b__items.b__items--grid .b__items_item:nth-child(3n+1),
        .b__school-widget .b__items.b__items--grid .b__items_item:nth-child(3n+1) {
          margin-left: 0; }
        .b__profile-items .b__items.b__items--grid .b__items_item:nth-child(3n+3),
        .b__school-widget .b__items.b__items--grid .b__items_item:nth-child(3n+3) {
          margin-right: 0; } }

@media (max-width: 1030px) {
  .b__items.b__items--grid {
    height: auto; }
    .b__items.b__items--grid .b__items_item {
      width: calc(33.33% - 28px); }
  .b__profile-items .b__items.b__items--grid .b__items_item, .b__school-widget .b__items.b__items--grid .b__items_item {
    width: calc(50% - 28px); }
  .b__profile-items .b__items.b__items--grid .b__items_item:nth-child(3n+3),
  .b__school-widget .b__items.b__items--grid .b__items_item:nth-child(3n+3) {
    margin-right: 28px; } }

@media (max-width: 767px) {
  .b__items.b__items--grid {
    height: auto;
    margin: 0;
    width: 100%;
    padding-right: 0; }
    .b__items.b__items--grid .b__items_item {
      width: 100%;
      margin-right: 0; }
  .b__items.b__items--grid {
    height: auto; }
    .b__items.b__items--grid .b__items_item {
      width: calc(33.33% - 28px); }
  .b__profile-items .b__items.b__items--grid .b__items_item, .b__school-widget .b__items.b__items--grid .b__items_item {
    width: 100%;
    margin-right: 0; }
  .b__profile-items .b__items.b__items--grid .b__items_item:nth-child(3n+3),
  .b__school-widget .b__items.b__items--grid .b__items_item:nth-child(3n+3) {
    margin-right: 0; } }

@keyframes spinanim {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.b__items-container {
  width: 100%;
  transition: width 150ms ease-out 0s; }
  .b__items-container.has-map {
    width: calc(50% + 28px);
    padding-right: 28px;
    float: left;
    margin-top: -25px;
    padding-top: 25px;
    z-index: 4;
    position: relative; }

.b__items-map {
  display: none;
  overflow: hidden;
  position: absolute;
  top: 0px;
  width: calc(50vw - 28px);
  left: calc(50% + 28px); }
  .b__items-container.has-map + .b__items-map {
    display: block; }
  .b__items-map:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -10px;
    width: 10px;
    height: 100%;
    box-shadow: 10px 0px 5px 0px rgba(0, 0, 0, 0.05);
    z-index: 20; }
  .b__items-map .marker-wrapper {
    position: absolute;
    cursor: pointer;
    width: 31px;
    height: 31px;
    background-color: transparent;
    z-index: 190;
    transform: translateX(-50%) translateY(-100%); }
  .b__items-map .marker-thumb {
    border-width: 0px;
    width: 31px;
    height: 31px;
    text-align: center;
    font-size: 18px;
    font-family: "SF UI  Text";
    line-height: 31px;
    font-weight: bold;
    color: white; }
    .b__items-map .marker-thumb__mask {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
      transition: transform 200ms ease-in-out !important; }
      .b__items-map .marker-thumb__mask:hover {
        transform: scale(1.1); }
    .b__items-map .marker-thumb__photo {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      transition: color 100ms ease-in-out; }
    .b__items-map .marker-thumb.isForSale .marker-thumb__photo {
      background-color: #cc3333; }
    .b__items-map .marker-thumb.isForRepair .marker-thumb__photo {
      background-color: #e8ce2c; }
    .b__items-map .marker-thumb.isForRent .marker-thumb__photo {
      background-color: #33cc99; }
    .b__items-map .marker-thumb.isForExchange .marker-thumb__photo {
      background-color: #ffa500; }
    .b__items-map .marker-thumb.isForDonation .marker-thumb__photo {
      background-color: #227cd6; }
  .b__items-map .marker-info {
    position: absolute;
    left: 50%;
    bottom: 31px;
    width: 300px;
    padding: 18px 20px;
    margin-left: -150px;
    background-color: white;
    border-radius: 5px;
    text-align: left;
    color: #333;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
    opacity: 0;
    transition: all 300ms ease-in-out !important;
    transform: translateY(0) scale(0.1);
    transform-origin: 50% 100%;
    z-index: -1;
    visibility: hidden;
    cursor: default; }
    .b__items-map .marker-info:after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: -16px;
      width: 0;
      height: 0;
      margin-left: -17px;
      border-style: solid;
      border-width: 16px 17px 0 17px;
      border-color: #fff transparent transparent transparent; }
    .b__items-map .marker-info .item-title {
      font-size: 16px;
      font-weight: 600;
      color: #333333;
      line-height: 1.3;
      padding-right: 30px; }
      .b__items-map .marker-info .item-title a {
        color: #333333;
        text-decoration: none; }
        .b__items-map .marker-info .item-title a:hover {
          text-decoration: underline; }
    .b__items-map .marker-info .close-tooltip {
      color: #999;
      font-size: 0;
      color: transparent;
      width: 20px;
      height: 20px;
      position: absolute;
      right: 5px;
      top: 10px;
      cursor: pointer; }
      .b__items-map .marker-info .close-tooltip:before, .b__items-map .marker-info .close-tooltip:after {
        content: "";
        display: block;
        width: 14px;
        height: 2px;
        background-color: #999;
        position: absolute;
        top: 50%;
        right: 50%;
        transition: all 0.2s ease-in-out; }
      .b__items-map .marker-info .close-tooltip:before {
        transform: rotate(45deg); }
      .b__items-map .marker-info .close-tooltip:after {
        transform: rotate(-45deg); }
      .b__items-map .marker-info .close-tooltip:hover:before, .b__items-map .marker-info .close-tooltip:hover:after {
        background-color: #000; }
    .b__items-map .marker-info .item-description {
      margin-top: 4px;
      margin-bottom: 12px;
      font-size: 12px;
      font-weight: 400;
      line-height: 1.4; }
    .b__items-map .marker-info .item-author {
      height: 33px; }
      .b__items-map .marker-info .item-author a {
        color: #333;
        text-decoration: none; }
        .b__items-map .marker-info .item-author a:hover {
          text-decoration: underline; }
      .b__items-map .marker-info .item-author_photo {
        overflow: hidden;
        float: left;
        width: 33px;
        height: 33px;
        margin-right: 12px;
        border: solid 1px #cccccc;
        border-radius: 50%;
        text-align: center;
        line-height: 28px; }
        .b__items-map .marker-info .item-author_photo img {
          width: 31px;
          height: 31px;
          object-fit: cover; }
        .b__items-map .marker-info .item-author_photo .icon {
          display: inline-block;
          vertical-align: middle;
          width: 60%;
          height: 60%;
          fill: #cccccc;
          color: #cccccc; }
      .b__items-map .marker-info .item-author_description {
        float: left;
        font-size: 12px; }
      .b__items-map .marker-info .item-author_name {
        font-weight: 400; }
  .b__items-map .is-showed .marker-thumb__mask {
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    transform: scale(0.5);
    transform-origin: 50% 50%;
    color: transparent; }
  .b__items-map .is-showed .marker-info {
    opacity: 1;
    z-index: 1111;
    transform: translateY(0) scale(1);
    visibility: visible; }

.b__items.b__items--map {
  position: relative;
  width: calc(100% + 22px);
  height: calc(100% - 115px);
  margin-right: -22px;
  padding-right: 22px; }
  .b__items.b__items--map:after {
    content: "";
    display: block;
    clear: both; }
  .b__items.b__items--map .b__items_item {
    position: relative;
    height: 175px; }
    .b__items.b__items--map .b__items_item:after {
      content: "";
      display: block;
      clear: both; }
    .b__items.b__items--map .b__items_item + .b__items_item {
      margin-top: 17px; }
    .b__items.b__items--map .b__items_item .item-thumbnail {
      width: 212px;
      float: left;
      height: 100%; }
      .b__items.b__items--map .b__items_item .item-thumbnail img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
      .b__items.b__items--map .b__items_item .item-thumbnail .item-university {
        display: none; }
    .b__items.b__items--map .b__items_item .item-university {
      position: absolute;
      top: -6px;
      right: -26px;
      width: 80px;
      height: 38px;
      text-align: center;
      font-size: 10px;
      font-family: "Ahellya";
      line-height: 60px;
      color: white;
      transform: rotate(45deg); }
      .b__items.b__items--map .b__items_item .item-university span {
        display: inline-block;
        vertical-align: middle; }
    .b__items.b__items--map .b__items_item .item-content {
      position: relative;
      float: right;
      width: calc(100% - 212px);
      height: 100%;
      padding-left: 20px;
      box-sizing: border-box; }
    .b__items.b__items--map .b__items_item .item-title {
      width: calc(100% - 60px);
      margin-bottom: 8px;
      margin-top: 20px; }
    .b__items.b__items--map .b__items_item .item-description {
      width: calc(100% - 60px);
      font-size: 12px;
      font-weight: 400; }
    .b__items.b__items--map .b__items_item .item-place {
      position: absolute;
      top: 18px;
      right: 19px;
      width: 25px;
      height: 33px;
      display: block; }
      .b__items.b__items--map .b__items_item .item-place span {
        position: absolute;
        top: 4px;
        left: 1px;
        right: 2px;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        color: #a81c30; }
    .b__items.b__items--map .b__items_item .item-author {
      position: absolute;
      height: 33px;
      bottom: 24px; }
      .b__items.b__items--map .b__items_item .item-author:after {
        content: "";
        display: block;
        clear: both; }
      .b__items.b__items--map .b__items_item .item-author_photo {
        float: left;
        overflow: hidden;
        width: 33px;
        height: 33px;
        margin-right: 12px;
        border: solid 1px #cccccc;
        border-radius: 50%;
        text-align: center;
        line-height: 28px; }
        .b__items.b__items--map .b__items_item .item-author_photo img {
          width: 31px;
          height: 31px;
          object-fit: cover; }
        .b__items.b__items--map .b__items_item .item-author_photo .icon {
          width: 60%;
          height: 60%;
          display: inline-block;
          vertical-align: middle;
          fill: #cccccc;
          color: #cccccc; }
      .b__items.b__items--map .b__items_item .item-author_description {
        float: left;
        font-size: 12px;
        line-height: 1.4; }
      .b__items.b__items--map .b__items_item .item-author_name a {
        text-decoration: none;
        color: inherit; }
      .b__items.b__items--map .b__items_item .item-author_place {
        color: #999999; }
    .b__items.b__items--map .b__items_item .item-price {
      position: absolute;
      right: 19px;
      bottom: 20px; }

.map-cluster__awesome {
  line-height: 41px !important; }
  #items-map .map-cluster__awesome {
    transition: transform 200ms ease;
    transform-origin: 50%; }
  .map-cluster__awesome:hover {
    transform: scale(1.1); }
  .map-cluster__awesome:before {
    content: '';
    position: absolute;
    top: 4px;
    left: 50%;
    width: 31px;
    height: 31px;
    border-radius: 50%;
    border-width: 0px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
    transform: translateX(-50%);
    z-index: -1; }
  .map-cluster__awesome.isForSale:before {
    background-color: #cc3333; }
  .map-cluster__awesome.isForRepair:before {
    background-color: #e8ce2c; }
  .map-cluster__awesome.isForRent:before {
    background-color: #33cc99; }
  .map-cluster__awesome.isForExchange:before {
    background-color: #ffa500; }
  .map-cluster__awesome.isForDonation:before {
    background-color: #227cd6; }

#items-map {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #A5CBFF;
  /* Google map zoom controls */ }
  #items-map.loading:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9); }
  #items-map.loading:before {
    content: '';
    display: block;
    position: absolute;
    border: solid 6px rgba(0, 0, 0, 0.08);
    border-left-color: transparent;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    z-index: 1;
    transform: translateY(-50%) translateX(-50%);
    transform-origin: center;
    animation-name: spinanim;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; }
  #items-map div {
    transition: none; }
  #items-map a[title="Click to see this area on Google Maps"] {
    display: none !important; }
  #items-map .gm-style-cc {
    display: none !important; }
  #items-map .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
    bottom: 50% !important;
    right: 56px !important; }
    #items-map .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom > .gmnoprint {
      transform: translateY(-50%); }
      #items-map .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom > .gmnoprint > div {
        cursor: pointer;
        user-select: none;
        box-shadow: none !important;
        border-radius: 0 !important;
        background-color: transparent !important;
        width: auto !important;
        height: auto !important; }
        #items-map .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom > .gmnoprint > div > div {
          display: none !important; }
    #items-map .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom button {
      width: 48px !important;
      height: 48px !important;
      background-color: white !important;
      border-radius: 50% !important;
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1); }
      #items-map .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom button:last-child {
        margin-top: 4px !important; }
      #items-map .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom button > div {
        left: 17px !important;
        top: 16px !important; }
        #items-map .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom button > div img {
          transition: none; }

@media (max-width: 1199px) {
  .b__items--map {
    display: none; }
  .b__items-map {
    position: relative;
    top: auto;
    right: auto;
    left: auto;
    bottom: auto;
    height: calc(100vh - 220px) !important;
    box-shadow: border-box;
    width: calc(100% + 76px);
    margin-left: -38px; }
    .b__items-map:before {
      display: none; }
  .b__items-container.has-map {
    float: none;
    width: 100%;
    margin: 0;
    padding: 0;
    height: auto !important; } }

@media (max-width: 767px) {
  .b__items-map {
    height: calc(100vh - 160px) !important;
    width: calc(100% + 30px);
    margin-left: -15px; } }

.b__items-fast-filter {
  width: 100%;
  overflow-x: auto;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start; }
  .b__items-fast-filter ::-webkit-scrollbar {
    opacity: 0;
    width: 0px;
    /* Remove scrollbar space */
    background: transparent;
    /* Optional: just make scrollbar invisible */ }
  .b__items-fast-filter::-webkit-scrollbar {
    opacity: 0;
    width: 0px;
    /* Remove scrollbar space */
    background: transparent;
    /* Optional: just make scrollbar invisible */ }
  .b__profile-content .b__items-fast-filter {
    margin-top: -15px;
    margin-bottom: 15px; }
  .b__items-fast-filter ul {
    padding: 0;
    margin: 0;
    list-style: none;
    flex-shrink: 0;
    white-space: nowrap;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex; }
    .b__items-fast-filter ul:after {
      content: "";
      display: block;
      clear: both; }
    .b__items-fast-filter ul li {
      display: block;
      flex-shrink: 0;
      padding: 0 16px;
      height: 25px; }
      .b__items-fast-filter ul li a {
        text-decoration: none;
        font-size: 14px;
        color: #999999; }
      .b__items-fast-filter ul li.active {
        border-bottom: solid 2px #3399ff; }
        .b__items-fast-filter ul li.active a {
          color: #3399ff; }
      .b__items-fast-filter ul li.has-no-items a {
        color: #d6d6d6;
        cursor: default; }

@media (min-width: 768px) and (max-width: 1199px) {
  .b__items-fast-filter ul li {
    padding-left: 8px;
    padding-right: 8px; } }

@media (max-width: 767px) {
  .b__items-fast-filter {
    width: calc(100% + 30px);
    margin-left: -15px; }
    .b__items-fast-filter ul {
      padding-left: 15px;
      padding-right: 15px; }
      .b__items-fast-filter ul li {
        padding-left: 8px;
        padding-right: 8px; } }

.b__item {
  width: calc(50% + 28px);
  padding-right: 28px;
  float: left;
  z-index: 4;
  position: relative; }
  .b__posting-item .b__item {
    padding-bottom: 76px; }
  .b__item-map {
    width: calc(50% - 28px);
    float: left;
    margin-left: 0;
    position: fixed;
    overflow: hidden;
    margin-bottom: -75px;
    position: absolute;
    left: calc(50% + 28px);
    max-height: calc(100vh - 80px); }
    .b__item-map:before {
      z-index: 20;
      content: '';
      display: block;
      position: absolute;
      left: -10px;
      top: 0;
      height: 100%;
      width: 10px;
      box-shadow: 10px 0px 5px 0px rgba(0, 0, 0, 0.05); }
    .b__item-map #item-map {
      width: 100%;
      height: 100%;
      /* Google map zoom controls */ }
      .b__item-map #item-map div {
        will-change: transform;
        transition: none; }
      .b__item-map #item-map a[title="Click to see this area on Google Maps"] {
        display: none !important; }
      .b__item-map #item-map .gm-style-cc {
        display: none !important; }
      .b__item-map #item-map .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
        bottom: 50% !important;
        right: 56px !important; }
        .b__item-map #item-map .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom > .gmnoprint {
          transform: translateY(-50%); }
          .b__item-map #item-map .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom > .gmnoprint > div {
            cursor: pointer;
            user-select: none;
            box-shadow: none !important;
            border-radius: 0 !important;
            background-color: transparent !important;
            width: auto !important;
            height: auto !important; }
            .b__item-map #item-map .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom > .gmnoprint > div > div {
              display: none !important; }
        .b__item-map #item-map .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom button {
          width: 48px !important;
          height: 48px !important;
          background-color: white !important;
          border-radius: 50% !important;
          box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1); }
          .b__item-map #item-map .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom button:last-child {
            margin-top: 4px !important; }
          .b__item-map #item-map .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom button > div {
            left: 17px !important;
            top: 16px !important; }
            .b__item-map #item-map .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom button > div img {
              transition: none; }
    .b__item-map .marker a {
      cursor: pointer; }
    .b__item-map .marker-thumb {
      width: 100%;
      height: 100%;
      position: relative;
      border: solid 2px #3399ff;
      background-color: rgba(52, 154, 255, 0.25);
      border-radius: 50%; }
      .b__item-map .marker-thumb__mask {
        background-image: url(data:image/svg+xml,%3Csvg%20%0A%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%0A%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%0A%20width%3D%2259px%22%20height%3D%2270px%22%3E%0A%3Cdefs%3E%0A%3Cfilter%20filterUnits%3D%22userSpaceOnUse%22%20id%3D%22Filter_0%22%20x%3D%22-1px%22%20y%3D%22-1px%22%20width%3D%2260px%22%20height%3D%2271px%22%20%20%3E%0A%20%20%20%20%3CfeOffset%20in%3D%22SourceAlpha%22%20dx%3D%220%22%20dy%3D%225%22%20/%3E%0A%20%20%20%20%3CfeGaussianBlur%20result%3D%22blurOut%22%20stdDeviation%3D%223.162%22%20/%3E%0A%20%20%20%20%3CfeFlood%20flood-color%3D%22rgb%280%2C%200%2C%200%29%22%20result%3D%22floodOut%22%20/%3E%0A%20%20%20%20%3CfeComposite%20operator%3D%22atop%22%20in%3D%22floodOut%22%20in2%3D%22blurOut%22%20/%3E%0A%20%20%20%20%3CfeComponentTransfer%3E%3CfeFuncA%20type%3D%22linear%22%20slope%3D%220.25%22/%3E%3C/feComponentTransfer%3E%0A%20%20%20%20%3CfeMerge%3E%0A%20%20%20%20%3CfeMergeNode/%3E%0A%20%20%20%20%3CfeMergeNode%20in%3D%22SourceGraphic%22/%3E%0A%20%20%3C/feMerge%3E%0A%3C/filter%3E%0A%0A%3C/defs%3E%0A%3Cg%20filter%3D%22url%28%23Filter_0%29%22%3E%0A%3Cpath%20fill-rule%3D%22evenodd%22%20%20stroke%3D%22rgb%28255%2C%20255%2C%20255%29%22%20stroke-width%3D%222px%22%20stroke-linecap%3D%22butt%22%20stroke-linejoin%3D%22miter%22%20fill%3D%22rgb%28255%2C%20255%2C%20255%29%22%0A%20d%3D%22M28.500%2C6.999%20C37.613%2C6.999%2045.001%2C14.387%2045.001%2C23.500%20C45.001%2C32.613%2028.500%2C50.000%2028.500%2C50.000%20C28.500%2C50.000%2012.000%2C32.613%2012.000%2C23.500%20C12.000%2C14.387%2019.388%2C6.999%2028.500%2C6.999%20Z%22/%3E%0A%3C/g%3E%0A%3C/svg%3E);
        background-position: center center;
        background-repeat: no-repeat;
        width: 59px;
        height: 70px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%); }
      .b__item-map .marker-thumb__photo {
        position: absolute;
        width: 33px;
        height: 33px;
        border-radius: 50%;
        background-color: #a81c30;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        left: 12px;
        top: 7px; }
  .b__item-container {
    background: white;
    margin-top: -25px;
    padding-bottom: 75px; }
    .b__item-container:after {
      content: "";
      display: block;
      clear: both; }
  .b__item-title {
    padding-top: 25px;
    padding-bottom: 15px;
    transition: color 300ms ease-out 0s; }
    .b__item-title:after {
      content: "";
      display: block;
      clear: both; }
    .b__item-title a.link--back {
      position: relative;
      display: inline-block;
      text-decoration: none;
      color: #333333;
      font-size: 18px;
      font-weight: 500; }
    .b__item-title a.link--item-share {
      display: inline-block;
      font-size: 14px;
      font-weight: 500;
      color: #bbbbbb;
      text-decoration: none;
      float: right;
      margin-top: 5px; }
  .b__item-images {
    position: relative;
    height: 428px;
    overflow: hidden; }
    .b__item-images_counter {
      position: absolute;
      left: 18px;
      bottom: 24px;
      user-select: none; }
      .b__item-images_counter .icon {
        fill: white;
        color: white;
        width: 29px;
        height: 25px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 4px; }
      .b__item-images_counter span {
        font-size: 16px;
        font-weight: bold;
        color: white;
        text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);
        display: inline-block;
        vertical-align: middle; }
    .b__item-images_zoom {
      position: absolute;
      width: 46px;
      height: 46px;
      background: white;
      border-radius: 50%;
      right: 17px;
      bottom: 17px;
      cursor: pointer;
      box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.05); }
      .b__item-images_zoom .icon {
        width: 21px;
        height: 20px;
        fill: #333333;
        color: #333333;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%); }
    .b__item-images .slick-list {
      border-radius: 4px 4px 0 0; }
    .b__item-images_carousel .carousel-item {
      display: block;
      text-decoration: none;
      height: 428px;
      cursor: zoom-in; }
      .b__item-images_carousel .carousel-item img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover; }
  .b__item-details {
    padding-top: 30px;
    padding-bottom: 45px; }
    .b__item-details:after {
      content: "";
      display: block;
      clear: both; }
    .b__item-details_description, .b__item-details_additional {
      width: calc(50% - 14px); }
    .b__item-details_description {
      float: left;
      padding: 0 18px;
      font-size: 13px;
      color: #333333;
      line-height: 1.846; }
    .b__item-details_additional {
      float: right;
      padding-right: 18px; }
      .b__item-details_additional:after {
        content: "";
        display: block;
        clear: both; }
    .b__item-details_meta {
      font-size: 12px;
      color: #999999;
      line-height: 1.2;
      text-align: right;
      padding-top: 11px; }
    .b__item-details_author {
      clear: both;
      padding-top: 25px; }
      .b__item-details_author:after {
        content: "";
        display: block;
        clear: both; }
      .b__item-details_author-photo {
        width: 72px;
        height: 72px;
        float: left;
        margin-right: 18px;
        overflow: hidden;
        border-radius: 50%;
        border: solid 1px rgba(0, 0, 0, 0.08); }
        .b__item-details_author-photo img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .b__item-details_author-details {
        width: calc(100% - 90px);
        float: right;
        padding: 5px 0 0; }
        .b__item-details_author-details > div {
          height: 22px; }
      .b__item-details_author-name {
        font-size: 16px;
        line-height: 22px; }
        .b__item-details_author-name a {
          text-decoration: none;
          color: inherit; }
      .b__item-details_author-meta {
        font-size: 12px;
        line-height: 24px; }
        .b__item-details_author-meta span {
          display: inline-block; }
          .b__item-details_author-meta span + span {
            margin-left: 14px; }
      .b__item-details_author-place {
        line-height: 24px;
        font-size: 12px;
        color: #999999; }
    .b__item-details_actions {
      margin-top: 20px; }
      .b__item-details_actions .btn {
        width: 100%;
        border-color: #3399ff;
        background-color: #3399ff;
        font-size: 14px;
        padding: 10px 0; }
        .b__item-details_actions .btn:hover {
          background-color: #3399ff; }

.b__items_item .item-title a {
  transition: color 240ms ease-out 0s; }
  .b__items_item .item-title a:hover {
    color: #227cd6; }

@media (max-width: 1199px) {
  .b__item {
    float: none;
    width: 100%;
    padding-right: 0;
    margin-bottom: 40px; }
    .b__item-map {
      width: 100% !important;
      height: 500px !important;
      margin-bottom: 0;
      float: none;
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto; } }

@media (max-width: 767px) {
  #comments, #comment-form-wrapper {
    width: 100% !important; }
  .b__item-comments {
    margin-top: 0 !important;
    padding: 0; }
  .b__item-map {
    height: 400px !important; }
  .b__item-images {
    height: 60vw; }
  .b__item-images_carousel .carousel-item {
    height: 60vw; }
  .b__item-details_description {
    float: none;
    padding: 0;
    width: 100%;
    margin-bottom: 30px; }
  .b__item-details_additional {
    float: none;
    padding: 0;
    width: 100%; } }

/* Users */
.b__users.b__users--grid:after {
  content: "";
  display: block;
  clear: both; }

.b__users.b__users--grid .b__users_item {
  text-align: center;
  margin-bottom: 50px; }
  .b__users.b__users--grid .b__users_item .user-pic {
    border-style: solid;
    border-width: 4px;
    border-color: rgba(255, 255, 255, 0.25);
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.25);
    width: 144px;
    height: 144px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden; }
    .b__users.b__users--grid .b__users_item .user-pic img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .b__users.b__users--grid .b__users_item .user-name {
    font-size: 18px;
    margin-top: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    text-align: center;
    display: block; }
    .b__users.b__users--grid .b__users_item .user-name a {
      color: inherit;
      text-decoration: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      text-align: center;
      display: block; }
  .b__users.b__users--grid .b__users_item .user-place {
    font-size: 12px;
    color: #999999;
    margin-top: 6px; }
  .b__users.b__users--grid .b__users_item .user-actions {
    margin-top: 10px; }
  .b__users.b__users--grid .b__users_item:hover .user-name a {
    color: #227cd6; }

.b__users.b__users--grid .b__users_item.b__users_item--more {
  border-style: solid;
  border-width: 4px;
  border-color: rgba(255, 255, 255, 0.25);
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.25);
  width: 144px;
  height: 144px;
  overflow: hidden;
  font-size: 18px;
  text-decoration: none;
  color: #333333; }
  .b__users.b__users--grid .b__users_item.b__users_item--more:before {
    content: '';
    display: inline-block;
    height: 105%;
    vertical-align: middle; }
  .b__users.b__users--grid .b__users_item.b__users_item--more > span {
    display: inline-block;
    vertical-align: middle; }
    .b__users.b__users--grid .b__users_item.b__users_item--more > span span {
      display: block;
      font-size: 12px;
      color: #999999; }

@media (min-width: 768px) and (max-width: 1199px) {
  .b__users.b__users--grid {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap; }
    .b__users.b__users--grid .b__users_item {
      width: 20%;
      height: auto;
      padding-left: 5px;
      padding-right: 5px; }
      .b__users.b__users--grid .b__users_item .user-pic {
        width: 100px;
        height: 100px; } }

@media (max-width: 767px) {
  .b__users.b__users--grid {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap; }
    .b__users.b__users--grid .b__users_item {
      width: 50%;
      height: auto;
      padding-left: 10px;
      padding-right: 10px; }
      .b__users.b__users--grid .b__users_item .user-pic {
        width: 100px;
        height: 100px; } }

.b__people {
  position: relative;
  overflow: hidden; }
  .b__people:after {
    content: "";
    display: block;
    clear: both; }
  .b__people:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    background-color: white;
    bottom: 0;
    z-index: -1;
    margin-left: -25%; }
    @media only screen and (min-width: 600px) and (max-width: 899px) {
      .b__people:before {
        content: none; } }
    @media only screen and (max-width: 599px) {
      .b__people:before {
        content: none; } }
  .b__people-sidebar {
    background-color: white;
    height: 100%; }
    @media only screen and (min-width: 900px) {
      .b__people-sidebar {
        width: calc(56px + ((100% - 308px) / 12 * 3)); } }
    @media only screen and (min-width: 600px) and (max-width: 899px) {
      .b__people-sidebar {
        width: auto;
        height: 150px !important;
        padding: 0 38px;
        margin: 0 -38px;
        float: none; } }
    @media only screen and (max-width: 599px) {
      .b__people-sidebar {
        width: auto;
        height: 150px !important;
        padding: 0 38px;
        margin: 0 -38px;
        float: none; } }
    .b__people-sidebar .b__title {
      text-align: left;
      padding-top: 40px; }
    .b__people-sidebar_form {
      margin-top: 30px; }
  .b__people-inner {
    width: calc(224px + ((100% - 308px) / 12 * 9));
    margin-right: 0;
    min-height: 700px;
    padding-top: 40px;
    padding-bottom: 50px; }
    @media only screen and (max-width: 599px) {
      .b__people-inner {
        width: 100%;
        float: none; } }
    @media only screen and (min-width: 600px) and (max-width: 899px) {
      .b__people-inner {
        width: 100%;
        float: none; } }

/* Plugins */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.slick-dots {
  text-align: center;
  font-size: 0;
  list-style: none;
  margin: 20px 0;
  padding: 0; }
  .slick-dots li {
    display: inline-block;
    overflow: hidden;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    width: 13px;
    height: 13px;
    margin: 0 5px; }
    .slick-dots li button {
      padding: 0;
      margin: 0;
      display: block;
      text-indent: -999px;
      opacity: 0;
      width: 100%;
      height: 100%; }
    .slick-dots li.slick-active {
      background: #227cd6;
      box-shadow: none; }

.selectize-control {
  cursor: pointer; }

.selectize-dropdown-content {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 200px;
  -webkit-overflow-scrolling: touch; }

/*
  
.wtf {
  .selectize-control.plugin-drag_drop.multi {
  	>.selectize-input {
  		>div.ui-sortable-placeholder {
  			visibility: visible !important;
  			background: #f2f2f2 !important;
  			background: rgba(0, 0, 0, 0.06) !important;
  			border: 0 none !important;
  			-webkit-box-shadow: inset 0 0 12px 4px #ffffff;
  			box-shadow: inset 0 0 12px 4px #ffffff;
  		}
  	}
  }
  .selectize-control.plugin-drag_drop {
  	.ui-sortable-placeholder {
  		&::after {
  			content: '!';
  			visibility: hidden;
  		}
  	}
  	.ui-sortable-helper {
  		-webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  		box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  	}
  }
  .selectize-dropdown-header {
  	position: relative;
  	padding: 5px 8px;
  	border-bottom: 1px solid #d0d0d0;
  	background: #f8f8f8;
  	-webkit-border-radius: 3px 3px 0 0;
  	-moz-border-radius: 3px 3px 0 0;
  	border-radius: 3px 3px 0 0;
  }
  .selectize-dropdown-header-close {
  	position: absolute;
  	right: 8px;
  	top: 50%;
  	color: #303030;
  	opacity: 0.4;
  	margin-top: -12px;
  	line-height: 20px;
  	font-size: 20px !important;
  	&:hover {
  		color: #000000;
  	}
  }
  .selectize-dropdown.plugin-optgroup_columns {
  	.optgroup {
  		border-right: 1px solid #f2f2f2;
  		border-top: 0 none;
  		float: left;
  		-webkit-box-sizing: border-box;
  		-moz-box-sizing: border-box;
  		box-sizing: border-box;
  		&:last-child {
  			border-right: 0 none;
  		}
  		&:before {
  			display: none;
  		}
  	}
  	.optgroup-header {
  		border-top: 0 none;
  	}
  }
  .selectize-control.plugin-remove_button {
  	[data-value] {
  		position: relative;
  		padding-right: 24px !important;
  		.remove {
  			z-index: 1;
  			position: absolute;
  			top: 0;
  			right: 0;
  			bottom: 0;
  			width: 17px;
  			text-align: center;
  			font-weight: bold;
  			font-size: 12px;
  			color: inherit;
  			text-decoration: none;
  			vertical-align: middle;
  			display: inline-block;
  			padding: 2px 0 0 0;
  			border-left: 1px solid #d0d0d0;
  			-webkit-border-radius: 0 2px 2px 0;
  			-moz-border-radius: 0 2px 2px 0;
  			border-radius: 0 2px 2px 0;
  			-webkit-box-sizing: border-box;
  			-moz-box-sizing: border-box;
  			box-sizing: border-box;
  			&:hover {
  				background: rgba(0, 0, 0, 0.05);
  			}
  		}
  	}
  	[data-value].active {
  		.remove {
  			border-left-color: #cacaca;
  		}
  	}
  	.disabled {
  		[data-value] {
  			.remove {
  				&:hover {
  					background: none;
  				}
  				border-left-color: #ffffff;
  			}
  		}
  	}
  	.remove-single {
  		position: absolute;
  		right: 28px;
  		top: 6px;
  		font-size: 23px;
  	}
  }
  .selectize-control {
  	position: relative;
  	.selectize-input.disabled {
  		opacity: 0.5;
  		background-color: #fafafa;
  	}
  }
  .selectize-dropdown {
  	color: #303030;
  	font-family: inherit;
  	font-size: 13px;
  	line-height: 18px;
  	-webkit-font-smoothing: inherit;
  	position: absolute;
  	z-index: 10;
  	border: 1px solid #d0d0d0;
  	background: #ffffff;
  	margin: -1px 0 0 0;
  	border-top: 0 none;
  	-webkit-box-sizing: border-box;
  	-moz-box-sizing: border-box;
  	box-sizing: border-box;
  	-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  	-webkit-border-radius: 0 0 3px 3px;
  	-moz-border-radius: 0 0 3px 3px;
  	border-radius: 0 0 3px 3px;
  	[data-selectable] {
  		cursor: pointer;
  		overflow: hidden;
  		padding: 5px 8px;
  		.highlight {
  			background: rgba(125, 168, 208, 0.2);
  			-webkit-border-radius: 1px;
  			-moz-border-radius: 1px;
  			border-radius: 1px;
  		}
  	}
  	.optgroup-header {
  		padding: 5px 8px;
  		color: #303030;
  		background: #ffffff;
  		cursor: default;
  	}
  	.optgroup {
  		&:first-child {
  			.optgroup-header {
  				border-top: 0 none;
  			}
  		}
  	}
  	.active {
  		background-color: #f5fafd;
  		color: #495c68;
  	}
  	.active.create {
  		color: #495c68;
  	}
  	.create {
  		color: rgba(48, 48, 48, 0.5);
  	}
  }
  .selectize-input {
  	color: #303030;
  	font-family: inherit;
  	font-size: 13px;
  	line-height: 18px;
  	-webkit-font-smoothing: inherit;
  	background: #ffffff;
  	cursor: text;
  	display: inline-block;
  	border: 1px solid #d0d0d0;
  	padding: 8px 8px;
  	display: inline-block;
  	width: 100%;
  	overflow: hidden;
  	position: relative;
  	z-index: 1;
  	-webkit-box-sizing: border-box;
  	-moz-box-sizing: border-box;
  	box-sizing: border-box;
  	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
  	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
  	-webkit-border-radius: 3px;
  	-moz-border-radius: 3px;
  	border-radius: 3px;
  	input {
  		color: #303030;
  		font-family: inherit;
  		font-size: 13px;
  		line-height: 18px;
  		-webkit-font-smoothing: inherit;
  	}
  	>* {
  		vertical-align: baseline;
  		display: -moz-inline-stack;
  		display: inline-block;
  		zoom: 1;
  		*display: inline;
  	}
  	>input {
  		display: inline-block !important;
  		padding: 0 !important;
  		min-height: 0 !important;
  		max-height: none !important;
  		max-width: 100% !important;
  		margin: 0 2px 0 0 !important;
  		text-indent: 0 !important;
  		border: 0 none !important;
  		background: none !important;
  		line-height: inherit !important;
  		-webkit-user-select: auto !important;
  		-webkit-box-shadow: none !important;
  		box-shadow: none !important;
  		&::-ms-clear {
  			display: none;
  		}
  		&:focus {
  			outline: none !important;
  		}
  	}
  	&::after {
  		content: ' ';
  		display: block;
  		clear: left;
  	}
  }
  .selectize-control.single {
  	.selectize-input.input-active {
  		background: #ffffff;
  		cursor: text;
  		display: inline-block;
  		cursor: text;
  		input {
  			cursor: text;
  		}
  	}
  	.selectize-input {
  		cursor: pointer;
  		input {
  			cursor: pointer;
  		}
  		&:after {
  			content: ' ';
  			display: block;
  			position: absolute;
  			top: 50%;
  			right: 15px;
  			margin-top: -3px;
  			width: 0;
  			height: 0;
  			border-style: solid;
  			border-width: 5px 5px 0 5px;
  			border-color: #808080 transparent transparent transparent;
  		}
  	}
  	.selectize-input.dropdown-active {
  		&:after {
  			margin-top: -4px;
  			border-width: 0 5px 5px 5px;
  			border-color: transparent transparent #808080 transparent;
  		}
  	}
  }
  .selectize-control.multi {
  	.selectize-input.has-items {
  		padding: 6px 8px 3px;
  	}
  	.selectize-input {
  		>div {
  			cursor: pointer;
  			margin: 0 3px 3px 0;
  			padding: 2px 6px;
  			background: #f2f2f2;
  			color: #303030;
  			border: 0 solid #d0d0d0;
  		}
  		>div.active {
  			background: #e8e8e8;
  			color: #303030;
  			border: 0 solid #cacaca;
  		}
  	}
  	.selectize-input.disabled {
  		>div {
  			color: #7d7d7d;
  			background: #ffffff;
  			border: 0 solid #ffffff;
  		}
  		>div.active {
  			color: #7d7d7d;
  			background: #ffffff;
  			border: 0 solid #ffffff;
  		}
  	}
  }
  .selectize-input.full {
  	background-color: #ffffff;
  }
  .selectize-input.disabled {
  	cursor: default !important;
  	* {
  		cursor: default !important;
  	}
  }
  .selectize-input.focus {
  	-webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15);
  	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15);
  }
  .selectize-input.dropdown-active {
  	-webkit-border-radius: 3px 3px 0 0;
  	-moz-border-radius: 3px 3px 0 0;
  	border-radius: 3px 3px 0 0;
  	&::before {
  		content: ' ';
  		display: block;
  		position: absolute;
  		background: #f0f0f0;
  		height: 1px;
  		bottom: 0;
  		left: 0;
  		right: 0;
  	}
  }
  .selectize-dropdown-content {
  	overflow-y: auto;
  	overflow-x: hidden;
  	max-height: 200px;
  	-webkit-overflow-scrolling: touch;
  }
  .selectize-control.rtl.single {
  	.selectize-input {
  		&:after {
  			left: 15px;
  			right: auto;
  		}
  	}
  }
  .selectize-control.rtl {
  	.selectize-input {
  		>input {
  			margin: 0 4px 0 -2px !important;
  		}
  	}
  }
}

*/
body.compensate-for-scrollbar {
  overflow: hidden; }

.fancybox-active {
  height: auto; }

.fancybox-is-hidden {
  left: -9999px;
  margin: 0;
  position: absolute !important;
  top: -9999px;
  visibility: hidden; }

.fancybox-container {
  backface-visibility: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  height: 100%;
  left: 0;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  transform: translateZ(0);
  width: 100%;
  z-index: 99992; }

.fancybox-container * {
  box-sizing: border-box; }

.fancybox-outer,
.fancybox-inner,
.fancybox-bg,
.fancybox-stage {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0; }

.fancybox-outer {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto; }

.fancybox-bg {
  background: #1e1e1e;
  opacity: 0;
  transition-duration: inherit;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71); }

.fancybox-is-open .fancybox-bg {
  opacity: .87;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1); }

.fancybox-infobar,
.fancybox-toolbar,
.fancybox-caption,
.fancybox-navigation .fancybox-button {
  direction: ltr;
  opacity: 0;
  position: absolute;
  transition: opacity .25s, visibility 0s linear .25s;
  visibility: hidden;
  z-index: 99997; }

.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-toolbar .fancybox-toolbar,
.fancybox-show-caption .fancybox-caption,
.fancybox-show-nav .fancybox-navigation .fancybox-button {
  opacity: 1;
  transition: opacity .25s, visibility 0s;
  visibility: visible; }

.fancybox-infobar {
  color: #ccc;
  font-size: 13px;
  -webkit-font-smoothing: subpixel-antialiased;
  height: 44px;
  left: 0;
  line-height: 44px;
  min-width: 44px;
  mix-blend-mode: difference;
  padding: 0 10px;
  pointer-events: none;
  text-align: center;
  top: 0;
  -webkit-touch-callout: none;
  user-select: none; }

.fancybox-toolbar {
  right: 0;
  top: 0; }

.fancybox-stage {
  direction: ltr;
  overflow: visible;
  -webkit-transform: translate3d(0, 0, 0);
  z-index: 99994; }

.fancybox-is-open .fancybox-stage {
  overflow: hidden; }

.fancybox-slide {
  backface-visibility: hidden;
  display: none;
  height: 100%;
  left: 0;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: absolute;
  text-align: center;
  top: 0;
  transition-property: transform, opacity;
  white-space: normal;
  width: 100%;
  z-index: 99994; }

.fancybox-slide::before {
  content: '';
  display: inline-block;
  height: 100%;
  margin-right: -.25em;
  vertical-align: middle;
  width: 0; }

.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--previous,
.fancybox-slide--current,
.fancybox-slide--next {
  display: block; }

.fancybox-slide--next {
  z-index: 99995; }

.fancybox-slide--image {
  overflow: visible;
  padding: 44px 0; }

.fancybox-slide--image::before {
  display: none; }

.fancybox-slide--html {
  padding: 6px 6px 0 6px; }

.fancybox-slide--iframe {
  padding: 44px 44px 0; }

.fancybox-content {
  background: #fff;
  display: inline-block;
  margin: 0 0 6px 0;
  max-width: 100%;
  overflow: auto;
  padding: 0;
  padding: 24px;
  position: relative;
  text-align: left;
  vertical-align: middle; }

.fancybox-slide--image .fancybox-content {
  animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  backface-visibility: hidden;
  background: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: 0;
  margin: 0;
  max-width: none;
  overflow: visible;
  padding: 0;
  position: absolute;
  top: 0;
  transform-origin: top left;
  transition-property: transform, opacity;
  user-select: none;
  z-index: 99995; }

.fancybox-can-zoomOut .fancybox-content {
  cursor: zoom-out; }

.fancybox-can-zoomIn .fancybox-content {
  cursor: zoom-in; }

.fancybox-can-drag .fancybox-content {
  cursor: grab; }

.fancybox-is-dragging .fancybox-content {
  cursor: grabbing; }

.fancybox-container [data-selectable='true'] {
  cursor: text; }

.fancybox-image,
.fancybox-spaceball {
  background: transparent;
  border: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none;
  padding: 0;
  position: absolute;
  top: 0;
  user-select: none;
  width: 100%; }

.fancybox-spaceball {
  z-index: 1; }

.fancybox-slide--html .fancybox-content {
  margin-bottom: 6px; }

.fancybox-slide--video .fancybox-content,
.fancybox-slide--map .fancybox-content,
.fancybox-slide--iframe .fancybox-content {
  height: 100%;
  margin: 0;
  overflow: visible;
  padding: 0;
  width: 100%; }

.fancybox-slide--video .fancybox-content {
  background: #000; }

.fancybox-slide--map .fancybox-content {
  background: #e5e3df; }

.fancybox-slide--iframe .fancybox-content {
  background: #fff;
  height: calc(100% - 44px);
  margin-bottom: 44px; }

.fancybox-video,
.fancybox-iframe {
  background: transparent;
  border: 0;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%; }

.fancybox-iframe {
  vertical-align: top; }

.fancybox-error {
  background: #fff;
  cursor: default;
  max-width: 400px;
  padding: 40px;
  width: 100%; }

.fancybox-error p {
  color: #444;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0; }

/* Buttons */
.fancybox-button {
  background: rgba(30, 30, 30, 0.6);
  border: 0;
  border-radius: 0;
  cursor: pointer;
  display: inline-block;
  height: 44px;
  margin: 0;
  outline: none;
  padding: 10px;
  transition: color .2s;
  vertical-align: top;
  width: 44px; }

.fancybox-button,
.fancybox-button:visited,
.fancybox-button:link {
  color: #ccc; }

.fancybox-button:focus,
.fancybox-button:hover {
  color: #fff; }

.fancybox-button.disabled,
.fancybox-button.disabled:hover,
.fancybox-button[disabled],
.fancybox-button[disabled]:hover {
  color: #888;
  cursor: default; }

.fancybox-button svg {
  display: block;
  overflow: visible;
  position: relative;
  shape-rendering: geometricPrecision; }

.fancybox-button svg path {
  fill: transparent;
  stroke: currentColor;
  stroke-linejoin: round;
  stroke-width: 3; }

.fancybox-button--play svg path:nth-child(2) {
  display: none; }

.fancybox-button--pause svg path:nth-child(1) {
  display: none; }

.fancybox-button--play svg path,
.fancybox-button--share svg path,
.fancybox-button--thumbs svg path {
  fill: currentColor; }

.fancybox-button--share svg path {
  stroke-width: 1; }

/* Navigation arrows */
.fancybox-navigation .fancybox-button {
  height: 38px;
  opacity: 0;
  padding: 6px;
  position: absolute;
  top: 50%;
  width: 38px; }

.fancybox-show-nav .fancybox-navigation .fancybox-button {
  transition: opacity .25s, visibility 0s, color .25s; }

.fancybox-navigation .fancybox-button::after {
  content: '';
  left: -25px;
  padding: 50px;
  position: absolute;
  top: -25px; }

.fancybox-navigation .fancybox-button--arrow_left {
  left: 6px; }

.fancybox-navigation .fancybox-button--arrow_right {
  right: 6px; }

/* Close button on the top right corner of html content */
.fancybox-close-small {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: #555;
  cursor: pointer;
  height: 44px;
  margin: 0;
  padding: 6px;
  position: absolute;
  right: 0;
  top: 0;
  width: 44px;
  z-index: 10; }

.fancybox-close-small svg {
  fill: transparent;
  opacity: .8;
  stroke: currentColor;
  stroke-width: 1.5;
  transition: stroke .1s; }

.fancybox-close-small:focus {
  outline: none; }

.fancybox-close-small:hover svg {
  opacity: 1; }

.fancybox-slide--image .fancybox-close-small,
.fancybox-slide--video .fancybox-close-small,
.fancybox-slide--iframe .fancybox-close-small {
  color: #ccc;
  padding: 5px;
  right: -12px;
  top: -44px; }

.fancybox-slide--image .fancybox-close-small:hover svg,
.fancybox-slide--video .fancybox-close-small:hover svg,
.fancybox-slide--iframe .fancybox-close-small:hover svg {
  background: transparent;
  color: #fff; }

.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-drag .fancybox-close-small {
  display: none; }

/* Caption */
.fancybox-caption {
  bottom: 0;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  line-height: 1.5;
  padding: 25px 44px 25px 44px;
  right: 0; }

.fancybox-caption::before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAEtCAQAAABjBcL7AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAHRJREFUKM+Vk8EOgDAIQ0vj/3+xBw8qIZZueFnIKC90MCAI8DlrkHGeqqGIU6lVigrBtpCWqeRWoHDNqs0F7VNVBVxmHRlvoVqjaYkdnDIaivH2HqZ5+oZj3JUzWB+cOz4G48Bg+tsJ/tqu4dLC/4Xb+0GcF5BwBC0AA53qAAAAAElFTkSuQmCC);
  background-repeat: repeat-x;
  background-size: contain;
  bottom: 0;
  content: '';
  display: block;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: -25px;
  z-index: -1; }

.fancybox-caption::after {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  content: '';
  display: block;
  left: 44px;
  position: absolute;
  right: 44px;
  top: 0; }

.fancybox-caption a,
.fancybox-caption a:link,
.fancybox-caption a:visited {
  color: #ccc;
  text-decoration: none; }

.fancybox-caption a:hover {
  color: #fff;
  text-decoration: underline; }

/* Loading indicator */
.fancybox-loading {
  animation: fancybox-rotate .8s infinite linear;
  background: transparent;
  border: 6px solid rgba(100, 100, 100, 0.5);
  border-radius: 100%;
  border-top-color: #fff;
  height: 60px;
  left: 50%;
  margin: -30px 0 0 -30px;
  opacity: .6;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 60px;
  z-index: 99999; }

@keyframes fancybox-rotate {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }

/* Transition effects */
.fancybox-animated {
  transition-timing-function: cubic-bezier(0, 0, 0.25, 1); }

/* transitionEffect: slide */
.fancybox-fx-slide.fancybox-slide--previous {
  opacity: 0;
  transform: translate3d(-100%, 0, 0); }

.fancybox-fx-slide.fancybox-slide--next {
  opacity: 0;
  transform: translate3d(100%, 0, 0); }

.fancybox-fx-slide.fancybox-slide--current {
  opacity: 1;
  transform: translate3d(0, 0, 0); }

/* transitionEffect: fade */
.fancybox-fx-fade.fancybox-slide--previous,
.fancybox-fx-fade.fancybox-slide--next {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }

.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1; }

/* transitionEffect: zoom-in-out */
.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(1.5, 1.5, 1.5); }

.fancybox-fx-zoom-in-out.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5); }

.fancybox-fx-zoom-in-out.fancybox-slide--current {
  opacity: 1;
  transform: scale3d(1, 1, 1); }

/* transitionEffect: rotate */
.fancybox-fx-rotate.fancybox-slide--previous {
  opacity: 0;
  transform: rotate(-360deg); }

.fancybox-fx-rotate.fancybox-slide--next {
  opacity: 0;
  transform: rotate(360deg); }

.fancybox-fx-rotate.fancybox-slide--current {
  opacity: 1;
  transform: rotate(0deg); }

/* transitionEffect: circular */
.fancybox-fx-circular.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0); }

.fancybox-fx-circular.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0); }

.fancybox-fx-circular.fancybox-slide--current {
  opacity: 1;
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0); }

/* transitionEffect: tube */
.fancybox-fx-tube.fancybox-slide--previous {
  transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg); }

.fancybox-fx-tube.fancybox-slide--next {
  transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg); }

.fancybox-fx-tube.fancybox-slide--current {
  transform: translate3d(0, 0, 0) scale(1); }

.fancybox-button--fullscreen::before {
  width: 15px;
  height: 11px;
  left: calc(50% - 7px);
  top: calc(50% - 6px);
  border: 2px solid;
  background: none; }

/* Share */
.fancybox-share {
  background: #f4f4f4;
  border-radius: 3px;
  max-width: 90%;
  padding: 30px;
  text-align: center; }

.fancybox-share h1 {
  color: #222;
  font-size: 35px;
  font-weight: 700;
  margin: 0 0 20px 0; }

.fancybox-share p {
  margin: 0;
  padding: 0; }

.fancybox-share__button {
  border: 0;
  border-radius: 3px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  margin: 0 5px 10px 5px;
  min-width: 130px;
  padding: 0 15px;
  text-decoration: none;
  transition: all .2s;
  user-select: none;
  white-space: nowrap; }

.fancybox-share__button:visited,
.fancybox-share__button:link {
  color: #fff; }

.fancybox-share__button:hover {
  text-decoration: none; }

.fancybox-share__button--fb {
  background: #3b5998; }

.fancybox-share__button--fb:hover {
  background: #344e86; }

.fancybox-share__button--pt {
  background: #bd081d; }

.fancybox-share__button--pt:hover {
  background: #aa0719; }

.fancybox-share__button--tw {
  background: #1da1f2; }

.fancybox-share__button--tw:hover {
  background: #0d95e8; }

.fancybox-share__button svg {
  height: 25px;
  margin-right: 7px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 25px; }

.fancybox-share__button svg path {
  fill: #fff; }

.fancybox-share__input {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 0;
  color: #5d5b5b;
  font-size: 14px;
  margin: 10px 0 0 0;
  outline: none;
  padding: 10px 15px;
  width: 100%; }

/* Slideshow button */
.fancybox-button--play::before,
.fancybox-button--pause::before {
  top: calc(50% - 6px);
  left: calc(50% - 4px);
  background: transparent; }

.fancybox-button--play::before {
  width: 0;
  height: 0;
  border-top: 6px inset transparent;
  border-bottom: 6px inset transparent;
  border-left: 10px solid;
  border-radius: 1px; }

.fancybox-button--pause::before {
  width: 7px;
  height: 11px;
  border-style: solid;
  border-width: 0 2px 0 2px; }

/* Thumbs */
.fancybox-thumbs {
  background: #fff;
  bottom: 0;
  display: none;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 2px 2px 4px 2px;
  position: absolute;
  right: 0;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  width: 212px;
  z-index: 99995; }

.fancybox-thumbs-x {
  overflow-x: auto;
  overflow-y: hidden; }

.fancybox-show-thumbs .fancybox-thumbs {
  display: block; }

.fancybox-show-thumbs .fancybox-inner {
  right: 212px; }

.fancybox-thumbs > ul {
  font-size: 0;
  height: 100%;
  list-style: none;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  position: relative;
  white-space: nowrap;
  width: 100%; }

.fancybox-thumbs-x > ul {
  overflow: hidden; }

.fancybox-thumbs-y > ul::-webkit-scrollbar {
  width: 7px; }

.fancybox-thumbs-y > ul::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }

.fancybox-thumbs-y > ul::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  border-radius: 10px; }

.fancybox-thumbs > ul > li {
  backface-visibility: hidden;
  cursor: pointer;
  float: left;
  height: 75px;
  margin: 2px;
  max-height: calc(100% - 8px);
  max-width: calc(50% - 4px);
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  width: 100px; }

.fancybox-thumbs-loading {
  background: rgba(0, 0, 0, 0.1); }

.fancybox-thumbs > ul > li {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; }

.fancybox-thumbs > ul > li:before {
  border: 4px solid #4ea7f9;
  bottom: 0;
  content: '';
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 99991; }

.fancybox-thumbs .fancybox-thumbs-active:before {
  opacity: 1; }

/* Styling for Small-Screen Devices */
@media all and (max-width: 800px) {
  .fancybox-thumbs {
    width: 110px; }
  .fancybox-show-thumbs .fancybox-inner {
    right: 110px; }
  .fancybox-thumbs > ul > li {
    max-width: calc(100% - 10px); } }

/*
 * Container style
 */
.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto; }

/*
 * Scrollbar rail styles
 */
.ps__rail-x {
  display: none;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  height: 15px;
  /* there must be 'bottom' or 'top' for ps__rail-x */
  bottom: 0px;
  /* please don't change 'position' */
  position: absolute; }

.ps__rail-y {
  display: none;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  width: 15px;
  /* there must be 'right' or 'left' for ps__rail-y */
  right: 0;
  left: auto !important;
  /* please don't change 'position' */
  position: absolute; }

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  display: block;
  background-color: transparent; }

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  opacity: 0.6; }

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background-color: #eee;
  opacity: 0.9; }

/*
 * Scrollbar thumb styles
 */
.ps__thumb-x {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color .2s linear, height .2s ease-in-out;
  -webkit-transition: background-color .2s linear, height .2s ease-in-out;
  height: 6px;
  /* there must be 'bottom' for ps__thumb-x */
  bottom: 2px;
  /* please don't change 'position' */
  position: absolute;
  cursor: move; }

.ps__thumb-y {
  cursor: move;
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color .2s linear, width .2s ease-in-out;
  -webkit-transition: background-color .2s linear, width .2s ease-in-out;
  width: 6px;
  /* there must be 'right' for ps__thumb-y */
  right: 2px;
  /* please don't change 'position' */
  position: absolute; }

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
  background-color: #999;
  height: 11px; }

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #999;
  width: 11px; }

/* MS supports */
@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important; } }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important; } }

/* Footer */
footer#layout-footer {
  background-color: white;
  padding-top: 50px;
  text-align: center; }
  .page-messenger footer#layout-footer {
    display: none; }
  footer#layout-footer .footer__signup {
    padding-top: 5px;
    padding-bottom: 50px; }
    footer#layout-footer .footer__signup .btn {
      display: inline-block;
      font-size: 14px;
      min-width: 0;
      padding-left: 30px;
      padding-right: 30px; }
  footer#layout-footer .footer-logo {
    margin-bottom: 15px; }
  footer#layout-footer .footer-menu.footer-menu--categories {
    margin-bottom: 8px; }
  footer#layout-footer .footer-menu a {
    display: inline-block;
    font-size: 12px;
    text-decoration: none;
    color: rgba(72, 72, 72, 0.5);
    margin: 0 8px;
    padding-top: 9px;
    padding-bottom: 8px;
    vertical-align: middle; }
    footer#layout-footer .footer-menu a.link--bordered {
      padding-left: 24px;
      padding-right: 24px;
      border: solid 1px rgba(72, 72, 72, 0.5);
      border-radius: 4px; }
      footer#layout-footer .footer-menu a.link--bordered:hover {
        border: solid 1px #484848; }
    footer#layout-footer .footer-menu a:hover {
      color: #484848; }
  footer#layout-footer .footer-social {
    margin-top: 18px;
    margin-bottom: 8px; }
    footer#layout-footer .footer-social a {
      display: inline-block;
      text-decoration: none;
      vertical-align: middle;
      margin-left: 14px;
      margin-right: 14px; }
      footer#layout-footer .footer-social a:hover svg {
        fill: #484848;
        color: #484848; }
    footer#layout-footer .footer-social svg {
      height: 16px;
      width: 16px;
      fill: rgba(72, 72, 72, 0.5);
      color: rgba(72, 72, 72, 0.5); }
  footer#layout-footer .footer-copyrights {
    padding-top: 18px;
    padding-bottom: 28px;
    font-size: 12px;
    color: rgba(72, 72, 72, 0.25); }

@media (max-width: 767.98px) {
  footer#layout-footer .footer-menu a.link--bordered:last-child {
    width: 190px;
    margin-left: calc((100vw - 220px) / 2);
    margin-right: calc((100vw - 220px) / 2); } }
