.b__universities {
	background-color: white;
	padding-bottom: 80px;
	padding-top: 55px;
	
	@include media(phone-only) { 
		padding-top: 30px;
		padding-bottom: 40px;
	}

	.universities {
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
		margin-left: -14px;
		margin-right: -14px;
		flex-wrap: wrap;
	}

	
	.university {
		width: calc(25% - 28px);
		margin-left: 14px;
		margin-right: 14px;
		border: solid 2px #f9f9f9;
		border-radius: 3px;
		margin-top: 20px;
		margin-bottom: 5px;
		text-align: center;
		padding-bottom: 22px;
		text-decoration: none;
		transition: border-color 250ms ease-out 0s;

		@include media(phone-only) {
			width: 100%;
			margin-left: 0;
			margin-right: 0;
			
		}


		@include media(tablet-p-only) {
			width: calc(50% - 28px);
		}


		&:hover {
			border-color: #e5e5e5;
		}

		&-name {
			font-family: $font__special;
			font-size: 28px;
			margin-top: 35px;
			margin-bottom: 28px;
		}

		&-counter {
			font-size: 14px;
			color: $color__main_gray;
			padding-bottom: 2px;
		}

		&-sub {
			font-size: 12px;
			color: #cccccc;
		}
	}
}

.university-name {
	.university--yale & {
		color: #084b85;
	}

	.university--harvard & {
		color: #a61b2f;
	}

	.university--cornell & {
		color: #b72d32;
	}

	.university--princeton & {
		color: #ff6600;
	}

	.university--darmouth & {
		color: #006132;
	}

	.university--penn & {
		color: #2b437b;
	}

	.university--brown & {
		color: #d50000;
	}

	.university--columbia & {
		color: #0055a7;
	}
}

.item-university {
	&.item-university--yale-university {
		background-color: #084b85;
	}

	&.item-university--yale {
		background-color: #084b85;
	}

	&.item-university--harvard-university {
		background-color: #a61b2f;
	}

	&.item-university--harvard {
		background-color: #a61b2f;
	}

	&.item-university--cornell-university {
		background-color: #b72d32;
	}

	&.item-university--cornell {
		background-color: #b72d32;
	}

	&.item-university--princeton-university {
		background-color: #ff6600;
	}

	&.item-university--princeton {
		background-color: #ff6600;
	}

	&.item-university--darmouth-university {
		background-color: #006132;
	}

	&.item-university--darmouth {
		background-color: #006132;
	}

	&.item-university--penn-university {
		background-color: #2b437b;
	}

	&.item-university--penn {
		background-color: #2b437b;
	}

	&.item-university--brown-university {
		background-color: #d50000;
	}

	&.item-university--brown {
		background-color: #d50000;
	}

	&.item-university--columbia-university {
		background-color: #0055a7;
	}

	&.item-university--columbia {
		background-color: #0055a7;
	}
}